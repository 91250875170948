import {
  openMsgModal,
  openSubModal,
  getMasterName,
  formatYYYYMMDD,
  formatHHmmss,
  displayYYYYYMMDDHHmm,
  formatYYYYYMMDDHHmm,
} from '@/common/Common.js'
import {
  MSG_TYPE,
  DETAIL_SCREEN_KBN,
  PLANNING_STATUS,
  MASTER_CODE,
  CHG_TCH_STATUS,
  DATE_DIFF_PERIOD,
  VISIBILITY,
} from '@/common/const.js'
import VehicleOperationService from './VehicleOperationService'
import _ from 'lodash'

export function init(obj) {
  if (obj.screenKbn != DETAIL_SCREEN_KBN.NEW) {
    obj.$store.dispatch('init/setServiceLoading')
    VehicleOperationService.get(obj.vehicleOperationId)
      .then(
        (response) => {
          obj.VehicleOperation = response.data
          if (obj.screenKbn == DETAIL_SCREEN_KBN.COPY) {
            // コピーの場合は計画開始日時、計画開始場所、計画終了日時、計画終了場所をクリアする
            obj.VehicleOperation.planBeginTime = ''
            obj.VehicleOperation.planBeginPlaceId = null
            obj.VehicleOperation.planBeginPlaceName = ''
            obj.VehicleOperation.planEndTime = ''
            obj.VehicleOperation.planEndPlaceId = null
            obj.VehicleOperation.planEndPlaceName = ''
            // コピーの場合は配車ステータスを[未配車]に設定する
            // ここで未配車とすることで、表示項目・機能ボタンの非活性条件が
            // 未配車の稼働情報を選択し、「編集」ボタンで遷移した場合と同じになる
            obj.VehicleOperation.planningStatus = PLANNING_STATUS.NOT_ASSIGNED
            obj.VehicleOperation.planningStatusName = getMasterName(
              MASTER_CODE.PLANNING_STATUS,
              PLANNING_STATUS.NOT_ASSIGNED
            )
            // 変更通知ステータスを[無し]に設定
            obj.VehicleOperation.changeNoticeStatus = CHG_TCH_STATUS.NASI
            obj.VehicleOperation.changeNoticeStatusName = getMasterName(
              MASTER_CODE.CHANGE_NOTICE_STATUS,
              CHG_TCH_STATUS.NASI
            )
            obj.VehicleOperation.vehicleOperationId = null

            obj.VehicleOperation.registrationTimeDisplay = null
            obj.VehicleOperation.registrationUser = null
            obj.VehicleOperation.updateTimeDisplay = null
            obj.VehicleOperation.updateUser = null
          } else {
            obj.VehicleOperation.planBeginTime = displayYYYYYMMDDHHmm(
              obj,
              obj.VehicleOperation.planBeginTime
            )
            obj.VehicleOperation.planEndTime = displayYYYYYMMDDHHmm(
              obj,
              obj.VehicleOperation.planEndTime
            )
          }
          obj.oriData = _.cloneDeep(obj.VehicleOperation)
        },
        (e) => {
          // エラーモーダル表示
          var msg
          if (e.response == undefined) {
            msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
          } else if (e.response.status == 404) {
            msg = obj.$store.getters['init/getMessage']('YZ00MG996E')
          } else {
            msg = e.response.data.message
          }
          openMsgModal(obj, MSG_TYPE.E, msg)
        }
      )
      .finally(() => {
        obj.$store.dispatch('init/setServiceUnLoading')
      })
  } else {
    // 新規時の表示項目・機能ボタンの非活性条件は、未配車の稼働情報を選択し、「編集」ボタンで遷移した場合と同じになる
    obj.VehicleOperation.planningStatus = PLANNING_STATUS.NOT_ASSIGNED
    obj.VehicleOperation.planningStatusName = getMasterName(
      MASTER_CODE.PLANNING_STATUS,
      PLANNING_STATUS.NOT_ASSIGNED
    )
    // 変更通知ステータスを[無し]に設定
    obj.VehicleOperation.changeNoticeStatus = CHG_TCH_STATUS.NASI
    obj.VehicleOperation.changeNoticeStatusName = getMasterName(
      MASTER_CODE.CHANGE_NOTICE_STATUS,
      CHG_TCH_STATUS.NASI
    )
    // 削除区分
    obj.VehicleOperation.deleted = 0

    obj.oriData = _.cloneDeep(obj.VehicleOperation)
  }
}

export function showAreaModal(obj, num) {
  if (obj.VehicleOperation.chargeKind == null) {
    openMsgModal(
      obj,
      MSG_TYPE.E,
      obj.$store.getters['init/getMessage']('EE01S002_MG006E')
    )
    return
  } else {
    obj.areaModalSearchCondition = {}
    obj.areaModalSearchCondition.areaKind = obj.VehicleOperation.chargeKind
  }
  obj.btnClickChargeAreaId = num
  obj.openSubModal(obj, 'area-modal')
}

export async function goRegister(obj) {
  const validated = await obj.$refs['tab-group'].$refs[
    'base-tab-group'
  ].validate()
  if (!validated) return

  obj.registerMessage = obj.$store.getters['init/getMessage'](
    'YZ00MG994Q',
    obj.detailCtrlInfo.register.label
  )
  openSubModal(obj, 'register-modal')
}

export async function register(obj) {
  obj.$bvModal.hide('register-modal')

  const validated = await obj.$refs['tab-group'].$refs[
    'base-tab-group'
  ].validate()
  if (!validated) return

  // 予定開始日・予定終了日の整合性チェック
  if ((await checkLogical(obj)) == false) {
    // 期間日付の入力に不整合があります。
    openMsgModal(
      obj,
      MSG_TYPE.E,
      obj.$store.getters['init/getMessage']('YZ00MG992E')
    )

    return
  }

  // ガント排他テーブルを検索し、ガント編集中の場合は登録エラーとする
  if ((await checkRelation(obj)) == false) {
    return
  }

  obj.VehicleOperation.scheduleBeginDate = formatYYYYMMDD(
    obj,
    obj.VehicleOperation.scheduleBeginDate
  )
  obj.VehicleOperation.scheduleBeginTime = formatHHmmss(
    obj.VehicleOperation.scheduleBeginTime
  )
  obj.VehicleOperation.scheduleEndDate = formatYYYYMMDD(
    obj,
    obj.VehicleOperation.scheduleEndDate
  )
  obj.VehicleOperation.scheduleEndTime = formatHHmmss(
    obj.VehicleOperation.scheduleEndTime
  )
  obj.VehicleOperation.planBeginTime = formatYYYYYMMDDHHmm(
    obj.VehicleOperation.planBeginTime
  )
  obj.VehicleOperation.planEndTime = formatYYYYYMMDDHHmm(
    obj.VehicleOperation.planEndTime
  )

  var callApi
  if (obj.screenKbn != DETAIL_SCREEN_KBN.DETAIL) {
    // 新規
    callApi = VehicleOperationService.create(obj.VehicleOperation)
  } else {
    // 詳細
    callApi = VehicleOperationService.update(
      obj.vehicleOperationId,
      obj.VehicleOperation
    )
  }
  obj.$store.dispatch('init/setServiceLoading')
  callApi
    .then(
      () => {
        // 情報モーダル表示
        openSubModal(obj, 'vehicle-operation-detail-info-modal')
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message + e.response.data.messageDetail
        }
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

export function clear(obj) {
  console.log('AAA', obj.oriData)
  console.log('AAA', obj.VehicleOperation)
  obj.VehicleOperation = _.cloneDeep(obj.oriData)

  // validate reset
  obj.$refs['tab-group'].$refs['base-tab-group'].reset()
}

function checkLogical(obj) {
  let result = true

  let scheduleBeginDateTime =
    formatYYYYMMDD(obj, obj.VehicleOperation.scheduleBeginDate) +
    ' ' +
    formatHHmmss(obj.VehicleOperation.scheduleBeginTime)

  let scheduleEndDateTime =
    formatYYYYMMDD(obj, obj.VehicleOperation.scheduleEndDate) +
    ' ' +
    formatHHmmss(obj.VehicleOperation.scheduleEndTime)

  // 予定開始日・予定終了日の整合性チェック
  if (obj.VehicleOperation != null) {
    let startDate = obj
      .$moment(scheduleBeginDateTime)
      .format('YYYY-MM-DD HH:mm:ss')
    let endDate = obj.$moment(scheduleEndDateTime).format('YYYY-MM-DD HH:mm:ss')

    // 時刻チェック
    if (
      obj.$moment(startDate).isSame(obj.$moment(endDate)) == false &&
      obj.$moment(startDate).isBefore(obj.$moment(endDate)) == false
    ) {
      // ①同一レコードの開始日と終了日チェック異常
      result = false
    }

    // 日付の最大差分チェック
    {
      let ts = obj
        .$moment(obj.VehicleOperation.scheduleEndDate)
        .diff(obj.$moment(obj.VehicleOperation.scheduleBeginDate), 'days')
      if (ts > DATE_DIFF_PERIOD.MAX) {
        // ①同一レコードの開始日と終了日チェック異常
        result = false
      }
    }
  }
  return result
}

async function checkRelation(obj) {
  let result = true

  if (obj.VehicleOperation.vehicleOperationId != null) {
    obj.$store.dispatch('init/setServiceLoading')
    await VehicleOperationService.getGntEx(
      obj.VehicleOperation.vehicleOperationId
    )
      .then(
        (response) => {
          if (response.data != null) {
            // 立案処理中の車両のため、処理を継続できません。
            openMsgModal(
              obj,
              MSG_TYPE.E,
              obj.$store.getters['init/getMessage']('EE01S001_MG005E')
            )

            result = false
          }
        },
        (e) => {
          if (e.response.status != 404) {
            // エラーモーダル表示
            var msg
            if (e.response == undefined) {
              msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
            } else {
              msg = e.response.data.message + e.response.data.messageDetail
            }
            openMsgModal(obj, MSG_TYPE.E, msg)
            result = false
          }
        }
      )
      .finally(() => {
        obj.$store.dispatch('init/setServiceUnLoading')
      })

    if (result) {
      const searchCondition =
        '/?vehicleId=' +
        obj.VehicleOperation.vehicleId +
        '&vehicleOperationId=' +
        obj.VehicleOperation.vehicleOperationId +
        `&scheduleBeginDate=${obj.VehicleOperation.scheduleBeginDate}&scheduleEndDate=${obj.VehicleOperation.scheduleEndDate}&scheduleBeginTime=${obj.VehicleOperation.scheduleBeginTime}&scheduleEndTime=${obj.VehicleOperation.scheduleEndTime}`

      obj.$store.dispatch('init/setServiceLoading')
      await VehicleOperationService.getCountKadouChohuku(searchCondition)
        .then(
          (response) => {
            if (response.data > 0) {
              // 稼動時間が重複しています。
              openMsgModal(
                obj,
                MSG_TYPE.E,
                obj.$store.getters['init/getMessage']('EE01S002_MG005E')
              )

              result = false
            }
          },
          (e) => {
            // エラーモーダル表示
            var msg
            if (e.response == undefined) {
              msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
            } else {
              msg = e.response.data.message + e.response.data.messageDetail
            }
            openMsgModal(obj, MSG_TYPE.E, msg)
            result = false
          }
        )
        .finally(() => {
          obj.$store.dispatch('init/setServiceUnLoading')
        })
    }
  }

  return result
}

// 配車ステータスによる抑止
export function setEditItem(obj, detailCtrlInfo) {
  // 「詳細」ボタン押下での遷移の場合
  if (obj.screenKbn == DETAIL_SCREEN_KBN.DETAIL) {
    // 車両参照ボタンを非活性
    detailCtrlInfo.sub_modal_vehicle.visibility = VISIBILITY.DISABLE
  }

  // 配車ステータスが配車済、確定の情報を表示した場合、以下の項目が非活性。
  // ①未配車
  if (obj.VehicleOperation.planningStatus == PLANNING_STATUS.NOT_ASSIGNED) {
    // 無し
  } else {
    // ②配車済み・確定

    //・稼働区分コンボボックス
    //・積置き指定フラグコンボボックス
    //・予定開始日カレンダー
    //・予定開始時刻カレンダー
    //・予定開始場所参照ボタン
    //・予定終了日カレンダー
    //・予定終了時刻カレンダー
    //・予定終了場所参照ボタン

    detailCtrlInfo.vehicle_operation_kind.visibility = VISIBILITY.DISABLE
    detailCtrlInfo.preloadable.visibility = VISIBILITY.DISABLE
    detailCtrlInfo.schedule_begin_date.visibility = VISIBILITY.DISABLE
    detailCtrlInfo.schedule_begin_time.visibility = VISIBILITY.DISABLE
    detailCtrlInfo.sub_modal_schedule_begin.visibility = VISIBILITY.DISABLE
    detailCtrlInfo.schedule_end_date.visibility = VISIBILITY.DISABLE
    detailCtrlInfo.schedule_end_time.visibility = VISIBILITY.DISABLE
    detailCtrlInfo.sub_modal_schedule_end.visibility = VISIBILITY.DISABLE

    //
    //・車両優先順テキストボックス
    //・配車グループ名コンボボックス
    //・担当区分コンボボックス
    //・担当方面・コース１～５参照ボタン
    //・自動配車対象外フラグコンボボックス
    //・週テキストボックス
    //・曜日コンボボックス
    //・表示順テキストボックス

    detailCtrlInfo.vehicle_priority_order.visibility = VISIBILITY.DISABLE
    detailCtrlInfo.planning_group_code.visibility = VISIBILITY.DISABLE

    detailCtrlInfo.charge_kind.visibility = VISIBILITY.DISABLE
    detailCtrlInfo.sub_modal_charge_area_id_01.visibility = VISIBILITY.DISABLE
    detailCtrlInfo.sub_modal_charge_area_id_02.visibility = VISIBILITY.DISABLE
    detailCtrlInfo.sub_modal_charge_area_id_03.visibility = VISIBILITY.DISABLE
    detailCtrlInfo.sub_modal_charge_area_id_04.visibility = VISIBILITY.DISABLE
    detailCtrlInfo.sub_modal_charge_area_id_05.visibility = VISIBILITY.DISABLE

    //・自動配車対象外フラグコンボボックス
    //・週テキストボックス
    //・曜日コンボボックス
    //・表示順テキストボックス
    detailCtrlInfo.auto_planning_exclude_flag.visibility = VISIBILITY.DISABLE
    detailCtrlInfo.week.visibility = VISIBILITY.DISABLE
    detailCtrlInfo.weekday.visibility = VISIBILITY.DISABLE
    detailCtrlInfo.display_order.visibility = VISIBILITY.DISABLE

    //・輸送距離区分コンボボックス
    detailCtrlInfo.transport_distance_kind.visibility = VISIBILITY.DISABLE
  }

  return detailCtrlInfo
}
