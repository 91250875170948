import {
  openMsgModal,
  openSubModal,
  formatYYYYMMDD,
  formatHHmmss,
  displaYyyymmdd,
  displayYYYYYMMDDHHmm,
} from '@/common/Common.js'
import { SCREEN_ID, MSG_TYPE, DETAIL_SCREEN_KBN } from '@/common/const.js'
import router from '@/router'
import AutoPlanningService from './AutoPlanningService'
import _ from 'lodash'
import { isNil, isEmpty } from 'lodash'

const screenId = SCREEN_ID.AUTO_PLANNING_LIST
const detailScreenId = SCREEN_ID.AUTO_PLANNING_DETAIL

export function init(obj) {
  // サーバーの処理後再検索
  if (obj.isReSearch == true) {
    reSearch(obj)
  }
}

export function reSearch(obj) {
  var searchCondition = obj.$store.getters['search/getSearchCondition'](
    screenId
  )
  if (searchCondition != null) {
    obj.searchCondition = JSON.parse(searchCondition)
    search(obj)
  }
}

/**
 * 自動配車結果を検索します.
 * @param {*} obj
 */
export function search(obj) {
  // 時間のみ入力された場合
  let fromDate = formatYYYYMMDD(obj, obj.searchCondition.planBeginDate)
  let toDate = formatYYYYMMDD(obj, obj.searchCondition.planEndDate)
  let fromTime = formatHHmmss(obj.searchCondition.planBeginTime)
  let toTime = formatHHmmss(obj.searchCondition.planEndTime)

  if (
    ((isNil(fromDate) || isEmpty(fromDate)) && !isNil(fromTime)) ||
    ((isNil(toDate) || isEmpty(toDate)) && !isNil(toTime))
  ) {
    // 警告モーダル表示
    //日付の入力に不整合があります。
    openMsgModal(
      obj,
      MSG_TYPE.E,
      obj.$store.getters['init/getMessage']('YZ00MG970E')
    )
    return
  }

  let newSearchCondition = _.cloneDeep(obj.searchCondition)
  convertServiceParam(obj, newSearchCondition)

  obj.$store.dispatch('init/setServiceLoading')
  AutoPlanningService.search(newSearchCondition)
    .then(
      (response) => {
        obj.tableItems = response.data.resultList

        obj.tableItems.forEach((element) => {
          //立案開始日時
          element.plan_begin_time = displayYYYYYMMDDHHmm(
            obj,
            element.plan_begin_time
          )
          //立案終了日時
          element.plan_end_time = displayYYYYYMMDDHHmm(
            obj,
            element.plan_end_time
          )
          //計画開始日
          element.plan_begin_date = displaYyyymmdd(obj, element.plan_begin_date)
          //計画終了日
          element.plan_end_date = displaYyyymmdd(obj, element.plan_end_date)
        })

        if (obj.tableItems.length < 1) {
          // 警告モーダル表示
          openMsgModal(
            obj,
            MSG_TYPE.W,
            obj.$store.getters['init/getMessage']('YZ00MG907I')
          )
        }
      },
      (e) => {
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message
        }
        // エラーモーダル表示
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })

  var payload = {
    viewId: screenId,
    params: JSON.stringify(obj.searchCondition),
  }
  obj.$store.dispatch('search/setSearchCondition', payload)
}

function convertServiceParam(obj, newSearchCondition) {
  let fromDate = formatYYYYMMDD(obj, newSearchCondition.planBeginDate)
  let toDate = formatYYYYMMDD(obj, newSearchCondition.planEndDate)
  let fromTime = formatHHmmss(newSearchCondition.planBeginTime)
  let toTime = formatHHmmss(newSearchCondition.planEndTime)

  newSearchCondition.planBeginDate = fromDate
  newSearchCondition.planEndDate = toDate
  newSearchCondition.planBeginTime = fromTime
  newSearchCondition.planEndTime = toTime

  //日付のみ（時刻なし）入力された場合
  if ((isNil(fromTime) || isEmpty(fromTime)) && !isNil(fromDate)) {
    fromTime = '000000'
  }
  if ((isNil(toTime) || isEmpty(toTime)) && !isNil(toDate)) {
    toTime = '235900'
  }

  //日付＋時刻
  newSearchCondition.planBeginDateTime = fromDate + fromTime
  newSearchCondition.planEndDateTime = toDate + toTime

  let reqFromDate = formatYYYYMMDD(obj, newSearchCondition.requestBeginDate)
  let reqToDate = formatYYYYMMDD(obj, newSearchCondition.requestEndDate)

  newSearchCondition.requestBeginDate = reqFromDate
  newSearchCondition.requestEndDate = reqToDate
}

/**
 * 検索項目をクリアします.
 */
export function clear(obj) {
  obj.searchCondition = obj.defaultSearchCondition()
  var payload = {
    viewId: screenId,
    params: JSON.stringify(obj.searchCondition),
  }
  obj.$store.dispatch('search/setSearchCondition', payload)

  // validate reset
  obj.$refs['result-search'].$refs['base-search'].$refs[
    'base-search-condition'
  ].reset()
}

// 「詳細」ボタンクリック
export function goDetail(obj) {
  if (obj.selectedItem.length != 1) {
    openMsgModal(
      obj,
      MSG_TYPE.E,
      obj.$store.getters['init/getMessage']('YZ00MG901E')
    )
  } else {
    router.push({
      name: detailScreenId,
      params: {
        requestId: obj.selectedItem[0].request_id,
        screenKbn: DETAIL_SCREEN_KBN.DETAIL,
        beforeScreenRouteInfo: {
          routeInfo: {
            name: screenId,
            params: { isReSearch: true },
          },
        },
      },
    })
  }
}

// 「新規」ボタンクリック
export function goNew(obj) {
  // 自動配車指示モーダル表示
  obj.autoPlanningRequestModalSearchCondition = {}
  obj.autoPlanningRequestModalSearchCondition.screenKbn = DETAIL_SCREEN_KBN.NEW
  obj.openSubModal(obj, 'auto-planning-request-modal')
}

// 「コピー」ボタンクリック
export function goCopy(obj) {
  if (obj.selectedItem.length != 1) {
    openMsgModal(
      obj,
      MSG_TYPE.E,
      obj.$store.getters['init/getMessage']('YZ00MG901E')
    )
  } else {
    // 自動配車指示モーダル表示
    obj.autoPlanningRequestModalSearchCondition = {}
    obj.autoPlanningRequestModalSearchCondition.requestId =
      obj.selectedItem[0].request_id
    obj.autoPlanningRequestModalSearchCondition.screenKbn =
      DETAIL_SCREEN_KBN.COPY
    obj.openSubModal(obj, 'auto-planning-request-modal')
  }
}

// 「中止」ボタンクリック
export function goStop(obj) {
  obj.stopMessage = obj.$store.getters['init/getMessage'](
    'YZ00MG994Q',
    obj.resultControlInfo.stop.label
  )
  openSubModal(obj, 'stop-modal')
}

/**
 * 選択した作業データを中止します.
 */
export async function stop(obj) {
  try {
    obj.$bvModal.hide('stop-modal')

    let isError = false
    //let removeList = []
    for (let index = 0; index < obj.selectedItem.length; index++) {
      var realVehicleId = obj.selectedItem[index].request_id
      obj.$store.dispatch('init/setServiceLoading')
      await AutoPlanningService.stop(realVehicleId)
        .then(
          (response) => {
            //removeList.push(response.data)
            console.log('stop===>' + response.data)
          },
          (e) => {
            // エラーモーダル表示
            var msg
            if (e.response == undefined) {
              msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
            } else {
              msg = e.response.data.message
            }
            openMsgModal(obj, MSG_TYPE.E, msg)
            isError = true
          }
        )
        .finally(() => {
          obj.$store.dispatch('init/setServiceUnLoading')
        })
      if (isError) {
        break
      }
    }
  } catch (err) {
    console.error(err)
  } finally {
    var searchCondition = obj.$store.getters['search/getSearchCondition'](
      screenId
    )
    obj.searchCondition = JSON.parse(searchCondition)
    search(obj)
  }
}
