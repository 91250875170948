import { SCREEN_ID, MSG_TYPE, VISIBILITY } from '@/common/const.js'
import { openMsgModal, openSubModal } from '@/common/Common.js'
import VehicleGroupService from '@/master/vehicleGroup/VehicleGroupService.js'
import VehicleGroupInfo from '@/master/vehicleGroup/VehicleGroupInfo.js'
import VehicleInfo from '@/master/vehicle/VehicleInfo.js'
import router from '@/router'
import _ from 'lodash'

const screenId = SCREEN_ID.MANUAL_PLANNING_SEARCH
const detailScreenId = SCREEN_ID.MANUAL_PLANNING_EDIT

export function init(obj) {
  searchVehicleGroup(obj)
  if (obj.isReSearch == true) {
    var formData = obj.$store.getters['search/getSearchCondition'](screenId)
    if (formData != null) {
      obj.formData = JSON.parse(formData)
    }
  }
  setControlEnableStatus(obj)
}

export function searchVehicleGroup(obj) {
  // 車両パターンコンボボックスの設定
  const searchCondition = {
    screenId: SCREEN_ID.VEHICLE_GROUP_LIST,
    domainName: 'result_fields',
  }
  obj.$store.dispatch('init/setServiceLoading')
  VehicleGroupService.search(searchCondition)
    .then(
      (response) => {
        obj.formData.vehicleGroupOptions = []
        response.data.resultList.forEach((element) => {
          obj.formData.vehicleGroupOptions.push({
            value: element.vehicle_group_id,
            text: element.vehicle_group_name,
          })
        })
        obj.oriData.vehicleGroupOptions = _.cloneDeep(
          obj.formData.vehicleGroupOptions
        )
      },
      (e) => {
        console.log(e)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

export function clear(obj) {
  obj.formData = _.cloneDeep(obj.oriData)

  var payload = {
    viewId: screenId,
    params: JSON.stringify(obj.formData),
  }
  obj.$store.dispatch('search/setSearchCondition', payload)
}

export async function search(obj) {
  const validated = await obj.$refs['tab-group'].$refs[
    'base-tab-group'
  ].validate()
  if (!validated) return

  let searchCondition = _.cloneDeep(obj.formData.searchCondition)
  searchCondition.vehicleIdList = []
  if (obj.formData.optValue == '1') {
    searchCondition.placeCode = null
    searchCondition.placeName = null
    searchCondition.carrierCode = null
    searchCondition.carrierName = null
    obj.formData.tableItems.forEach((element) => {
      searchCondition.vehicleIdList.push(element.vehicle_id)
    })
  }

  console.log('searchCondition:', searchCondition)

  var payload = {
    viewId: screenId,
    params: JSON.stringify(obj.formData),
  }
  obj.$store.dispatch('search/setSearchCondition', payload)

  router.push({
    name: detailScreenId,
    params: {
      searchCondition: searchCondition,
      beforeScreenRouteInfo: {
        routeInfo: {
          name: screenId,
          params: {
            searchDate: obj.searchDate,
            searchCondition: obj.searchVehicleIdList,
            isReSearch: true,
          },
        },
        beforeScreenRouteInfo: obj.beforeScreenRouteInfo,
      },
    },
  })
}

/**
 * 輸送手段参照から取得データ設定
 */
export function addVehicle(obj, selectedItems) {
  selectedItems.forEach((vehicle) => {
    let info = {
      vehicle_id: vehicle.vehicle_id,
      vehicle_name: vehicle.vehicle_name,
      vehicle_model: vehicle.vehicle_model_name,
      carrier_name: vehicle.carrier_name,
      numberplate: vehicle.numberplate,
    }

    let exists = false
    obj.formData.tableItems.some((element) => {
      if (element.vehicle_id == vehicle.vehicle_id) {
        exists = true
        // return true
      }
    })
    if (exists == false) {
      obj.formData.tableItems.push(info)
    }
  })
}

// 車両削除
export function remove(obj) {
  // 一覧の選択行が存在しない場合、エラーを出力し、以降の処理は行わない。
  if (obj.selectedItems.length == 0) {
    // 一覧より行を選択してください。
    openMsgModal(
      obj,
      MSG_TYPE.E,
      obj.$store.getters['init/getMessage']('YZ00MG901E')
    )
    return
  }

  // 選択行を一覧より、削除する。
  obj.selectedItems.forEach((element) => {
    const index = obj.formData.tableItems.indexOf(element)
    obj.formData.tableItems.splice(index, 1)
  })
}

// 車両パターン追加
export function addVehiclePattern(obj) {
  if (obj.selectedVehiclePattern == null) {
    return
  }
  obj.$store.dispatch('init/setServiceLoading')
  VehicleGroupService.get(obj.selectedVehiclePattern)
    .then(
      (response) => {
        let exists = false
        response.data.vehicles.forEach((vElement) => {
          obj.formData.tableItems.some((element) => {
            if (vElement.vehicleId == element.vehicle_id) {
              exists = true
              return true
            }
          })

          if (!exists) {
            let info = {
              vehicle_id: vElement.vehicleId,
              vehicle_name: vElement.vehicleName,
              vehicle_model: vElement.vehicleModelName,
              carrier_name: vElement.carrierName,
              numberplate: vElement.numberplate,
            }

            obj.formData.tableItems.push(info)
          }
        })
      },
      (e) => {
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message
        }
        // エラーモーダル表示
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

export function registerVehicleGroup(obj, vehicleGroupName, note) {
  let info = new VehicleGroupInfo()
  info.vehicleGroupName = vehicleGroupName
  info.note = note
  info.blockCode = obj.$store.getters['authentication/getBlockCode']

  //tableItems
  obj.formData.tableItems.forEach((element) => {
    let vehicleInfo = new VehicleInfo()
    vehicleInfo.vehicleId = element.vehicle_id
    info.vehicles.push(vehicleInfo)
  })
  obj.$store.dispatch('init/setServiceLoading')
  VehicleGroupService.create(info)
    .then(
      () => {
        // 情報モーダル表示
        openSubModal(obj, 'manual-planning-search-info-modal')
        searchVehicleGroup(obj)
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message + e.response.data.messageDetail
        }
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

export function setControlEnableStatus(obj) {
  if (obj.formData.optValue == '0') {
    obj.searchConditionInfo.plan_begin_place_code.visibility =
      VISIBILITY.VISIBLE
    obj.searchConditionInfo.plan_begin_place_name.visibility =
      VISIBILITY.VISIBLE
    obj.searchConditionInfo.sub_plan_begin_place.visibility = VISIBILITY.VISIBLE
    obj.searchConditionInfo.carrier_code.visibility = VISIBILITY.VISIBLE
    obj.searchConditionInfo.carrier_name.visibility = VISIBILITY.VISIBLE
    obj.searchConditionInfo.sub_carrier.visibility = VISIBILITY.VISIBLE

    obj.resultControlInfo.add_vehicle.visibility = VISIBILITY.DISABLE
    obj.resultControlInfo.remove_vehicle.visibility = VISIBILITY.DISABLE
    obj.resultControlInfo.add_vehicle_pattern.visibility = VISIBILITY.DISABLE
    obj.resultControlInfo.register_vehicle_pattern.visibility =
      VISIBILITY.DISABLE
    obj.resultControlInfo.vehicle_pattern.visibility = VISIBILITY.DISABLE
  } else {
    obj.searchConditionInfo.plan_begin_place_code.visibility =
      VISIBILITY.DISABLE
    obj.searchConditionInfo.plan_begin_place_name.visibility =
      VISIBILITY.DISABLE
    obj.searchConditionInfo.sub_plan_begin_place.visibility = VISIBILITY.DISABLE
    obj.searchConditionInfo.carrier_code.visibility = VISIBILITY.DISABLE
    obj.searchConditionInfo.carrier_name.visibility = VISIBILITY.DISABLE
    obj.searchConditionInfo.sub_carrier.visibility = VISIBILITY.DISABLE

    obj.resultControlInfo.add_vehicle.visibility = VISIBILITY.VISIBLE
    obj.resultControlInfo.remove_vehicle.visibility = VISIBILITY.VISIBLE
    obj.resultControlInfo.add_vehicle_pattern.visibility = VISIBILITY.VISIBLE
    obj.resultControlInfo.register_vehicle_pattern.visibility =
      VISIBILITY.VISIBLE
    obj.resultControlInfo.vehicle_pattern.visibility = VISIBILITY.VISIBLE
  }
}
