<template>
  <div data-class="list">
    <BaseSearchListPage
      ref="planning-result-search"
      :breadcrumbItems="breadcrumbItems"
      :searchCondition="searchCondition"
      :searchConditionInfo="searchConditionInfo"
      :buttonItems="buttonItems"
      :tableItems="tableItems"
      :fields="fields"
      selectMode="range"
      @row-selected="onRowSelected"
      @max-count-changed="onMaxCountChanged"
      @clear="clear(obj)"
      @search="search(obj)"
    >
    </BaseSearchListPage>

    <!-- 場所マスタモーダル -->
    <PlaceModal
      id="place-modal"
      type="entry"
      @after-close-set="afterCloseSetPlace"
      @after-close-unset="afterCloseUnsetPlace"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />

    <!-- 部署マスタモーダル -->
    <DepartmentModal
      id="department-modal"
      type="entry"
      :selectedSearchCondition="departmentModalSearchCondition"
      @after-close-set="afterCloseSetDepartment"
      @after-close-unset="afterCloseUnsetDepartment"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />

    <!-- 実車番マスタモーダル -->
    <RealVehicleModal
      id="real-vehicle-modal-four"
      type="entry"
      displayModals="four"
      :selectedSearchCondition="realVehicleModalSearchCondition"
      @after-close-set="afterCloseSetRealVehicle"
      @after-close-unset="afterCloseUnsetRealVehicle"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />

    <!-- 登録モーダル -->
    <ConfirmModal
      id="register-modal"
      :columnInfos="messageInfo"
      :message="registerMessage"
      :event="event"
      @placeOrder="placeOrder(obj)"
      @approve="approve(obj)"
    />

    <!-- 情報モーダル -->
    <InfoModal
      id="planning-result-list-info-modal"
      :columnInfos="messageInfo"
      :message="infoMessage"
      @after-close="reSearch(obj)"
    />

    <!-- 一括登録条件指定モーダル -->
    <WorKRecordSub
      id="workact-sub-modal"
      type="entry"
      @after-close-set="afterCloseSetWorkact"
    />
  </div>
</template>

<script>
import {
  getListOptions,
  getSearchMaxCount,
  openSubModal,
} from '@/common/Common.js'
import {
  SCREEN_ID,
  REAL_VEHICLE_KIND_SEARCH,
  DEPARTMENT_KIND,
} from '@/common/const.js'
import {
  init,
  reSearch,
  search,
  clear,
  goDetail,
  goWorkact, //一括登録
  doWorkact,
} from './WorkRecordList.js'

const screenId = SCREEN_ID.WORK_RECORD_LIST

export default {
  components: {
    PlaceModal: () => import('@/master/place/PlaceModal.vue'),
    DepartmentModal: () => import('@/master/department/DepartmentModal.vue'),
    RealVehicleModal: () => import('@/master/realVehicle/RealVehicleModal.vue'),
    WorKRecordSub: () => import('./WorkRecordSub.vue'),
  },

  props: {
    isReSearch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tableItems: [],
      selectedItem: [],
      placeModalKbn: 0, //place-modal
      realVehicleModalKbn: 0, //real-vehicle-modal-four
      realVehicleModalSearchCondition: {},
      departmentModalSearchCondition: {},
      /**
       * 検索パラメータ.
       * @type {Object}
       */
      searchCondition: this.defaultSearchCondition(),
      obj: this,
      event: 'confirm',
      infoMessage: null,
      registerMessage: null,
      isDeparturePlace: false,
    }
  },

  created() {
    init(this)
  },

  computed: {
    locale() {
      let lang = this.$store.getters['authentication/getLanguage']
      return lang
    },
    searchConditionInfo() {
      const searchConditionInfo = this.$store.getters['init/getScreens'](
        screenId
      )('search_condition')
      return {
        header: searchConditionInfo.title.label,
        clear: searchConditionInfo.clear,
        search: searchConditionInfo.search,
        inputComponents: [
          {
            // 荷積日
            type: 'dateFromTo',
            fromId: 'departureBeginDate',
            toId: 'departureEndDate',
            fromColumnInfo: searchConditionInfo.departure_begin_date,
            toColumnInfo: searchConditionInfo.departure_end_date,
            locale: this.locale,
            fromRules: [
              this.INPUT_TYPE_CHECK.REQUIRED,
              this.INPUT_TYPE_CHECK.DATETIMERANGE,
            ],
            toRules: [this.INPUT_TYPE_CHECK.REQUIRED],
          },
          {
            // 出発場所
            type: 'referenceCodeName',
            codeId: 'departurePlaceCode',
            nameId: 'departurePlaceName',
            codeColumnInfo: searchConditionInfo.departure_place_code,
            nameColumnInfo: searchConditionInfo.departure_place_name,
            btnColumnInfo: searchConditionInfo.sub_modal_departure_place,
            codeMaxLength: 30,
            nameMaxLength: 50,
            codeRules: [this.INPUT_TYPE_CHECK.ALPHANUMERIC_CODE],
            onClickCallback: () => {
              //...ボタン
              this.placeModalKbn = 1
              this.openSubModal(this, 'place-modal')
            },
          },
          {
            // 運送会社
            type: 'referenceCodeName',
            codeId: 'carrierCode',
            nameId: 'carrierName',
            codeColumnInfo: searchConditionInfo.carrier_code,
            nameColumnInfo: searchConditionInfo.carrier_name,
            btnColumnInfo: searchConditionInfo.sub_modal_carrier,
            codeRules: [this.INPUT_TYPE_CHECK.ALPHANUMERIC_CODE],
            codeMaxLength: 10,
            nameMaxLength: 30,
            onClickCallback: () => {
              this.departmentModalSearchCondition = {}
              this.departmentModalSearchCondition.departmentKind =
                DEPARTMENT_KIND.CARRIER
              this.openSubModal(this, 'department-modal')
            },
          },
          // 実車両(TR) / 実車両(TL)
          {
            // トラック/トラクタ (TR)
            type: 'referenceCodeName',
            codeId: 'realVehicleId',
            nameId: 'realVehicleNumberplate',
            codeColumnInfo: searchConditionInfo.real_vehicle_id,
            nameColumnInfo: searchConditionInfo.real_vehicle_numberplate,
            btnColumnInfo: searchConditionInfo.sub_modal_real_vehicle,
            onClickCallback: () => {
              this.realVehicleModalKbn = 1
              this.realVehicleModalSearchCondition = {}
              // ヘッドorトラクタ
              this.realVehicleModalSearchCondition.realVehicleKind =
                REAL_VEHICLE_KIND_SEARCH.TRTC
              this.openSubModal(this, 'real-vehicle-modal-four')
            },
          },

          {
            // 進捗状態
            type: 'select',
            id: 'progressStatus',
            columnInfo: searchConditionInfo.progress_status,
            options: getListOptions(this.MASTER_CODE.PROGRESS_STATUS),
            onChangeCallback: () => {},
          },
          {
            // 作業区分（荷積・荷卸）
            type: 'select',
            id: 'workKind',
            columnInfo: searchConditionInfo.work_kind,
            options: getListOptions(this.MASTER_CODE.RECORD_WORK_KIND),
            onChangeCallback: () => {},
          },
          {
            // 受付番号
            type: 'text',
            id: 'receiptNo',
            columnInfo: searchConditionInfo.receipt_no,
          },
          {
            // ブロック名
            type: 'text',
            id: 'blockName',
            columnInfo: searchConditionInfo.block_name,
          },
          {
            // 摘要01(機種)
            type: 'text',
            id: 'description01',
            columnInfo: searchConditionInfo.description01,
            maxLength: 50,
          },
          {
            // 摘要02(機番)
            type: 'text',
            id: 'description02',
            columnInfo: searchConditionInfo.description02,
            maxLength: 50,
          },
        ],
      }
    },
    resultControlInfo() {
      return this.$store.getters['init/getScreens'](screenId)(
        'result_ctrl_info'
      )
    },
    fileInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'file'
      )
    },
    messageInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'message'
      )
    },
    breadcrumbItems() {
      return [
        {
          name: this.$store.getters['init/getScreenName'](screenId),
        },
      ]
    },
    /**
     * テーブル列定義リスト.
     * @return {Array}
     */
    fields() {
      return this.$store.getters['init/getFields'](screenId)(
        this.DOMAIN_NAME.FIELDS
      )
    },

    /**
     * ボタン情報リスト.
     * @return {Array}
     */
    buttonItems() {
      return [
        // ボタングループ#1
        [
          // 詳細ボタン
          {
            variant: 'primary',
            icon: 'list-alt',
            columnInfo: this.resultControlInfo.detail,
            disabled: !this.isSelected,
            onClickCallback: () => {
              goDetail(this)
            },
          },
        ],
        // ボタングループ#2
        [
          // 一括登録ボタン
          {
            variant: 'primary',
            icon: 'list',
            columnInfo: this.resultControlInfo.all_register,
            disabled: !this.isSelected,
            onClickCallback: () => {
              goWorkact(this)
            },
          },
        ],
      ]
    },

    /**
     * 単数選択しているか.
     * @return {Boolean}
     */
    isSelected() {
      return this.selectedItem.length > 0
    },
  },

  methods: {
    getListOptions,
    openSubModal,
    reSearch,
    search,
    clear,
    goWorkact,
    /**
     * デフォルト検索パラメータを返します.
     * @return {Object}
     */
    defaultSearchCondition() {
      let today = this.$moment(new Date()).format('YYYY-MM-DD')
      return {
        departureBeginDate: today,
        departureEndDate: today,
        departurePlaceCode: null,
        departurePlaceName: null,
        carrierCode: null,
        carrierName: null,
        realVehicleId: null,
        realVehicleNumberplate: null,
        progressStatus: null,
        description01: null, //摘要01(機種)
        description02: null, //摘要02(機番)
        workKind: null, //作業区分（荷積荷卸）
        receiptNo: null,
        blockName: null,
        size: getSearchMaxCount(),
        screenId: screenId,
        domainName: 'result_fields',
      }
    },

    /**
     * 部署マスタから取得データ設定
     */
    afterCloseSetDepartment(id, code, name) {
      this.searchCondition.carrierCode = code
      this.searchCondition.carrierName = name
    },

    /**
     * 部署情報解除
     */
    afterCloseUnsetDepartment() {
      this.searchCondition.carrierCode = null
      this.searchCondition.carrierName = null
    },

    /**
     * 場所マスタから取得データ設定
     */
    afterCloseSetPlace(selectedItem) {
      if (this.placeModalKbn == 1) {
        this.searchCondition.departurePlaceCode = selectedItem.place_code
        this.searchCondition.departurePlaceName = selectedItem.place_name
        // } else if (this.placeModalKbn == 2) {
        //   this.searchCondition.arrivalPlaceCode = selectedItem.place_code
        //   this.searchCondition.arrivalPlaceName = selectedItem.place_name
      }
    },
    /**
     * 場所情報解除
     */
    afterCloseUnsetPlace() {
      if (this.placeModalKbn == 1) {
        this.searchCondition.departurePlaceId = null
        this.searchCondition.departurePlaceCode = null
        this.searchCondition.departurePlaceName = null
        // } else if (this.placeModalKbn == 2) {
        //   this.searchCondition.arrivalPlaceId = null
        //   this.searchCondition.arrivalPlaceCode = null
        //   this.searchCondition.arrivalPlaceName = null
      }
    },

    /**
     * 実車番マスタから取得データ設定
     */
    afterCloseSetRealVehicle(selectedItem) {
      if (this.realVehicleModalKbn == 1) {
        //トラック/トラクタ(TR)
        this.searchCondition.realVehicleId = selectedItem.real_vehicle_id
        this.searchCondition.realVehicleNumberplate = selectedItem.numberplate
      }
    },

    afterCloseUnsetRealVehicle() {
      if (this.realVehicleModalKbn == 1) {
        //トラック/トラクタ(TR)
        this.searchCondition.realVehicleId = null
        this.searchCondition.realVehicleNumberplate = null
      }
    },

    /**
     * 一括登録条件指定参照から取得データ設定
     */
    afterCloseSetWorkact(workactDate, workactHhmmss) {
      doWorkact(this, workactDate, workactHhmmss)
    },

    setErrorMsg(msg) {
      this.$emit('set-error-msg', msg)
    },

    setWarnMsg(msg) {
      this.$emit('set-warn-msg', msg)
    },

    setInfoMsg(msg) {
      this.$emit('set-info-msg', msg)
    },

    onRowSelected(selectedItems) {
      this.selectedItem = selectedItems
    },

    onMaxCountChanged(maxCount) {
      this.searchCondition.size = maxCount
    },

    xx(condition) {
      console.log(condition)
      alert(condition.periodDateFrom)
    },
  },
}
</script>

<style scoped>
#footer {
  bottom: 0;
  position: fixed;
  width: 96.8%;
  height: 50px;
  margin-bottom: 20px;
  box-sizing: border-box;
  color: white;
  /* margin-left:300px; */
  text-align: center;
}
</style>
