<template>
  <div>
    <DetailPageLayout
      :breadcrumbItems="breadcrumbItems"
      :buttonItems="buttonItems"
    >
      <template #top>
        <div class="tab-group">
          <BaseTabGroup
            ref="tab-group"
            :inputModel="PlanningPattern"
            :inputComponents="inputComponents"
          ></BaseTabGroup>
        </div>
      </template>
      <template #bottom>
        <b-card no-body>
          <b-tabs card>
            <b-tab :title="detailInfo.planning_parameter.label" class="tab">
              <b-table
                data-class="base-table"
                class="table table-bordered base-table"
                bordered
                hover
                no-border-collapse
                responsive
                :selectable="false"
                small
                sticky-header
                outlined
                :fields="planningParameterFieldInfo"
                :items="planningParameterItems"
                v-bind="$attrs"
                v-on="$listeners"
              >
                <template v-slot:cell(planning_parameter_define_value)="row">
                  <b-form-select
                    v-if="row.item.planning_parameter_type === '40'"
                    :disabled="isBaseData || row.item.display_kind !== '2'"
                    v-model="row.item.planning_parameter_define_value"
                    size="sm"
                    :options="getListOptions(row.item.code_id)"
                    v-bind="$attrs"
                    v-on="$listeners"
                  />
                  <b-form-input
                    v-else
                    :disabled="isBaseData || row.item.display_kind !== '2'"
                    v-model="row.item.planning_parameter_define_value"
                    size="sm"
                    type="text"
                    :formatter="maxInputNumber"
                    v-bind="$attrs"
                    v-on="$listeners"
                  />
                </template>
              </b-table>
            </b-tab>
            <b-tab :title="detailInfo.planning_constraint.label" class="tab">
              <b-table
                data-class="base-table"
                class="table table-bordered base-table"
                bordered
                hover
                no-border-collapse
                responsive
                :selectable="false"
                small
                sticky-header
                outlined
                :fields="planningConstraintFieldInfo"
                :items="planningConstraintItems"
                v-bind="$attrs"
                v-on="$listeners"
              >
                <template v-slot:cell(auto_planning_check_level)="row">
                  <b-form-select
                    :disabled="isBaseData || row.item.display_kind !== '2'"
                    v-model="row.item.auto_planning_check_level"
                    size="sm"
                    :options="
                      getListOptions(MASTER_CODE.AUTO_PLANNING_CHECK_LEVEL)
                    "
                    v-bind="$attrs"
                    v-on="$listeners"
                  />
                </template>
                <template v-slot:cell(manual_planning_check_level)="row">
                  <b-form-select
                    :disabled="isBaseData || row.item.display_kind !== '2'"
                    v-model="row.item.manual_planning_check_level"
                    size="sm"
                    :options="
                      getListOptions(MASTER_CODE.MANUAL_PLANNING_CHECK_LEVEL)
                    "
                    v-bind="$attrs"
                    v-on="$listeners"
                  />
                </template>
              </b-table>
            </b-tab>
          </b-tabs>
        </b-card>
      </template>
    </DetailPageLayout>
    <!-- 情報モーダル -->
    <InfoModal
      id="planning-pattern-detail-info-modal"
      :columnInfos="messageInfo"
      :message="infoMessage"
      @after-close="back(obj)"
    />
    <!-- 登録モーダル -->
    <ConfirmModal
      id="register-modal"
      :columnInfos="messageInfo"
      :message="registerMessage"
      @confirm="register(obj)"
    />
  </div>
</template>

<script>
import PlanningPatternInfo from './PlanningPatternInfo'
import { getListOptions, openSubModal } from '@/common/Common.js'
import { getBreadcrumbItems, back } from '@/common/screen-common.js'
import { init, goRegister, register, clear } from './PlanningPatternDetail.js'

export default {
  name: 'planningPatternDetail',

  props: {
    beforeScreenRouteInfo: {
      type: Object,
    },
    planningPatternId: {
      type: Number,
      default: -1,
    },
    screenKbn: {
      type: Number,
    },
  },

  data() {
    return {
      PlanningPattern: new PlanningPatternInfo(),
      obj: this,
      planningParameterItems: [],
      planningConstraintItems: [],
      registerMessage: null,
      oriData: new PlanningPatternInfo(),
      oriPlanningParameterItems: [],
      oriPlanningConstraintItems: [],
    }
  },

  created() {
    init(this)
  },

  computed: {
    isBaseData() {
      return this.PlanningPattern.planningPatternId === 0
    },
    detailCtrlInfo() {
      return this.$store.getters['init/getScreens'](
        this.SCREEN_ID.PLANNING_PATTERN_DETAIL
      )('detail_ctrl_info')
    },
    detailInfo() {
      let detailInfo = this.$store.getters['init/getScreens'](
        this.SCREEN_ID.PLANNING_PATTERN_DETAIL
      )('detail_info')

      detailInfo.planning_pattern_id.visibility = this.VISIBILITY.DISABLE

      // 標準データ(配車計画パターンID = 0)の場合、削除可否区分非活性
      if (this.isBaseData) {
        detailInfo.delete_impossible_kind.visibility = this.VISIBILITY.DISABLE
      }

      return detailInfo
    },
    planningParameterFieldInfo() {
      return this.$store.getters['init/getFields'](
        this.SCREEN_ID.PLANNING_PATTERN_DETAIL
      )('planning_parameter_field')
    },
    planningConstraintFieldInfo() {
      return this.$store.getters['init/getFields'](
        this.SCREEN_ID.PLANNING_PATTERN_DETAIL
      )('planning_constraint_field')
    },
    messageInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'message'
      )
    },
    breadcrumbItems() {
      return getBreadcrumbItems(
        this.beforeScreenRouteInfo,
        this.SCREEN_ID.PLANNING_PATTERN_DETAIL
      )
    },
    /**
     * ボタン情報リスト.
     * @return {Array}
     */
    buttonItems() {
      return [
        // ボタングループ#1
        [
          // 登録ボタン
          {
            variant: 'success',
            icon: 'edit',
            columnInfo: this.detailCtrlInfo.register,
            onClickCallback: () => {
              goRegister(this)
            },
          },
          // クリアボタン
          {
            variant: 'success',
            icon: 'times-circle',
            columnInfo: this.detailCtrlInfo.clear,
            onClickCallback: () => {
              clear(this)
            },
          },
        ],
        // ボタングループ#2
        [
          // 戻るボタン
          {
            variant: 'success',
            icon: 'times-circle',
            columnInfo: this.detailCtrlInfo.back,
            onClickCallback: () => {
              back(this)
            },
          },
        ],
      ]
    },
    inputComponents() {
      return [
        {
          label: this.detailInfo.tab1.label,
          inputComponents: [
            {
              // 配車計画パターンID
              type: 'text',
              id: 'planningPatternId',
              columnInfo: this.detailInfo.planning_pattern_id,
            },
            {
              // 配車計画パターン名
              type: 'text',
              id: 'planningPatternName',
              columnInfo: this.detailInfo.planning_pattern_name,
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
            },
            {
              // ブロック名
              type: 'text',
              id: 'blockName',
              columnInfo: this.detailInfo.block_name,
            },
            {
              // 備考
              type: 'text',
              id: 'note',
              columnInfo: this.detailInfo.note,
            },
            {
              // 表示順
              type: 'text',
              id: 'displayOrder',
              columnInfo: this.detailInfo.display_order,
              rules: [
                this.INPUT_TYPE_CHECK.REQUIRED,
                this.INPUT_TYPE_CHECK.ONLY_NUMERIC,
              ],
            },
            {
              // 削除可否区分
              type: 'select',
              id: 'deleteImpossibleKind',
              columnInfo: this.detailInfo.delete_impossible_kind,
              options: getListOptions(this.MASTER_CODE.DELETE_IMPOSSIBLE_KIND),
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onChangeCallback: () => {},
            },
            {
              // 登録日時
              type: 'text',
              id: 'registrationTimeDisplay',
              columnInfo: this.detailInfo.registration_time_display,
            },
            {
              // 登録ユーザ
              type: 'text',
              id: 'registrationUser',
              columnInfo: this.detailInfo.registration_user,
            },
            {
              // 更新日時
              type: 'text',
              id: 'updateTimeDisplay',
              columnInfo: this.detailInfo.update_time_display,
            },
            {
              // 更新ユーザ
              type: 'text',
              id: 'updateUser',
              columnInfo: this.detailInfo.update_user,
            },
          ],
        },
      ]
    },
    /**
     * 情報メッセージ.
     * @return {String}
     */
    infoMessage() {
      return this.$store.getters['init/getMessage'](
        'YZ00MG995I',
        this.detailCtrlInfo.register.label
      )
    },
  },

  methods: {
    getListOptions,
    openSubModal,
    register,
    back,
    clear,
    maxInputNumber(value) {
      //金額のMaxLength
      return String(value).substring(0, 9)
    },

    setErrorMsg(msg) {
      this.$emit('set-error-msg', msg)
    },

    setWarnMsg(msg) {
      this.$emit('set-warn-msg', msg)
    },
  },
}
</script>

<style lang="scss" scoped>
.tab-group {
  margin-bottom: 12px;
}

// .data-group-role {
//   border: 1px solid #e9e9e9;
//   border-radius: 4px;
//   padding: 14px;
// }

// .datagroup-role-controll {
//   display: flex;
//   justify-content: space-between;
//   align-items: baseline;
//   margin: 8px 12px;
// }

// .td-radio {
//   align-self: center;
//   margin-left: auto;
//   margin-right: auto;
// }

// .detail-table {
//   font-size: 0.8rem;
//   th,
//   td {
//     padding: 5px;
//     vertical-align: middle;
//   }

//   th {
//     background-color: $primary;
//     color: #eee;
//     padding: 0 1rem;
//     text-align: center;
//     width: 130px;
//   }
// }
</style>
