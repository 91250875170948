import {
  openMsgModal,
  openSubModal,
  formatYYYYMMDD,
  formatHHmmss,
  convertSnakeCace,
  convertCamelCace,
} from '@/common/Common.js'
import {
  MSG_TYPE,
  DETAIL_SCREEN_KBN,
  PROCESS_KIND,
  VISIBILITY,
} from '@/common/const.js'
import PlanningOrderService from './PlanningOrderService'
import _ from 'lodash'

export function init(obj) {
  if (obj.screenKbn != DETAIL_SCREEN_KBN.NEW) {
    obj.$store.dispatch('init/setServiceLoading')
    PlanningOrderService.get(obj.planningOrderId)
      .then(
        (response) => {
          obj.PlanningOrder = response.data
          obj.PlanningOrder.receiveOrderDto = response.data.receiveOrderDto

          obj.PlanningOrder.ownerName = response.data.receiveOrderDto.ownerName
          obj.PlanningOrder.distributorName =
            response.data.receiveOrderDto.distributorName
          obj.PlanningOrder.consigneeName =
            response.data.receiveOrderDto.consigneeName
          obj.PlanningOrder.orderEntryKind =
            response.data.receiveOrderDto.orderEntryKind

          //[#2662] 配車オーダ詳細画面の総数量・総質量・総容量 => 配車オーダーの各情報で設定
          obj.PlanningOrder.totalQuantity = response.data.quantity
          //response.data.receiveOrderDto.totalQuantity
          obj.PlanningOrder.totalWeight = response.data.weight
          //response.data.receiveOrderDto.totalWeight
          obj.PlanningOrder.totalVolume = response.data.volume
          //response.data.receiveOrderDto.totalVolume

          obj.PlanningOrder.orderReserve01 =
            response.data.receiveOrderDto.orderReserve01
          obj.PlanningOrder.orderReserve02 =
            response.data.receiveOrderDto.orderReserve02
          obj.PlanningOrder.orderReserve03 =
            response.data.receiveOrderDto.orderReserve03
          obj.PlanningOrder.orderReserve04 =
            response.data.receiveOrderDto.orderReserve04
          obj.PlanningOrder.orderReserve05 =
            response.data.receiveOrderDto.orderReserve05
          obj.PlanningOrder.orderReserve06 =
            response.data.receiveOrderDto.orderReserve06
          obj.PlanningOrder.orderReserve07 =
            response.data.receiveOrderDto.orderReserve07
          obj.PlanningOrder.orderReserve08 =
            response.data.receiveOrderDto.orderReserve08
          obj.PlanningOrder.orderReserve09 =
            response.data.receiveOrderDto.orderReserve09
          obj.PlanningOrder.orderReserve10 =
            response.data.receiveOrderDto.orderReserve10
          obj.PlanningOrder.orderReserve11 =
            response.data.receiveOrderDto.orderReserve11
          obj.PlanningOrder.orderReserve12 =
            response.data.receiveOrderDto.orderReserve12
          obj.PlanningOrder.orderReserve13 =
            response.data.receiveOrderDto.orderReserve13
          obj.PlanningOrder.orderReserve14 =
            response.data.receiveOrderDto.orderReserve14
          obj.PlanningOrder.orderReserve15 =
            response.data.receiveOrderDto.orderReserve15
          obj.PlanningOrder.orderReserve16 =
            response.data.receiveOrderDto.orderReserve16
          obj.PlanningOrder.orderReserve17 =
            response.data.receiveOrderDto.orderReserve17
          obj.PlanningOrder.orderReserve18 =
            response.data.receiveOrderDto.orderReserve18
          obj.PlanningOrder.orderReserve19 =
            response.data.receiveOrderDto.orderReserve19
          obj.PlanningOrder.orderReserve20 =
            response.data.receiveOrderDto.orderReserve20

          // details
          var details = convertSnakeCace(
            obj.PlanningOrder.planningOrderDetailDto
          )

          details.sort(function (a, b) {
            return a.planning_order_id - b.planning_order_id
          })

          obj.PlanningOrder.planningOrderDetailDto = details

          if (obj.screenKbn == DETAIL_SCREEN_KBN.COPY) {
            obj.PlanningOrder.planningOrderId = null
            obj.PlanningOrder.registrationTimeDisplay = null
            obj.PlanningOrder.registrationUser = null
            obj.PlanningOrder.updateTimeDisplay = null
            obj.PlanningOrder.updateUser = null
            _.map(obj.PlanningOrder.planningOrderDetailDto, function (x) {
              x.process_kind = PROCESS_KIND.UPDATE
              return x
            })
            obj.tableItems = obj.PlanningOrder.planningOrderDetailDto
          } else {
            obj.PlanningOrder.planningOrderId = obj.planningOrderId
            obj.tableItems = obj.PlanningOrder.planningOrderDetailDto
          }
          obj.oriData = _.cloneDeep(obj.PlanningOrder)
        },
        (e) => {
          // エラーモーダル表示
          var msg
          if (e.response == undefined) {
            msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
          } else if (e.response.status == 404) {
            msg = obj.$store.getters['init/getMessage']('YZ00MG996E')
          } else {
            msg = e.response.data.message
          }
          openMsgModal(obj, MSG_TYPE.E, msg)
        }
      )
      .finally(() => {
        obj.$store.dispatch('init/setServiceUnLoading')
      })
  }
}

// 配車ステータスによる抑止
export function setEditItem(detailCtrlInfo) {
  detailCtrlInfo.planning_order_id.visibility = VISIBILITY.DISABLE
  detailCtrlInfo.order_detail_id.visibility = VISIBILITY.DISABLE

  //detailCtrlInfo.receipt_no.visibility = VISIBILITY.VISIBLE
  detailCtrlInfo.receipt_no.visibility = VISIBILITY.DISABLE

  detailCtrlInfo.planning_section_no.visibility = VISIBILITY.DISABLE
  detailCtrlInfo.planning_cargo_no.visibility = VISIBILITY.DISABLE
  detailCtrlInfo.orgin_planning_order_id.visibility = VISIBILITY.DISABLE
  detailCtrlInfo.block_name.visibility = VISIBILITY.DISABLE
  detailCtrlInfo.packuped_id.visibility = VISIBILITY.DISABLE
  detailCtrlInfo.packup_id.visibility = VISIBILITY.DISABLE
  detailCtrlInfo.packup_flag.visibility = VISIBILITY.DISABLE
  detailCtrlInfo.delivery_packup_flag.visibility = VISIBILITY.DISABLE
  detailCtrlInfo.section_partition_flag.visibility = VISIBILITY.DISABLE
  detailCtrlInfo.count_partition_flag.visibility = VISIBILITY.DISABLE
  detailCtrlInfo.pntchd_kind.visibility = VISIBILITY.DISABLE

  detailCtrlInfo.departure_postal_code.visibility = VISIBILITY.DISABLE
  detailCtrlInfo.departure_address.visibility = VISIBILITY.DISABLE
  detailCtrlInfo.arrival_postal_code.visibility = VISIBILITY.DISABLE
  detailCtrlInfo.arrival_address.visibility = VISIBILITY.DISABLE

  detailCtrlInfo.total_quantity.visibility = VISIBILITY.DISABLE
  detailCtrlInfo.total_weight.visibility = VISIBILITY.DISABLE
  detailCtrlInfo.total_volume.visibility = VISIBILITY.DISABLE
  // detailCtrlInfo.planning_group_code.visibility = VISIBILITY.DISABLE

  detailCtrlInfo.departure_condition.visibility = VISIBILITY.VISIBLE
  detailCtrlInfo.arrival_condition.visibility = VISIBILITY.VISIBLE
  detailCtrlInfo.note.visibility = VISIBILITY.VISIBLE

  detailCtrlInfo.planning_status.visibility = VISIBILITY.DISABLE
  detailCtrlInfo.assign_carrier_code.visibility = VISIBILITY.DISABLE
  detailCtrlInfo.assign_carrier_name.visibility = VISIBILITY.DISABLE

  detailCtrlInfo.assign_trip_no.visibility = VISIBILITY.VISIBLE
  detailCtrlInfo.assign_drop_no.visibility = VISIBILITY.VISIBLE

  // detailCtrlInfo.auto_planning_exclude_flag.visibility = VISIBILITY.DISABLE
  detailCtrlInfo.order_kind.visibility = VISIBILITY.VISIBLE

  detailCtrlInfo.transport_type_kind.visibility = VISIBILITY.VISIBLE
  detailCtrlInfo.transport_distance_kind.visibility = VISIBILITY.VISIBLE
  detailCtrlInfo.order_distance.visibility = VISIBILITY.DISABLE
  detailCtrlInfo.change_kind.visibility = VISIBILITY.DISABLE
  detailCtrlInfo.old_vehicle_id.visibility = VISIBILITY.DISABLE

  detailCtrlInfo.registration_time_display.visibility = VISIBILITY.DISABLE
  detailCtrlInfo.registration_user.visibility = VISIBILITY.DISABLE
  detailCtrlInfo.update_time_display.visibility = VISIBILITY.DISABLE
  detailCtrlInfo.update_user.visibility = VISIBILITY.DISABLE

  detailCtrlInfo.delete_status.visibility = VISIBILITY.DISABLE
  detailCtrlInfo.owner_name.visibility = VISIBILITY.DISABLE
  detailCtrlInfo.distributor_name.visibility = VISIBILITY.DISABLE
  detailCtrlInfo.consignee_name.visibility = VISIBILITY.DISABLE
  //detailCtrlInfo.order_entry_kind.visibility = VISIBILITY.DISABLE

  return detailCtrlInfo
}

export async function goRegister(obj) {
  const validated = await obj.$refs['tab-group'].$refs[
    'base-tab-group'
  ].validate()
  if (!validated) return

  obj.registerMessage = obj.$store.getters['init/getMessage'](
    'YZ00MG994Q',
    obj.detailCtrlInfo.register.label
  )
  openSubModal(obj, 'register-modal')
}

export async function register(obj) {
  obj.$bvModal.hide('register-modal')
  obj.PlanningOrder.planningOrderDetailDto = changeCamelCase(obj)

  const validated = await obj.$refs['tab-group'].$refs[
    'base-tab-group'
  ].validate()
  if (!validated) return

  obj.PlanningOrder.departureBeginDate = formatYYYYMMDD(
    obj,
    obj.PlanningOrder.departureBeginDate
  )
  obj.PlanningOrder.departureEndDate = formatYYYYMMDD(
    obj,
    obj.PlanningOrder.departureEndDate
  )
  obj.PlanningOrder.arrivalBeginDate = formatYYYYMMDD(
    obj,
    obj.PlanningOrder.arrivalBeginDate
  )
  obj.PlanningOrder.arrivalEndDate = formatYYYYMMDD(
    obj,
    obj.PlanningOrder.arrivalEndDate
  )
  obj.PlanningOrder.preloadAssignDate = formatYYYYMMDD(
    obj,
    obj.PlanningOrder.preloadAssignDate
  )
  obj.PlanningOrder.departureBeginTime = formatHHmmss(
    obj.PlanningOrder.departureBeginTime
  )
  obj.PlanningOrder.departureEndTime = formatHHmmss(
    obj.PlanningOrder.departureEndTime
  )
  obj.PlanningOrder.arrivalBeginTime = formatHHmmss(
    obj.PlanningOrder.arrivalBeginTime
  )
  obj.PlanningOrder.arrivalEndTime = formatHHmmss(
    obj.PlanningOrder.arrivalEndTime
  )
  obj.PlanningOrder.preloadBeginTime = formatHHmmss(
    obj.PlanningOrder.preloadBeginTime
  )

  obj.PlanningOrder.receiveOrderDto.ownerName = obj.PlanningOrder.ownerName
  obj.PlanningOrder.receiveOrderDto.distributorName =
    obj.PlanningOrder.distributorName
  obj.PlanningOrder.receiveOrderDto.consigneeName =
    obj.PlanningOrder.consigneeName
  obj.PlanningOrder.receiveOrderDto.orderEntryKind =
    obj.PlanningOrder.orderEntryKind
  obj.PlanningOrder.receiveOrderDto.totalWeight = obj.PlanningOrder.totalWeight
  obj.PlanningOrder.receiveOrderDto.totalVolume = obj.PlanningOrder.totalVolume
  obj.PlanningOrder.receiveOrderDto.totalQuantity =
    obj.PlanningOrder.totalQuantity
  obj.PlanningOrder.receiveOrderDto.orderReserve01 =
    obj.PlanningOrder.orderReserve01
  obj.PlanningOrder.receiveOrderDto.orderReserve02 =
    obj.PlanningOrder.orderReserve02
  obj.PlanningOrder.receiveOrderDto.orderReserve03 =
    obj.PlanningOrder.orderReserve03
  obj.PlanningOrder.receiveOrderDto.orderReserve04 =
    obj.PlanningOrder.orderReserve04
  obj.PlanningOrder.receiveOrderDto.orderReserve05 =
    obj.PlanningOrder.orderReserve05
  obj.PlanningOrder.receiveOrderDto.orderReserve06 =
    obj.PlanningOrder.orderReserve06
  obj.PlanningOrder.receiveOrderDto.orderReserve07 =
    obj.PlanningOrder.orderReserve07
  obj.PlanningOrder.receiveOrderDto.orderReserve08 =
    obj.PlanningOrder.orderReserve08
  obj.PlanningOrder.receiveOrderDto.orderReserve09 =
    obj.PlanningOrder.orderReserve09
  obj.PlanningOrder.receiveOrderDto.orderReserve10 =
    obj.PlanningOrder.orderReserve10
  obj.PlanningOrder.receiveOrderDto.orderReserve11 =
    obj.PlanningOrder.orderReserve11
  obj.PlanningOrder.receiveOrderDto.orderReserve12 =
    obj.PlanningOrder.orderReserve12
  obj.PlanningOrder.receiveOrderDto.orderReserve13 =
    obj.PlanningOrder.orderReserve13
  obj.PlanningOrder.receiveOrderDto.orderReserve14 =
    obj.PlanningOrder.orderReserve14
  obj.PlanningOrder.receiveOrderDto.orderReserve15 =
    obj.PlanningOrder.orderReserve15
  obj.PlanningOrder.receiveOrderDto.orderReserve16 =
    obj.PlanningOrder.orderReserve16
  obj.PlanningOrder.receiveOrderDto.orderReserve17 =
    obj.PlanningOrder.orderReserve17
  obj.PlanningOrder.receiveOrderDto.orderReserve18 =
    obj.PlanningOrder.orderReserve18
  obj.PlanningOrder.receiveOrderDto.orderReserve19 =
    obj.PlanningOrder.orderReserve19
  obj.PlanningOrder.receiveOrderDto.orderReserve20 =
    obj.PlanningOrder.orderReserve20

  var callApi
  if (obj.screenKbn != DETAIL_SCREEN_KBN.DETAIL) {
    // 新規
    callApi = PlanningOrderService.create(obj.PlanningOrder)
  } else {
    // 詳細
    callApi = PlanningOrderService.update(
      obj.planningOrderId,
      obj.PlanningOrder
    )
  }
  obj.$store.dispatch('init/setServiceLoading')
  callApi
    .then(
      () => {
        // 情報モーダル表示
        openSubModal(obj, 'PlanningOrder-detail-info-modal')
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message
        }
        openMsgModal(this, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

export function remove(obj) {
  // 複数個削除
  for (let info of obj.selectedItem) {
    const index = obj.PlanningOrder.planningOrderDetailDto.indexOf(info)
    //this.tableItems.splice(index, 1)
    obj.PlanningOrder.planningOrderDetailDto[index].process_kind =
      PROCESS_KIND.DELETE
    //obj.PlanningOrder.planningOrderDetailDto.splice(index, 1)
  }
}

export function clear(obj) {
  obj.PlanningOrder = _.cloneDeep(obj.oriData)
  obj.tableItems = obj.PlanningOrder.planningOrderDetailDto || []
  // validate reset
  obj.$refs['tab-group'].$refs['base-tab-group'].reset()
}

function changeCamelCase(obj) {
  var details_camel = convertCamelCace(obj.PlanningOrder.planningOrderDetailDto)

  return details_camel
}

export function setPlaceInfo(obj, selectedItem) {
  if (obj.placeModalKbn == 1) {
    //出荷
    obj.PlanningOrder.departurePlaceId = selectedItem.place_id
    obj.PlanningOrder.departurePlaceCode = selectedItem.place_code
    obj.PlanningOrder.departurePlaceName = selectedItem.place_name
    obj.PlanningOrder.departurePostalCode = selectedItem.postal_code
    // obj.PlanningOrder.departureAddress =
    //   selectedItem.prefecture_code + selectedItem.address2
    if (selectedItem.address1 == null) {
      obj.PlanningOrder.departureAddress = ''
    } else {
      obj.PlanningOrder.departureAddress = selectedItem.address1
        .concat('')
        .concat(selectedItem.address2)
    }

    return
  } else {
    //搬入
    obj.PlanningOrder.arrivalPlaceId = selectedItem.place_id
    obj.PlanningOrder.arrivalPlaceCode = selectedItem.place_code
    obj.PlanningOrder.arrivalPlaceName = selectedItem.place_name
    obj.PlanningOrder.arrivalPostalCode = selectedItem.postal_code
    // obj.PlanningOrder.arrivalAddress =
    //   selectedItem.prefecture_code + selectedItem.address2
    if (selectedItem.address1 == null) {
      obj.PlanningOrder.arrivalAddress = ''
    } else {
      obj.PlanningOrder.arrivalAddress = selectedItem.address1
        .concat('')
        .concat(selectedItem.address2)
    }
    return
  }
}

export function unsetPlaceInfo(obj) {
  if (obj.placeModalKbn == 1) {
    //出荷
    obj.PlanningOrder.departurePlaceId = null
    obj.PlanningOrder.departurePlaceCode = null
    obj.PlanningOrder.departurePlaceName = null
    obj.PlanningOrder.departurePostalCode = null
    obj.PlanningOrder.departureAddress = null
    return
  } else {
    //搬入
    obj.PlanningOrder.arrivalPlaceId = null
    obj.PlanningOrder.arrivalPlaceCode = null
    obj.PlanningOrder.arrivalPlaceName = null
    obj.PlanningOrder.arrivalPostalCode = null
    obj.PlanningOrder.arrivalAddress = null
    return
  }
}
