/**
 * ユーザデータ.
 * @class
 * @exports
 */
export default class PlanningEvaluationInfo {
  planningEvaluationPatternId = null
  blockCode = null
  blockName = null
  planningEvaluationPatternName = null
  planningEvaluationLogic = null
  autoPlanningLogic = null
  note = null
  deleteImpossibleKind = null
  deleteImpossibleKindName = null
  displayOrder = 0
  displayKind = null
  displayKindName = null
  deleted = null
  registrationTimeDisplay = null
  registrationUser = null
  updateTimeDisplay = null
  updateUser = null
  planningEvaluationPatternDetails = []
}
