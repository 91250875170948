<template>
  <div>
    <DetailPageLayout
      :breadcrumbItems="breadcrumbItems"
      :buttonItems="buttonItems"
    >
      <template #top>
        <BaseTabGroup
          ref="tab-group"
          :inputModel="VehicleOperation"
          :inputComponents="inputComponents"
        ></BaseTabGroup>
      </template>
    </DetailPageLayout>
    <!-- 輸送手段マスタモーダル -->
    <VehicleModal
      id="vehicle-modal"
      type="entry"
      selectMode="single"
      @after-close-set="afterCloseSetVehicle"
      @after-close-unset="afterCloseUnsetVehicle"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />
    <!-- 場所モーダル -->
    <PlaceModal
      id="place-modal"
      type="entry"
      selectMode="single"
      @after-close-set="afterCloseSetPlace"
      @after-close-unset="afterCloseUnsetPlace"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />
    <!--方面・コースモーダル -->
    <AreaModal
      id="area-modal"
      type="entry"
      :selectedSearchCondition.sync="areaModalSearchCondition"
      @after-close-set="afterCloseSetArea"
      @after-close-unset="afterCloseUnsetArea"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />
    <!-- 情報モーダル -->
    <InfoModal
      id="vehicle-operation-detail-info-modal"
      :columnInfos="messageInfo"
      :message="infoMessage"
      @after-close="back(obj)"
    />
    <!-- 登録モーダル -->
    <ConfirmModal
      id="register-modal"
      :columnInfos="messageInfo"
      :message="registerMessage"
      @confirm="register(obj)"
    />
  </div>
</template>

<script>
import VehicleOperationInfo from './VehicleOperationInfo'
import { getListOptions, openSubModal } from '@/common/Common.js'
import { SCREEN_ID, INTEGER_MAX_MIN } from '@/common/const.js'
import { getBreadcrumbItems, back } from '@/common/screen-common.js'
import {
  init,
  showAreaModal,
  goRegister,
  register,
  clear,
  setEditItem,
} from './VehicleOperationDetail.js'

const screenId = SCREEN_ID.VEHICLE_OPERATION_DETAIL

export default {
  name: 'VehicleOperationDetail',

  components: {
    VehicleModal: () => import('@/master/vehicle/VehicleModal.vue'),
    PlaceModal: () => import('@/master/place/PlaceModal.vue'),
    AreaModal: () => import('@/master/area/AreaModal.vue'),
  },

  props: {
    beforeScreenRouteInfo: {
      type: Object,
    },
    vehicleOperationId: {
      type: Number,
      default: -1,
    },
    screenKbn: {
      type: Number,
    },
  },

  data() {
    return {
      VehicleOperation: new VehicleOperationInfo(),
      obj: this,
      registerMessage: null,
      oriData: new VehicleOperationInfo(),
      isScheduleBeginPlace: true,
      btnClickChargeAreaId: 1,
      areaModalSearchCondition: {},
    }
  },

  created() {
    init(this)
  },

  computed: {
    detailCtrlInfo() {
      return this.$store.getters['init/getScreens'](screenId)(
        'detail_ctrl_info'
      )
    },
    detailInfo() {
      let info = this.$store.getters['init/getScreens'](screenId)('detail_info')

      return setEditItem(this, info)
    },
    messageInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'message'
      )
    },
    breadcrumbItems() {
      return getBreadcrumbItems(this.beforeScreenRouteInfo, screenId)
    },
    /**
     * ボタン情報リスト.
     * @return {Array}
     */
    buttonItems() {
      return [
        // ボタングループ#1
        [
          // 登録ボタン
          {
            variant: 'success',
            icon: 'edit',
            columnInfo: this.detailCtrlInfo.register,
            disabled:
              this.VehicleOperation.planningStatus !=
              this.PLANNING_STATUS.NOT_ASSIGNED,
            onClickCallback: () => {
              goRegister(this)
            },
          },
          // クリアボタン
          {
            variant: 'success',
            icon: 'times-circle',
            columnInfo: this.detailCtrlInfo.clear,
            onClickCallback: () => {
              clear(this)
            },
          },
          // 戻るボタン
          {
            variant: 'success',
            icon: 'times-circle',
            columnInfo: this.detailCtrlInfo.back,
            onClickCallback: () => {
              back(this)
            },
          },
        ],
        // ボタングループ#2
        [],
      ]
    },
    inputComponents() {
      return [
        {
          label: this.detailInfo.tab1.label,
          inputComponents: [
            {
              // 車両稼働ID
              type: 'text',
              id: 'vehicleOperationId',
              columnInfo: this.detailInfo.vehicle_operation_id,
            },
            {
              // 車両
              type: 'referenceCodeName',
              codeId: 'vehicleCode',
              nameId: 'vehicleName',
              codeColumnInfo: this.detailInfo.vehicle_code,
              nameColumnInfo: this.detailInfo.vehicle_name,
              btnColumnInfo: this.detailInfo.sub_modal_vehicle,
              codeRules: [
                this.INPUT_TYPE_CHECK.REQUIRED,
                this.INPUT_TYPE_CHECK.ALPHANUMERIC_CODE,
              ],
              nameRules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onClickCallback: () => {
                this.openSubModal(this, 'vehicle-modal')
              },
            },
            {
              // 車両番号
              type: 'text',
              id: 'numberplate',
              columnInfo: this.detailInfo.numberplate,
            },
            {
              // 車型
              type: 'text',
              id: 'vehicleModelName',
              columnInfo: this.detailInfo.vehicle_model,
            },
            {
              // 輸送手段カテゴリ
              type: 'text',
              id: 'vehicleCategoryName',
              columnInfo: this.detailInfo.vehicle_category,
            },
            {
              // 最大積載質量
              type: 'text',
              id: 'maxLoadingWeight',
              columnInfo: this.detailInfo.max_loading_weight,
              rules: [this.INPUT_TYPE_CHECK.FLOAT],
            },
            {
              // 最大積載容量
              type: 'text',
              id: 'maxLoadingVolume',
              columnInfo: this.detailInfo.max_loading_volume,
              rules: [this.INPUT_TYPE_CHECK.FLOAT],
            },
            {
              // 作業準備時間
              type: 'text',
              id: 'preparationTime',
              columnInfo: this.detailInfo.preparation_time,
              minValue: INTEGER_MAX_MIN.MIN,
              maxValue: INTEGER_MAX_MIN.MAX,
              rules: [
                this.INPUT_TYPE_CHECK.ONLY_NUMERIC,
                this.INPUT_TYPE_CHECK.MINVALUE,
                this.INPUT_TYPE_CHECK.MAXVALUE,
              ],
            },
            {
              // 車両区分
              type: 'text',
              id: 'vehicleKind',
              columnInfo: this.detailInfo.vehicle_kind,
            },
            {
              // 仮車区分
              type: 'text',
              id: 'managementVehicleKind',
              columnInfo: this.detailInfo.management_vehicle_kind,
            },
            {
              // 運送会社
              type: 'referenceCodeName',
              codeId: 'carrierCode',
              nameId: 'carrierName',
              codeColumnInfo: this.detailInfo.carrier_code,
              nameColumnInfo: this.detailInfo.carrier_name,
              codeRules: [this.INPUT_TYPE_CHECK.ALPHANUMERIC],
            },
            {
              // 車両基地場所
              type: 'referenceCodeName',
              codeId: 'vehicleBasePlaceCode',
              nameId: 'vehicleBasePlaceName',
              codeColumnInfo: this.detailInfo.vehicle_base_place_code,
              nameColumnInfo: this.detailInfo.vehicle_base_place_name,
              codeRules: [this.INPUT_TYPE_CHECK.ALPHANUMERIC],
            },
            {
              // ブロック名
              type: 'text',
              id: 'blockName',
              columnInfo: this.detailInfo.block_name,
            },
            {
              // 稼動区分
              type: 'select',
              id: 'vehicleOperationKind',
              columnInfo: this.detailInfo.vehicle_operation_kind,
              options: getListOptions(this.MASTER_CODE.VEHICLE_OPERATION_KIND),
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onChangeCallback: () => {},
            },
            {
              // 積置きフラグ
              type: 'select',
              id: 'preloadable',
              columnInfo: this.detailInfo.preloadable,
              options: getListOptions(this.MASTER_CODE.PRELOADABLE),
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onChangeCallback: () => {},
            },
            {
              // 予定開始日時
              type: 'dateTime',
              dateId: 'scheduleBeginDate',
              timeId: 'scheduleBeginTime',
              dateColumnInfo: this.detailInfo.schedule_begin_date,
              timeColumnInfo: this.detailInfo.schedule_begin_time,
              dateRules: [this.INPUT_TYPE_CHECK.REQUIRED],
              timeRules: [this.INPUT_TYPE_CHECK.REQUIRED],
            },
            {
              // 予定開始場所
              type: 'referenceCodeName',
              codeId: 'scheduleBeginPlaceCode',
              nameId: 'scheduleBeginPlaceName',
              codeColumnInfo: this.detailInfo.schedule_begin_place_code,
              nameColumnInfo: this.detailInfo.schedule_begin_place_name,
              btnColumnInfo: this.detailInfo.sub_modal_schedule_begin,
              codeRules: [this.INPUT_TYPE_CHECK.REQUIRED],
              nameRules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onClickCallback: () => {
                this.isScheduleBeginPlace = true
                this.openSubModal(this, 'place-modal')
              },
            },
            {
              // 予定終了日時
              type: 'dateTime',
              dateId: 'scheduleEndDate',
              timeId: 'scheduleEndTime',
              dateColumnInfo: this.detailInfo.schedule_end_date,
              timeColumnInfo: this.detailInfo.schedule_end_time,
              dateRules: [this.INPUT_TYPE_CHECK.REQUIRED],
              timeRules: [this.INPUT_TYPE_CHECK.REQUIRED],
            },
            {
              // 予定終了場所
              type: 'referenceCodeName',
              codeId: 'scheduleEndPlaceCode',
              nameId: 'scheduleEndPlaceName',
              codeColumnInfo: this.detailInfo.schedule_end_place_code,
              nameColumnInfo: this.detailInfo.schedule_end_place_name,
              btnColumnInfo: this.detailInfo.sub_modal_schedule_end,
              codeRules: [this.INPUT_TYPE_CHECK.REQUIRED],
              nameRules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onClickCallback: () => {
                this.isScheduleBeginPlace = false
                this.openSubModal(this, 'place-modal')
              },
            },
            {
              // 計画開始日時
              type: 'text',
              id: 'planBeginTime',
              columnInfo: this.detailInfo.plan_begin_time,
            },
            {
              // 計画開始場所
              type: 'referenceCodeName',
              codeId: 'planBeginPlaceId',
              nameId: 'planBeginPlaceName',
              codeColumnInfo: this.detailInfo.plan_begin_place_id,
              nameColumnInfo: this.detailInfo.plan_begin_place_name,
            },
            {
              // 計画終了日時
              type: 'text',
              id: 'planEndTime',
              columnInfo: this.detailInfo.plan_end_time,
            },
            {
              // 計画終了場所
              type: 'referenceCodeName',
              codeId: 'planEndPlaceId',
              nameId: 'planEndPlaceName',
              codeColumnInfo: this.detailInfo.plan_end_place_id,
              nameColumnInfo: this.detailInfo.plan_end_place_name,
            },
            {
              // 車両優先順
              type: 'text',
              id: 'vehiclePriorityOrder',
              columnInfo: this.detailInfo.vehicle_priority_order,
              minValue: INTEGER_MAX_MIN.MIN,
              maxValue: INTEGER_MAX_MIN.MAX,
              rules: [
                this.INPUT_TYPE_CHECK.ONLY_NUMERIC,
                this.INPUT_TYPE_CHECK.MINVALUE,
                this.INPUT_TYPE_CHECK.MAXVALUE,
              ],
            },
            {
              // 輸送距離区分
              type: 'select',
              id: 'transportDistanceKind',
              columnInfo: this.detailInfo.transport_distance_kind,
              options: getListOptions(this.MASTER_CODE.TRANSPORT_DISTANCE_KIND),
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onChangeCallback: () => {},
            },
            // {
            //   // 配車計画グループ名
            //   type: 'select',
            //   id: 'planningGroupCode',
            //   columnInfo: this.detailInfo.planning_group_code,
            //   options: getListOptions(this.MASTER_CODE.PLANNING_GROUP),
            //   onChangeCallback: () => {},
            // },
            {
              // 担当区分
              type: 'select',
              id: 'chargeKind',
              columnInfo: this.detailInfo.charge_kind,
              options: getListOptions(this.MASTER_CODE.AREA_KIND),
              onChangeCallback: () => {
                this.VehicleOperation.chargeAreaId01 = null
                this.VehicleOperation.chargeAreaName01 = null
                this.VehicleOperation.chargeAreaId02 = null
                this.VehicleOperation.chargeAreaName02 = null
                this.VehicleOperation.chargeAreaId03 = null
                this.VehicleOperation.chargeAreaName03 = null
                this.VehicleOperation.chargeAreaId04 = null
                this.VehicleOperation.chargeAreaName04 = null
                this.VehicleOperation.chargeAreaId05 = null
                this.VehicleOperation.chargeAreaName05 = null
              },
            },
            {
              // 担当方面･コース１
              type: 'referenceCode',
              codeId: 'chargeAreaName01',
              codeColumnInfo: this.detailInfo.charge_area_id_01,
              btnColumnInfo: this.detailInfo.sub_modal_charge_area_id_01,
              onClickCallback: () => {
                showAreaModal(this, 1)
              },
            },
            {
              // 担当方面･コース２
              type: 'referenceCode',
              codeId: 'chargeAreaName02',
              codeColumnInfo: this.detailInfo.charge_area_id_02,
              btnColumnInfo: this.detailInfo.sub_modal_charge_area_id_02,
              onClickCallback: () => {
                showAreaModal(this, 2)
              },
            },
            {
              // 担当方面･コース３
              type: 'referenceCode',
              codeId: 'chargeAreaName03',
              codeColumnInfo: this.detailInfo.charge_area_id_03,
              btnColumnInfo: this.detailInfo.sub_modal_charge_area_id_03,
              onClickCallback: () => {
                showAreaModal(this, 3)
              },
            },
            {
              // 担当方面･コース４
              type: 'referenceCode',
              codeId: 'chargeAreaName04',
              codeColumnInfo: this.detailInfo.charge_area_id_04,
              btnColumnInfo: this.detailInfo.sub_modal_charge_area_id_04,
              onClickCallback: () => {
                showAreaModal(this, 4)
              },
            },
            {
              // 担当方面･コース５
              type: 'referenceCode',
              codeId: 'chargeAreaName05',
              codeColumnInfo: this.detailInfo.charge_area_id_05,
              btnColumnInfo: this.detailInfo.sub_modal_charge_area_id_05,
              onClickCallback: () => {
                showAreaModal(this, 5)
              },
            },
            {
              // 自動配車対象外フラグ
              type: 'select',
              id: 'autoPlanningExcludeFlag',
              columnInfo: this.detailInfo.auto_planning_exclude_flag,
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              options: getListOptions(
                this.MASTER_CODE.AUTO_PLANNING_EXCLUDE_FLAG
              ),
              onChangeCallback: () => {},
            },
            {
              // 週
              type: 'text',
              id: 'week',
              columnInfo: this.detailInfo.week,
              minValue: INTEGER_MAX_MIN.MIN,
              maxValue: INTEGER_MAX_MIN.MAX,
              rules: [
                this.INPUT_TYPE_CHECK.REQUIRED,
                this.INPUT_TYPE_CHECK.ONLY_NUMERIC,
                this.INPUT_TYPE_CHECK.MINVALUE,
                this.INPUT_TYPE_CHECK.MAXVALUE,
              ],
            },
            {
              // 曜日
              type: 'select',
              id: 'weekday',
              columnInfo: this.detailInfo.weekday,
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              options: getListOptions(this.MASTER_CODE.WEEKDAY_NAME),
              onChangeCallback: () => {},
            },
            {
              // 表示順
              type: 'text',
              id: 'displayOrder',
              columnInfo: this.detailInfo.display_order,
              minValue: INTEGER_MAX_MIN.MIN,
              maxValue: INTEGER_MAX_MIN.MAX,
              rules: [
                this.INPUT_TYPE_CHECK.REQUIRED,
                this.INPUT_TYPE_CHECK.ONLY_NUMERIC,
                this.INPUT_TYPE_CHECK.MINVALUE,
                this.INPUT_TYPE_CHECK.MAXVALUE,
              ],
            },
            {
              // 配車ステータス
              type: 'text',
              id: 'planningStatusName',
              columnInfo: this.detailInfo.planning_status,
            },
            {
              // 変更通知ステータス
              type: 'text',
              id: 'changeNoticeStatusName',
              columnInfo: this.detailInfo.change_notice_status,
            },
            {
              // 登録日時
              type: 'text',
              id: 'registrationTimeDisplay',
              columnInfo: this.detailInfo.registration_time_display,
            },
            {
              // 登録ユーザ
              type: 'text',
              id: 'registrationUser',
              columnInfo: this.detailInfo.registration_user,
            },
            {
              // 更新日時
              type: 'text',
              id: 'updateTimeDisplay',
              columnInfo: this.detailInfo.update_time_display,
            },
            {
              // 更新ユーザ
              type: 'text',
              id: 'updateUser',
              columnInfo: this.detailInfo.update_user,
            },
          ],
        },
      ]
    },
    /**
     * 情報メッセージ.
     * @return {String}
     */
    infoMessage() {
      return this.$store.getters['init/getMessage'](
        'YZ00MG995I',
        this.detailCtrlInfo.register.label
      )
    },
  },

  methods: {
    getListOptions,
    openSubModal,
    register,
    back,

    /**
     * 輸送手段参照から取得データ設定
     */
    afterCloseSetVehicle(selectedItem) {
      this.VehicleOperation.vehicleId = selectedItem.vehicle_id
      this.VehicleOperation.vehicleCode = selectedItem.vehicle_code
      this.VehicleOperation.vehicleName = selectedItem.vehicle_name
      this.VehicleOperation.numberplate = selectedItem.numberplate
      this.VehicleOperation.vehicleModelName = selectedItem.vehicle_model_name
      this.VehicleOperation.vehicleCategoryName =
        selectedItem.vehicle_category_name
      this.VehicleOperation.maxLoadingWeight = selectedItem.max_loading_weight
      this.VehicleOperation.maxLoadingVolume = selectedItem.max_loading_volume
      this.VehicleOperation.preparationTime = selectedItem.preparation_time
      this.VehicleOperation.vehicleKind = selectedItem.vehicle_kind
      this.VehicleOperation.managementVehicleKind =
        selectedItem.management_vehicle_kind
      this.VehicleOperation.carrierCode = selectedItem.carrier_code
      this.VehicleOperation.carrierName = selectedItem.carrier_name
      this.VehicleOperation.vehicleBasePlaceCode =
        selectedItem.vehicle_base_place_code
      this.VehicleOperation.vehicleBasePlaceName =
        selectedItem.vehicle_base_place_name
    },
    /**
     * 輸送手段情報解除
     */
    afterCloseUnsetVehicle() {
      this.VehicleOperation.vehicleId = null
      this.VehicleOperation.vehicleCode = null
      this.VehicleOperation.vehicleName = null
      this.VehicleOperation.numberplate = null
      this.VehicleOperation.numberplateAreaName = null
      this.VehicleOperation.numberplateClassNo = null
      this.VehicleOperation.numberplateKana = null
      this.VehicleOperation.numberplateSerialNo = null
      this.VehicleOperation.vehicleModelName = null
      this.VehicleOperation.vehicleCategoryName = null
      this.VehicleOperation.maxLoadingWeight = null
      this.VehicleOperation.maxLoadingVolume = null
      this.VehicleOperation.preparationTime = null
      this.VehicleOperation.vehicleKind = null
      this.VehicleOperation.managementVehicleKind = null
      this.VehicleOperation.carrierCode = null
      this.VehicleOperation.carrierName = null
      this.VehicleOperation.vehicleBasePlaceCode = null
      this.VehicleOperation.vehicleBasePlaceName = null
    },

    /**
     * 場所参照から取得データ設定
     */
    afterCloseSetPlace(selectedItem) {
      if (this.isScheduleBeginPlace) {
        this.VehicleOperation.scheduleBeginPlaceId = selectedItem.place_id
        this.VehicleOperation.scheduleBeginPlaceCode = selectedItem.place_code
        this.VehicleOperation.scheduleBeginPlaceName = selectedItem.place_name
      } else {
        this.VehicleOperation.scheduleEndPlaceId = selectedItem.place_id
        this.VehicleOperation.scheduleEndPlaceCode = selectedItem.place_code
        this.VehicleOperation.scheduleEndPlaceName = selectedItem.place_name
      }
    },
    /**
     * 場所情報解除
     */
    afterCloseUnsetPlace() {
      if (this.isScheduleBeginPlace) {
        this.VehicleOperation.scheduleBeginPlaceId = null
        this.VehicleOperation.scheduleBeginPlaceCode = null
        this.VehicleOperation.scheduleBeginPlaceName = null
      } else {
        this.VehicleOperation.scheduleEndPlaceId = null
        this.VehicleOperation.scheduleEndPlaceCode = null
        this.VehicleOperation.scheduleEndPlaceName = null
      }
    },

    /**
     * 方面・コース参照から取得データ設定
     */
    afterCloseSetArea(id, name) {
      switch (this.btnClickChargeAreaId) {
        case 1:
          this.VehicleOperation.chargeAreaId01 = id
          this.VehicleOperation.chargeAreaName01 = name
          break
        case 2:
          this.VehicleOperation.chargeAreaId02 = id
          this.VehicleOperation.chargeAreaName02 = name
          break
        case 3:
          this.VehicleOperation.chargeAreaId03 = id
          this.VehicleOperation.chargeAreaName03 = name
          break
        case 4:
          this.VehicleOperation.chargeAreaId04 = id
          this.VehicleOperation.chargeAreaName04 = name
          break
        case 5:
          this.VehicleOperation.chargeAreaId05 = id
          this.VehicleOperation.chargeAreaName05 = name
          break

        default:
          break
      }
    },
    afterCloseUnsetArea() {
      switch (this.btnClickChargeAreaId) {
        case 1:
          this.VehicleOperation.chargeAreaId01 = null
          this.VehicleOperation.chargeAreaName01 = null
          break
        case 2:
          this.VehicleOperation.chargeAreaId02 = null
          this.VehicleOperation.chargeAreaName02 = null
          break
        case 3:
          this.VehicleOperation.chargeAreaId03 = null
          this.VehicleOperation.chargeAreaName03 = null
          break
        case 4:
          this.VehicleOperation.chargeAreaId04 = null
          this.VehicleOperation.chargeAreaName04 = null
          break
        case 5:
          this.VehicleOperation.chargeAreaId05 = null
          this.VehicleOperation.chargeAreaName05 = null
          break

        default:
          break
      }
    },
    setErrorMsg(msg) {
      this.$emit('set-error-msg', msg)
    },

    setWarnMsg(msg) {
      this.$emit('set-warn-msg', msg)
    },
  },
}
</script>

<style lang="scss" scoped>
.detailForm {
  height: 730px;
  overflow-y: auto;
  overflow-x: hidden;
}

#BaseView {
  height: 730px;
}
</style>
