import {
  openMsgModal,
  openSubModal,
  convertSnakeCace,
} from '@/common/Common.js'
import { SCREEN_ID, MSG_TYPE, DETAIL_SCREEN_KBN } from '@/common/const.js'
import router from '@/router'
import AutoPlanningService from './AutoPlanningService'
import _ from 'lodash'

export function init(obj) {
  if (obj.screenKbn != DETAIL_SCREEN_KBN.NEW) {
    obj.$store.dispatch('init/setServiceLoading')
    AutoPlanningService.get(obj.requestId)
      .then(
        (response) => {
          obj.formData = response.data
          obj.oriData = _.cloneDeep(obj.formData)

          // 平価結果ーヘーダ
          let fields = convertFields(response.data.domainColumns)
          obj.tableFieldsEvaluation = fields
          obj.tableItemsEvaluation = response.data.resultList

          // 最良世代の結果
          var datas = convertSnakeCace(response.data.bestGenerationDtos)
          obj.tableItemsBestGeneration = datas

          obj.itemNames = getChartItemNames(response.data.domainColumns)
          // console.log('【グラフ】===========')
          // console.log('itemNames[length]=' + obj.itemNames.length)
          // for (var i = 0; i < obj.itemNames.length; i++) {
          //   console.log('itemNames[' + i + ']=' + obj.itemNames[i])
          // }

          obj.chartLabels = getChartLabels(
            obj.itemNames,
            response.data.domainColumns
          )
          // console.log('chartLabels[length]=' + obj.chartLabels.length)
          // for (i = 0; i < obj.chartLabels.length; i++) {
          //   console.log('chartLabels[' + i + ']=' + obj.chartLabels[i])
          // }

          // グラフ初期表示
          setChartInfo(obj, response.data.resultList[0])
        },
        (e) => {
          // エラーモーダル表示
          var msg
          if (e.response == undefined) {
            msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
          } else if (e.response.status == 404) {
            msg = obj.$store.getters['init/getMessage']('YZ00MG996E')
          } else {
            msg = e.response.data.message
          }
          openMsgModal(obj, MSG_TYPE.E, msg)
        }
      )
      .finally(() => {
        obj.$store.dispatch('init/setServiceUnLoading')
      })
  }
}

export function setChartInfo(obj, selectedItem) {
  // グラフデータのみ取得
  let chartDatas = getChartDatas(obj.itemNames, selectedItem)
  // console.log('chartDatas[length]=' + chartDatas.length)
  // for (var i = 0; i < chartDatas.length; i++) {
  //   console.log('chartDatas[' + i + ']=' + chartDatas[i])
  // }

  // グラフにラベル情報とデータ情報再設定
  //obj.datacollection.labels = obj.chartLabels
  //※配列に直接値を変更することはできないため、spliceでswapさせる
  //obj.datacollection.datasets[0].data = chartDatas

  let newDataCollection = _.cloneDeep(obj.datacollection)
  let newDataSet = makeDataset('', chartDatas)

  newDataCollection.labels = obj.chartLabels
  //swap
  newDataCollection.datasets.splice(0, 1, newDataSet)

  obj.datacollection = newDataCollection
}

/**
 * 平価結果ヘッダ行構成情報からチャット表示用項目名称を取得する
 * @param {*} domainColumns
 */
function getChartItemNames(domainColumns) {
  let itemNames = []
  itemNames = domainColumns
    .filter(
      (item) =>
        item.domainColumnName != 'evaluation_name' &&
        item.domainColumnName != 'evaluation_code' &&
        item.domainColumnName != 'unit'
    )
    .map((item) => item.domainColumnName)

  return itemNames
}

/**
 * 平価結果ヘッダ行構成情報からチャット表示用ラベルを取得する
 * @param {*} itemNames
 * @param {*} domainColumns
 */
function getChartLabels(itemNames, domainColumns) {
  let labels = []
  for (var i = 0; i < itemNames.length; i++) {
    let elementName = itemNames[i]
    let xlabel = domainColumns
      .filter((item) => item.domainColumnName == elementName)
      .map((item) => item.label)

    // console.log('itemNames[' + i + ']=' + elementName)
    // console.log('label(value)=' + xlabel)

    labels.push(xlabel)
  }
  return labels
}
/**
 * 平価結果選択行からチャット表示用データを取得する
 * @param {*} itemNames
 * @param {*} selectedItem
 */
export function getChartDatas(itemNames, selectedItem) {
  let datas = []
  for (var i = 0; i < itemNames.length; i++) {
    let elementName = itemNames[i]
    // console.log('itemNames[' + i + ']=' + elementName)
    // console.log('value=' + selectedItem[elementName])
    datas.push(selectedItem[elementName])
  }
  return datas
}

/**
 * チャットのデータセットを作成する
 * @param {*} unit
 * @param {*} datas
 */
function makeDataset(unit, datas) {
  // 例）
  // {
  //   label: 'Data One',
  //   borderWidth: 2,
  //   borderColor: '#249EBF',
  //   backgroundColor: '#249EBF',
  //   pointBorderColor: '#f87979',
  //   pointBackgroundColor: '#f87979',
  //   fill: false,
  //   //Data to be represented on y-axis
  //   data: [40, 20, 30, 50, 90, 10, 20, 40, 50, 70, 90, 100],
  // },
  let newElement = {
    label: unit,
    borderWidth: 2,
    borderColor: '#249EBF',
    backgroundColor: '#249EBF',
    pointBorderColor: '#f87979',
    pointBackgroundColor: '#f87979',
    lineTension: 0,
    fill: false,
    data: datas,
  }
  return newElement
}

/**
 * ドメインの一覧ヘッダ情報リストを取得します.
 * @param {*} domainColumns
 */
function convertFields(domainColumns) {
  if (domainColumns) {
    return domainColumns
      .filter((e) => e.visibility !== 0)
      .map((e) => ({
        ['key']: e.domainColumnName,
        ['label']: e.label,
        ['sortable']: true,
        ['thStyle']: {
          whiteSpace: 'nowrap',
          minWidth: `${e.width}px`,
          width: `${e.width}px`,
        },
      }))
  }
  return null
}

/**
 * 「手動配車」ボタンクリック
 * @param {*} obj
 */
export function goManualPlanning(obj) {
  obj.registerMessage = obj.$store.getters['init/getMessage'](
    'YZ00MG994Q',
    obj.detailCtrlInfo.register.label
  )
  openSubModal(obj, 'register-modal')
}

/**
 * 「手動配車」遷移処理
 * @param {*} obj
 */
export async function register(obj) {
  obj.$bvModal.hide('register-modal')

  // 車両IDリスト作成
  let vehicleIds = []
  if (obj.formData.vehicleConditionDtos.length > 0) {
    vehicleIds = obj.formData.vehicleConditionDtos.map((item) => item.vehicleId)
  }

  console.log('【手動配車】パラメータ===========')
  console.log('予定日=' + obj.formData.planBeginDate)
  for (var i = 0; i < vehicleIds.length; i++) {
    console.log('vehicleIds[' + i + ']=' + vehicleIds[i])
  }

  router.push({
    name: SCREEN_ID.MANUAL_PLANNING_SEARCH,
    params: {
      searchDate: obj.formData.planBeginDate,
      searchVehicleIdList: vehicleIds,
      beforeScreenRouteInfo: {
        routeInfo: {
          name: SCREEN_ID.AUTO_PLANNING_DETAIL,
          params: {
            requestId: obj.requestId,
            screenKbn: obj.screenKbn,
          },
        },
        beforeScreenRouteInfo: obj.beforeScreenRouteInfo,
      },
    },
  })
}
