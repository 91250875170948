<template>
  <div data-class="list">
    <BaseSearchListPage
      ref="planning-result-search"
      :breadcrumbItems="breadcrumbItems"
      :searchCondition="searchCondition"
      :searchConditionInfo="searchConditionInfo"
      :buttonItems="buttonItems"
      :tableItems="tableItems"
      :fields="fields"
      selectMode="range"
      @row-selected="onRowSelected"
      @max-count-changed="onMaxCountChanged"
      @clear="clear(obj)"
      @search="search(obj)"
    >
    </BaseSearchListPage>

    <!-- 部署マスタモーダル -->
    <DepartmentModal
      id="department-modal"
      type="entry"
      :selectedSearchCondition="departmentModalSearchCondition"
      @after-close-set="afterCloseSetDepartment"
      @after-close-unset="afterCloseUnsetDepartment"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />

    <!-- 場所モーダル -->
    <PlaceModal
      id="place-modal"
      type="entry"
      @after-close-set="afterCloseSetPlace"
      @after-close-unset="afterCloseUnsetPlace"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />

    <!-- 登録モーダル -->
    <ConfirmModal
      id="register-modal"
      :columnInfos="messageInfo"
      :message="registerMessage"
      :event="event"
      @placeOrder="placeOrder(obj)"
      @approve="approve(obj)"
    />

    <!-- 情報モーダル -->
    <InfoModal
      id="planning-result-list-info-modal"
      :columnInfos="messageInfo"
      :message="infoMessage"
      @after-close="reSearch(obj)"
    />
  </div>
</template>

<script>
import {
  getListOptions,
  getSearchMaxCount,
  openSubModal,
} from '@/common/Common.js'
import { SCREEN_ID, DEPARTMENT_KIND } from '@/common/const.js'
import {
  init,
  reSearch,
  search,
  clear,
  goDetail,
  goPlaceOrder,
  placeOrder,
  goApprove,
  approve,
} from './PlaceOrderList.js'

const screenId = SCREEN_ID.PLACE_ORDER_LIST

export default {
  components: {
    DepartmentModal: () => import('@/master/department/DepartmentModal.vue'),
    PlaceModal: () => import('@/master/place/PlaceModal.vue'),
  },

  props: {
    isReSearch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tableItems: [],
      selectedItem: [],
      /**
       * 検索パラメータ.
       * @type {Object}
       */
      searchCondition: this.defaultSearchCondition(),
      obj: this,
      event: 'confirm',
      infoMessage: null,
      registerMessage: null,
      isDeparturePlace: false,
      departmentModalSearchCondition: {},
    }
  },

  created() {
    init(this)
  },

  computed: {
    locale() {
      let lang = this.$store.getters['authentication/getLanguage']
      return lang
    },
    searchConditionInfo() {
      const searchConditionInfo = this.$store.getters['init/getScreens'](
        screenId
      )('search_condition')
      return {
        header: searchConditionInfo.title.label,
        clear: searchConditionInfo.clear,
        search: searchConditionInfo.search,
        inputComponents: [
          {
            // 出荷日(荷積日)
            type: 'dateFromTo',
            fromId: 'departureBeginDate',
            toId: 'departureEndDate',
            fromColumnInfo: searchConditionInfo.departure_begin_date,
            toColumnInfo: searchConditionInfo.departure_end_date,
            locale: this.locale,
            fromRules: [
              this.INPUT_TYPE_CHECK.REQUIRED,
              this.INPUT_TYPE_CHECK.DATERANGE,
            ],
            toRules: [this.INPUT_TYPE_CHECK.REQUIRED],
          },
          {
            // 搬入日(荷卸日)
            type: 'dateFromTo',
            fromId: 'arrivalBeginDate',
            toId: 'arrivalEndDate',
            fromColumnInfo: searchConditionInfo.arrival_begin_date,
            toColumnInfo: searchConditionInfo.arrival_end_date,
            locale: this.locale,
            fromRules: [
              this.INPUT_TYPE_CHECK.REQUIRED,
              this.INPUT_TYPE_CHECK.DATERANGE,
            ],
            toRules: [this.INPUT_TYPE_CHECK.REQUIRED],
          },
          {
            // 配車ステータス
            type: 'checkboxgroup',
            id: 'planningStatus',
            columnInfo: searchConditionInfo.planning_status,
            options: getListOptions(this.MASTER_CODE.PLANNING_STATUS, false),
          },
          {
            // 輸送依頼状態
            type: 'checkboxgroup',
            id: 'status',
            columnInfo: searchConditionInfo.status,
            options: getListOptions(this.MASTER_CODE.STATUS, false),
          },
          {
            // 許可証チェック状態
            type: 'checkboxgroup',
            id: 'permitCheckStatus',
            columnInfo: searchConditionInfo.permit_check_status,
            options: getListOptions(
              this.MASTER_CODE.PERMIT_CHECK_STATUS,
              false
            ),
          },
          {
            // 出荷場所(荷積場所)
            type: 'referenceCodeName',
            codeId: 'departurePlaceCode',
            nameId: 'departurePlaceName',
            codeColumnInfo: searchConditionInfo.departure_place_code,
            nameColumnInfo: searchConditionInfo.departure_place_name,
            btnColumnInfo: searchConditionInfo.sub_modal_departure_place,
            codeRules: [this.INPUT_TYPE_CHECK.ALPHANUMERIC_CODE],
            codeMaxLength: 10,
            nameMaxLength: 30,
            onClickCallback: () => {
              this.isDeparturePlace = true
              this.openSubModal(this, 'place-modal')
            },
          },
          {
            // 搬入場所(荷卸場所)
            type: 'referenceCodeName',
            codeId: 'arrivalPlaceCode',
            nameId: 'arrivalPlaceName',
            codeColumnInfo: searchConditionInfo.arrival_place_code,
            nameColumnInfo: searchConditionInfo.arrival_place_name,
            btnColumnInfo: searchConditionInfo.sub_modal_arrival_place,
            codeRules: [this.INPUT_TYPE_CHECK.ALPHANUMERIC_CODE],
            codeMaxLength: 10,
            nameMaxLength: 30,
            onClickCallback: () => {
              this.isDeparturePlace = false
              this.openSubModal(this, 'place-modal')
            },
          },
          {
            // 運送会社
            type: 'referenceCodeName',
            codeId: 'carrierCode',
            nameId: 'carrierName',
            codeColumnInfo: searchConditionInfo.carrier_code,
            nameColumnInfo: searchConditionInfo.carrier_name,
            btnColumnInfo: searchConditionInfo.sub_modal_carrier,
            codeRules: [this.INPUT_TYPE_CHECK.ALPHANUMERIC_CODE],
            codeMaxLength: 10,
            nameMaxLength: 30,
            onClickCallback: () => {
              this.departmentModalSearchCondition = {}
              this.departmentModalSearchCondition.departmentKind =
                DEPARTMENT_KIND.CARRIER
              this.openSubModal(this, 'department-modal')
            },
          },
          {
            // 受注オーダーID
            type: 'text',
            id: 'orderId',
            maxLength: 10,
            columnInfo: searchConditionInfo.order_id,
            rules: [this.INPUT_TYPE_CHECK.ONLY_NUMERIC],
          },
          {
            // 受付番号
            type: 'text',
            id: 'receiptNo',
            columnInfo: searchConditionInfo.receipt_no,
            rules: [this.INPUT_TYPE_CHECK.ALPHANUMERIC_CODE],
            maxLength: 30,
          },
          {
            // 摘要01(機種)
            type: 'text',
            id: 'description01',
            columnInfo: searchConditionInfo.description01,
            maxLength: 50,
          },
          {
            // 摘要02(機番)
            type: 'text',
            id: 'description02',
            columnInfo: searchConditionInfo.description02,
            maxLength: 50,
          },
          {
            // 摘要03
            type: 'text',
            id: 'description03',
            columnInfo: searchConditionInfo.description03,
            maxLength: 50,
          },
          {
            // 摘要04
            type: 'text',
            id: 'description04',
            columnInfo: searchConditionInfo.description04,
            maxLength: 50,
          },
          {
            // 摘要05
            type: 'text',
            id: 'description05',
            columnInfo: searchConditionInfo.description05,
            maxLength: 50,
          },
        ],
      }
    },
    resultControlInfo() {
      return this.$store.getters['init/getScreens'](screenId)(
        'result_ctrl_info'
      )
    },
    fileInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'file'
      )
    },
    messageInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'message'
      )
    },
    breadcrumbItems() {
      return [
        {
          name: this.$store.getters['init/getScreenName'](screenId),
        },
      ]
    },
    /**
     * テーブル列定義リスト.
     * @return {Array}
     */
    fields() {
      return this.$store.getters['init/getFields'](screenId)(
        this.DOMAIN_NAME.FIELDS
      )
    },

    /**
     * ボタン情報リスト.
     * @return {Array}
     */
    buttonItems() {
      return [
        // ボタングループ#1
        [
          // 詳細ボタン
          {
            variant: 'primary',
            icon: 'list-alt',
            columnInfo: this.resultControlInfo.detail,
            disabled: !this.isSelected,
            onClickCallback: () => {
              goDetail(this)
            },
          },
          // 発注ボタン
          {
            variant: 'primary',
            icon: 'list-alt',
            columnInfo: this.resultControlInfo.place_order,
            disabled: !this.multiSelected,
            onClickCallback: () => {
              goPlaceOrder(this)
            },
          },
          // 承認ボタン
          {
            variant: 'primary',
            icon: 'copy',
            columnInfo: this.resultControlInfo.approve,
            disabled: !this.isSelected,
            onClickCallback: () => {
              goApprove(this)
            },
          },
        ],
        // ボタングループ#2
        [],
      ]
    },

    /**
     * 単数選択しているか.
     * @return {Boolean}
     */
    isSelected() {
      return this.selectedItem.length === 1
    },
    /**
     * 複数選択しているか.
     * @return {Boolean}
     */
    multiSelected() {
      return this.selectedItem.length >= 1
    },
  },

  methods: {
    getListOptions,
    openSubModal,
    reSearch,
    search,
    clear,
    placeOrder,
    approve,
    /**
     * デフォルト検索パラメータを返します.
     * @return {Object}
     */
    defaultSearchCondition() {
      let today = this.$moment(new Date()).format('YYYY-MM-DD')
      return {
        departureBeginDate: today,
        departureEndDate: today,
        arrivalBeginDate: today,
        arrivalEndDate: today,
        planningStatus: [],
        status: [],
        permitCheckStatus: [],
        departurePlaceCode: null,
        departurePlaceName: null,
        arrivalPlaceCode: null,
        arrivalPlaceName: null,
        carrierCode: null,
        carrierName: null,
        orderId: null,
        receiptNo: null,
        description01: null, //摘要01(機種)
        description02: null, //摘要02(機番)
        description03: null,
        description04: null,
        description05: null,
        size: getSearchMaxCount(),
        screenId: screenId,
        domainName: 'result_fields',
      }
    },

    /**
     * 部署マスタから取得データ設定
     */
    afterCloseSetDepartment(id, code, name) {
      this.searchCondition.carrierCode = code
      this.searchCondition.carrierName = name
    },

    /**
     * 部署情報解除
     */
    afterCloseUnsetDepartment() {
      this.searchCondition.carrierCode = null
      this.searchCondition.carrierName = null
    },

    /**
     * 場所マスタ参照から取得データ設定
     */
    afterCloseSetPlace(selectedItem) {
      if (this.isDeparturePlace) {
        this.searchCondition.departurePlaceCode = selectedItem.place_code
        this.searchCondition.departurePlaceName = selectedItem.place_name
      } else {
        this.searchCondition.arrivalPlaceCode = selectedItem.place_code
        this.searchCondition.arrivalPlaceName = selectedItem.place_name
      }
    },
    /**
     * 場所情報解除
     */
    afterCloseUnsetPlace() {
      if (this.isDeparturePlace) {
        this.searchCondition.departurePlaceCode = null
        this.searchCondition.departurePlaceName = null
      } else {
        this.searchCondition.arrivalPlaceCode = null
        this.searchCondition.arrivalPlaceName = null
      }
    },

    setErrorMsg(msg) {
      this.$emit('set-error-msg', msg)
    },

    setWarnMsg(msg) {
      this.$emit('set-warn-msg', msg)
    },

    setInfoMsg(msg) {
      this.$emit('set-info-msg', msg)
    },

    onRowSelected(selectedItems) {
      this.selectedItem = selectedItems
    },

    onMaxCountChanged(maxCount) {
      this.searchCondition.size = maxCount
    },

    xx(condition) {
      console.log(condition)
      alert(condition.periodDateFrom)
    },
  },
}
</script>

<style scoped>
#footer {
  bottom: 0;
  position: fixed;
  width: 96.8%;
  height: 50px;
  margin-bottom: 20px;
  box-sizing: border-box;
  color: white;
  /* margin-left:300px; */
  text-align: center;
}
</style>
