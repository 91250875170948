import { createAuthenticaionService, makeQueryParams } from '@/services/index'

const BASE_URL = `${process.env.VUE_APP_API_URL}transportrequest/`

class TransportRequestService {
  getAll() {
    return createAuthenticaionService(BASE_URL).get('/')
  }

  get(id) {
    return createAuthenticaionService(BASE_URL).get(`/${id}`)
  }

  update(id, data) {
    return createAuthenticaionService(BASE_URL).put(`/${id}`, data)
  }

  search(searchCondition) {
    const param = makeQueryParams(searchCondition)
    return createAuthenticaionService(`${BASE_URL}search/`).get(`/?${param}`)
  }
}

export default new TransportRequestService()
