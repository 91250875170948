import {
  openMsgModal,
  openSubModal,
  convertSnakeCace,
  convertCamelCace,
} from '@/common/Common.js'
import { MSG_TYPE, DETAIL_SCREEN_KBN } from '@/common/const.js'
import PlanningEvaluationService from './PlanningEvaluationService'
import _ from 'lodash'

export function init(obj) {
  if (obj.screenKbn != DETAIL_SCREEN_KBN.NEW) {
    obj.$store.dispatch('init/setServiceLoading')
    PlanningEvaluationService.get(obj.planningEvaluationPatternId)
      .then(
        (response) => {
          obj.PlanningEvaluation = response.data

          // parameters
          var planningParameters = convertSnakeCace(
            obj.PlanningEvaluation.planningEvaluationPatternDetails
          )

          planningParameters.sort(function (a, b) {
            return a.display_order - b.display_order
          })
          obj.PlanningEvaluation.planningEvaluationPatternDetails = planningParameters

          if (obj.screenKbn == DETAIL_SCREEN_KBN.COPY) {
            obj.PlanningEvaluation.planningEvaluationPatternId = null
            obj.PlanningEvaluation.registrationTimeDisplay = null
            obj.PlanningEvaluation.registrationUser = null
            obj.PlanningEvaluation.updateTimeDisplay = null
            obj.PlanningEvaluation.updateUser = null
            obj.PlanningEvaluation.planningEvaluationPatternDetails.forEach(
              (element) => {
                element.planning_evaluation_detail_id = null
                element.planning_evaluation_pattern_id = null
                element.registration_time = null
                element.registration_time_display = null
                element.registration_user = null
                element.update_time = null
                element.update_time_display = null
                element.update_user = null
              }
            )
          } else {
            obj.PlanningEvaluation.planningEvaluationPatternId =
              obj.planningEvaluationPatternId
          }
          obj.planningEvaluationPatternDetails =
            obj.PlanningEvaluation.planningEvaluationPatternDetails

          obj.oriData = _.cloneDeep(obj.PlanningEvaluation)
          obj.oriPlanningEvaluationPatternDetails = _.cloneDeep(
            obj.planningEvaluationPatternDetails
          )
        },
        (e) => {
          // エラーモーダル表示
          var msg
          if (e.response == undefined) {
            msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
          } else if (e.response.status == 404) {
            msg = obj.$store.getters['init/getMessage']('YZ00MG996E')
          } else {
            msg = e.response.data.message
          }
          openMsgModal(obj, MSG_TYPE.E, msg)
        }
      )
      .finally(() => {
        obj.$store.dispatch('init/setServiceUnLoading')
      })
  }
}

export async function goRegister(obj) {
  const validated = await obj.$refs['tab-group'].$refs[
    'base-tab-group'
  ].validate()
  if (!validated) return

  obj.registerMessage = obj.$store.getters['init/getMessage'](
    'YZ00MG994Q',
    obj.detailCtrlInfo.register.label
  )
  openSubModal(obj, 'register-modal')
}

export async function register(obj) {
  obj.$bvModal.hide('register-modal')

  const validated = await obj.$refs['tab-group'].$refs[
    'base-tab-group'
  ].validate()
  if (!validated) return

  let registerData = _.cloneDeep(obj.PlanningEvaluation)

  registerData.planningEvaluationPatternDetails = changeToCamelCaseKey(
    registerData.planningEvaluationPatternDetails
  )
  var callApi
  if (obj.screenKbn != DETAIL_SCREEN_KBN.DETAIL) {
    // 新規
    callApi = PlanningEvaluationService.create(registerData)
  } else {
    // 詳細
    callApi = PlanningEvaluationService.update(
      registerData.planningEvaluationPatternId,
      registerData
    )
  }

  obj.$store.dispatch('init/setServiceLoading')
  callApi
    .then(
      () => {
        // 情報モーダル表示
        openSubModal(obj, 'planning-evaluation-detail-info-modal')
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message + e.response.data.messageDetail
        }
        openMsgModal(this, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

export function clear(obj) {
  obj.PlanningEvaluation = _.cloneDeep(obj.oriData)
  obj.planningEvaluationPatternDetails = _.cloneDeep(
    obj.oriPlanningEvaluationPatternDetails
  )
}

function changeToCamelCaseKey(items) {
  var camelItems = convertCamelCace(items)

  return camelItems
}
