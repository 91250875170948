<template>
  <div>
    <DetailPageLayout
      :breadcrumbItems="breadcrumbItems"
      :buttonItems="buttonItems"
    >
      <template #top>
        <BaseTabGroup
          ref="tab-group"
          :inputModel="PlanningOrder"
          :inputComponents="inputComponents"
        >
          <template v-slot:tab3TableDown>
            <!-- テーブル -->
            <b-table
              data-class="base-table"
              class="table table-bordered base-table"
              bordered
              hover
              no-border-collapse
              responsive
              :selectable="true"
              small
              sticky-header
              outlined
              select-mode="range"
              @row-selected="onRowSelected"
              :fields="fields"
              :items="tableItems"
              v-bind="$attrs"
              v-on="$listeners"
            >
            </b-table>
          </template>
        </BaseTabGroup>
      </template>
    </DetailPageLayout>
    <!-- 場所モーダル -->
    <PlaceModal
      id="place-modal"
      type="entry"
      selectMode="single"
      @after-close-set="afterCloseSetPlace"
      @after-close-unset="afterCloseUnsetPlace"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />
    <!-- 部署マスタモーダル -->
    <DepartmentModal
      id="order-department-modal"
      type="entry"
      selectMode="single"
      :selectedSearchCondition="departmentModalSearchCondition"
      @after-close-set="afterCloseSetDepartment"
      @after-close-unset="afterCloseUnsetDepartment"
    />
    <!-- 車両マスタモーダル -->
    <VehicleModal
      id="vehicle-modal"
      type="entry"
      selectMode="single"
      @after-close-set="afterCloseSetVehicle"
      @after-close-unset="afterCloseUnsetVehicle"
    />
    <!-- 情報モーダル -->
    <InfoModal
      id="PlanningOrder-detail-info-modal"
      :columnInfos="messageInfo"
      :message="infoMessage"
      @after-close="back(obj)"
    />
    <!-- 登録モーダル -->
    <ConfirmModal
      id="register-modal"
      :columnInfos="messageInfo"
      :message="registerMessage"
      @confirm="register(obj)"
    />
  </div>
</template>

<script>
import PlanningOrderInfo from './PlanningOrderInfo'
import { getListOptions, openSubModal } from '@/common/Common.js'
import { DEPARTMENT_KIND, INTEGER_MAX_MIN, SLOT_NAME } from '@/common/const.js'
import { getBreadcrumbItems, back } from '@/common/screen-common.js'
import {
  init,
  goRegister,
  register,
  setPlaceInfo,
  unsetPlaceInfo,
  clear,
  setEditItem,
} from '@/plan/planningOrder/PlanningOrderDetail.js'

export default {
  name: 'planningOrderDetail',
  components: {
    PlaceModal: () => import('@/master/place/PlaceModal.vue'),
    DepartmentModal: () => import('@/master/department/DepartmentModal.vue'),
    VehicleModal: () => import('@/master/vehicle/VehicleModal.vue'),
  },
  props: {
    beforeScreenRouteInfo: {
      type: Object,
    },
    planningOrderId: {
      type: Number,
      default: -1,
    },
    screenKbn: {
      type: Number,
    },
  },

  data() {
    return {
      tableItems: [],
      selectedItem: [],
      PlanningOrder: new PlanningOrderInfo(),
      obj: this,
      placeModalKbn: -1,
      registerMessage: null,
      oriData: new PlanningOrderInfo(),
      departmentModalKbn: -1,
      departmentModalSearchCondition: {},
    }
  },

  created() {
    init(this)
  },

  computed: {
    detailCtrlInfo() {
      return this.$store.getters['init/getScreens'](
        this.SCREEN_ID.PLANNING_ORDER_DETAIL
      )('detail_ctrl_info')
    },
    detailInfo() {
      let info = this.$store.getters['init/getScreens'](
        this.SCREEN_ID.PLANNING_ORDER_DETAIL
      )('detail_info')
      return setEditItem(info)
    },
    messageInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'message'
      )
    },
    breadcrumbItems() {
      return getBreadcrumbItems(
        this.beforeScreenRouteInfo,
        this.SCREEN_ID.PLANNING_ORDER_DETAIL
      )
    },

    /**
     * ボタン情報リスト.
     * @return {Array}
     */
    buttonItems() {
      return [
        // ボタングループ#1
        [
          // 登録ボタン
          {
            variant: 'success',
            icon: 'edit',
            columnInfo: this.detailCtrlInfo.register,
            onClickCallback: () => {
              goRegister(this)
            },
          },
          // クリアボタン
          {
            variant: 'success',
            icon: 'times-circle',
            columnInfo: this.detailCtrlInfo.clear,
            onClickCallback: () => {
              clear(this)
            },
          },
        ],
        // ボタングループ#2
        [
          // 戻るボタン
          {
            variant: 'success',
            icon: 'times-circle',
            columnInfo: this.detailCtrlInfo.back,
            onClickCallback: () => {
              back(this)
            },
          },
        ],
      ]
    },
    inputComponents() {
      return [
        {
          // 受注オーダタブ
          label: this.detailInfo.tab1.label,
          inputComponents: [
            {
              // 配車オーダID
              type: 'text',
              id: 'planningOrderId',
              columnInfo: this.detailInfo.planning_order_id,
            },
            {
              // 受注オーダ明細ID
              type: 'text',
              id: 'orderDetailId',
              columnInfo: this.detailInfo.order_detail_id,
            },
            {
              // 受付番号
              type: 'text',
              id: 'receiptNo',
              columnInfo: this.detailInfo.receipt_no,
              rules: [
                this.INPUT_TYPE_CHECK.REQUIRED,
                this.INPUT_TYPE_CHECK.ALPHANUMERIC_CODE,
              ],
              maxLength: 30,
            },
            {
              // 配車区間番号
              type: 'text',
              id: 'planningSectionNo',
              columnInfo: this.detailInfo.planning_section_no,
            },
            {
              // 配車荷物番号
              type: 'text',
              id: 'planningCargoNo',
              columnInfo: this.detailInfo.planning_cargo_no,
            },
            {
              // 元配車オーダ番号
              type: 'text',
              id: 'orginPlanningOrderId',
              columnInfo: this.detailInfo.orgin_planning_order_id,
            },
            {
              // ブロック
              type: 'select',
              id: 'blockCode',
              columnInfo: this.detailInfo.block_code,
              options: getListOptions(this.MASTER_CODE.BLOCK),
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onChangeCallback: () => {},
            },
            {
              // 被荷纏めID
              type: 'text',
              id: 'packupedId',
              columnInfo: this.detailInfo.packuped_id,
            },
            {
              // 荷纏めID
              type: 'text',
              id: 'packupId',
              columnInfo: this.detailInfo.packup_id,
            },
            {
              // 荷纏めフラグ
              type: 'select',
              id: 'packupFlag',
              columnInfo: this.detailInfo.packup_flag,
              options: getListOptions(this.MASTER_CODE.PACKUP_FLAG),
              onChangeCallback: () => {},
            },
            {
              // 配送纏めフラグ
              type: 'select',
              id: 'deliveryPackupFlag',
              columnInfo: this.detailInfo.delivery_packup_flag,
              options: getListOptions(this.MASTER_CODE.PACKUP_FLAG),
              onChangeCallback: () => {},
            },
            {
              // 区間分割フラグ
              type: 'select',
              id: 'sectionPartitionFlag',
              columnInfo: this.detailInfo.section_partition_flag,
              options: getListOptions(this.MASTER_CODE.SECTION_PARTITION_FLAG),
              onChangeCallback: () => {},
            },
            {
              // 数量分割フラグ
              type: 'select',
              id: 'countPartitionFlag',
              columnInfo: this.detailInfo.count_partition_flag,
              options: getListOptions(this.MASTER_CODE.COUNT_PARTITION_FLAG),
              onChangeCallback: () => {},
            },
            {
              // 親子フラグ
              type: 'select',
              id: 'pntchdKind',
              columnInfo: this.detailInfo.pntchd_kind,
              options: getListOptions(this.MASTER_CODE.PNTCHD_KIND),
              onChangeCallback: () => {},
            },
            {
              // 出発可能日時 (Begin)
              type: 'dateTime',
              dateId: 'departureBeginDate',
              timeId: 'departureBeginTime',
              dateColumnInfo: this.detailInfo.departure_begin_date,
              timeColumnInfo: this.detailInfo.departure_begin_time,
              dateRules: [this.INPUT_TYPE_CHECK.REQUIRED],
              //timeRules: [this.INPUT_TYPE_CHECK.REQUIRED],
            },
            {
              // 出発可能日時 (End)
              type: 'dateTime',
              dateId: 'departureEndDate',
              timeId: 'departureEndTime',
              dateColumnInfo: this.detailInfo.departure_end_date,
              timeColumnInfo: this.detailInfo.departure_end_time,
              dateRules: [this.INPUT_TYPE_CHECK.REQUIRED],
              //timeRules: [this.INPUT_TYPE_CHECK.REQUIRED],
            },
            {
              // 出発場所名
              type: 'referenceCodeName',
              codeId: 'departurePlaceCode',
              nameId: 'departurePlaceName',
              codeColumnInfo: this.detailInfo.departure_place_code,
              nameColumnInfo: this.detailInfo.departure_place_name,
              btnColumnInfo: this.detailInfo.sub_modal_departure_place,
              codeRules: [this.INPUT_TYPE_CHECK.REQUIRED],
              nameRules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onClickCallback: () => {
                //...ボタン
                this.placeModalKbn = 1
                this.openSubModal(this, 'place-modal')
              },
            },
            {
              // 出発場所住所
              type: 'referenceCodeName',
              codeId: 'departurePostalCode',
              nameId: 'departureAddress',
              codeColumnInfo: this.detailInfo.departure_postal_code,
              nameColumnInfo: this.detailInfo.departure_address,
            },
            {
              // 出発場所指定フラグ
              type: 'select',
              id: 'departurePlaceAssignFlag',
              columnInfo: this.detailInfo.departure_place_assign_flag,
              options: getListOptions(this.MASTER_CODE.PLACE_ASSIGN_FLAG),
              onChangeCallback: () => {},
            },
            {
              // 出発時間指定区分
              type: 'select',
              id: 'departureTimeAssignKind',
              columnInfo: this.detailInfo.departure_time_assign_kind,
              options: getListOptions(this.MASTER_CODE.TIME_ASSIGN_KIND),
              onChangeCallback: () => {},
            },
            {
              // 出発時間厳格度
              type: 'select',
              id: 'departureTimeStrict',
              columnInfo: this.detailInfo.departure_time_strict,
              options: getListOptions(this.MASTER_CODE.TIME_STRICT),
              onChangeCallback: () => {},
            },
            {
              // 搬入可能日時 (Begin)
              type: 'dateTime',
              dateId: 'arrivalBeginDate',
              timeId: 'arrivalBeginTime',
              dateColumnInfo: this.detailInfo.arrival_begin_date,
              timeColumnInfo: this.detailInfo.arrival_begin_time,
              dateRules: [this.INPUT_TYPE_CHECK.REQUIRED],
              //timeRules: [this.INPUT_TYPE_CHECK.REQUIRED],
            },
            {
              // 搬入可能日時 (End)
              type: 'dateTime',
              dateId: 'arrivalEndDate',
              timeId: 'arrivalEndTime',
              dateColumnInfo: this.detailInfo.arrival_end_date,
              timeColumnInfo: this.detailInfo.arrival_end_time,
              dateRules: [this.INPUT_TYPE_CHECK.REQUIRED],
              //timeRules: [this.INPUT_TYPE_CHECK.REQUIRED],
            },

            {
              // 搬入場所名
              type: 'referenceCodeName',
              codeId: 'arrivalPlaceCode',
              nameId: 'arrivalPlaceName',
              codeColumnInfo: this.detailInfo.arrival_place_code,
              nameColumnInfo: this.detailInfo.arrival_place_name,
              btnColumnInfo: this.detailInfo.sub_modal_arrival_place,
              codeRules: [this.INPUT_TYPE_CHECK.REQUIRED],
              nameRules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onClickCallback: () => {
                //...ボタン
                this.placeModalKbn = 2
                this.openSubModal(this, 'place-modal')
              },
            },
            {
              // 搬入場所住所
              type: 'referenceCodeName',
              codeId: 'arrivalPostalCode',
              nameId: 'arrivalAddress',
              codeColumnInfo: this.detailInfo.arrival_postal_code,
              nameColumnInfo: this.detailInfo.arrival_address,
            },
            {
              // 搬入時間指定区分
              type: 'select',
              id: 'arrivalTimeAssignKind',
              columnInfo: this.detailInfo.arrival_time_assign_kind,
              options: getListOptions(this.MASTER_CODE.TIME_ASSIGN_KIND),
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onChangeCallback: () => {},
            },
            {
              // 搬入時間厳格度
              type: 'select',
              id: 'arrivalTimeStrict',
              columnInfo: this.detailInfo.arrival_time_strict,
              options: getListOptions(this.MASTER_CODE.TIME_STRICT),
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onChangeCallback: () => {},
            },
            {
              // 総数量
              type: 'text',
              id: 'totalQuantity',
              columnInfo: this.detailInfo.total_quantity,
              minValue: INTEGER_MAX_MIN.MIN,
              maxValue: INTEGER_MAX_MIN.MAX,
              rules: [
                this.INPUT_TYPE_CHECK.REQUIRED,
                this.INPUT_TYPE_CHECK.ONLY_NUMERIC,
                this.INPUT_TYPE_CHECK.MINVALUE,
                this.INPUT_TYPE_CHECK.MAXVALUE,
              ],
            },
            {
              // 総質量
              type: 'text',
              id: 'totalWeight',
              columnInfo: this.detailInfo.total_weight,
              rules: [
                this.INPUT_TYPE_CHECK.REQUIRED,
                this.INPUT_TYPE_CHECK.FLOAT,
              ],
            },
            {
              // 総容量
              type: 'text',
              id: 'totalVolume',
              columnInfo: this.detailInfo.total_volume,
              rules: [
                this.INPUT_TYPE_CHECK.REQUIRED,
                this.INPUT_TYPE_CHECK.FLOAT,
              ],
            },
            // {
            //   // 配車計画グループ
            //   type: 'select',
            //   id: 'planningGroupCode',
            //   columnInfo: this.detailInfo.planning_group_code,
            //   options: getListOptions(this.MASTER_CODE.PLANNING_GROUP),
            //   onChangeCallback: () => {},
            // },
            {
              // 出発条件
              type: 'text',
              id: 'departureCondition',
              columnInfo: this.detailInfo.departure_condition,
              maxlength: 40,
            },
            {
              // 到着条件
              type: 'text',
              id: 'arrivalCondition',
              columnInfo: this.detailInfo.arrival_condition,
              maxlength: 40,
            },
            {
              // 備考
              type: 'text',
              id: 'note',
              columnInfo: this.detailInfo.note,
              maxlength: 60,
            },
            {
              // 配車ステータス
              type: 'select',
              id: 'planningStatus',
              columnInfo: this.detailInfo.planning_status,
              options: getListOptions(this.MASTER_CODE.PLANNING_STATUS),
              onChangeCallback: () => {},
            },
            {
              // 指定運送会社
              type: 'referenceCodeName',
              codeId: 'assignCarrierCode',
              nameId: 'assignCarrierName',
              codeColumnInfo: this.detailInfo.assign_carrier_code,
              nameColumnInfo: this.detailInfo.assign_carrier_name,
              btnColumnInfo: this.detailInfo.sub_modal_assign_carrier,
              //rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onClickCallback: () => {
                this.departmentModalKbn = 1
                this.departmentModalSearchCondition = {}
                this.departmentModalSearchCondition.departmentKind =
                  DEPARTMENT_KIND.CARRIER
                openSubModal(this, 'order-department-modal')
              },
            },
            {
              // 指定車両
              type: 'referenceCodeName',
              codeId: 'assignVehicleCode',
              nameId: 'assignVehicleName',
              codeColumnInfo: this.detailInfo.assign_vehicle_code,
              nameColumnInfo: this.detailInfo.assign_vehicle_name,
              btnColumnInfo: this.detailInfo.sub_modal_assign_vehicle,
              //rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onClickCallback: () => {
                openSubModal(this, 'vehicle-modal')
              },
            },
            {
              // トリップ指定
              type: 'text',
              id: 'assignTripNo',
              columnInfo: this.detailInfo.assign_trip_no,
              rules: [this.INPUT_TYPE_CHECK.NUMERIC],
              maxlength: 2,
            },
            {
              // ドロップ指定
              type: 'text',
              id: 'assignDropNo',
              columnInfo: this.detailInfo.assign_drop_no,
              rules: [this.INPUT_TYPE_CHECK.NUMERIC],
              maxlength: 3,
            },
            {
              // 自動配車対象外フラグ
              type: 'select',
              id: 'autoPlanningExcludeFlag',
              columnInfo: this.detailInfo.auto_planning_exclude_flag,
              options: getListOptions(
                this.MASTER_CODE.AUTO_PLANNING_EXCLUDE_FLAG
              ),
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onChangeCallback: () => {},
            },
            {
              // オーダ区分
              type: 'select',
              id: 'orderKind',
              columnInfo: this.detailInfo.order_kind,
              options: getListOptions(this.MASTER_CODE.ORDER_KIND),
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onChangeCallback: () => {},
            },
            {
              // 積置き指定
              type: 'select',
              id: 'preloadKind',
              columnInfo: this.detailInfo.preload_kind,
              options: getListOptions(this.MASTER_CODE.PRELOAD_KIND),
              onChangeCallback: () => {},
            },
            {
              // 積置き指定日時
              type: 'dateTime',
              dateId: 'preloadAssignDate',
              timeId: 'preloadBeginTime',
              dateColumnInfo: this.detailInfo.preload_assign_date,
              timeColumnInfo: this.detailInfo.preload_begin_time,
            },
            {
              // 輸送形態区分
              type: 'select',
              id: 'transportTypeKind',
              columnInfo: this.detailInfo.transport_type_kind,
              options: getListOptions(this.MASTER_CODE.TRANSPORT_TYPE_KIND),
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onChangeCallback: () => {},
            },
            {
              // 輸送距離区分
              type: 'select',
              id: 'transportDistanceKind',
              columnInfo: this.detailInfo.transport_distance_kind,
              options: getListOptions(this.MASTER_CODE.TRANSPORT_DISTANCE_KIND),
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onChangeCallback: () => {},
            },
            {
              // オーダ距離
              type: 'text',
              id: 'orderDistance',
              columnInfo: this.detailInfo.order_distance,
              //rules: [this.INPUT_TYPE_CHECK.REQUIRED],
            },
            {
              // 変更区分
              type: 'text',
              id: 'changeKind',
              columnInfo: this.detailInfo.change_kind,
              //rules: [this.INPUT_TYPE_CHECK.REQUIRED],
            },
            {
              // 変更前割付車両
              type: 'text',
              id: 'oldVehicleId',
              columnInfo: this.detailInfo.old_vehicle_id,
              //rules: [this.INPUT_TYPE_CHECK.REQUIRED],
            },

            {
              // 登録日時表示
              type: 'text',
              id: 'registrationTimeDisplay',
              columnInfo: this.detailInfo.registration_time_display,
            },
            {
              // 登録ユーザ
              type: 'text',
              id: 'registrationUser',
              columnInfo: this.detailInfo.registration_user,
            },
            {
              // 更新日時表示
              type: 'text',
              id: 'updateTimeDisplay',
              columnInfo: this.detailInfo.update_time_display,
            },
            {
              // 更新ユーザ
              type: 'text',
              id: 'updateUser',
              columnInfo: this.detailInfo.update_user,
            },
            {
              // 削除ステータス
              type: 'select',
              id: 'deleteStatus',
              columnInfo: this.detailInfo.delete_status,
              options: getListOptions(this.MASTER_CODE.DELETE_STATUS),
              onChangeCallback: () => {},
            },
            {
              // 荷主名
              type: 'text',
              id: 'ownerName',
              columnInfo: this.detailInfo.owner_name,
            },
            {
              // 特約店名
              type: 'text',
              id: 'distributorName',
              columnInfo: this.detailInfo.distributor_name,
            },
            {
              // 荷受先名
              type: 'text',
              id: 'consigneeName',
              columnInfo: this.detailInfo.consignee_name,
            },
            // {
            //   // 受付元
            //   type: 'text',
            //   id: 'orderEntryKind',
            //   columnInfo: this.detailInfo.order_entry_kind,
            //   rules: [this.INPUT_TYPE_CHECK.REQUIRED],
            // },
            // {
            //   // 荷姿名
            //   type: 'text',
            //   id: 'productName',
            //   columnInfo: this.detailInfo.product_name,
            // },
          ],
        },
        {
          // 配車オーダー予備タブ
          label: this.detailInfo.tab2.label,
          inputComponents: [
            {
              // 車両条件１
              type: 'select',
              id: 'vehicleCondition01',
              columnInfo: this.detailInfo.vehicle_condition01,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 車両条件２
              type: 'select',
              id: 'vehicleCondition02',
              columnInfo: this.detailInfo.vehicle_condition02,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 車両条件３
              type: 'select',
              id: 'vehicleCondition03',
              columnInfo: this.detailInfo.vehicle_condition03,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 車両条件４
              type: 'select',
              id: 'vehicleCondition04',
              columnInfo: this.detailInfo.vehicle_condition04,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 車両条件５
              type: 'select',
              id: 'vehicleCondition05',
              columnInfo: this.detailInfo.vehicle_condition05,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 車両条件６
              type: 'select',
              id: 'vehicleCondition06',
              columnInfo: this.detailInfo.vehicle_condition06,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 車両条件７
              type: 'select',
              id: 'vehicleCondition07',
              columnInfo: this.detailInfo.vehicle_condition07,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 車両条件８
              type: 'select',
              id: 'vehicleCondition08',
              columnInfo: this.detailInfo.vehicle_condition08,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 車両条件９
              type: 'select',
              id: 'vehicleCondition09',
              columnInfo: this.detailInfo.vehicle_condition09,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 車両条件１０
              type: 'select',
              id: 'vehicleCondition10',
              columnInfo: this.detailInfo.vehicle_condition10,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 車両条件１１
              type: 'select',
              id: 'vehicleCondition11',
              columnInfo: this.detailInfo.vehicle_condition11,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 車両条件１２
              type: 'select',
              id: 'vehicleCondition12',
              columnInfo: this.detailInfo.vehicle_condition12,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 車両条件１３
              type: 'select',
              id: 'vehicleCondition13',
              columnInfo: this.detailInfo.vehicle_condition13,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 車両条件１４
              type: 'select',
              id: 'vehicleCondition14',
              columnInfo: this.detailInfo.vehicle_condition14,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 車両条件１５
              type: 'select',
              id: 'vehicleCondition15',
              columnInfo: this.detailInfo.vehicle_condition15,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 車両条件１６
              type: 'select',
              id: 'vehicleCondition16',
              columnInfo: this.detailInfo.vehicle_condition16,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 車両条件１７
              type: 'select',
              id: 'vehicleCondition17',
              columnInfo: this.detailInfo.vehicle_condition17,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 車両条件１８
              type: 'select',
              id: 'vehicleCondition18',
              columnInfo: this.detailInfo.vehicle_condition18,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 車両条件１９
              type: 'select',
              id: 'vehicleCondition19',
              columnInfo: this.detailInfo.vehicle_condition19,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 車両条件２０
              type: 'select',
              id: 'vehicleCondition20',
              columnInfo: this.detailInfo.vehicle_condition20,
              options: getListOptions(this.MASTER_CODE.VEHICLE_CONDITION),
              onChangeCallback: () => {},
            },
            {
              // 配車オーダ予備１
              type: 'text',
              id: 'reserve01',
              columnInfo: this.detailInfo.reserve01,
              maxLength: 50,
            },
            {
              // 配車オーダ予備２
              type: 'text',
              id: 'reserve02',
              columnInfo: this.detailInfo.reserve02,
              maxLength: 50,
            },
            {
              // 配車オーダ予備３
              type: 'text',
              id: 'reserve03',
              columnInfo: this.detailInfo.reserve03,
              maxLength: 50,
            },
            {
              // 配車オーダ予備４
              type: 'text',
              id: 'reserve04',
              columnInfo: this.detailInfo.reserve04,
              maxLength: 50,
            },
            {
              // 配車オーダ予備５
              type: 'text',
              id: 'reserve05',
              columnInfo: this.detailInfo.reserve05,
              maxLength: 50,
            },
            {
              // 配車オーダ予備６
              type: 'text',
              id: 'reserve06',
              columnInfo: this.detailInfo.reserve06,
              maxLength: 50,
            },
            {
              //配車オーダ予備７
              type: 'text',
              id: 'reserve07',
              columnInfo: this.detailInfo.reserve07,
              maxLength: 50,
            },
            {
              // 配車オーダ予備８
              type: 'text',
              id: 'reserve08',
              columnInfo: this.detailInfo.reserve08,
              maxLength: 50,
            },
            {
              // 配車オーダ予備９
              type: 'text',
              id: 'reserve09',
              columnInfo: this.detailInfo.reserve09,
              maxLength: 50,
            },
            {
              // 配車オーダ予備１０
              type: 'text',
              id: 'reserve10',
              columnInfo: this.detailInfo.reserve10,
              maxLength: 50,
            },
            {
              // 配車オーダ予備１１
              type: 'text',
              id: 'reserve11',
              columnInfo: this.detailInfo.reserve11,
              maxLength: 50,
            },
            {
              // 配車オーダ予備１２
              type: 'text',
              id: 'reserve12',
              columnInfo: this.detailInfo.reserve12,
              maxLength: 50,
            },
            {
              // 配車オーダ予備１３
              type: 'text',
              id: 'reserve13',
              columnInfo: this.detailInfo.reserve13,
              maxLength: 50,
            },
            {
              // 配車オーダ予備１４
              type: 'text',
              id: 'reserve14',
              columnInfo: this.detailInfo.reserve14,
              maxLength: 50,
            },
            {
              // 配車オーダ予備１５
              type: 'text',
              id: 'reserve15',
              columnInfo: this.detailInfo.reserve15,
              maxLength: 50,
            },
            {
              // 配車オーダ予備１６
              type: 'text',
              id: 'reserve16',
              columnInfo: this.detailInfo.reserve16,
              maxLength: 50,
            },
            {
              // 配車オーダ予備１７
              type: 'text',
              id: 'reserve17',
              columnInfo: this.detailInfo.reserve17,
              maxLength: 50,
            },
            {
              // 配車オーダ予備１８
              type: 'text',
              id: 'reserve18',
              columnInfo: this.detailInfo.reserve18,
              maxLength: 50,
            },
            {
              // 配車オーダ予備１９
              type: 'text',
              id: 'reserve19',
              columnInfo: this.detailInfo.reserve19,
              maxLength: 50,
            },
            {
              // 配車オーダ予備２０
              type: 'text',
              id: 'reserve20',
              columnInfo: this.detailInfo.reserve20,
              maxLength: 50,
            },
          ],
        },
        {
          // 配車オーダー明細タブ
          label: this.detailInfo.tab3.label,
          slot: SLOT_NAME.TAB3_TABLE_DOWN,
          inputComponents: [],
        },
      ]
    },

    /**
     * テーブル列定義リスト.
     * @return {Array}
     */
    fields() {
      return this.$store.getters['init/getFields'](
        this.SCREEN_ID.PLANNING_ORDER_DETAIL
      )(this.DOMAIN_NAME.FIELDS)
    },
    /**
     * 情報メッセージ.
     * @return {String}
     */
    infoMessage() {
      return this.$store.getters['init/getMessage'](
        'YZ00MG995I',
        this.detailCtrlInfo.register.label
      )
    },
  },

  methods: {
    getListOptions,
    openSubModal,
    setPlaceInfo,
    unsetPlaceInfo,
    register,
    back,
    clear,

    /**
     * 部署マスタから取得データ設定
     */
    afterCloseSetDepartment(id, code, name) {
      // [department-modal] -> [order-department-modal]
      this.$bvModal.hide('order-department-modal')
      this.obj.PlanningOrder.assignCarrierId = id
      this.obj.PlanningOrder.assignCarrierCode = code
      this.obj.PlanningOrder.assignCarrierName = name
    },

    /**
     * 部署情報解除
     */
    afterCloseUnsetDepartment() {
      // [department-modal] -> [order-department-modal]
      this.$bvModal.hide('order-department-modal')
      this.obj.PlanningOrder.assignCarrierId = null
      this.obj.PlanningOrder.assignCarrierCode = null
      this.obj.PlanningOrder.assignCarrierName = null
    },
    afterCloseSetPlace(selectedItem) {
      setPlaceInfo(this.obj, selectedItem)
    },
    afterCloseUnsetPlace() {
      unsetPlaceInfo(this.obj)
    },
    afterCloseSetVehicle(selectedItem) {
      this.obj.PlanningOrder.assignVehicleId = selectedItem.vehicle_id
      this.obj.PlanningOrder.assignVehicleCode = selectedItem.vehicle_code
      this.obj.PlanningOrder.assignVehicleName = selectedItem.vehicle_name
      //this.obj.PlanningOrder.assignCarrierId = selectedItem.carrier_id
    },
    afterCloseUnsetVehicle() {
      this.obj.PlanningOrder.assignVehicleId = null
      this.obj.PlanningOrder.assignVehicleCode = null
      this.obj.PlanningOrder.assignVehicleName = null
      //this.obj.PlanningOrder.assignCarrierId = null
    },

    setErrorMsg(msg) {
      this.$emit('set-error-msg', msg)
    },

    setWarnMsg(msg) {
      this.$emit('set-warn-msg', msg)
    },
    onRowSelected(selectedItems) {
      this.selectedItem = selectedItems
    },
  },

  // mounted() {
  //     this.$refs.map.initialize()
  // }
}
</script>

<style lang="scss" scoped>
.detailForm {
  height: 730px;
  overflow-y: auto;
  overflow-x: hidden;
}
.data-group-role {
  border: 1px solid #e9e9e9;
  border-radius: 4px;
  padding: 14px;
}
.edit-button {
  margin-right: 8px;
}
</style>
