/**
 * 車両稼動情報データ.
 * @class
 * @exports
 */
export default class VehicleOperationInfo {
  vehicleOperationId = null
  vehicleId
  vehicleCode
  vehicleName
  numberplateAreaName
  numberplateClassNo
  numberplateKana
  numberplateSerialNo
  vehicleModel
  vehicleModelName
  vehicleCategory
  vehicleCategoryName
  maxLoadingWeight
  maxLoadingVolume
  preparationTime
  vehicleKind
  vehicleKindName
  managementVehicleKind
  managementVehicleKindName
  carrierCode
  carrierName
  vehicleBasePlaceCode
  vehicleBasePlaceName
  blockName
  vehicleOperationKind
  preloadable = 0
  scheduleBeginDate
  scheduleBeginTime
  scheduleBeginPlaceId
  scheduleBeginPlaceCode
  scheduleBeginPlaceName
  scheduleEndDate
  scheduleEndTime
  scheduleEndPlaceId
  scheduleEndPlaceCode
  scheduleEndPlaceName
  planBeginTime
  planBeginPlaceId
  planBeginPlaceName
  planEndTime
  planEndPlaceId
  planEndPlaceName
  vehiclePriorityOrder
  transportDistanceKind
  chargeKind = 0
  chargeAreaId01
  chargeAreaName01
  chargeAreaId02
  chargeAreaName02
  chargeAreaId03
  chargeAreaName03
  chargeAreaId04
  chargeAreaName04
  chargeAreaId05
  chargeAreaName05
  autoPlanningExcludeFlag = 0
  week
  weekday = 1
  displayOrder
  planningStatus
  planningStatusName
  changeNoticeStatus
  changeNoticeStatusName
  registrationTimeDisplay
  registrationUser
  updateTimeDisplay
  updateUser
  // null or 'changed'
  modifyFlag = null
}
