<template>
  <div>
    <DetailPageLayout
      :breadcrumbItems="breadcrumbItems"
      :buttonItems="buttonItems"
    >
      <template #top>
        <div class="tab-group">
          <BaseTabGroup
            ref="tab-group"
            :inputModel="PlanningEvaluation"
            :inputComponents="inputComponents"
          ></BaseTabGroup>
        </div>
      </template>
      <template #bottom>
        <b-card no-body>
          <b-table
            data-class="base-table"
            class="table table-bordered base-table"
            bordered
            hover
            no-border-collapse
            responsive
            :selectable="false"
            small
            sticky-header
            outlined
            :fields="planningEvaluationFieldInfo"
            :items="planningEvaluationPatternDetails"
            v-bind="$attrs"
            v-on="$listeners"
          >
            <template v-slot:cell(planning_evaluation_order)="row">
              <b-form-input
                :disabled="isBaseData || row.item.display_kind !== '2'"
                v-model="row.item.planning_evaluation_order"
                size="sm"
                type="number"
                v-bind="$attrs"
                v-on="$listeners"
              />
            </template>

            <template v-slot:cell(planning_evaluation_range)="row">
              <b-form-input
                :disabled="isBaseData || row.item.display_kind !== '2'"
                v-model="row.item.planning_evaluation_range"
                size="sm"
                type="number"
                v-bind="$attrs"
                v-on="$listeners"
              />
            </template>
          </b-table>
        </b-card>
      </template>
    </DetailPageLayout>
    <!-- 情報モーダル -->
    <InfoModal
      id="planning-evaluation-detail-info-modal"
      :columnInfos="messageInfo"
      :message="infoMessage"
      @after-close="back(obj)"
    />
    <!-- 登録モーダル -->
    <ConfirmModal
      id="register-modal"
      :columnInfos="messageInfo"
      :message="registerMessage"
      @confirm="register(obj)"
    />
  </div>
</template>

<script>
import PlanningEvaluationInfo from './PlanningEvaluationInfo'
import { getListOptions, openSubModal } from '@/common/Common.js'
import { getBreadcrumbItems, back } from '@/common/screen-common.js'
import {
  init,
  goRegister,
  register,
  clear,
} from './PlanningEvaluationDetail.js'

export default {
  name: 'planningEvaluationDetail',

  props: {
    beforeScreenRouteInfo: {
      type: Object,
    },
    planningEvaluationPatternId: {
      type: Number,
      default: -1,
    },
    screenKbn: {
      type: Number,
    },
  },

  data() {
    return {
      PlanningEvaluation: new PlanningEvaluationInfo(),
      obj: this,
      planningEvaluationPatternDetails: [],
      planningConstraintItems: [],
      registerMessage: null,
      oriData: new PlanningEvaluationInfo(),
      oriPlanningEvaluationPatternDetails: [],
    }
  },

  created() {
    init(this)
  },

  computed: {
    isBaseData() {
      return this.PlanningEvaluation.planningEvaluationPatternId === 0
    },
    detailCtrlInfo() {
      return this.$store.getters['init/getScreens'](
        this.SCREEN_ID.PLANNING_EVALUATION_DETAIL
      )('detail_ctrl_info')
    },
    detailInfo() {
      let detailInfo = this.$store.getters['init/getScreens'](
        this.SCREEN_ID.PLANNING_EVALUATION_DETAIL
      )('detail_info')

      detailInfo.planning_evaluation_pattern_id.visibility = this.VISIBILITY.DISABLE

      // 標準データ(配車評価パターンID = 0)の場合、削除可否区分非活性
      if (this.isBaseData) {
        detailInfo.delete_impossible_kind.visibility = this.VISIBILITY.DISABLE
      }

      return detailInfo
    },
    planningEvaluationFieldInfo() {
      return this.$store.getters['init/getFields'](
        this.SCREEN_ID.PLANNING_EVALUATION_DETAIL
      )('planning_evaluation_field')
    },
    // planningConstraintFieldInfo() {
    //   return this.$store.getters['init/getFields'](
    //     this.SCREEN_ID.PLANNING_EVALUATION_DETAIL
    //   )('planning_constraint_field')
    // },
    messageInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'message'
      )
    },
    breadcrumbItems() {
      return getBreadcrumbItems(
        this.beforeScreenRouteInfo,
        this.SCREEN_ID.PLANNING_EVALUATION_DETAIL
      )
    },
    /**
     * ボタン情報リスト.
     * @return {Array}
     */
    buttonItems() {
      return [
        // ボタングループ#1
        [
          // 登録ボタン
          {
            variant: 'success',
            icon: 'edit',
            columnInfo: this.detailCtrlInfo.register,
            onClickCallback: () => {
              goRegister(this)
            },
          },
          // クリアボタン
          {
            variant: 'success',
            icon: 'times-circle',
            columnInfo: this.detailCtrlInfo.clear,
            onClickCallback: () => {
              clear(this)
            },
          },
        ],
        // ボタングループ#2
        [
          // 戻るボタン
          {
            variant: 'success',
            icon: 'times-circle',
            columnInfo: this.detailCtrlInfo.back,
            onClickCallback: () => {
              back(this)
            },
          },
        ],
      ]
    },
    inputComponents() {
      return [
        {
          label: this.detailInfo.tab1.label,
          inputComponents: [
            {
              // 配車評価パターンID
              type: 'text',
              id: 'planningEvaluationPatternId',
              columnInfo: this.detailInfo.planning_evaluation_pattern_id,
            },
            {
              // 配車評価パターン名
              type: 'text',
              id: 'planningEvaluationPatternName',
              columnInfo: this.detailInfo.planning_evaluation_pattern_name,
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
            },
            {
              // ブロック名
              type: 'text',
              id: 'blockName',
              columnInfo: this.detailInfo.block_name,
            },
            {
              // 配車評価ロジック(コード)
              type: 'select',
              id: 'planningEvaluationLogic',
              columnInfo: this.detailInfo.planning_evaluation_logic,
              options: getListOptions(
                this.MASTER_CODE.PLANNING_EVALUATION_LOGIC
              ),
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onChangeCallback: () => {},
            },
            {
              // 自動配車ロジック(コード)
              type: 'select',
              id: 'autoPlanningLogic',
              columnInfo: this.detailInfo.auto_planning_logic,
              options: getListOptions(this.MASTER_CODE.AUTO_PLANNING_LOGIC),
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onChangeCallback: () => {},
            },
            {
              // 備考
              type: 'text',
              id: 'note',
              columnInfo: this.detailInfo.note,
            },
            {
              // 削除可否区分
              type: 'select',
              id: 'deleteImpossibleKind',
              columnInfo: this.detailInfo.delete_impossible_kind,
              options: getListOptions(this.MASTER_CODE.DELETE_IMPOSSIBLE_KIND),
              rules: [this.INPUT_TYPE_CHECK.REQUIRED],
              onChangeCallback: () => {},
            },
            {
              // 表示順
              type: 'text',
              id: 'displayOrder',
              columnInfo: this.detailInfo.display_order,
              rules: [
                this.INPUT_TYPE_CHECK.REQUIRED,
                this.INPUT_TYPE_CHECK.ONLY_NUMERIC,
              ],
            },
            {
              // 登録日時
              type: 'text',
              id: 'registrationTimeDisplay',
              columnInfo: this.detailInfo.registration_time_display,
            },
            {
              // 登録ユーザ
              type: 'text',
              id: 'registrationUser',
              columnInfo: this.detailInfo.registration_user,
            },
            {
              // 更新日時
              type: 'text',
              id: 'updateTimeDisplay',
              columnInfo: this.detailInfo.update_time_display,
            },
            {
              // 更新ユーザ
              type: 'text',
              id: 'updateUser',
              columnInfo: this.detailInfo.update_user,
            },
          ],
        },
      ]
    },
    /**
     * 情報メッセージ.
     * @return {String}
     */
    infoMessage() {
      return this.$store.getters['init/getMessage'](
        'YZ00MG995I',
        this.detailCtrlInfo.register.label
      )
    },
  },

  methods: {
    getListOptions,
    openSubModal,
    register,
    back,
    clear,

    setErrorMsg(msg) {
      this.$emit('set-error-msg', msg)
    },

    setWarnMsg(msg) {
      this.$emit('set-warn-msg', msg)
    },
  },
}
</script>

<style lang="scss" scoped>
.tab-group {
  margin-bottom: 12px;
}
</style>
