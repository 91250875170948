<template>
  <div data-class="list">
    <BaseSearchListPage
      ref="result-search"
      :breadcrumbItems="breadcrumbItems"
      :searchCondition="searchCondition"
      :searchConditionInfo="searchConditionInfo"
      :buttonItems="buttonItems"
      :tableItems="tableItems"
      :fields="fields"
      selectMode="range"
      @row-selected="onRowSelected"
      @max-count-changed="onMaxCountChanged"
      @clear="clear(obj)"
      @search="search(obj)"
    >
    </BaseSearchListPage>

    <!-- 自動配車指示モーダル -->
    <AutoPlanningRequestModal
      id="auto-planning-request-modal"
      type="entry"
      :selectedSearchCondition="autoPlanningRequestModalSearchCondition"
      @after-close-set="afterCloseSetAutoPlanningRequest"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />

    <!-- 中止モーダル -->
    <ConfirmModal
      id="stop-modal"
      :columnInfos="messageInfo"
      :message="stopMessage"
      @confirm="stop(obj)"
    />
  </div>
</template>

<script>
import {
  getListOptions,
  getSearchMaxCount,
  openSubModal,
} from '@/common/Common.js'
import { SCREEN_ID } from '@/common/const.js'
import {
  init,
  search,
  clear,
  goDetail,
  goNew,
  goCopy,
  goStop,
  stop,
  reSearch,
} from '@/plan/autoPlanning/AutoPlanningList.js'

const screenId = SCREEN_ID.AUTO_PLANNING_LIST

export default {
  components: {
    AutoPlanningRequestModal: () =>
      import('@/plan/autoPlanning/AutoPlanningRequestModal.vue'),
  },
  props: {
    isReSearch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tableItems: [],
      selectedItem: [],
      /**
       * 検索パラメータ.
       * @type {Object}
       */
      searchCondition: this.defaultSearchCondition(),
      obj: this,
      stopMessage: null,
      autoPlanningRequestModalSearchCondition: {},
    }
  },

  created() {
    init(this)
  },

  computed: {
    searchConditionInfo() {
      const searchConditionInfo = this.$store.getters['init/getScreens'](
        screenId
      )('search_condition')
      return {
        header: searchConditionInfo.title.label,
        clear: searchConditionInfo.clear,
        search: searchConditionInfo.search,
        inputComponents: [
          // {
          //   // 実施開始日時
          //   type: 'dateTime',
          //   dateId: 'planBeginDate',
          //   timeId: 'planBeginTime',
          //   dateColumnInfo: searchConditionInfo.plan_begin_date,
          //   timeColumnInfo: searchConditionInfo.plan_begin_time,
          // },
          // {
          //   // 実施終了日時
          //   type: 'dateTime',
          //   dateId: 'planEndDate',
          //   timeId: 'planEndTime',
          //   dateColumnInfo: searchConditionInfo.plan_end_date,
          //   timeColumnInfo: searchConditionInfo.plan_end_time,
          // },
          {
            // 実施期間
            type: 'dateTimeFromTo',
            dateFromId: 'planBeginDate',
            timeFromId: 'planBeginTime',
            dateToId: 'planEndDate',
            timeToId: 'planEndTime',
            dateFromColumnInfo: searchConditionInfo.plan_begin_date,
            timeFromColumnInfo: searchConditionInfo.plan_begin_time,
            dateToColumnInfo: searchConditionInfo.plan_end_date,
            timeToColumnInfo: searchConditionInfo.plan_end_time,
            // dateFromRules: [
            //   this.INPUT_TYPE_CHECK.REQUIRED,
            //   this.INPUT_TYPE_CHECK.DATETIMERANGE,
            // ],
            dateFromRules: [this.INPUT_TYPE_CHECK.DATETIMERANGE],
            // timeFromRules: [this.INPUT_TYPE_CHECK.REQUIRED],
            //dateToRules: [this.INPUT_TYPE_CHECK.REQUIRED],
            // timeToRules: [this.INPUT_TYPE_CHECK.REQUIRED],
          },
          {
            // 実施状態
            type: 'select',
            id: 'planStatus',
            columnInfo: searchConditionInfo.plan_status,
            options: getListOptions(this.MASTER_CODE.PLAN_STATUS),
            onChangeCallback: () => {},
          },
          {
            // 立案対象日
            type: 'dateFromTo',
            fromId: 'requestBeginDate',
            toId: 'requestEndDate',
            fromColumnInfo: searchConditionInfo.request_begin_date,
            toColumnInfo: searchConditionInfo.request_end_date,
            locale: this.locale,
            fromRules: [this.INPUT_TYPE_CHECK.DATETIMERANGE],
          },
        ],
      }
    },
    resultControlInfo() {
      return this.$store.getters['init/getScreens'](screenId)(
        'result_ctrl_info'
      )
    },
    fileInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'file'
      )
    },
    messageInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'message'
      )
    },
    breadcrumbItems() {
      return [
        {
          name: this.$store.getters['init/getScreenName'](screenId),
        },
      ]
    },
    /**
     * テーブル列定義リスト.
     * @return {Array}
     */
    fields() {
      return this.$store.getters['init/getFields'](screenId)(
        this.DOMAIN_NAME.FIELDS
      )
    },

    /**
     * ボタン情報リスト.
     * @return {Array}
     */
    buttonItems() {
      return [
        // ボタングループ#1
        [
          // 詳細ボタン
          {
            variant: 'primary',
            icon: 'list-alt',
            columnInfo: this.resultControlInfo.detail,
            disabled: !this.isSelected,
            onClickCallback: () => {
              goDetail(this)
            },
          },
          // 新規ボタン
          {
            variant: 'primary',
            icon: 'list-alt',
            columnInfo: this.resultControlInfo.new,
            onClickCallback: () => {
              goNew(this)
            },
          },
          // コピーボタン
          {
            variant: 'primary',
            icon: 'copy',
            columnInfo: this.resultControlInfo.copy,
            disabled: !this.isSelected,
            onClickCallback: () => {
              goCopy(this)
            },
          },
          // 中止ボタン
          {
            variant: 'primary',
            icon: 'times',
            columnInfo: this.resultControlInfo.stop,
            disabled: !this.isSelected,
            onClickCallback: () => {
              goStop(this)
            },
          },
        ],
        // ボタングループ#2
        [],
      ]
    },

    /**
     * メッセージ.
     * @return {String}
     */
    downloadMessage() {
      return this.$store.getters['init/getMessage'](
        'YZ00MG994Q',
        this.resultControlInfo.file_print.label
      )
    },
    /**
     * 単数選択しているか.
     * @return {Boolean}
     */
    isSelected() {
      return this.selectedItem.length > 0
    },
  },

  methods: {
    getListOptions,
    openSubModal,
    search,
    reSearch,
    clear,
    stop,

    /**
     * デフォルト検索パラメータを返します.
     * @return {Object}
     */
    defaultSearchCondition() {
      return {
        planBeginDate: null, //実施開始日
        planBeginTime: null, //実施開始時刻
        planEndDate: null, //実施終了日
        planEndTime: null, //実施終了時刻
        planBeginDateTime: null,
        planEndDateTime: null,
        planStatus: null, //実施状態
        requestBeginDate: null, //立案対象開始日
        requestEndDate: null, //立案対象終了日
        deleted: null,
        size: getSearchMaxCount(),
        screenId: screenId,
        domainName: 'result_fields',
      }
    },

    setErrorMsg(msg) {
      this.$emit('set-error-msg', msg)
    },

    setWarnMsg(msg) {
      this.$emit('set-warn-msg', msg)
    },

    onRowSelected(selectedItems) {
      this.selectedItem = selectedItems
    },

    onMaxCountChanged(maxCount) {
      this.searchCondition.size = maxCount
    },

    afterCloseSetAutoPlanningRequest(requestId) {
      console.log('afterCloseSetAutoPlanningRequest(requestId)=' + requestId)
      if (requestId >= 0) {
        // router.push({
        //   name: SCREEN_ID.AUTO_PLANNING_DETAIL,
        //   params: {
        //     requestId: requestId,
        //     screenKbn: DETAIL_SCREEN_KBN.DETAIL,
        //   },
        // })
        console.log('reSearch(requestId)=' + requestId)
        reSearch(this)
      }
    },
  },
}
</script>

<style scoped>
#footer {
  bottom: 0;
  position: fixed;
  width: 96.8%;
  height: 50px;
  margin-bottom: 20px;
  box-sizing: border-box;
  color: white;
  /* margin-left:300px; */
  text-align: center;
}
</style>
