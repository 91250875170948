import {
  openMsgModal,
  openSubModal,
  formatYYYYMMDD,
  formatHHmmss,
  //formatYYYYYMMDDHHmm,
  displaYyyymmdd,
  displayHHmmss,
  displayYYYYYMMDDHHmm,
  convertCamelCace,
} from '@/common/Common.js'
import {
  SCREEN_ID,
  MSG_TYPE,
  DETAIL_SCREEN_KBN,
  //TRANSPORT_STATUS,
  RECORD_WORK_KIND,
  RECORD_PROGRESS_STATUS,
} from '@/common/const.js'
import router from '@/router'
import WorkRecordService from './WorkRecordService'
//import _ from 'lodash'

const screenId = SCREEN_ID.WORK_RECORD_LIST
const detailScreenId = SCREEN_ID.WORK_RECORD_DETAIL

export function init(obj) {
  // サーバーの処理後再検索
  if (obj.isReSearch == true) {
    reSearch(obj)
  }
}

export function reSearch(obj) {
  var searchCondition = obj.$store.getters['search/getSearchCondition'](
    screenId
  )
  if (searchCondition != null) {
    obj.searchCondition = JSON.parse(searchCondition)
    search(obj)
  }
}

/**
 * 場所を検索します.
 * @param {*} obj
 */
export function search(obj) {
  obj.searchCondition.departureBeginDate = formatYYYYMMDD(
    obj,
    obj.searchCondition.departureBeginDate
  )
  obj.searchCondition.departureEndDate = formatYYYYMMDD(
    obj,
    obj.searchCondition.departureEndDate
  )

  if (obj.searchCondition.realVehicleId != null) {
    obj.searchCondition.realVehicleId = obj.searchCondition.realVehicleId + '' //ID (String)
  }

  obj.$store.dispatch('init/setServiceLoading')
  WorkRecordService.search(obj.searchCondition)
    .then(
      (response) => {
        obj.tableItems = response.data.resultList

        obj.tableItems.forEach((element) => {
          element.departure_date = displaYyyymmdd(obj, element.departure_date)
          element.departure_time = displayHHmmss(element.departure_time)
          element.departure_date_time = displayYYYYYMMDDHHmm(
            obj,
            element.departure_date_time
          )
          element.arrival_date = displaYyyymmdd(obj, element.arrival_date)
          element.arrival_time = displayHHmmss(element.arrival_time)
          element.arrival_date_time = displayYYYYYMMDDHHmm(
            obj,
            element.arrival_date_time
          )

          // 荷積・荷卸の予定日時
          element.departure_work_act_time = displayYYYYYMMDDHHmm(
            obj,
            element.departure_work_act_time
          )
          element.arrival_work_act_time = displayYYYYYMMDDHHmm(
            obj,
            element.arrival_work_act_time
          )
          // 荷積・荷卸の実績日時
          element.departure_real_work_act_datetime = displayYYYYYMMDDHHmm(
            obj,
            element.departure_real_work_act_datetime
          )
          element.arrival_real_work_act_datetime = displayYYYYYMMDDHHmm(
            obj,
            element.arrival_real_work_act_datetime
          )

          // 実績日時
          element.real_work_act_datetime = displayYYYYYMMDDHHmm(
            obj,
            element.real_work_act_datetime
          )
        })

        if (obj.tableItems.length < 1) {
          // 警告モーダル表示
          openMsgModal(
            obj,
            MSG_TYPE.W,
            obj.$store.getters['init/getMessage']('YZ00MG907I')
          )
        }
      },
      (e) => {
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message
        }
        // エラーモーダル表示
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })

  var payload = {
    viewId: screenId,
    params: JSON.stringify(obj.searchCondition),
  }
  obj.$store.dispatch('search/setSearchCondition', payload)
}

/**
 * 検索項目をクリアします.
 */
export function clear(obj) {
  obj.searchCondition = obj.defaultSearchCondition()

  var payload = {
    viewId: screenId,
    params: JSON.stringify(obj.searchCondition),
  }
  obj.$store.dispatch('search/setSearchCondition', payload)
}

// 「詳細」ボタンクリック
export function goDetail(obj) {
  if (obj.selectedItem.length != 1) {
    openMsgModal(
      obj,
      MSG_TYPE.E,
      obj.$store.getters['init/getMessage']('YZ00MG901E')
    )
  } else {
    router.push({
      name: detailScreenId,
      params: {
        planningResultId: obj.selectedItem[0].planning_result_id,
        planningResultPlaceId: obj.selectedItem[0].planning_result_place_id,
        screenKbn: DETAIL_SCREEN_KBN.DETAIL,
        beforeScreenRouteInfo: {
          routeInfo: {
            name: screenId,
            params: { isReSearch: true },
          },
        },
      },
    })
  }
}

//「一括登録」ボタンクリック
// 実績日付の選択画面表示
export function goWorkact(obj) {
  //一括登録の整合性チェック
  var errorMsg = checkWorkactRelation(obj)
  if (errorMsg != null) {
    openMsgModal(obj, MSG_TYPE.E, errorMsg)
  } else {
    openSubModal(obj, 'workact-sub-modal')
  }
}

//「一括登録」処理
// 選択した荷積荷卸の実績日付が一括で更新
export function doWorkact(obj, workactDate, workactHhmmss) {
  let pWorkactDate = formatYYYYMMDD(obj, workactDate)
  let pWorkactHhmmss = formatHHmmss(workactHhmmss)
  let datas = convertCamelCace(obj.selectedItem)

  obj.$store.dispatch('init/setServiceLoading')
  WorkRecordService.allregister(pWorkactDate, pWorkactHhmmss, datas)
    .then(
      () => {
        //荷積荷卸実績を登録しました。
        obj.infoMessage = obj.$store.getters['init/getMessage']('YZ00MG923I')
        // 情報モーダル表示
        openSubModal(obj, 'planning-result-list-info-modal')
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message + e.response.data.messageDetail
        }
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

// 一括登録の整合性チェック
//・obj.selectedItem.length >=2
//・進捗状態「実施済」が含まれる場合はNG
//・積卸区分が異なる場合はNG(積と卸を同時に設定するのはNG)
function checkWorkactRelation(obj) {
  var errorMsg = null

  if (obj.selectedItem.length <= 1) {
    //一覧より2行を選択してください。
    errorMsg = obj.$store.getters['init/getMessage']('YZ00MG905E')
  } else {
    var record_work_load = 0
    var record_work_unload = 0
    //・obj.selectedItem.length >=2
    //・進捗状態「実施済」が含まれる場合はNG
    //・積卸区分が異なる場合はNG(積と卸を同時に設定するのはNG)

    obj.selectedItem.forEach((element) => {
      //進捗状態「実施済」が含まれる場合
      if (element.progress_status == RECORD_PROGRESS_STATUS.RUN) {
        errorMsg = obj.$store.getters['init/getMessage']('YZ00MG921E')
        return errorMsg
      }
      //積卸区分が異なる場合はNG
      if (element.work_kind == RECORD_WORK_KIND.LOAD) {
        record_work_load++
      }
      if (element.work_kind == RECORD_WORK_KIND.UNLOAD) {
        record_work_unload++
      }
      if (record_work_load > 0 && record_work_unload > 0) {
        errorMsg = obj.$store.getters['init/getMessage']('YZ00MG922E')
        return errorMsg
      }
    })
  }

  return errorMsg
}
