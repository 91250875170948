import { openMsgModal, openSubModal } from '@/common/Common.js'
import {
  SCREEN_ID,
  MSG_TYPE,
  DETAIL_SCREEN_KBN,
  DELETE_IMPOSSIBLE_KIND,
} from '@/common/const.js'
import router from '@/router'
import PlanningPatternService from './PlanningPatternService'

const screenId = SCREEN_ID.PLANNING_PATTERN_LIST
const detailScreenId = SCREEN_ID.PLANNING_PATTERN_DETAIL

export function init(obj) {
  // サーバーの処理後再検索
  if (obj.isReSearch == true) {
    reSearch(obj)
  }
}

export function reSearch(obj) {
  var searchCondition = obj.$store.getters['search/getSearchCondition'](
    screenId
  )
  if (searchCondition != null) {
    obj.searchCondition = JSON.parse(searchCondition)
    search(obj)
  }
}

/**
 * 配車計画パターンを検索します.
 * @param {*} obj
 */
export function search(obj) {
  obj.$store.dispatch('init/setServiceLoading')
  PlanningPatternService.search(obj.searchCondition)
    .then(
      (response) => {
        obj.tableItems = response.data.resultList
        if (obj.tableItems.length < 1) {
          // 警告モーダル表示
          openMsgModal(
            obj,
            MSG_TYPE.W,
            obj.$store.getters['init/getMessage']('YZ00MG907I')
          )
        }
      },
      (e) => {
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message
        }
        // エラーモーダル表示
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })

  var payload = {
    viewId: screenId,
    params: JSON.stringify(obj.searchCondition),
  }
  obj.$store.dispatch('search/setSearchCondition', payload)
}

/**
 * 検索項目をクリアします.
 */
export function clear(obj) {
  obj.searchCondition = obj.defaultSearchCondition()

  var payload = {
    viewId: screenId,
    params: JSON.stringify(obj.searchCondition),
  }
  obj.$store.dispatch('search/setSearchCondition', payload)
}

// 「詳細」ボタンクリック
export function goDetail(obj) {
  if (obj.selectedItem.length != 1) {
    openMsgModal(
      obj,
      MSG_TYPE.E,
      obj.$store.getters['init/getMessage']('YZ00MG901E')
    )
  } else {
    router.push({
      name: detailScreenId,
      params: {
        planningPatternId: obj.selectedItem[0].planning_pattern_id,
        screenKbn: DETAIL_SCREEN_KBN.DETAIL,
        beforeScreenRouteInfo: {
          routeInfo: {
            name: screenId,
            params: { isReSearch: true },
          },
        },
      },
    })
  }
}

// 「コピー」ボタンクリック
export function goCopy(obj) {
  if (obj.selectedItem.length != 1) {
    openMsgModal(
      obj,
      MSG_TYPE.E,
      obj.$store.getters['init/getMessage']('YZ00MG901E')
    )
  } else {
    router.push({
      name: detailScreenId,
      params: {
        planningPatternId: obj.selectedItem[0].planning_pattern_id,
        screenKbn: DETAIL_SCREEN_KBN.COPY,
        beforeScreenRouteInfo: {
          routeInfo: {
            name: screenId,
            params: { isReSearch: true },
          },
        },
      },
    })
  }
}

// 「削除」ボタンクリック
export function goRemove(obj) {
  let errorMsg = false

  // 配車計画パターンIDのチェックを行う。IDが"0"の場合、エラーメッセージを出力する。
  obj.selectedItem.some((element) => {
    if (element.planning_pattern_id === 0) {
      openMsgModal(
        obj,
        MSG_TYPE.E,
        obj.$store.getters['init/getMessage']('EF01S001_MG002E')
      )
      errorMsg = true
    }
  })

  if (errorMsg) {
    return
  }

  // 削除可否区分が「1：削除不可」の場合、メッセージを出力する。
  obj.selectedItem.some((element) => {
    if (element.delete_impossible_kind === DELETE_IMPOSSIBLE_KIND.IMPOSSIBLE) {
      openMsgModal(
        obj,
        MSG_TYPE.E,
        obj.$store.getters['init/getMessage']('EF01S001_MG001E')
      )
      errorMsg = true
    }
  })

  if (errorMsg) {
    return
  }

  // 処理確認メッセージ
  obj.removeMessage = obj.$store.getters['init/getMessage'](
    'YZ00MG994Q',
    obj.resultControlInfo.delete.label
  )
  openSubModal(obj, 'remove-modal')
}

/**
 * 選択した作業データを削除します.
 */
export async function remove(obj) {
  try {
    obj.$bvModal.hide('remove-modal')

    let isError = false
    let removeList = []
    for (let index = 0; index < obj.selectedItem.length; index++) {
      var planningPatternId = obj.selectedItem[index].planning_pattern_id
      obj.$store.dispatch('init/setServiceLoading')
      await PlanningPatternService.get(planningPatternId)
        .then(
          (response) => {
            removeList.push(response.data)
          },
          (e) => {
            // エラーモーダル表示
            var msg
            if (e.response == undefined) {
              msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
            } else {
              msg = e.response.data.message
            }
            openMsgModal(obj, MSG_TYPE.E, msg)
            isError = true
          }
        )
        .finally(() => {
          obj.$store.dispatch('init/setServiceUnLoading')
        })
      if (isError) {
        break
      }
    }
    if (isError == false && removeList.length == obj.selectedItem.length) {
      obj.$store.dispatch('init/setServiceLoading')
      await PlanningPatternService.removeList(removeList)
        .then(
          () => {
            // 処理完了メッセージ表示
            openMsgModal(
              obj,
              MSG_TYPE.I,
              obj.$store.getters['init/getMessage'](
                'YZ00MG995I',
                obj.resultControlInfo.delete.label
              )
            )
          },
          (e) => {
            // エラーモーダル表示
            var msg
            if (e.response == undefined) {
              msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
            } else {
              msg = e.response.data.message
            }
            openMsgModal(obj, MSG_TYPE.E, msg)
          }
        )
        .finally(() => {
          obj.$store.dispatch('init/setServiceUnLoading')
        })
    }
  } catch (err) {
    console.error(err)
  } finally {
    var searchCondition = obj.$store.getters['search/getSearchCondition'](
      screenId
    )
    obj.searchCondition = JSON.parse(searchCondition)
    search(obj)
  }
}
