import {
  openMsgModal,
  openSubModal,
  displayYYYYYMMDDHHmm,
  displaYyyymmdd,
  displayHHmmss,
  formatYYYYYMMDDHHmm,
  formatYYYYMMDD,
  convertSnakeCace,
  convertCamelCace,
} from '@/common/Common.js'
import {
  MSG_TYPE,
  PERMIT_REFUSE,
  VISIBILITY,
  TRANSPORT_STATUS,
} from '@/common/const.js'
import TransportRequestService from './TransportRequestService'
import FileSaver from 'file-saver'
import _ from 'lodash'
import { isNil, isEmpty } from 'lodash'
import PlaceOrderService from '@/plan/placeOrder/PlaceOrderService'

export function init(obj) {
  obj.$store.dispatch('init/setServiceLoading')
  TransportRequestService.get(obj.planningResultId)
    .then(
      (response) => {
        obj.TransportRequest = response.data

        // #2644 - 承諾、拒否を非活性
        // 承諾以降＝「'40' 承認承諾」「 '50' 承認承諾 & 実車番確定済み」「'51' 承認承諾 & 輸送計画確定済」
        // 拒否以降＝「'11' 承認拒否」（検索対象外のため、処理なし）
        if (response.data.status != null) {
          if (
            TRANSPORT_STATUS.REQUEST_PERMIT == response.data.status ||
            TRANSPORT_STATUS.REQUEST_REALVEHICLE_CONFIRM ==
              response.data.status ||
            TRANSPORT_STATUS.REQUEST_REALVEHICLE_FINISH == response.data.status
          ) {
            obj.TransportRequest.permitRefuse = PERMIT_REFUSE.PERMIT //'1' 承諾
            obj.detailInfo.permit_refuse.visibility = VISIBILITY.DISABLE
          }
        }

        obj.TransportRequest.departureDateTime = displayYYYYYMMDDHHmm(
          obj,
          obj.TransportRequest.departureDateTime
        )
        obj.TransportRequest.arrivalDateTime = displayYYYYYMMDDHHmm(
          obj,
          obj.TransportRequest.arrivalDateTime
        )
        obj.TransportRequest.planningTargetDate = displaYyyymmdd(
          obj,
          obj.TransportRequest.planningTargetDate
        )
        obj.TransportRequest.planningDecisionDateTime = displayYYYYYMMDDHHmm(
          obj,
          obj.TransportRequest.planningDecisionDateTime
        )
        obj.TransportRequest.realVehicleRequestTime = displayYYYYYMMDDHHmm(
          obj,
          obj.TransportRequest.realVehicleRequestTime
        )
        obj.TransportRequest.realVehicleEntryTime = displayYYYYYMMDDHHmm(
          obj,
          obj.TransportRequest.realVehicleEntryTime
        )

        // details
        var details = convertSnakeCace(obj.TransportRequest.planningResults)

        // costDetails
        var costDetails = convertSnakeCace(obj.TransportRequest.costDetails)

        // requests
        var permitRequests = convertSnakeCace(
          obj.TransportRequest.permitRequests
        )

        details.forEach((element) => {
          element.departure_begin_date = displaYyyymmdd(
            obj,
            element.departure_begin_date
          )
          element.departure_begin_time = displayHHmmss(
            element.departure_begin_time
          )
          element.departure_end_date = displaYyyymmdd(
            obj,
            element.departure_end_date
          )
          element.departure_end_time = displayHHmmss(element.departure_end_time)
          element.arrival_begin_date = displaYyyymmdd(
            obj,
            element.arrival_begin_date
          )
          element.arrival_begin_time = displayHHmmss(element.arrival_begin_time)
          element.arrival_end_date = displaYyyymmdd(
            obj,
            element.arrival_end_date
          )
          element.arrival_end_time = displayHHmmss(element.arrival_end_time)

          //order
          element.order_departure_begin_date = displaYyyymmdd(
            obj,
            element.order_departure_begin_date
          )
          element.order_departure_begin_time = displayHHmmss(
            element.order_departure_begin_time
          )
          element.order_departure_end_date = displaYyyymmdd(
            obj,
            element.order_departure_end_date
          )
          element.order_departure_end_time = displayHHmmss(
            element.order_departure_end_time
          )
          element.order_arrival_begin_date = displaYyyymmdd(
            obj,
            element.order_arrival_begin_date
          )
          element.order_arrival_begin_time = displayHHmmss(
            element.order_arrival_begin_time
          )
          element.order_arrival_end_date = displaYyyymmdd(
            obj,
            element.order_arrival_end_date
          )
          element.order_arrival_end_time = displayHHmmss(
            element.order_arrival_end_time
          )
        })

        permitRequests.forEach((element) => {
          element.valid_begin_date = displaYyyymmdd(
            obj,
            element.valid_begin_date
          )
          element.valid_end_date = displaYyyymmdd(obj, element.valid_end_date)
        })

        obj.TransportRequest.planningResults = details
        obj.tableItems = details

        obj.TransportRequest.costDetails = costDetails
        obj.tableCosts = costDetails

        obj.TransportRequest.permitRequests = permitRequests
        obj.tablePermits = permitRequests

        var orderResults = convertSnakeCace(obj.TransportRequest.orderResults)

        orderResults.forEach((element) => {
          element.departure_begin_date = displaYyyymmdd(
            obj,
            element.departure_begin_date
          )
          element.arrival_begin_date = displaYyyymmdd(
            obj,
            element.arrival_begin_date
          )
          element.departure_begin_time = displayHHmmss(
            element.departure_begin_time
          )
          element.arrival_begin_time = displayHHmmss(element.arrival_begin_time)
        })
        obj.TransportRequest.orderResults = orderResults
        obj.tableOrders = orderResults

        obj.oriData = _.cloneDeep(obj.TransportRequest)
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else if (e.response.status == 404) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG996E')
        } else {
          msg = e.response.data.message
        }
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

export async function goRegister(obj) {
  const validated = await obj.$refs['tab-group'].$refs[
    'base-tab-group'
  ].validate()
  if (!validated) return

  obj.registerMessage = obj.$store.getters['init/getMessage'](
    'YZ00MG994Q',
    obj.detailCtrlInfo.register.label
  )

  if (obj.TransportRequest.deleted == '1') {
    obj.registerMessage = obj.$store.getters['init/getMessage'](
      'YZ00MG905Q',
      obj.detailCtrlInfo.register.label
    )
  }

  // 拒否時、拒否理由必須チェック
  if (
    obj.TransportRequest.permitRefuse == PERMIT_REFUSE.REFUSE &&
    (isNil(obj.TransportRequest.refusalComment) ||
      isEmpty(obj.TransportRequest.refusalComment))
  ) {
    // 警告モーダル表示
    // %sを入力してください。
    openMsgModal(
      obj,
      MSG_TYPE.E,
      obj.$store.getters['init/getMessage'](
        'YZ00MG984E',
        obj.detailInfo.refusal_comment.label
      )
    )
    return
  }

  openSubModal(obj, 'register-modal')
}

export async function register(obj) {
  obj.$bvModal.hide('register-modal')

  const validated = await obj.$refs['tab-group'].$refs[
    'base-tab-group'
  ].validate()
  if (!validated) return

  let registerData = _.cloneDeep(obj.TransportRequest)

  if (registerData.deleted == '1') {
    registerData.deleted = '0'
  }

  // planningResult
  registerData.planningResults = changeToCamelCaseKey(
    registerData.planningResults
  )
  // costDetails
  registerData.costDetails = changeToCamelCaseKey(registerData.costDetails)
  // permitRequests
  registerData.permitRequests = changeToCamelCaseKey(
    registerData.permitRequests
  )

  registerData.departureDateTime = formatYYYYYMMDDHHmm(
    registerData.departureDateTime
  )
  registerData.arrivalDateTime = formatYYYYYMMDDHHmm(
    registerData.arrivalDateTime
  )
  registerData.planningTargetDate = formatYYYYMMDD(
    obj,
    registerData.planningTargetDate
  )
  registerData.planningDecisionDateTime = formatYYYYYMMDDHHmm(
    registerData.planningDecisionDateTime
  )
  registerData.realVehicleRequestTime = formatYYYYYMMDDHHmm(
    registerData.realVehicleRequestTime
  )
  registerData.realVehicleEntryTime = formatYYYYYMMDDHHmm(
    registerData.realVehicleEntryTime
  )

  obj.$store.dispatch('init/setServiceLoading')
  TransportRequestService.update(obj.planningResultId, registerData)
    .then(
      () => {
        // 情報モーダル表示
        openSubModal(obj, 'planning-result-info-modal')
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message + e.response.data.messageDetail
        }
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

export function clear(obj) {
  obj.TransportRequest = _.cloneDeep(obj.oriData)
  obj.tableItems = obj.TransportRequest.planningResults || []
  obj.tableCosts = obj.TransportRequest.costDetails || []
  obj.tablePermits = obj.TransportRequest.permitRequests || []
  obj.tableOrders = obj.TransportRequest.orderResults || []

  // validate reset
  obj.$refs['tab-group'].$refs['base-tab-group'].reset()
}

// 承諾・拒否のRadioチェック変更
export function goRadioChanged(obj) {
  if (obj.TransportRequest.permitRefuse == PERMIT_REFUSE.PERMIT) {
    //obj.detailInfo.refusal_comment.visibility = VISIBILITY.DISABLE
    obj.TransportRequest.refusalComment = ''
  }
}

function changeToCamelCaseKey(items) {
  var camelItems = convertCamelCace(items)

  return camelItems
}

export function add(obj) {
  var permitVehicleIds = ''

  // isNil & isEmpty
  if (obj.TransportRequest.realVehicleId) {
    permitVehicleIds = obj.TransportRequest.realVehicleId + ''

    if (obj.TransportRequest.realTrailerId) {
      permitVehicleIds =
        permitVehicleIds + ',' + obj.TransportRequest.realTrailerId
    }
  } else {
    if (obj.TransportRequest.realTrailerId) {
      permitVehicleIds = obj.TransportRequest.realTrailerId + ''
    }
  }

  //console.log('permitVehicleIds_Info:', permitVehicleIds)
  obj.passagePermitModalSearchCondition = {}
  obj.passagePermitModalSearchCondition.permitVehicleIds = permitVehicleIds

  //[#2689]
  obj.passagePermitModalSearchCondition.carrierCode =
    obj.TransportRequest.carrierCode
  obj.passagePermitModalSearchCondition.carrierName =
    obj.TransportRequest.carrierName
  if (obj.TransportRequest.realVehicleId) {
    obj.passagePermitModalSearchCondition.realVehicleId =
      obj.TransportRequest.realVehicleId + ''
  }
  obj.passagePermitModalSearchCondition.realVehicleNumberplate =
    obj.TransportRequest.realVehicleNumberplate
  if (obj.TransportRequest.realTrailerId) {
    obj.passagePermitModalSearchCondition.realTrailerId =
      obj.TransportRequest.realTrailerId + ''
  }
  obj.passagePermitModalSearchCondition.realTrailerNumberplate =
    obj.TransportRequest.realTrailerNumberplate

  obj.passagePermitModalSearchCondition.departurePlaceCode =
    obj.TransportRequest.departurePlaceCode
  obj.passagePermitModalSearchCondition.departurePlaceName =
    obj.TransportRequest.departurePlaceName
  obj.passagePermitModalSearchCondition.arrivalPlaceCode =
    obj.TransportRequest.arrivalPlaceCode
  obj.passagePermitModalSearchCondition.arrivalPlaceName =
    obj.TransportRequest.arrivalPlaceName

  // 情報モーダル表示
  openSubModal(obj, 'passage-permit-modal')
}

//info
export function setPermitDetail(obj, selectedItem) {
  //追加
  setItemInfo(obj, selectedItem)
}

function setItemInfo(obj, selectedItem) {
  let passagePermitId = selectedItem.passage_permit_id

  let exists = false
  if (
    obj.TransportRequest.permitRequests.some(
      (list) => list.passage_permit_id == passagePermitId
    )
  ) {
    exists = true
  }

  if (exists == false) {
    let addInfo = {
      //---------->>
      permit_request_id: null,
      transport_request_id: null,
      passage_permit_id: passagePermitId,
      permit_no: selectedItem.permit_no,
      valid_begin_date: selectedItem.valid_begin_date,
      valid_end_date: selectedItem.valid_end_date,
      //----------<<
      registration_time: null,
      registration_user: null,
      update_by_trigger: null,
      update_time: null,
      update_user: null,
      version: null,
      deleted: 0,
    }
    obj.TransportRequest.permitRequests.push(addInfo)
  }
  obj.tablePermits = obj.TransportRequest.permitRequests
}

export function remove(obj) {
  if (obj.selectedItem.length == 0) {
    openMsgModal(
      obj,
      MSG_TYPE.E,
      obj.$store.getters['init/getMessage']('YZ00MG969E')
    )
    return
  }
  // 行削除処理
  obj.selectedItem.forEach((element) => {
    const index = obj.tablePermits.indexOf(element)
    obj.tablePermits.splice(index, 1)
  })
  // // 複数個削除
  // for (let info of obj.selectedItem) {
  //   const index = obj.TransportRequest.permitRequests.indexOf(info)
  //   obj.TransportRequest.permitRequests.splice(index, 1)
  // }
  // obj.tablePermits = obj.TransportRequest.permitRequests
}

// 「輸送依頼書出力」ボタンクリック
export async function excelDownload(obj) {
  obj.$bvModal.hide('download-modal')

  let nowDateTime = obj.$moment(new Date()).format('YYYYMMDDHHmmss')
  let fileName = obj.reportId + '_' + nowDateTime.toString() + '.xlsx'
  obj.$store.dispatch('init/setServiceLoading')
  await PlaceOrderService.download(obj.planningResultId, obj.reportId)
    .then(
      (response) => {
        if (response.data == '') {
          // 警告モーダル表示
          openMsgModal(
            obj,
            MSG_TYPE.W,
            obj.$store.getters['init/getMessage']('YZ00MG907I')
          )
        } else {
          //ファイル保存
          FileSaver.saveAs(response.data, fileName)
        }
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message
        }
        openMsgModal(obj, obj.MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}
