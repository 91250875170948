import {
  openMsgModal,
  openSubModal,
  displayYYYYYMMDDHHmm,
  displaYyyymmdd,
  displayHHmmss,
  formatYYYYYMMDDHHmm,
  formatYYYYMMDD,
  convertSnakeCace,
  convertCamelCace,
} from '@/common/Common.js'
import {
  MSG_TYPE,
  TRANSPORT_STATUS,
  PLANNING_STATUS,
  COST_TYPE,
} from '@/common/const.js'
import PlaceOrderService from './PlaceOrderService'
import FileSaver from 'file-saver'
import _ from 'lodash'

export function init(obj) {
  obj.$store.dispatch('init/setServiceLoading')
  PlaceOrderService.get(obj.planningResultId)
    .then(
      (response) => {
        obj.PlaceOrder = response.data

        obj.PlaceOrder.departureDateTime = displayYYYYYMMDDHHmm(
          obj,
          obj.PlaceOrder.departureDateTime
        )
        obj.PlaceOrder.arrivalDateTime = displayYYYYYMMDDHHmm(
          obj,
          obj.PlaceOrder.arrivalDateTime
        )
        obj.PlaceOrder.planningTargetDate = displaYyyymmdd(
          obj,
          obj.PlaceOrder.planningTargetDate
        )
        obj.PlaceOrder.planningDecisionDateTime = displayYYYYYMMDDHHmm(
          obj,
          obj.PlaceOrder.planningDecisionDateTime
        )
        obj.PlaceOrder.realVehicleRequestTime = displayYYYYYMMDDHHmm(
          obj,
          obj.PlaceOrder.realVehicleRequestTime
        )
        obj.PlaceOrder.realVehicleEntryTime = displayYYYYYMMDDHHmm(
          obj,
          obj.PlaceOrder.realVehicleEntryTime
        )

        // details
        var details = convertSnakeCace(obj.PlaceOrder.planningResults)

        // costdetails
        var costdetails = convertSnakeCace(obj.PlaceOrder.costDetails)

        // permitdetails
        var permitRequests = convertSnakeCace(obj.PlaceOrder.permitRequests)

        details.forEach((element) => {
          element.departure_begin_date = displaYyyymmdd(
            obj,
            element.departure_begin_date
          )
          element.departure_begin_time = displayHHmmss(
            element.departure_begin_time
          )
          element.departure_end_date = displaYyyymmdd(
            obj,
            element.departure_end_date
          )
          element.departure_end_time = displayHHmmss(element.departure_end_time)
          element.arrival_begin_date = displaYyyymmdd(
            obj,
            element.arrival_begin_date
          )
          element.arrival_begin_time = displayHHmmss(element.arrival_begin_time)
          element.arrival_end_date = displaYyyymmdd(
            obj,
            element.arrival_end_date
          )
          element.arrival_end_time = displayHHmmss(element.arrival_end_time)

          //order
          element.order_departure_begin_date = displaYyyymmdd(
            obj,
            element.order_departure_begin_date
          )
          element.order_departure_begin_time = displayHHmmss(
            element.order_departure_begin_time
          )
          element.order_departure_end_date = displaYyyymmdd(
            obj,
            element.order_departure_end_date
          )
          element.order_departure_end_time = displayHHmmss(
            element.order_departure_end_time
          )
          element.order_arrival_begin_date = displaYyyymmdd(
            obj,
            element.order_arrival_begin_date
          )
          element.order_arrival_begin_time = displayHHmmss(
            element.order_arrival_begin_time
          )
          element.order_arrival_end_date = displaYyyymmdd(
            obj,
            element.order_arrival_end_date
          )
          element.order_arrival_end_time = displayHHmmss(
            element.order_arrival_end_time
          )
        })

        permitRequests.forEach((element) => {
          element.valid_begin_date = displaYyyymmdd(
            obj,
            element.valid_begin_date
          )
          element.valid_end_date = displaYyyymmdd(obj, element.valid_end_date)
        })

        obj.PlaceOrder.planningResults = details
        obj.tableItems = details

        obj.PlaceOrder.costDetails = costdetails
        obj.tableCosts = costdetails

        obj.PlaceOrder.permitRequests = permitRequests
        obj.tablePermits = permitRequests

        //許可証情報
        // permitRequests[0] -> selectedPermit[0]
        setPermitRequestDetail(obj, permitRequests)

        obj.oriData = _.cloneDeep(obj.PlaceOrder)
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else if (e.response.status == 404) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG996E')
        } else {
          msg = e.response.data.message
        }
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

export async function goRegister(obj) {
  const validated = await obj.$refs['tab-group'].$refs[
    'base-tab-group'
  ].validate()
  if (!validated) return

  obj.registerMessage = obj.$store.getters['init/getMessage'](
    'YZ00MG994Q',
    obj.detailCtrlInfo.register.label
  )

  if (obj.PlaceOrder.deleted == '1') {
    obj.registerMessage = obj.$store.getters['init/getMessage'](
      'YZ00MG905Q',
      obj.detailCtrlInfo.register.label
    )
  }

  obj.event = 'confirm'
  openSubModal(obj, 'register-modal')
}

// 登録
export async function register(obj) {
  obj.$bvModal.hide('register-modal')

  const validated = await obj.$refs['tab-group'].$refs[
    'base-tab-group'
  ].validate()
  if (!validated) return

  let registerData = cloneFormatDateTime(obj)

  let isNullcostWithoutTax = false
  registerData.costDetails.forEach((element) => {
    if (element.costWithoutTax == null) {
      isNullcostWithoutTax = true
    }
  })

  //税抜き金額 = null の場合
  if (isNullcostWithoutTax) {
    let msg = this.$store.getters['init/getMessage']('YZ00MG995E')
    openMsgModal(obj, MSG_TYPE.E, msg)
    return
  }

  obj.$store.dispatch('init/setServiceLoading')
  PlaceOrderService.update(obj.planningResultId, registerData)
    .then(
      () => {
        // %s処理を完了しました。
        obj.infoMessage = this.$store.getters['init/getMessage'](
          'YZ00MG995I',
          this.detailCtrlInfo.register.label
        )
        // 情報モーダル表示
        openSubModal(obj, 'planning-result-info-modal')
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message + e.response.data.messageDetail
        }
        //obj.PlaceOrder.PlaceOrder = _.cloneDeep(obj.oriData)
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

export function clear(obj) {
  obj.PlaceOrder = _.cloneDeep(obj.oriData)
  obj.tableItems = obj.PlaceOrder.planningResults || []
  obj.tableCosts = obj.PlaceOrder.costDetails || []

  //obj.tablePermits
}

function changeToCamelCaseKey(items) {
  var camelItems = convertCamelCace(items)

  return camelItems
}

export function tableRowAdd(obj) {
  // 請求支払区分参照時、請求支払区分デフォルト設定
  obj.freightItemModalSearchCondition = {}
  // 請求支払区分 =支払
  obj.freightItemModalSearchCondition.costType = COST_TYPE.PAYMENT

  // #2786
  // 荷主の部署グループ
  obj.freightItemModalSearchCondition.departmentGroup =
    obj.PlaceOrder.orderOwnerDepartmentGroup

  // 情報モーダル表示
  openSubModal(obj, 'freight-item-modal')
}

//info
// 追加→Close処理
export function setOrderDetail(obj, selectedItem) {
  //追加
  let planningResultId = obj.selectedItem[0].planning_result_id
  // planning_result_detail_id = relation_id
  let relationId = obj.selectedItem[0].planning_result_detail_id //relation_id

  setItemInfo(obj, planningResultId, relationId, selectedItem)
}

// Cost追加表示
function setItemInfo(obj, planningResultId, relationId, selectedItem) {
  let exists = false
  if (
    obj.PlaceOrder.costDetails.some(
      (list) =>
        //list.planning_result_detail_id == planningResultDetailId &&
        list.relation_id == relationId &&
        list.freight_item_id == selectedItem.freight_item_id
    )
  ) {
    exists = true
    //exists -> Update #2761
    obj.PlaceOrder.costDetails.forEach((oldElement) => {
      if (oldElement.freight_item_id === selectedItem.freight_item_id) {
        oldElement.amount_money = selectedItem.amount_money
        oldElement.cost_without_tax = selectedItem.cost_without_tax
        oldElement.freight_item_name = selectedItem.freight_item_name
        oldElement.tariff_no = selectedItem.tariff_no
      }
    })
  }

  if (exists == false) {
    let addInfo = {
      planning_result_id: planningResultId,
      //planning_result_detail_id: planningResultDetailId,
      relation_id: relationId,
      freight_item_id: selectedItem.freight_item_id,
      freight_item_name: selectedItem.freight_item_name,
      cost_type: selectedItem.cost_type,
      cost_type_name: selectedItem.cost_type_name,
      interface_key: selectedItem.interface_key,
      work_type: selectedItem.work_type,
      sales_type: selectedItem.sales_type,
      tax_type: selectedItem.tax_type,
      amount_money: selectedItem.amount_money, //null,
      cost_without_tax: selectedItem.cost_without_tax, //null,
      tax_percentage: selectedItem.tax_percentage, //null,
      cost_id: null,
      cost_relation_id: null,
      relation_type: '0', //0：配車結果
      tariff_no: selectedItem.tariff_no, //null,
      note: selectedItem.note, //null,
      registration_time: null,
      registration_user: null,
      update_by_trigger: null,
      update_time: null,
      update_user: null,
      version: null,
      deleted: 0,
    }
    obj.PlaceOrder.costDetails.push(addInfo)
  }
  obj.tableCosts = obj.PlaceOrder.costDetails
}

//許可証情報
//info (PermitRequest = selectedPermit)
export function setPermitRequestDetail(obj, selectedPermit) {
  if (selectedPermit == null || selectedPermit.length == 0) {
    return
  }

  // 許可証情報
  obj.PlaceOrder.detailPassagePermitId = selectedPermit[0].passage_permit_id
  obj.PlaceOrder.detailPermitNo = selectedPermit[0].permit_no

  obj.PlaceOrder.detailValidBeginDate = selectedPermit[0].valid_begin_date
  obj.PlaceOrder.detailValidEndDate = selectedPermit[0].valid_end_date

  // PassagePermit
  let passagePermit = selectedPermit[0].passage_permit
  if (passagePermit != null) {
    //運送会社
    obj.PlaceOrder.detailCarrierCode = passagePermit.carrierCode
    obj.PlaceOrder.detailCarrierName = passagePermit.carrierName

    //通行区分
    //誘導者有無
    obj.PlaceOrder.detailOnewayOrRound = passagePermit.onewayOrRound
    obj.PlaceOrder.detailNeedGuide = passagePermit.needGuide

    //通行可能時間帯（From）
    //通行可能時間帯（To）
    obj.PlaceOrder.detailPermitTimeTo = displayHHmmss(
      passagePermit.permitTimeTo
    )
    obj.PlaceOrder.detailPermitTimeFrom = displayHHmmss(
      passagePermit.permitTimeTo
    )

    // 長さL
    obj.PlaceOrder.detailLength = passagePermit.length
    // 幅W
    obj.PlaceOrder.detailWidth = passagePermit.width
    // 総重量
    obj.PlaceOrder.detailHeight = passagePermit.height
    // 幅W
    obj.PlaceOrder.detailWeight = passagePermit.weight

    //高速使用有無(高速利用可)
    obj.PlaceOrder.detailUseHighway = passagePermit.useHighway

    // ルートの説明
    obj.PlaceOrder.detailRouteDescription = passagePermit.routeDescription
    // 経路数
    obj.PlaceOrder.detailRouteCount = passagePermit.routeCount
    // 総通行経路数
    obj.PlaceOrder.detailTotalRouteCount = passagePermit.totalRouteCount

    //担当部門
    obj.PlaceOrder.detailChargeDepartmentCode =
      passagePermit.chargeDepartmentCode
    obj.PlaceOrder.detailChargeDepartmentName =
      passagePermit.chargeDepartmentName

    // 新規時許可証番号
    obj.PlaceOrder.detailFirstPermitNo = passagePermit.firstPermitNo
    // 前回許可証番号
    obj.PlaceOrder.detailPreviousPermitNo = passagePermit.previousPermitNo
    // 予備1（支払済フラグ）
    obj.PlaceOrder.detailReserve01 = passagePermit.reserve01
    // 予備1（支払処理日付）
    obj.PlaceOrder.detailReserve02 = passagePermit.reserve02

    //console.log('passagePermit= ', passagePermit)
    //console.log('passagePermit.needGuide= ', passagePermit.needGuide)
  }

  //test
  // let a = obj.PlaceOrder.realVehicleNumberplate

  // console.log(
  //   'selectedPermit[0].passage_permit_id = ',
  //   selectedPermit[0].passage_permit_id
  // )

  //車両情報
  setPermitInfoForVehicle(obj, selectedPermit)

  //経路場所情報
  setPermitInfoForRoutePlace(obj, selectedPermit)
}

// 許可証
//車両表示
function setPermitInfoForVehicle(obj, selectedPermit) {
  let passagePermit = selectedPermit[0].passage_permit
  if (passagePermit != null) {
    var details = convertSnakeCace(passagePermit.permitVehicleDtos)
    obj.PlaceOrder.permitVehicles = details
    obj.tablePermitVehicles = details
  }
}
// 許可証
//経路場所表示
function setPermitInfoForRoutePlace(obj, selectedPermit) {
  let passagePermit = selectedPermit[0].passage_permit
  if (passagePermit != null) {
    var details = convertSnakeCace(passagePermit.permitRoutePlaceDtos)
    obj.PlaceOrder.permitPlaces = details
    obj.tablePermitPlaces = details
  }
}

export function tableRowRemove(obj) {
  // 複数個削除
  for (let info of obj.selectedCost) {
    const index = obj.PlaceOrder.costDetails.indexOf(info)
    obj.tableCosts.splice(index, 1)
  }
  obj.tableCosts = obj.PlaceOrder.costDetails
}

// 「運賃計算」ボタン押下時、チェック
// 金額追加や変更可否チェック
export function checkStatusPossibleEdit(obj) {
  //発注確定処理前
  // OR
  //ステータスが
  //「10：発注承認差戻」
  //「11：輸送依頼拒否」
  //「12：輸送依頼期限切れ」
  //「13：自動運賃計算済み」 (add)
  //「14：自動運賃計算失敗」 (add)
  if (
    obj.PlaceOrder.status == null ||
    obj.PlaceOrder.status == TRANSPORT_STATUS.APPROVE_BACK ||
    obj.PlaceOrder.status == TRANSPORT_STATUS.REQUEST_REJECT ||
    obj.PlaceOrder.status == TRANSPORT_STATUS.REQUEST_FIRED_CANCEL ||
    obj.PlaceOrder.status == TRANSPORT_STATUS.AUTO_FARE_CALC_DONE ||
    obj.PlaceOrder.status == TRANSPORT_STATUS.AUTO_FARE_CALC_FAIL
  ) {
    return true
  }
  return false
}

// 発注チェック
export function goPlaceOrder(obj) {
  // 複合条件必須チェック

  // 論理性(その他)チェック
  // 未発注の状態のみ押下可能。
  // 選択行の状態が輸送依頼データが無い場合 OR 輸送依頼データの状態が「拒否（否認）:11」の場合
  //  OR 輸送依頼データの状態が「承認差し戻し:10」の場合
  //「13：自動運賃計算済み」 (add)
  //「14：自動運賃計算失敗」 (add)
  if (
    obj.PlaceOrder.status == null ||
    obj.PlaceOrder.status == TRANSPORT_STATUS.REQUEST_REJECT ||
    obj.PlaceOrder.status == TRANSPORT_STATUS.APPROVE_BACK ||
    obj.PlaceOrder.status == TRANSPORT_STATUS.AUTO_FARE_CALC_DONE ||
    obj.PlaceOrder.status == TRANSPORT_STATUS.AUTO_FARE_CALC_FAIL
  ) {
    // run
    //%s処理を行います。よろしいですか？
    obj.registerMessage = obj.$store.getters['init/getMessage'](
      'YZ00MG994Q',
      obj.detailCtrlInfo.place_order.label
    )
    obj.event = 'placeOrder'
    openSubModal(obj, 'register-modal')
  } else {
    //発注済みのため、発注依頼できません。
    openMsgModal(
      obj,
      MSG_TYPE.E,
      obj.$store.getters['init/getMessage']('EI01S001_MG031E')
    )
  }
}

// 発注
export async function placeOrder(obj) {
  obj.$bvModal.hide('register-modal')

  const validated = await obj.$refs['tab-group'].$refs[
    'base-tab-group'
  ].validate()
  if (!validated) return

  let registerData = cloneFormatDateTime(obj)

  obj.$store.dispatch('init/setServiceLoading')
  PlaceOrderService.placeOrderDetail(registerData)
    .then(
      () => {
        //%s件の発注依頼を登録しました。
        obj.infoMessage = obj.$store.getters['init/getMessage'](
          'EI01S001_MG032I',
          1
        )
        // 情報モーダル表示
        openSubModal(obj, 'planning-result-info-modal')
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message + e.response.data.messageDetail
        }
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

// 承認チェック
export function goApprove(obj) {
  // 複合条件必須チェック

  // 論理性(その他)チェック
  // 選択行の状態 = 20(承認待ち(依頼))以外の場合、エラーを出力して以降の処理を実施しない
  if (obj.PlaceOrder.status != TRANSPORT_STATUS.APPROVE_WAIT) {
    //承認依頼ではないため、%sできません。
    openMsgModal(
      obj,
      MSG_TYPE.E,
      obj.$store.getters['init/getMessage'](
        'EI01S001_MG035E',
        obj.detailCtrlInfo.approve.label
      )
    )
    //return true
  } else {
    //%s処理を行います。よろしいですか？
    obj.registerMessage = obj.$store.getters['init/getMessage'](
      'YZ00MG994Q',
      obj.detailCtrlInfo.approve.label
    )
    obj.event = 'approve'
    openSubModal(obj, 'register-modal')
  }
}

// 承認
export function approve(obj) {
  obj.$bvModal.hide('register-modal')

  let registerData = cloneFormatDateTime(obj)

  obj.$store.dispatch('init/setServiceLoading')
  PlaceOrderService.approveDetail(registerData)
    .then(
      () => {
        //%s件の承認を登録しました。
        obj.infoMessage = obj.$store.getters['init/getMessage'](
          'EI01S001_MG036I',
          1
        )
        // 情報モーダル表示
        openSubModal(obj, 'planning-result-info-modal')
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message + e.response.data.messageDetail
        }
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

// 拒否チェック
export function goReject(obj) {
  // 複合条件必須チェック

  // 論理性(その他)チェック
  // 選択行の状態 = 20(承認待ち(依頼))以外の場合、エラーを出力して以降の処理を実施しない
  if (obj.PlaceOrder.status != TRANSPORT_STATUS.APPROVE_WAIT) {
    //承認依頼ではないため、%sできません。
    openMsgModal(
      obj,
      MSG_TYPE.E,
      obj.$store.getters['init/getMessage'](
        'EI01S001_MG035E',
        obj.detailCtrlInfo.reject.label
      )
    )
    //return true
  } else {
    //%s処理を行います。よろしいですか？
    obj.registerMessage = obj.$store.getters['init/getMessage'](
      'YZ00MG994Q',
      obj.detailCtrlInfo.reject.label
    )
    obj.event = 'reject'
    openSubModal(obj, 'register-modal')
  }
}

// 拒否
export function reject(obj) {
  obj.$bvModal.hide('register-modal')

  obj.$store.dispatch('init/setServiceLoading')
  PlaceOrderService.rejectDetail(obj.PlaceOrder)
    .then(
      () => {
        //%s件の承認拒否を登録しました。
        obj.infoMessage = obj.$store.getters['init/getMessage'](
          'EI01S001_MG037I',
          1
        )
        // 情報モーダル表示
        openSubModal(obj, 'planning-result-info-modal')
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message + e.response.data.messageDetail
        }
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

// 運賃計算チェック
export async function goFareCalculate(obj) {
  // 複合条件必須チェック

  // 距離の必須チェック
  // obj.PlaceOrder.transportRequestDistance
  const validated = await obj.$refs['tab-group'].$refs[
    'base-tab-group'
  ].validate()
  if (!validated) return

  // 論理性(その他)チェック
  // 選択行の配車ステータス = 30(確定)以外の場合、かつ
  // 選択行の状態 = 10，11，12以外の場合、エラーを出力して以降の処理を実施しない

  // 運賃計算が可能なタイミングは
  // T090_配車結果の配車ステータスが「30：確定」かつ、
  // T100_輸送依頼の状態が以下の場合とする（発注の確定以降は計算しない）
  //「10：発注承認差戻」
  //「11：輸送依頼拒否」
  //「12：輸送依頼期限切れ」
  // T100_輸送依頼情報がない場合も運賃計算を行う。

  let isRunFare = false
  if (obj.PlaceOrder.planningStatus == PLANNING_STATUS.COMPLETE) {
    // if (
    //   obj.PlaceOrder.status == null ||
    //   obj.PlaceOrder.status == TRANSPORT_STATUS.APPROVE_BACK ||
    //   obj.PlaceOrder.status == TRANSPORT_STATUS.REQUEST_REJECT ||
    //   obj.PlaceOrder.status == TRANSPORT_STATUS.REQUEST_FIRED_CANCEL
    // ) {
    //   isRunFare = true
    // }
    if (checkStatusPossibleEdit(obj)) {
      isRunFare = true
    }
  }

  if (isRunFare == false) {
    //承認依頼ではないため、%sできません。
    openMsgModal(
      obj,
      MSG_TYPE.E,
      obj.$store.getters['init/getMessage'](
        'EI01S001_MG035E',
        obj.detailCtrlInfo.fare_calculate.label
      )
    )
    //return true
  } else {
    //%s処理を行います。よろしいですか？
    obj.registerMessage = obj.$store.getters['init/getMessage'](
      'YZ00MG994Q',
      obj.detailCtrlInfo.fare_calculate.label
    )
    obj.event = 'fareCalculate'
    openSubModal(obj, 'register-modal')
  }
}

// 運賃計算
export function fareCalculate(obj) {
  obj.$bvModal.hide('register-modal')

  var distance = '' + obj.PlaceOrder.transportRequestDistance //距離：数字型
  var planningResultDetailIdKeys = ''
  var orderTariffProductCodeValues = ''

  // details
  var details = convertSnakeCace(obj.PlaceOrder.planningResults)
  details.forEach((element) => {
    // タリフ判別コードの空白を想定して「 」を設定する。
    if (planningResultDetailIdKeys == '') {
      planningResultDetailIdKeys = '' + element.planning_result_detail_id
      orderTariffProductCodeValues = '' + element.order_tariff_product_code
    } else {
      planningResultDetailIdKeys =
        planningResultDetailIdKeys + ',' + element.planning_result_detail_id
      orderTariffProductCodeValues =
        orderTariffProductCodeValues + ',' + element.order_tariff_product_code
    }
  })
  //console.log('distance= ', distance)
  // タリフ情報
  var planningResultDetailData = {
    distance: distance,
    planningResultDetailIdKeys: planningResultDetailIdKeys,
    orderTariffProductCodeValues: orderTariffProductCodeValues,
  }

  //obj.PlaceOrder -> obj.planningResultId
  obj.$store.dispatch('init/setServiceLoading')
  PlaceOrderService.fareCalculateDetail(
    obj.planningResultId,
    planningResultDetailData
  )
    .then(
      (response) => {
        // server:CostDto
        var costItems = response.data
        // cost add
        if (costItems != null) {
          for (let index = 0; index < costItems.length; index++) {
            var planningResultId = costItems[index].planningResultId
            var relationId = costItems[index].relationId // planningResultDetailId
            let selectedItem = {
              // 項目
              freight_item_id: costItems[index].freightItemId,
              freight_item_name: costItems[index].freightItemName,
              cost_type: costItems[index].costType,
              cost_type_name: costItems[index].costTypeName,
              interface_key: costItems[index].interfaceKey,
              work_type: costItems[index].workType,
              sales_type: costItems[index].salesType,
              tax_type: costItems[index].taxType,
              amount_money: costItems[index].amountMoney,
              cost_without_tax: costItems[index].costWithoutTax,
              tax_percentage: costItems[index].taxPercentage,
              tariff_no: costItems[index].tariffNo,
            }

            // Cost追加表示
            setItemInfo(obj, planningResultId, relationId, selectedItem)
          }
        }

        // 完了連絡モーダル表示
        openMsgModal(
          obj,
          MSG_TYPE.I,
          obj.$store.getters['init/getMessage'](
            'YZ00MG995I',
            obj.detailCtrlInfo.fare_calculate.label
          )
        )
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message + e.response.data.messageDetail
        }
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

// 特車許可了承チェック
export function goManualPermit(obj) {
  // 複合条件必須チェック
  // 論理性(その他)チェック

  //%s処理を行います。よろしいですか？
  obj.registerMessage = obj.$store.getters['init/getMessage'](
    'YZ00MG994Q',
    obj.detailCtrlInfo.manual_permit_pass.label
  )
  obj.event = 'manualPermit'
  openSubModal(obj, 'register-modal')
}

// 特車許可了承
export function manualPermit(obj) {
  obj.$bvModal.hide('register-modal')

  let registerData = cloneFormatDateTime(obj)

  obj.$store.dispatch('init/setServiceLoading')
  PlaceOrderService.manualPermit(registerData)
    .then(
      () => {
        //%s処理を完了しました。
        obj.infoMessage = obj.$store.getters['init/getMessage'](
          'YZ00MG995I',
          obj.detailCtrlInfo.manual_permit_pass.label
        )
        // 情報モーダル表示
        openSubModal(obj, 'planning-result-info-modal')
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message + e.response.data.messageDetail
        }
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

// 「登録」と「発注」
function cloneFormatDateTime(obj) {
  let registerData = _.cloneDeep(obj.PlaceOrder)

  if (registerData.deleted == '1') {
    registerData.deleted = '0'
  }

  // planningResult
  registerData.planningResults = changeToCamelCaseKey(
    registerData.planningResults
  )
  // costDetails
  registerData.costDetails = changeToCamelCaseKey(registerData.costDetails)

  registerData.departureDateTime = formatYYYYYMMDDHHmm(
    registerData.departureDateTime
  )
  registerData.arrivalDateTime = formatYYYYYMMDDHHmm(
    registerData.arrivalDateTime
  )
  registerData.planningTargetDate = formatYYYYMMDD(
    obj,
    registerData.planningTargetDate
  )
  registerData.planningDecisionDateTime = formatYYYYYMMDDHHmm(
    registerData.planningDecisionDateTime
  )
  registerData.realVehicleRequestTime = formatYYYYYMMDDHHmm(
    registerData.realVehicleRequestTime
  )
  registerData.realVehicleEntryTime = formatYYYYYMMDDHHmm(
    registerData.realVehicleEntryTime
  )
  return registerData
}

// 「輸送依頼書出力」ボタンクリック
export async function excelDownload(obj) {
  obj.$bvModal.hide('download-modal')

  let nowDateTime = obj.$moment(new Date()).format('YYYYMMDDHHmmss')
  let fileName = obj.reportId + '_' + nowDateTime.toString() + '.xlsx'
  obj.$store.dispatch('init/setServiceLoading')
  await PlaceOrderService.download(obj.planningResultId, obj.reportId)
    .then(
      (response) => {
        if (response.data == '') {
          // 警告モーダル表示
          openMsgModal(
            obj,
            MSG_TYPE.W,
            obj.$store.getters['init/getMessage']('YZ00MG907I')
          )
        } else {
          //ファイル保存
          FileSaver.saveAs(response.data, fileName)
        }
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message
        }
        openMsgModal(obj, obj.MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}
