/* eslint-disable no-irregular-whitespace */
import {
  openMsgModal,
  openSubModal,
  convertSnakeCace,
} from '@/common/Common.js'
import {
  MSG_TYPE,
  GANTT_WORK_KIND,
  BOX_ITEM_KIND,
  BOX_HEADER_STYLE,
  BOX_EDIT_ERROR_LEVEL,
  DELETE_STATUS,
  DECISION_FLAG,
  NODE_FIX_STATUS,
  IS_DELETED,
  CHG_TCH_STATUS,
  TABLE_OPTION,
  PLANNING_STATUS,
} from '@/common/const.js'
import router from '@/router'
import _ from 'lodash'
import ManualPlanningService from '@/plan/manualPlanning/ManualPlanningService'

export const EDIT_STATUS = {
  INITIAL: 0,
  EDITING: 1,
  CHECKED: 2,
  REFER: 3,
}

export const CONFIRM_KIND = {
  NONE: 0,
  REGISTER: 1,
  FIX: 2,
  UNFIX: 3,
  CLOSE_STEP1: 4,
  CLOSE_STEP2: 5,
}

export async function init(obj) {
  obj.selectedSortConditions = obj.sortConditionInfo.map((element) => {
    let keyInfo = element.key.split('#')
    return {
      sort_key_code: keyInfo[0],
      sort_key: element.label,
      order: keyInfo[1] === 'ASC' ? '1' : keyInfo[1] === 'DESC' ? '2' : '0',
    }
  })

  let searchCondition = _.cloneDeep(obj.searchCondition)
  searchCondition.searchBeginDate = searchCondition.searchBeginDate.replace(
    /-/gi,
    ''
  )
  searchCondition.searchEndDate = searchCondition.searchEndDate.replace(
    /-/gi,
    ''
  )
  let planningStatus = null
  if (
    searchCondition.planningStatus &&
    searchCondition.planningStatus.length > 0
  ) {
    planningStatus = searchCondition.planningStatus.join(',')
  }
  searchCondition.planningStatus = planningStatus

  let vehicleIdList = null
  if (
    searchCondition.vehicleIdList &&
    searchCondition.vehicleIdList.length > 0
  ) {
    vehicleIdList = searchCondition.vehicleIdList.join(',')
  }
  searchCondition.vehicleIdList = vehicleIdList

  obj.$store.dispatch('init/setServiceLoading')
  await ManualPlanningService.search(searchCondition)
    .then(
      (response) => {
        obj.formData = {}
        obj.formData.startDate = searchCondition.searchBeginDate
        obj.formData.endDate = searchCondition.searchEndDate
        obj.formData.reqId = response.data.requestId
        sessionStorage.setItem('ganttRequestId', response.data.requestId)
        obj.formData.ganttChartGanttList =
          response.data.ganttChartGanttList || []
        obj.formData.ganttChartVehicleList =
          response.data.ganttChartVehicleList || []
        obj.formData.planningOrderList = response.data.planningOrderList || []
        obj.formData.planningResultWarningList =
          response.data.planningResultWarningList || []

        if (obj.formData.ganttChartVehicleList.length < 1) {
          // 条件に合う輸送手段がありませんでした。
          openMsgModal(
            obj,
            MSG_TYPE.E,
            obj.$store.getters['init/getMessage']('EH02S002_MG002E')
          )
          return
        } else {
          if (
            obj.formData.planningOrderList.some((order) => {
              if (
                order.deleteStatus === DELETE_STATUS.WAIT_FOR_DELETE ||
                order.deleteStatus === DELETE_STATUS.WAIT_FOR_UNDELETE
              ) {
                return true
              }
            })
          ) {
            // 削除待もしくは、解除待オーダーがあります。
            openMsgModal(
              obj,
              MSG_TYPE.I,
              obj.$store.getters['init/getMessage']('EH02S002_MG013I')
            )
          }
        }

        obj.vehicleNodeList = makeVehicleNodeList(
          obj.selectedSortConditions,
          obj.formData.ganttChartVehicleList,
          obj.formData.ganttChartGanttList,
          obj.formData.planningOrderList
        )

        // TP版：初期表示時は、警告一覧を捨てっている
        // obj.warnings = convertSnakeCace(obj.formData.planningResultWarningList)
        obj.warnings = []
      },
      (e) => {
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getter['init/getMessage']('YZ00MG946E')
        } else if (e.response.status == 404) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG996E')
        } else {
          msg = e.response.data.message
        }
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

function sortVehicleList(sortConditions, vehicleList) {
  let sortedVehicleList
  if (
    sortConditions.some((element) => {
      if (element.order !== '0') {
        return true
      }
    })
  ) {
    sortedVehicleList = vehicleList.sort((a, b) => {
      let retval = 0
      for (let sort of sortConditions) {
        const camelKey = _.camelCase(sort.sort_key_code)
        if (sort.order === '1') {
          if (a[camelKey] == null || a[camelKey] < b[camelKey]) retval = -1
          if (b[camelKey] == null || a[camelKey] > b[camelKey]) retval = 1
        } else if (sort.order === '2') {
          if (b[camelKey] == null || a[camelKey] > b[camelKey]) retval = -1
          if (a[camelKey] == null || a[camelKey] < b[camelKey]) retval = 1
        }
        if (retval !== 0) {
          break
        }
      }
      return retval
    })
  } else {
    sortedVehicleList = vehicleList.sort((a, b) => {
      return a.vehicleIndex - b.vehicleIndex
    })
  }
  return sortedVehicleList
}

export function sortVehicleNodeList(sortConditions, vehicleNodeList) {
  let sortedVehicleNodeList
  if (
    sortConditions.some((element) => {
      if (element.order !== '0') {
        return true
      }
    })
  ) {
    sortedVehicleNodeList = vehicleNodeList.sort((a, b) => {
      let retval = 0
      for (let sort of sortConditions) {
        const camelKey = _.camelCase(sort.sort_key_code)
        if (sort.order === '1') {
          if (
            a.vehicle[camelKey] == null ||
            a.vehicle[camelKey] < b.vehicle[camelKey]
          )
            retval = -1
          if (
            b.vehicle[camelKey] == null ||
            a.vehicle[camelKey] > b.vehicle[camelKey]
          )
            retval = 1
        } else if (sort.order === '2') {
          if (
            b.vehicle[camelKey] == null ||
            a.vehicle[camelKey] > b.vehicle[camelKey]
          )
            retval = -1
          if (
            a.vehicle[camelKey] == null ||
            a.vehicle[camelKey] < b.vehicle[camelKey]
          )
            retval = 1
        }
        if (retval !== 0) {
          break
        }
      }
      return retval
    })
  } else {
    sortedVehicleNodeList = vehicleNodeList.sort((a, b) => {
      return a.vehicleIndex - b.vehicleIndex
    })
  }
  return sortedVehicleNodeList
}

function findGanttListByVehicleOperationId(
  ganttChartGanttList,
  vehicleOperationId
) {
  let ganttList = ganttChartGanttList.filter(
    (gantt) => gantt.vehicleOperationId === vehicleOperationId
  )
  // 未確定は ganttidに枝番が設定されている場合があるので、確定の ganttid順 ＋ 未確定の ganttid順で順番を決める
  // 確定優先
  let fixedList = ganttList.filter(
    (gantt) => gantt.decisionFlag === DECISION_FLAG.FIXED
  )
  fixedList = fixedList.sort((a, b) => {
    return a.ganttId - b.ganttId
  })
  let unfixedList = ganttList.filter(
    (gantt) => gantt.decisionFlag !== DECISION_FLAG.FIXED
  )
  unfixedList = unfixedList.sort((a, b) => {
    return a.ganttId - b.ganttId
  })
  return fixedList.concat(unfixedList)
}

function makeVehicleNodeList(
  sortConditionInfo,
  ganttChartVehicleList,
  ganttChartGanttList,
  planningOrderList
) {
  let vehicleNodeList = []
  let vehicleIndex = -1

  let planningOrders = convertSnakeCace(planningOrderList)

  // ソート
  ganttChartVehicleList = sortVehicleList(
    sortConditionInfo,
    ganttChartVehicleList
  )

  ganttChartVehicleList.forEach((ganttVehicle) => {
    let ganttList = findGanttListByVehicleOperationId(
      ganttChartGanttList,
      ganttVehicle.vehicleOperationId
    )

    vehicleIndex++
    let vehicleNode = makeVehicleNode(
      vehicleIndex,
      ganttVehicle,
      ganttList.length > 0 ? ganttList[0] : null
    )
    vehicleNodeList.push(vehicleNode)

    fillVehicleNode(vehicleNode, ganttList, planningOrders)
  })

  return vehicleNodeList
}

function fillVehicleNode(vehicleNode, ganttList, planningOrders) {
  let tripNode = null
  let itemNode = null
  let tripIndex = -1
  vehicleNode.trips = []
  ganttList.forEach((gantt) => {
    if (gantt.ganttLevel === 2) {
      tripIndex++
      tripNode = makeTripNode(vehicleNode, tripIndex, gantt)
      vehicleNode.trips.push(tripNode)
    } else if (gantt.ganttLevel === 3) {
      if (gantt.workKind === GANTT_WORK_KIND.DepoStart) {
        itemNode = makeDepoStartNode(vehicleNode, tripNode, gantt)
        tripNode.items.push(itemNode)
      } else if (gantt.workKind === GANTT_WORK_KIND.Depo) {
        itemNode = makeDepoNode(vehicleNode, tripNode, gantt)
        tripNode.items.push(itemNode)
      } else if (gantt.workKind === GANTT_WORK_KIND.Drop) {
        itemNode = makeDropNode(vehicleNode, tripNode, gantt)
        tripNode.items.push(itemNode)
      } else if (gantt.workKind === GANTT_WORK_KIND.DepoEnd) {
        itemNode = makeDepoEndNode(vehicleNode, tripNode, gantt)
        tripNode.items.push(itemNode)
      } else if (gantt.workKind === GANTT_WORK_KIND.LastDepo) {
        itemNode = makeLastDepoNode(vehicleNode, tripNode, gantt)
        tripNode.items.push(itemNode)
      }
    } else if (gantt.ganttLevel === 4) {
      if (itemNode) {
        itemNode.orderNodes.push(
          makeOrderNode(
            vehicleNode,
            itemNode,
            getOrder(planningOrders, gantt.planningOrderId),
            gantt
          )
        )
      }
    }
  })
}

// 割付処理
export function check(obj) {
  obj.clearBoxSelectedStyle()
  if (obj.editedUnassignedOrderIds.length > 0) {
    obj.editedUnassignedOrderIds = []
  }

  let editedVehicles = []
  obj.vehicleNodeList.forEach((vehicleNode) => {
    if (vehicleNode.edited) {
      editedVehicles.push(vehicleNode)
    }
  })
  if (editedVehicles.length > 0) {
    // 未割付のオーダーの中で、元々未割付だったオーダー以外のオーダーを抽出
    obj.unassignedOrders.forEach((order) => {
      if (
        obj.editedUnassignedOrderIds.indexOf(order.planning_order_id) === -1 &&
        obj.searchUnassignedOrderIds.indexOf(order.planning_order_id) === -1
      ) {
        obj.editedUnassignedOrderIds.push(order.planning_order_id)
      }
    })
  }

  if (
    !editedVehicles.some((vehicle) => {
      if (vehicle.edited) {
        return true
      }
    })
  ) {
    // 未編集のため、割付チェックを行いませんでした。
    openMsgModal(
      obj,
      MSG_TYPE.W,
      obj.$store.getters['init/getMessage']('EH02S002_MG014E')
    )
    return
  }

  if (
    editedVehicles.some((vehicle) => {
      vehicle.trips.some((trip) => {
        trip.items.some((item) => {
          item.orderNodes.some((orderNode) => {
            if (orderNode.order) {
              if (
                orderNode.order.delete_status ===
                  DELETE_STATUS.WAIT_FOR_DELETE ||
                orderNode.order.delete_status ===
                  DELETE_STATUS.WAIT_FOR_UNDELETE
              ) {
                return true
              }
            }
          })
        })
      })
    })
  ) {
    // '削除待もしくは、解除待オーダーは割付不可です。未割付にして再度割付チェックを行ってください。
    openMsgModal(
      obj,
      MSG_TYPE.W,
      obj.$store.getters['init/getMessage']('EH02S002_MG012E')
    )
    return
  }

  /* 要求ID */
  /* 立案セッションID */
  /* ガント編集用輸送手段排他 Entity List */
  /* ガント編集用オーダー排他 Entity List */
  /* 配車オーダー番号 List */

  let dtoVehicleList = []
  let dtoOrderList = []
  editedVehicles.forEach((vehicleNode) => {
    dtoVehicleList.push({
      vehicleOperationId: vehicleNode.vehicle.vehicleOperationId,
    })
    vehicleNode.trips.forEach((trip) => {
      let loadUnloadNo = 1
      let orderDictionary = {}
      trip.items.forEach((item) => {
        item.orderNodes.forEach((orderNode) => {
          let order = orderNode.order
          if (item.type === BOX_ITEM_KIND.LastDepo) {
            dtoOrderList.push({
              planningOrderId: order.planning_order_id,
              vehicleOperationId: vehicleNode.vehicle.vehicleOperationId,
              tripNo: 1,
              loadNo: '-1',
            })
            orderDictionary[order.planning_order_id] =
              dtoOrderList[dtoOrderList.length - 1]
          } else if (item.type === BOX_ITEM_KIND.Depo) {
            dtoOrderList.push({
              planningOrderId: order.planning_order_id,
              vehicleOperationId: vehicleNode.vehicle.vehicleOperationId,
              tripNo: item.gantt.tripNo,
              loadNo: loadUnloadNo++ + '',
            })
            orderDictionary[order.planning_order_id] =
              dtoOrderList[dtoOrderList.length - 1]
          } else if (item.type === BOX_ITEM_KIND.Drop) {
            orderDictionary[order.planning_order_id].unloadNo = loadUnloadNo++
          }
        })
      })
    })
  })

  let dataList = {
    requestId: obj.formData.reqId,
    planSessionId: ++obj.sessionId,
    dtoVehicleList: dtoVehicleList,
    dtoOrderList: dtoOrderList,
    planningOrderIdList: obj.editedUnassignedOrderIds,
  }

  console.log(
    'Neuplanet.bat ARGS1 ARGS2 ARGS3 ' +
      dataList.requestId +
      ' sbk01 1 ' +
      dataList.planSessionId +
      ' 91 1 >> xx.log'
  )

  obj.$store.dispatch('init/setServiceLoading')

  let checkStarted = false
  ManualPlanningService.registAssign(dataList)
    .then(
      () => {
        obj.checkAssignedCount = 0
        checkStarted = true
        startCheckAssigned(obj)
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message + e.response.data.messageDetail
        }
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      if (!checkStarted) {
        obj.$store.dispatch('init/setServiceUnLoading')
      }
    })
}

function startCheckAssigned(obj) {
  obj.checkAssignedInterval = setInterval(() => {
    searchTimerAssigned(obj)
  }, 3000)
}

function stopCheckAssigned(obj) {
  clearInterval(obj.checkAssignedInterval)
}

export function showMap(obj) {
  obj.clearBoxSelectedStyle()
  if (obj.isMapMode) {
    obj.isMapMode = false
    return
  }

  // 編集状態が「編集中」の場合
  // 編集中です。割付チェックを実施してください。
  if (obj.isEditing) {
    openMsgModal(
      obj,
      MSG_TYPE.W,
      obj.$store.getters['init/getMessage']('EH02S002_MG007E')
    )
    return
  }

  let targetVehicleList = obj.vehicleNodeList.filter(
    (vehicleNode) => vehicleNode.checked
  )

  if (targetVehicleList.length === 0) {
    // 一覧より行を選択してください。
    openMsgModal(
      obj,
      MSG_TYPE.W,
      obj.$store.getters['init/getMessage']('YZ00MG901E')
    )
    return
  }

  // 選択ボックスの車両が全て未割付車両の場合、エラーメッセージを出力する
  if (
    !targetVehicleList.some((vehicle) => {
      if (vehicle.trips.length > 0) {
        return true
      }
    })
  ) {
    // 未割付車両のため、{0}できません。
    openMsgModal(
      obj,
      MSG_TYPE.W,
      obj.$store.getters['init/getMessage']('EH02S002_MG018E', '地図表示')
    )
    return
  }

  obj.targetMapVehicleList = targetVehicleList

  obj.isMapMode = true
}

export function gotoGantt(obj) {
  obj.clearBoxSelectedStyle()
  if (obj.isGanttMode) {
    obj.isGanttMode = false
    return
  }

  // 編集状態が「編集中」の場合
  // 編集中です。割付チェックを実施してください。
  if (obj.isEditing) {
    openMsgModal(
      obj,
      MSG_TYPE.W,
      obj.$store.getters['init/getMessage']('EH02S002_MG007E')
    )
    return
  }

  let targetGanttChartVehicleList = obj.formData.ganttChartVehicleList
  // 選択した輸送手段が存在する場合、絞り込む
  let targetVehicleIdList = obj.vehicleNodeList
    .filter((vehicleNode) => vehicleNode.checked)
    .map((vehicleNode) => vehicleNode.vehicle.vehicleOperationId)

  if (targetVehicleIdList.length > 0) {
    targetGanttChartVehicleList = obj.formData.ganttChartVehicleList.filter(
      (vehicle) =>
        targetVehicleIdList.some(
          (vehicleOperationId) =>
            vehicleOperationId === vehicle.vehicleOperationId
        )
    )
  }

  obj.targetGanttChartVehicleList = targetGanttChartVehicleList

  obj.isGanttMode = true
}

export function goFix(obj) {
  obj.clearBoxSelectedStyle()
  let targetVehicles = obj.vehicleNodeList.filter(
    (vehicleNode) => vehicleNode.checked
  )

  // 行が選択されていない
  // 行を選択してください。
  if (targetVehicles.length === 0) {
    openMsgModal(
      obj,
      MSG_TYPE.W,
      obj.$store.getters['init/getMessage']('YZ00MG906E')
    )
    return
  }

  if (!checkFix(obj, targetVehicles, null)) {
    return
  }

  obj.targetFixVehicleList = targetVehicles
  obj.targetFixTripIndex = null

  obj.confirmKind = CONFIRM_KIND.FIX
  if (checkFixWarning(obj, targetVehicles)) {
    obj.confirmMessage = obj.$store.getters['init/getMessage'](
      'YZ00MG994Q',
      obj.detailCtrlInfo.fix.label
    )
  } else {
    // 警告が存在します。確定しますか？
    obj.confirmMessage = obj.$store.getters['init/getMessage'](
      'D006_20_CONFIRM_FIX_EXIST_WARNING'
    )
  }
  openSubModal(obj, 'confirm-modal')
}

export function goUnfix(obj) {
  obj.clearBoxSelectedStyle()
  let targetVehicles = obj.vehicleNodeList.filter(
    (vehicleNode) => vehicleNode.checked
  )

  // 行が選択されていない
  // 行を選択してください。
  if (targetVehicles.length === 0) {
    openMsgModal(
      obj,
      MSG_TYPE.W,
      obj.$store.getters['init/getMessage']('YZ00MG906E')
    )
    return
  }

  if (!checkUnfix(obj, targetVehicles, null)) {
    return
  }

  obj.targetFixVehicleList = targetVehicles
  obj.targetFixTripIndex = null

  obj.confirmKind = CONFIRM_KIND.UNFIX
  obj.confirmMessage = obj.$store.getters['init/getMessage'](
    'YZ00MG994Q',
    obj.detailCtrlInfo.unfix.label
  )
  openSubModal(obj, 'confirm-modal')
}

export function goFixNode(obj) {
  if (!checkFix(obj, obj.targetFixVehicleList, obj.targetFixTripIndex)) {
    return
  }

  if (!checkFixWarning(obj, obj.targetFixVehicleList)) {
    obj.confirmKind = CONFIRM_KIND.FIX
    // 警告が存在します。確定しますか？
    obj.confirmMessage = obj.$store.getters['init/getMessage'](
      'D006_20_CONFIRM_FIX_EXIST_WARNING'
    )
    openSubModal(obj, 'confirm-modal')
    return
  }

  fix(obj)
}

export function goUnfixNode(obj) {
  if (!checkUnfix(obj, obj.targetFixVehicleList, obj.targetFixTripIndex)) {
    return
  }

  unfix(obj)
}

function checkFix(obj, targetVehicles, targetTripIndex) {
  // 選択ボックスの輸送手段がガント排他中の場合
  if (
    targetVehicles.some((vehicleNode) => {
      if (
        getVehicleFixStatus(vehicleNode) === NODE_FIX_STATUS.OptimisticLocked
      ) {
        return true
      }
    })
  ) {
    // ガント排他中の輸送手段のため、確定できません。
    openMsgModal(
      obj,
      MSG_TYPE.W,
      obj.$store.getters['init/getMessage'](
        'EH02S002_MG017E',
        obj.detailCtrlInfo.fix.label
      )
    )
    return false
  }

  // 選択ボックスの輸送手段が未割付輸送手段の場合
  if (
    targetVehicles.some((vehicleNode) => {
      if (vehicleNode.trips.length === 0) {
        return true
      }
    })
  ) {
    // 未割付輸送手段のため、確定できません。
    openMsgModal(
      obj,
      MSG_TYPE.W,
      obj.$store.getters['init/getMessage'](
        'EH02S002_MG018E',
        obj.detailCtrlInfo.fix.label
      )
    )
    return false
  }

  // 選択ボックスの輸送手段が確定済みの場合
  // -- 輸送手段選択
  if (targetTripIndex === null) {
    if (
      targetVehicles.some((vehicleNode) => {
        return vehicleNode.vehicle.planningStatus === PLANNING_STATUS.COMPLETE
      })
    ) {
      // 確定済みのため、{0}できません。
      openMsgModal(
        obj,
        MSG_TYPE.W,
        obj.$store.getters['init/getMessage'](
          'EH02S002_MG020E',
          obj.detailCtrlInfo.fix.label
        )
      )
      return false
    }
  }
  // -- トリップ選択
  else {
    if (
      targetVehicles[0].trips[targetTripIndex].gantt.decisionFlag ===
      DECISION_FLAG.FIXED
    ) {
      // 確定済みのため、{0}できません。
      openMsgModal(
        obj,
        MSG_TYPE.W,
        obj.$store.getters['init/getMessage'](
          'EH02S002_MG020E',
          obj.detailCtrlInfo.fix.label
        )
      )
      return false
    }
  }

  // 編集状態が「編集中」の場合
  // 編集中です。割付チェックを実施してください。
  if (obj.isEditing) {
    openMsgModal(
      obj,
      MSG_TYPE.W,
      obj.$store.getters['init/getMessage']('EH02S002_MG007E')
    )
    return false
  }

  // エラーが存在する場合
  let errorKindList = []
  targetVehicles.forEach((vehicleNode) => {
    vehicleNode.trips.forEach((trip) => {
      trip.items.forEach((item) => {
        item.orderNodes.forEach((orderNode) => {
          if (orderNode.order.delete_status === DELETE_STATUS.WAIT_FOR_DELETE) {
            if (errorKindList.indexOf('削除待') === -1) {
              errorKindList.push('削除待')
            }
          } else if (
            orderNode.order.delete_status === DELETE_STATUS.WAIT_FOR_UNDELETE
          ) {
            if (errorKindList.indexOf('解除待') === -1) {
              errorKindList.push('解除待')
            }
          }
          // エラー
          if (orderNode.errorLevel === BOX_EDIT_ERROR_LEVEL.Error) {
            if (errorKindList.indexOf('エラー') === -1) {
              errorKindList.push('エラー')
            }
          }
        })
      })
    })
  })

  if (errorKindList.length > 0) {
    // {0}があるため、登録できません。
    openMsgModal(
      obj,
      MSG_TYPE.W,
      obj.$store.getters['init/getMessage'](
        'EH02S002_MG008E',
        errorKindList.join('、')
      )
    )
    return false
  }

  return true
}

function checkFixWarning(obj, targetVehicles) {
  // 警告が存在する場合
  let errorKindList = []
  targetVehicles.forEach((vehicleNode) => {
    vehicleNode.trips.forEach((trip) => {
      trip.items.forEach((item) => {
        item.orderNodes.forEach((orderNode) => {
          // 警告
          if (orderNode.errorLevel === BOX_EDIT_ERROR_LEVEL.Warning) {
            if (errorKindList.indexOf('警告') === -1) {
              errorKindList.push('警告')
            }
          }
        })
      })
    })
  })

  if (errorKindList.length > 0) {
    return false
  }
  return true
}

function checkUnfix(obj, targetVehicles, targetTripIndex) {
  // 選択ボックスの輸送手段がガント排他中の場合
  if (
    targetVehicles.some((vehicleNode) => {
      if (
        getVehicleFixStatus(vehicleNode) === NODE_FIX_STATUS.OptimisticLocked
      ) {
        return true
      }
    })
  ) {
    // ガント排他中の輸送手段のため、確定解除できません。
    openMsgModal(
      obj,
      MSG_TYPE.W,
      obj.$store.getters['init/getMessage'](
        'EH02S002_MG017E',
        obj.detailCtrlInfo.unfix.label
      )
    )
    return false
  }

  // #2746 >>>> S
  // NOTE: データ不整合で、車両が確定済でオーダー無しとなった場合、その車両が使えなくなる問題がある。
  // 車両確定解除はオーダーの割付有無関係無しに確定解除（T030.planning_status = 10 / 20）に更新できるようにしておく
  //
  // // 選択ボックスの輸送手段が未割付輸送手段の場合
  // if (
  //   targetVehicles.some((vehicleNode) => {
  //     if (vehicleNode.trips.length === 0) {
  //       return true
  //     }
  //   })
  // ) {
  //   // 未割付輸送手段のため、確定解除できません。
  //   openMsgModal(
  //     obj,
  //     MSG_TYPE.W,
  //     obj.$store.getters['init/getMessage'](
  //       'EH02S002_MG018E',
  //       obj.detailCtrlInfo.unfix.label
  //     )
  //   )
  //   return false
  // }
  // #2746 <<<< E

  // 選択ボックスの輸送手段が未確定の場合
  // -- 輸送手段選択
  if (targetTripIndex === null) {
    if (
      targetVehicles.some((vehicleNode) => {
        return vehicleNode.gantt.decisionFlag !== DECISION_FLAG.FIXED
      })
    ) {
      // 未確定のため、{0}できません。
      openMsgModal(
        obj,
        MSG_TYPE.W,
        obj.$store.getters['init/getMessage'](
          'EH02S002_MG021E',
          obj.detailCtrlInfo.unfix.label
        )
      )
      return false
    }
  }
  // -- トリップ選択
  else {
    if (
      targetVehicles[0].trips[targetTripIndex].gantt.decisionFlag !==
      DECISION_FLAG.FIXED
    ) {
      // 未確定のため、{0}できません。
      openMsgModal(
        obj,
        MSG_TYPE.W,
        obj.$store.getters['init/getMessage'](
          'EH02S002_MG021E',
          obj.detailCtrlInfo.unfix.label
        )
      )
      return false
    }
  }

  // 編集状態が「編集中」の場合
  // 編集中です。割付チェックを実施してください。
  if (obj.isEditing) {
    openMsgModal(
      obj,
      MSG_TYPE.W,
      obj.$store.getters['init/getMessage']('EH02S002_MG007E')
    )
    return false
  }

  return true
}

//確定処理
async function fix(obj) {
  obj.$bvModal.hide('confirm-modal')

  let targetVehicles = obj.targetFixVehicleList
  let targetTripIndex = obj.targetFixTripIndex

  // 未登録の場合、登録処理(E010)を実施する
  if (obj.isChecked) {
    obj.targetRegisterVehicleList = targetVehicles
    obj.registerFromLogic = true
    if (!(await register(obj))) {
      return
    }
  }

  /* トリップ番号 */
  /* ガントチャート検索表示VIEW（リスト） */

  let tripNo = 0
  if (targetTripIndex !== null) {
    tripNo = targetVehicles[0].trips[targetTripIndex].gantt.tripNo
  }

  let dtoGanttList = []
  targetVehicles.forEach((vehicleNode) => {
    dtoGanttList.push(vehicleNode.gantt)
    vehicleNode.trips.forEach((trip) => {
      dtoGanttList.push(trip.gantt)
      trip.items.forEach((item) => {
        dtoGanttList.push(item.gantt)
        item.orderNodes.forEach((orderNode) => {
          dtoGanttList.push(orderNode.gantt)
        })
      })
    })
  })

  let dataList = {
    tripNo: tripNo,
    dtoGanttList: dtoGanttList,
  }

  obj.$store.dispatch('init/setServiceLoading')
  ManualPlanningService.registConfirm(dataList)
    .then(
      async () => {
        // 確定、確定解除後の検索処理
        await searchAfterConfirmCancel(obj)

        obj.editState = EDIT_STATUS.INITIAL
        obj.searchUnassignedOrderIds = []

        openMsgModal(
          obj,
          MSG_TYPE.I,
          obj.$store.getters['init/getMessage'](
            'YZ00MG995I',
            obj.detailCtrlInfo.fix.label
          )
        )
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message + e.response.data.messageDetail
        }
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

//確定解除処理
async function unfix(obj) {
  obj.$bvModal.hide('confirm-modal')

  let targetVehicles = obj.targetFixVehicleList
  let targetTripIndex = obj.targetFixTripIndex

  // 未登録の場合、登録処理(E010)を実施する
  if (obj.isChecked) {
    obj.registerFromLogic = true
    obj.targetRegisterVehicleList = targetVehicles
    if (!(await register(obj))) {
      return
    }
  }

  /* トリップ番号 */
  /* ガントチャート検索表示VIEW（リスト） */

  let tripNo = 0
  if (targetTripIndex !== null) {
    tripNo = targetVehicles[0].trips[targetTripIndex].gantt.tripNo
  }

  let dtoGanttList = []
  targetVehicles.forEach((vehicleNode) => {
    dtoGanttList.push(vehicleNode.gantt)
    vehicleNode.trips.forEach((trip) => {
      dtoGanttList.push(trip.gantt)
      trip.items.forEach((item) => {
        dtoGanttList.push(item.gantt)
        item.orderNodes.forEach((orderNode) => {
          dtoGanttList.push(orderNode.gantt)
        })
      })
    })
  })

  let dataList = {
    tripNo: tripNo,
    dtoGanttList: dtoGanttList,
  }

  obj.$store.dispatch('init/setServiceLoading')
  ManualPlanningService.registConfirmCancel(dataList)
    .then(
      async () => {
        // 確定、確定解除後の検索処理
        await searchAfterConfirmCancel(obj)

        obj.editState = EDIT_STATUS.INITIAL
        obj.searchUnassignedOrderIds = []

        openMsgModal(
          obj,
          MSG_TYPE.I,
          obj.$store.getters['init/getMessage'](
            'YZ00MG995I',
            obj.detailCtrlInfo.unfix.label
          )
        )
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message + e.response.data.messageDetail
        }
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

// 確定、確定解除後の検索処理
async function searchAfterConfirmCancel(obj) {
  let searchCondition = _.cloneDeep(obj.searchCondition)
  searchCondition.searchBeginDate = searchCondition.searchBeginDate.replace(
    /-/gi,
    ''
  )
  searchCondition.searchEndDate = searchCondition.searchEndDate.replace(
    /-/gi,
    ''
  )

  // String Id
  if (obj.formData.reqId != null) {
    searchCondition.requestId = obj.formData.reqId + '' //ID (String)
  }

  obj.$store.dispatch('init/setServiceLoading')
  await ManualPlanningService.searchAfterConfirmCancel(searchCondition)
    .then(
      (response) => {
        obj.formData = {}
        obj.formData.startDate = searchCondition.searchBeginDate
        obj.formData.endDate = searchCondition.searchEndDate
        obj.formData.reqId = response.data.requestId
        sessionStorage.setItem('ganttRequestId', response.data.requestId)
        obj.formData.ganttChartGanttList =
          response.data.ganttChartGanttList || []
        obj.formData.ganttChartVehicleList =
          response.data.ganttChartVehicleList || []
        obj.formData.planningOrderList = response.data.planningOrderList || []
        obj.formData.planningResultWarningList =
          response.data.planningResultWarningList || []

        if (obj.formData.ganttChartVehicleList.length < 1) {
          obj.vehicleNodeList = []
          // 条件に合う輸送手段がありませんでした。
          openMsgModal(
            obj,
            MSG_TYPE.E,
            obj.$store.getters['init/getMessage']('EH02S002_MG002E')
          )
          return
        } else {
          if (
            obj.formData.planningOrderList.some((order) => {
              if (
                order.deleteStatus === DELETE_STATUS.WAIT_FOR_DELETE ||
                order.deleteStatus === DELETE_STATUS.WAIT_FOR_UNDELETE
              ) {
                return true
              }
            })
          ) {
            // 削除待もしくは、解除待オーダーがあります。
            openMsgModal(
              obj,
              MSG_TYPE.I,
              obj.$store.getters['init/getMessage']('EH02S002_MG013I')
            )
          }
        }

        obj.vehicleNodeList = makeVehicleNodeList(
          obj.selectedSortConditions,
          obj.formData.ganttChartVehicleList,
          obj.formData.ganttChartGanttList,
          obj.formData.planningOrderList
        )

        obj.warnings = convertSnakeCace(obj.formData.planningResultWarningList)

        if (obj.formData.planningResultWarningList.length > 0) {
          let errCarKeyList = []
          let chkErrCarKeyList = []
          let errOrderKeyDictionary = {}

          obj.formData.planningResultWarningList.forEach((warning) => {
            if (warning.vehicleOperationId) {
              if (errCarKeyList.indexOf(warning.vehicleOperationId) === -1) {
                errCarKeyList.push(warning.vehicleOperationId)
              }
              if (
                warning.warningKind === '1' &&
                chkErrCarKeyList.indexOf(warning.vehicleOperationId) === -1
              ) {
                chkErrCarKeyList.push(warning.vehicleOperationId)
              }
            }
            if (!(warning.planningOrderId in errOrderKeyDictionary)) {
              errOrderKeyDictionary[warning.planningOrderId] =
                warning.warningKind
            } else {
              //既存が警告で、自分はエラーの場合は警告区分を上書き
              if (
                errOrderKeyDictionary[warning.planningOrderId] === '2' &&
                warning.warningKind === '1'
              ) {
                errOrderKeyDictionary[warning.planningOrderId] = '1'
              }
            }
          })

          console.log('errCarKeyList', errCarKeyList)
          console.log('chkErrCarKeyList', chkErrCarKeyList)
          console.log('errOrderKeyDictionary', errOrderKeyDictionary)

          obj.vehicleNodeList.forEach((vehicleNode) => {
            let errorStatusChanged = false
            if (
              errCarKeyList.indexOf(vehicleNode.vehicle.vehicleOperationId) >= 0
            ) {
              console.log(
                'errCarKeyList.indexOf',
                vehicleNode.vehicle.vehicleOperationId
              )
              vehicleNode.trips.forEach((trip) => {
                trip.items.forEach((item) => {
                  item.orderNodes.forEach((orderNode) => {
                    if (
                      orderNode.order.planning_order_id in errOrderKeyDictionary
                    ) {
                      console.log(
                        'errOrderKeyDictionary',
                        errOrderKeyDictionary[orderNode.order.planning_order_id]
                      )
                      if (
                        errOrderKeyDictionary[
                          orderNode.order.planning_order_id
                        ] === '1'
                      ) {
                        // Error
                        orderNode.errorLevel = BOX_EDIT_ERROR_LEVEL.Error
                        errorStatusChanged = true
                      } else {
                        // Warning
                        orderNode.errorLevel = BOX_EDIT_ERROR_LEVEL.Warning
                        errorStatusChanged = true
                      }
                    }
                  })
                })
              })
            }
            // アイコン表示有無が再判定されるように配列の要素を一度切出してから再度付ける
            if (errorStatusChanged) {
              console.log('errorStatusChanged', errorStatusChanged)

              vehicleNode.trips.forEach((trip) => {
                trip.items.forEach((item) => {
                  let orderNodes = []
                  item.orderNodes.forEach((orderNode) => {
                    orderNodes.push(orderNode)
                  })
                  item.orderNodes = []
                  orderNodes.forEach((orderNode) => {
                    item.orderNodes.push(orderNode)
                  })
                })
              })
              vehicleNode.trips.forEach((trip) => {
                let items = []
                trip.items.forEach((item) => {
                  items.push(item)
                })
                trip.items = []
                items.forEach((item) => {
                  trip.items.push(item)
                })
              })
              let trips = []
              vehicleNode.trips.forEach((trip) => {
                trips.push(trip)
              })
              vehicleNode.trips = []
              trips.forEach((trip) => {
                vehicleNode.trips.push(trip)
              })
            }
          })
        }
      },
      (e) => {
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getter['init/getMessage']('YZ00MG946E')
        } else if (e.response.status == 404) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG996E')
        } else {
          msg = e.response.data.message
        }
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

// 割付後Timerの検索処理
function searchTimerAssigned(obj) {
  stopCheckAssigned(obj)
  obj.checkAssignedCount++

  let dataList = {
    requestId: obj.formData.reqId,
    planSessionId: obj.sessionId,
    dtoVehicleList: null,
    dtoOrderList: null,
    planningOrderIdList: null,
  }

  obj.$store.dispatch('init/setServiceLoading')
  let checkStarted = false
  ManualPlanningService.searchTimerAssigned(dataList)
    .then(
      (response) => {
        let planningRequest = response.data.planningRequest

        if (planningRequest.planStatus === '30') {
          let ganttChartGanttList = response.data.ganttChartGanttList || []
          obj.formData.planningResultWarningList =
            response.data.planningResultWarningList || []

          obj.warnings = convertSnakeCace(
            obj.formData.planningResultWarningList
          )

          let errCarKeyList = []
          let chkErrCarKeyList = []
          let errOrderKeyDictionary = {}

          if (obj.formData.planningResultWarningList.length > 0) {
            // 割付チェックが完了しました。チェックエラー、警告があります。
            openMsgModal(
              obj,
              MSG_TYPE.W,
              obj.$store.getters['init/getMessage']('EH02S002_MG005E')
            )
            obj.formData.planningResultWarningList.forEach((warning) => {
              if (warning.vehicleOperationId) {
                if (errCarKeyList.indexOf(warning.vehicleOperationId) === -1) {
                  errCarKeyList.push(warning.vehicleOperationId)
                }
                if (
                  warning.warningKind === '1' &&
                  chkErrCarKeyList.indexOf(warning.vehicleOperationId) === -1
                ) {
                  chkErrCarKeyList.push(warning.vehicleOperationId)
                }
              }
              if (!(warning.planningOrderId in errOrderKeyDictionary)) {
                errOrderKeyDictionary[warning.planningOrderId] =
                  warning.warningKind
              } else {
                //既存が警告で、自分はエラーの場合は警告区分を上書き
                if (
                  errOrderKeyDictionary[warning.planningOrderId] === '2' &&
                  warning.warningKind === '1'
                ) {
                  errOrderKeyDictionary[warning.planningOrderId] = '1'
                }
              }
            })
          } else {
            // 割付チェックが完了しました。チェックエラー、警告はありません。
            openMsgModal(
              obj,
              MSG_TYPE.I,
              obj.$store.getters['init/getMessage']('EH02S002_MG006I')
            )
          }

          obj.formData.ganttChartVehicleList.forEach((ganttVehicle) => {
            let currentVehicleNode = obj.vehicleNodeList.filter(
              (vehicleNode) =>
                vehicleNode.vehicle.vehicleOperationId ===
                ganttVehicle.vehicleOperationId
            )[0]
            if (currentVehicleNode.trips.length === 0) {
              ganttVehicle.ganttChartGanttDtos = []
            }
          })

          // 割付チェックエラー・警告なし(ガント編集用排他テーブル.立案対象フラグ = 0)のデータのみ、
          // 画面で保持しているガントワークテーブルと入れ替える
          // 割付チェックエラー・警告あり(ガント編集用排他テーブル.立案対象フラグ = 2(立案時警告輸送手段))の場合は、
          // 画面で保持しているガントワークテーブルを入れ替えずにエラー・警告アイコンを表示する
          if (ganttChartGanttList.length > 0) {
            let planningOrders = convertSnakeCace(
              obj.formData.planningOrderList
            )

            obj.formData.ganttChartVehicleList.forEach((ganttVehicle) => {
              if (
                chkErrCarKeyList.indexOf(ganttVehicle.vehicleOperationId) === -1
              ) {
                let currentVehicleNode = obj.vehicleNodeList.filter(
                  (vehicleNode) =>
                    vehicleNode.vehicle.vehicleOperationId ===
                    ganttVehicle.vehicleOperationId
                )[0]

                let ganttList = findGanttListByVehicleOperationId(
                  ganttChartGanttList,
                  ganttVehicle.vehicleOperationId
                )

                if (ganttList.length > 0) {
                  currentVehicleNode.gantt =
                    ganttList.length > 0 ? ganttList[0] : null
                  fillVehicleNode(currentVehicleNode, ganttList, planningOrders)
                  ganttVehicle.ganttChartGanttDtos = ganttList
                }
              }
            })
          }
          obj.editState = EDIT_STATUS.CHECKED

          obj.vehicleNodeList.forEach((vehicleNode) => {
            let errorStatusChanged = false
            if (
              errCarKeyList.indexOf(vehicleNode.vehicle.vehicleOperationId) >= 0
            ) {
              vehicleNode.trips.forEach((trip) => {
                trip.items.forEach((item) => {
                  item.orderNodes.forEach((orderNode) => {
                    if (
                      orderNode.order.planning_order_id in errOrderKeyDictionary
                    ) {
                      if (
                        errOrderKeyDictionary[
                          orderNode.order.planning_order_id
                        ] === '1'
                      ) {
                        // Error
                        orderNode.errorLevel = BOX_EDIT_ERROR_LEVEL.Error
                        errorStatusChanged = true
                      } else {
                        // Warning
                        orderNode.errorLevel = BOX_EDIT_ERROR_LEVEL.Warning
                        errorStatusChanged = true
                      }
                    }
                  })
                })
              })
            }
            // アイコン表示有無が再判定されるように配列の要素を一度切出してから再度付ける
            if (errorStatusChanged) {
              vehicleNode.trips.forEach((trip) => {
                trip.items.forEach((item) => {
                  let orderNodes = []
                  item.orderNodes.forEach((orderNode) => {
                    orderNodes.push(orderNode)
                  })
                  item.orderNodes = []
                  orderNodes.forEach((orderNode) => {
                    item.orderNodes.push(orderNode)
                  })
                })
              })
              vehicleNode.trips.forEach((trip) => {
                let items = []
                trip.items.forEach((item) => {
                  items.push(item)
                })
                trip.items = []
                items.forEach((item) => {
                  trip.items.push(item)
                })
              })
              let trips = []
              vehicleNode.trips.forEach((trip) => {
                trips.push(trip)
              })
              vehicleNode.trips = []
              trips.forEach((trip) => {
                vehicleNode.trips.push(trip)
              })
            }
          })
        } else if (planningRequest.planStatus === '40') {
          // 割付チェックが異常終了しました。
          openMsgModal(
            obj,
            MSG_TYPE.E,
            obj.$store.getters['init/getMessage']('EH02S002_MG004E')
          )
        } else if (
          planningRequest.planStatus === '10' ||
          planningRequest.planStatus === '20'
        ) {
          if (obj.checkAssignedCount < 20) {
            checkStarted = true
            startCheckAssigned(obj)
          } else {
            // 想定時間内に割付チェックが終了されませんでした。
            openMsgModal(
              obj,
              MSG_TYPE.E,
              obj.$store.getters['init/getMessage']('EH02S002_MG019E')
            )
          }
        }
      },
      (e) => {
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getter['init/getMessage']('YZ00MG946E')
        } else if (e.response.status == 404) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG996E')
        } else {
          msg = e.response.data.message
        }
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      if (!checkStarted) {
        obj.$store.dispatch('init/setServiceUnLoading')
      }
    })
}

export function afterConfirmMessage(obj) {
  if (obj.confirmKind === CONFIRM_KIND.REGISTER) {
    register(obj)
  } else if (obj.confirmKind === CONFIRM_KIND.FIX) {
    fix(obj)
  } else if (obj.confirmKind === CONFIRM_KIND.UNFIX) {
    unfix(obj)
  } else if (obj.confirmKind === CONFIRM_KIND.CLOSE_STEP1) {
    closeManualPlanningEditStep1(obj)
  } else if (obj.confirmKind === CONFIRM_KIND.CLOSE_STEP2) {
    closeManualPlanningEditStep2(obj)
  }
}

export async function goRegister(obj) {
  obj.clearBoxSelectedStyle()
  // 編集状態が「編集中」の場合
  // 編集中です。割付チェックを実施してください。
  if (obj.isEditing) {
    openMsgModal(
      obj,
      MSG_TYPE.W,
      obj.$store.getters['init/getMessage']('EH02S002_MG007E')
    )
    return
  }

  let targetVehicles = []
  obj.vehicleNodeList.forEach((vehicleNode) => {
    if (vehicleNode.edited) {
      targetVehicles.push(vehicleNode)
    }
  })

  // エラー・警告なしであること
  let errorKindList = []
  let isExistWarning = false
  targetVehicles.forEach((vehicleNode) => {
    vehicleNode.trips.forEach((trip) => {
      trip.items.forEach((item) => {
        item.orderNodes.forEach((orderNode) => {
          if (orderNode.order.delete_status === DELETE_STATUS.WAIT_FOR_DELETE) {
            if (errorKindList.indexOf('削除待') === -1) {
              errorKindList.push('削除待')
            }
          } else if (
            orderNode.order.delete_status === DELETE_STATUS.WAIT_FOR_UNDELETE
          ) {
            if (errorKindList.indexOf('解除待') === -1) {
              errorKindList.push('解除待')
            }
          }
          // エラー、警告
          if (orderNode.errorLevel === BOX_EDIT_ERROR_LEVEL.Error) {
            if (errorKindList.indexOf('エラー') === -1) {
              errorKindList.push('エラー')
            }
          } else if (orderNode.errorLevel === BOX_EDIT_ERROR_LEVEL.Warning) {
            isExistWarning = true
          }
        })
      })
    })
  })

  if (errorKindList.length > 0) {
    // {0}があるため、登録できません。
    openMsgModal(
      obj,
      MSG_TYPE.W,
      obj.$store.getters['init/getMessage'](
        'EH02S002_MG008E',
        errorKindList.join('、')
      )
    )
    return
  }

  obj.targetRegisterVehicleList = targetVehicles

  obj.registerFromLogic = false
  obj.confirmKind = CONFIRM_KIND.REGISTER
  // エラーは存在しないが、警告が存在する場合
  if (isExistWarning) {
    // 警告が存在します。登録しますか？
    obj.confirmMessage = obj.$store.getters['init/getMessage'](
      'D006_20_CONFIRM_REGISTER_EXIST_WARNING'
    )
  } else {
    obj.confirmMessage = obj.$store.getters['init/getMessage'](
      'YZ00MG994Q',
      obj.detailCtrlInfo.register.label
    )
  }
  openSubModal(obj, 'confirm-modal')
}

async function register(obj) {
  obj.$bvModal.hide('confirm-modal')
  /* 要求ID */
  /* ガントチャート検索表示VIEW（リスト） */

  let targetVehicles = obj.targetRegisterVehicleList

  let dtoGanttList = []
  targetVehicles.forEach((vehicleNode) => {
    dtoGanttList.push(vehicleNode.gantt)
    vehicleNode.trips.forEach((trip) => {
      dtoGanttList.push(trip.gantt)
      trip.items.forEach((item) => {
        dtoGanttList.push(item.gantt)
        item.orderNodes.forEach((orderNode) => {
          dtoGanttList.push(orderNode.gantt)
        })
      })
    })
  })

  if (dtoGanttList.length === 0) {
    return true
  }

  let dataList = {
    requestId: obj.formData.reqId,
    dtoGanttList: dtoGanttList,
  }

  obj.$store.dispatch('init/setServiceLoading')
  let result = false
  await ManualPlanningService.registRun(dataList)
    .then(
      async () => {
        await searchAfterConfirmCancel(obj)
        if (!obj.registerFromLogic) {
          obj.editState = EDIT_STATUS.INITIAL
          obj.searchUnassignedOrderIds = []

          openMsgModal(
            obj,
            MSG_TYPE.I,
            obj.$store.getters['init/getMessage'](
              'YZ00MG995I',
              obj.detailCtrlInfo.register.label
            )
          )
        }
        result = true
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message + e.response.data.messageDetail
        }
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
  return result
}

// 輸送手段ﾎﾞｯｸｽ
function makeVehicleNode(vehicleIndex, vehicle, gantt) {
  if (!gantt || gantt.workKind !== GANTT_WORK_KIND.Vehicle) {
    // NOTE: データ不整合で、車両が確定済でオーダー無しとなった場合、その車両が使えなくなる問題がある。
    // ガントデータが存在しない場合の確定フラグは、車両稼働情報の配車ステータスから判断する。
    let decisionFlagFromPlanningStatus = DECISION_FLAG.INIT
    if (vehicle.planningStatus === PLANNING_STATUS.COMPLETE) {
      decisionFlagFromPlanningStatus = DECISION_FLAG.FIXED
    }
    gantt = {
      ganttLevel: 1,
      vehicleOperationId: vehicle.vehicleOperationId,
      ganttId: null,
      workKind: 'A',
      decisionFlag: decisionFlagFromPlanningStatus,
    }
  }
  return {
    id: vehicleIndex,
    vehicleIndex: vehicleIndex,
    tripIndex: null,
    type: BOX_ITEM_KIND.Vehicle,
    color: '',
    vehicle: vehicle,
    gantt: gantt,
    trips: [],
    edited: false,
    checked: false,
    selected: false,
  }
}

// ﾄﾘｯﾌﾟﾎﾞｯｸｽ
export function makeTripNode(vehicleNode, tripIndex, gantt) {
  if (!gantt) {
    gantt = {
      ganttLevel: 2,
      vehicleOperationId: vehicleNode.vehicle.vehicleOperationId,
      ganttId: null,
      workKind: 'B',
      decisionFlag: DECISION_FLAG.INIT,
      // Eh05StartTime = DateTime.Now.ToString("yyyyMMdd") + "000000",
      // Eh05EndTime = DateTime.Now.ToString("yyyyMMdd") + "000000",
    }
  }
  return {
    id: gantt.ganttId,
    vehicleIndex: vehicleNode.vehicleIndex,
    vehicle: vehicleNode.vehicle,
    tripIndex: tripIndex,
    type: BOX_ITEM_KIND.Trip,
    color: BOX_HEADER_STYLE.Trip,
    maxLoadingWeight: vehicleNode.vehicle.maxLoadingWeight,
    gantt: gantt,
    items: [],
    selected: false,
  }
}

function makeBaseItemNode(vehicleNode, tripNode, gantt, workKind) {
  if (!gantt) {
    gantt = {
      ganttLevel: 3,
      vehicleOperationId: vehicleNode.vehicle.vehicleOperationId,
      ganttId: null,
      workKind: workKind,
      decisionFlag: DECISION_FLAG.INIT,
      // Eh05StartTime = DateTime.Now.ToString("yyyyMMdd") + "000000",
      // Eh05EndTime = DateTime.Now.ToString("yyyyMMdd") + "000000",
    }
  }
  return {
    id: gantt.ganttId,
    vehicleIndex: vehicleNode.vehicleIndex,
    vehicle: vehicleNode.vehicle,
    tripIndex: tripNode.tripIndex,
    type: null,
    color: null,
    gantt: gantt,
    orderNodes: [],
    selected: false,
  }
}

// 荷積ﾎﾞｯｸｽ
export function makeDepoNode(vehicleNode, tripNode, gantt) {
  let itemBox = makeBaseItemNode(
    vehicleNode,
    tripNode,
    gantt,
    GANTT_WORK_KIND.Depo
  )
  itemBox.type = BOX_ITEM_KIND.Depo
  itemBox.color = BOX_HEADER_STYLE.Depo
  return itemBox
}

// 荷卸ﾎﾞｯｸｽ
export function makeDropNode(vehicleNode, tripNode, gantt) {
  let itemBox = makeBaseItemNode(
    vehicleNode,
    tripNode,
    gantt,
    GANTT_WORK_KIND.Drop
  )
  itemBox.type = BOX_ITEM_KIND.Drop
  itemBox.color = BOX_HEADER_STYLE.Drop
  return itemBox
}

// 出発ﾎﾞｯｸｽ
export function makeDepoStartNode(vehicleNode, tripNode, gantt) {
  let itemBox = makeBaseItemNode(
    vehicleNode,
    tripNode,
    gantt,
    GANTT_WORK_KIND.DepoStart
  )
  itemBox.type = BOX_ITEM_KIND.DepoStart
  itemBox.color = BOX_HEADER_STYLE.DepoStart
  return itemBox
}

// 到着ﾎﾞｯｸｽ
export function makeDepoEndNode(vehicleNode, tripNode, gantt) {
  let itemBox = makeBaseItemNode(
    vehicleNode,
    tripNode,
    gantt,
    GANTT_WORK_KIND.DepoEnd
  )
  itemBox.type = BOX_ITEM_KIND.DepoEnd
  itemBox.color = BOX_HEADER_STYLE.DepoEnd
  return itemBox
}

// 宵積みﾎﾞｯｸｽ
export function makeLastDepoNode(vehicleNode, tripNode, gantt) {
  let itemBox = makeBaseItemNode(
    vehicleNode,
    tripNode,
    gantt,
    GANTT_WORK_KIND.LastDepo
  )
  itemBox.type = BOX_ITEM_KIND.LastDepo
  itemBox.color = BOX_HEADER_STYLE.LastDepo
  return itemBox
}

export function makeOrderNode(vehicleNode, itemNode, order, gantt) {
  if (!gantt) {
    gantt = {
      ganttLevel: 3,
      vehicleOperationId: vehicleNode.vehicle.vehicleOperationId,
      ganttId: null,
      workKind: itemNode.gantt.workKind,
      decisionFlag: DECISION_FLAG.INIT,
      planningOrderId: order.planning_order_id,
      // tripNo: itemNode.gantt.tripNo,
      // roundNo: itemNode.gantt.roundNo,
      // Eh05StartTime = DateTime.Now.ToString("yyyyMMdd") + "000000",
      // Eh05EndTime = DateTime.Now.ToString("yyyyMMdd") + "000000",
    }
  }
  return {
    order: order,
    gantt: gantt,
  }
}

export function changeNodeTypeToDepo(itemBox) {
  itemBox.type = BOX_ITEM_KIND.Depo
  itemBox.color = BOX_HEADER_STYLE.Depo
}

export function changeNodeTypeToLastDepo(itemBox) {
  itemBox.type = BOX_ITEM_KIND.LastDepo
  itemBox.color = BOX_HEADER_STYLE.LastDepo
}

function getOrder(planningOrders, planningOrderId) {
  if (planningOrderId === null) {
    return null
  }
  for (let index = 0; index < planningOrders.length; index++) {
    const planningOrder = planningOrders[index]
    if (planningOrderId === planningOrder.planning_order_id) {
      return planningOrder
    }
  }
  return null
}

export function getNodeFixStatus(node) {
  if (node.type === BOX_ITEM_KIND.Vehicle) {
    return getVehicleFixStatus(node)
  } else if (node.type === BOX_ITEM_KIND.Trip) {
    return getTripFixStatus(node)
  } else if (
    node.type === BOX_ITEM_KIND.DepoStart ||
    node.type === BOX_ITEM_KIND.Depo ||
    node.type === BOX_ITEM_KIND.Drop ||
    node.type === BOX_ITEM_KIND.DepoEnd ||
    node.type === BOX_ITEM_KIND.LastDepo
  ) {
    return getItemFixStatus(node)
  }
}

export function getVehicleFixStatus(vehicleNode) {
  if (
    vehicleNode.vehicle.ganttUserId ||
    vehicleNode.vehicle.deleted === IS_DELETED.DELETED
  ) {
    return NODE_FIX_STATUS.OptimisticLocked
  } else if (vehicleNode.vehicle.changeNoticeStatus === CHG_TCH_STATUS.IRI) {
    return NODE_FIX_STATUS.NeedChangeNotice
    // } else if (vehicleNode.gantt.decisionFlag === DECISION_FLAG.FIXED) {
  } else if (vehicleNode.vehicle.planningStatus === PLANNING_STATUS.COMPLETE) {
    return NODE_FIX_STATUS.Fixed
  } else {
    return NODE_FIX_STATUS.Unfixed
  }
}

export function getTripFixStatus(tripNode) {
  if (
    tripNode.vehicle.ganttUserId ||
    tripNode.vehicle.deleted === IS_DELETED.DELETED
  ) {
    return NODE_FIX_STATUS.OptimisticLocked
  } else if (tripNode.gantt.decisionFlag === DECISION_FLAG.FIXED) {
    return NODE_FIX_STATUS.Fixed
  } else {
    return NODE_FIX_STATUS.Unfixed
  }
}

export function getItemFixStatus(itemNode) {
  if (
    itemNode.vehicle.ganttUserId ||
    itemNode.vehicle.deleted === IS_DELETED.DELETED
  ) {
    return NODE_FIX_STATUS.OptimisticLocked
  } else if (itemNode.gantt.decisionFlag === DECISION_FLAG.FIXED) {
    return NODE_FIX_STATUS.Fixed
  } else {
    if (
      itemNode.type === BOX_ITEM_KIND.Depo ||
      itemNode.type === BOX_ITEM_KIND.Drop
    ) {
      if (
        itemNode.orderNodes.some((orderNode) => {
          if (orderNode.order) {
            if (
              orderNode.order.delete_status === DELETE_STATUS.WAIT_FOR_UNDELETE
            ) {
              return true
            }
          }
        })
      ) {
        return NODE_FIX_STATUS.WaitForUndelete
      } else if (
        itemNode.orderNodes.some((orderNode) => {
          if (orderNode.order) {
            if (
              orderNode.order.delete_status === DELETE_STATUS.WAIT_FOR_DELETE
            ) {
              return true
            }
          }
        })
      ) {
        return NODE_FIX_STATUS.WaitForDelete
      } else if (
        itemNode.orderNodes.some((orderNode) => {
          if (orderNode.order) {
            if (orderNode.order.delete_status === DELETE_STATUS.SASIKAE) {
              return true
            }
          }
        })
      ) {
        return NODE_FIX_STATUS.Sasikae
      }
    }
  }
  return NODE_FIX_STATUS.Unfixed
}

// 未割付検索
export function searchUnassigned(obj) {
  if (obj.editedUnassignedOrderIds.length > 0) {
    obj.editedUnassignedOrderIds = []
  }

  // 未割付のオーダーの中で、元々未割付だったオーダー以外のオーダーを抽出
  obj.unassignedOrders.forEach((order) => {
    if (
      obj.editedUnassignedOrderIds.indexOf(order.planning_order_id) === -1 &&
      obj.searchUnassignedOrderIds.indexOf(order.planning_order_id) === -1
    ) {
      obj.editedUnassignedOrderIds.push(order.planning_order_id)
    }
  })

  let searchCondition = _.cloneDeep(obj.searchConditionUnassign)
  searchCondition.periodDateFrom = searchCondition.periodDateFrom.replace(
    /-/gi,
    ''
  )
  searchCondition.periodDateTo = searchCondition.periodDateTo.replace(/-/gi, '')
  let planningGroupCode = null
  if (
    searchCondition.planningGroupCode &&
    searchCondition.planningGroupCode.length > 0
  ) {
    planningGroupCode = searchCondition.planningGroupCode.join(',')
  }
  searchCondition.planningGroupCode = planningGroupCode

  // String Id
  if (obj.formData.reqId != null) {
    searchCondition.requestId = obj.formData.reqId + '' //ID (String)
  }
  // String List Id [(,)で複数設定]
  let planningOrderIdList = []
  obj.vehicleNodeList.forEach((vehicleNode) => {
    vehicleNode.trips.forEach((trip) => {
      trip.items.forEach((item) => {
        if (item.type === BOX_ITEM_KIND.Drop) {
          item.orderNodes.forEach((orderNode) => {
            planningOrderIdList.push(String(orderNode.order.planning_order_id))
          })
        }
      })
    })
  })
  obj.editedUnassignedOrderIds.forEach((id) => {
    planningOrderIdList.push(String(id))
  })
  searchCondition.planningOrderIdList = null
  if (planningOrderIdList.length > 0) {
    searchCondition.planningOrderIdList = planningOrderIdList.join(',')
  }

  // 割付済みから未割りになったオーダーを残すためバックアップする。
  // 未割付のオーダーの中で、元々未割付だったオーダー以外のオーダーを抽出
  let editedUnassignedOrders = []
  obj.unassignedOrders.forEach((order) => {
    if (
      editedUnassignedOrders.indexOf(order) === -1 &&
      obj.searchUnassignedOrderIds.indexOf(order.planning_order_id) === -1
    ) {
      editedUnassignedOrders.push(order)
    }
  })

  obj.$store.dispatch('init/setServiceLoading')
  ManualPlanningService.searchunassigned(searchCondition)
    .then(
      (response) => {
        response.data.resultList[0].planningOrder.forEach((searchOrder) => {
          if (
            !obj.formData.planningOrderList.some((order) => {
              if (order.planningOrderId === searchOrder.planningOrderId) {
                return true
              }
            })
          ) {
            obj.formData.planningOrderList.push(searchOrder)
          }
        })

        obj.unassignedOrders = convertSnakeCace(
          response.data.resultList[0].planningOrder
        )
        obj.unassignedOrders.forEach((order) => {
          if (
            obj.searchUnassignedOrderIds.indexOf(order.planning_order_id) === -1
          ) {
            obj.searchUnassignedOrderIds.push(order.planning_order_id)
          }
        })
        // バックアップした、割付済みから未割りになったオーダーを追加する。
        editedUnassignedOrders.forEach((order) => {
          obj.unassignedOrders.push(order)
        })
        setUnassignedOrderVariant(obj)
      },
      (e) => {
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getter['init/getMessage']('YZ00MG946E')
          openMsgModal(obj, MSG_TYPE.E, msg)
        } else {
          if (e.response.data.messageId === 'YZ00MG907I') {
            obj.unassignedOrders = []
            // バックアップした、割付済みから未割りになったオーダーを追加する。
            editedUnassignedOrders.forEach((order) => {
              obj.unassignedOrders.push(order)
            })
            setUnassignedOrderVariant(obj)

            if (obj.unassignedOrders.length === 0) {
              msg = e.response.data.message
              openMsgModal(obj, MSG_TYPE.E, msg)
            }
          } else {
            msg = e.response.data.message
            openMsgModal(obj, MSG_TYPE.E, msg)
          }
        }
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

export function goCloseManualPlanningEdit(obj) {
  obj.clearBoxSelectedStyle()
  if (obj.editState !== EDIT_STATUS.INITIAL) {
    obj.confirmKind = CONFIRM_KIND.CLOSE_STEP1
    // 編集した内容が失われます。よろしいですか？
    obj.confirmMessage = obj.$store.getters['init/getMessage'](
      'D006_20_CONFIRM_CLOSE_EXIST_EDITED'
    )
    openSubModal(obj, 'confirm-modal')
    return
  }

  closeManualPlanningEditStep1(obj)
}

function closeManualPlanningEditStep1(obj) {
  obj.$bvModal.hide('confirm-modal')
  // 未割付一覧に正常オーダが残っている場合、警告メッセージを表示する
  if (
    obj.unassignedOrders.some((order) => {
      if (order.delete_status === DELETE_STATUS.NORMAL) {
        return true
      }
    })
  ) {
    obj.confirmKind = CONFIRM_KIND.CLOSE_STEP2
    // 「未割付一覧」にオーダーが残っています。終了しますか？
    obj.confirmMessage = obj.$store.getters['init/getMessage'](
      'D006_20_CONFIRM_CLOSE_EXIST_UNASSIGNED'
    )
    openSubModal(obj, 'confirm-modal')
    return
  }

  closeManualPlanningEditStep2(obj)
}

function closeManualPlanningEditStep2(obj) {
  obj.$bvModal.hide('confirm-modal')
  if (obj.beforeScreenRouteInfo) {
    let routeInfo = obj.beforeScreenRouteInfo.routeInfo
    routeInfo.params.beforeScreenRouteInfo =
      obj.beforeScreenRouteInfo.beforeScreenRouteInfo

    router.push(routeInfo)
  }
}

export function setUnassignedOrderVariant(obj) {
  obj.unassignedOrders.forEach((order) => {
    if (order.delete_status === DELETE_STATUS.WAIT_FOR_DELETE) {
      order._rowVariant = TABLE_OPTION.DELETED_ROW_VARIANT
    }
  })
}
