<template>
  <div>
    <DetailPageLayout
      :breadcrumbItems="breadcrumbItems"
      :buttonItems="buttonItems"
    >
    </DetailPageLayout>
    <!--------------------------------------------------------------------------------->
    <div style="padding: 5px">
      <b-card no-body style="padding: 10px">
        <b-form-row>
          <b-col cols="2">
            <BaseLabel :columnInfo="detailInfo.lbl_one_1" />
          </b-col>
          <b-col></b-col>
          <b-col></b-col>
          <b-col cols="2">
            <BaseLabel :columnInfo="detailInfo.lbl_one_2" />
          </b-col>
          <b-col></b-col>
          <b-col></b-col>
          <b-col cols="2">
            <BaseLabel :columnInfo="detailInfo.lbl_one_3" />
          </b-col>
          <b-col></b-col>
          <b-col></b-col>
          <b-col></b-col>
        </b-form-row>
        <b-form-row>
          <!-- オーダー［件］(対象、未割付) -->
          <!-- 対象 -->
          <b-col>
            <!-- md="1" class="ml-md-auto p-3" -->
            <!-- 対象輸送手段数 -->
            <span>{{ detailInfo.target_order_count.label }}</span>
          </b-col>
          <b-col>
            <BaseTextInput
              class="detail-input"
              v-model="formData['targetOrderCount']"
              :columnInfo="detailInfo.target_order_count"
            />
          </b-col>
          <!-- 未割付 -->
          <b-col>
            <span>{{ detailInfo.no_assign_order_count.label }}</span>
          </b-col>
          <b-col>
            <BaseTextInput
              class="detail-input"
              v-model="formData['noAssignOrderCount']"
              :columnInfo="detailInfo.no_assign_order_count"
            />
          </b-col>
          <!-- 車両稼働（台）（対象、割付） -->
          <!-- 対象 -->
          <b-col>
            <span>{{ detailInfo.target_vehicle_count.label }}</span>
          </b-col>
          <b-col>
            <BaseTextInput
              v-model="formData['targetVehicleCount']"
              :columnInfo="detailInfo.target_vehicle_count"
            />
          </b-col>
          <!-- 割付 -->
          <b-col>
            <span>{{ detailInfo.assign_vehicle_count.label }}</span>
          </b-col>
          <b-col>
            <BaseTextInput
              v-model="formData['assignVehicleCount']"
              :columnInfo="detailInfo.assign_vehicle_count"
            />
          </b-col>
          <!-- 実行情報（立案状態、経過時間） -->
          <!-- 立案状態 -->
          <b-col>
            <span>{{ detailInfo.plan_status_name.label }}</span>
          </b-col>
          <b-col>
            <BaseTextInput
              v-model="formData['planStatusName']"
              :columnInfo="detailInfo.plan_status_name"
            />
          </b-col>
          <!-- 経過時間 -->
          <b-col>
            <span>{{ detailInfo.passed_time.label }}</span>
          </b-col>
          <b-col>
            <BaseTextInput
              v-model="formData['passedTime']"
              :columnInfo="detailInfo.passed_time"
            />
          </b-col>
          <b-col>
            <span>{{ detailInfo.lbl_passed_time_unit.label }}</span>
          </b-col>
        </b-form-row>
      </b-card>
      <b-card no-body style="padding: 10px">
        <b-form-row>
          <b-col>
            <BaseLabel :columnInfo="detailInfo.sub_title_2" />
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col cols="3">
            <b-card no-body style="padding: 10px">
              <!-- 最良世代の結果ラベル -->
              <b-form-row>
                <b-col>
                  <span>{{ detailInfo.lbl_best_generation_title.label }}</span>
                </b-col>
              </b-form-row>
              <b-form-row>
                <b-col cols="1.5">
                  <span>{{ detailInfo.lbl_best_generation.label }}</span>
                </b-col>
                <b-col cols="10.5">
                  <span>{{ formData['bestGeneration'] }}</span>
                  <!-- <BaseTextInput
                    v-model="formData['bestGeneration']"
                    :columnInfo="detailInfo.best_generation"
                  /> -->
                </b-col>
              </b-form-row>
            </b-card>
            <br />
            <!-- 最良世代の結果テーブル -->
            <BaseTable
              select-mode="range"
              ref="table"
              :fields="fieldsBestGeneration"
              :items="tableItemsBestGeneration"
              selectMode="single"
            />
          </b-col>
          <b-col cols="9">
            <!-- グラフ -->
            <BaseChartLine :chart-data="datacollection" :options="options" />
          </b-col>
        </b-form-row>
      </b-card>
      <b-card no-body style="padding: 10px">
        <BaseLabel :columnInfo="detailInfo.sub_title_3" />
        <!-- テーブル -->
        <BaseTable
          select-mode="range"
          ref="table"
          :fields="tableFieldsEvaluation"
          :items="tableItemsEvaluation"
          selectMode="single"
          @row-selected="onRowSelected"
          @row-clicked="onRowClicked"
        />
      </b-card>
    </div>

    <!-- 情報モーダル -->
    <InfoModal
      id="detail-info-modal"
      :columnInfos="messageInfo"
      :message="infoMessage"
      @after-close="back(obj)"
    />
    <!-- 手動配車モーダル -->
    <ConfirmModal
      id="register-modal"
      :columnInfos="messageInfo"
      :message="registerMessage"
      @confirm="register(obj)"
    />
  </div>
</template>

<script>
import AutoPlanningInfo from './AutoPlanningInfo'
import { getListOptions, openSubModal } from '@/common/Common.js'
import { SCREEN_ID, DEPARTMENT_KIND } from '@/common/const.js'
import { getBreadcrumbItems, back } from '@/common/screen-common.js'
import {
  init,
  register,
  goManualPlanning,
  setChartInfo,
} from '@/plan/autoPlanning/AutoPlanningDetail.js'
import BaseChartLine from '@/common/components/base/BaseChartLine.vue'

const screenId = SCREEN_ID.AUTO_PLANNING_DETAIL

export default {
  name: 'autoPlanningDetail',
  components: { BaseChartLine },
  props: {
    beforeScreenRouteInfo: {
      type: Object,
    },
    requestId: {
      type: Number,
      default: -1,
    },
    screenKbn: {
      type: Number,
    },
  },

  data() {
    return {
      formData: new AutoPlanningInfo(),
      obj: this,
      /**
       * 選択された作業データリスト.
       * @type {Array}
       */
      selectedItem: [],

      /**
       * 作業データリスト.
       * @type {Array}
       */
      tableItemsEvaluation: [],
      tableFieldsEvaluation: [],
      tableItemsBestGeneration: [],

      registerMessage: null,
      oriData: new AutoPlanningInfo(),
      DEPARTMENT_KIND_CARRIER: DEPARTMENT_KIND.CARRIER,
      datacollection: {
        //Data to be represented on x-axis
        labels: [
          'January',
          'February',
          'March',
          'April',
          'May',
          'June',
          'July',
          'August',
          'September',
          'October',
          'November',
          'December',
        ],
        datasets: [
          {
            label: 'Data One',
            borderWidth: 2,
            borderColor: '#249EBF',
            backgroundColor: '#249EBF',
            pointBorderColor: '#f87979',
            pointBackgroundColor: '#f87979',
            lineTension: 0,
            fill: false,
            //Data to be represented on y-axis
            data: [40, 20, 30, 50, 90, 10, 20, 40, 50, 70, 90, 100],
          },
        ],
      },
      //Chart.js options that controls the appearance of the chart
      options: {
        scales: {
          yAxes: [
            {
              stacked: false,
              ticks: {
                beginAtZero: true,
              },
              gridLines: {
                display: true,
              },
            },
          ],
          xAxes: [
            {
              stacked: false,
              gridLines: {
                display: true,
              },
            },
          ],
        },
        title: {
          display: false,
          position: 'top',
          text: 'Graph',
        },
        legend: {
          display: false,
          position: 'bottom', //'top','left','bottom','right'
        },
        responsive: true,
        maintainAspectRatio: false, //default value. if false then div 0
      },
      chartLabels: [],
      chartItemNames: [],
    }
  },

  created() {
    init(this)
  },

  computed: {
    detailCtrlInfo() {
      return this.$store.getters['init/getScreens'](screenId)(
        'detail_ctrl_info'
      )
    },
    detailInfo() {
      return this.$store.getters['init/getScreens'](screenId)('detail_info')
    },
    messageInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'message'
      )
    },
    breadcrumbItems() {
      return getBreadcrumbItems(this.beforeScreenRouteInfo, screenId)
    },
    /**
     * ボタン情報リスト.
     * @return {Array}
     */
    buttonItems() {
      return [
        // ボタングループ#1
        [
          // 手動配車ボタン
          {
            variant: 'success',
            icon: 'edit',
            columnInfo: this.detailCtrlInfo.register,
            onClickCallback: () => {
              goManualPlanning(this)
              //register(this)
            },
          },
          // 戻るボタン
          {
            variant: 'success',
            icon: 'times-circle',
            columnInfo: this.detailCtrlInfo.back,
            onClickCallback: () => {
              back(this)
            },
          },
        ],
        // ボタングループ#2
        [],
      ]
    },
    inputComponents() {
      return [
        // {
        //   // 実車番情報タブ
        //   label: this.detailInfo.tab1.label,
        //   inputComponents: [
        {
          // 実車番ID
          type: 'text',
          id: 'realVehicleId',
          columnInfo: this.detailInfo.real_vehicle_id,
        },
        {
          // ブロックコード
          type: 'text',
          id: 'blockCode',
          columnInfo: this.detailInfo.block_code,
        },
        {
          // 車番
          type: 'text',
          id: 'numberplate',
          columnInfo: this.detailInfo.numberplate,
          maxLength: 30,
          rules: [
            this.INPUT_TYPE_CHECK.REQUIRED,
            //this.INPUT_TYPE_CHECK.ALPHANUMERIC_CODE,
          ],
        },
        {
          // 区分（車両種類）
          type: 'select',
          id: 'realVehicleKind',
          columnInfo: this.detailInfo.real_vehicle_kind,
          options: getListOptions(this.MASTER_CODE.CARGO_VEHICLE_KIND),
          rules: [this.INPUT_TYPE_CHECK.REQUIRED],
          onChangeCallback: () => {},
        },
        {
          // 車型
          type: 'select',
          id: 'vehicleModel',
          columnInfo: this.detailInfo.vehicle_model,
          options: getListOptions(this.MASTER_CODE.VEHICLE_MODEL),
          onChangeCallback: () => {},
        },
        {
          // 備考
          type: 'text',
          id: 'note',
          columnInfo: this.detailInfo.note,
        },
        {
          // 実車番予備1
          type: 'text',
          id: 'reserve01',
          columnInfo: this.detailInfo.reserve_01,
          maxLength: 50,
        },
        {
          // 実車番予備2
          type: 'text',
          id: 'reserve02',
          columnInfo: this.detailInfo.reserve_02,
          maxLength: 50,
        },
        {
          // 実車番予備3
          type: 'text',
          id: 'reserve03',
          columnInfo: this.detailInfo.reserve_03,
          maxLength: 50,
        },
        {
          // 実車番予備4
          type: 'text',
          id: 'reserve04',
          columnInfo: this.detailInfo.reserve_04,
          maxLength: 50,
        },
        {
          // 実車番予備5
          type: 'text',
          id: 'reserve05',
          columnInfo: this.detailInfo.reserve_05,
          maxLength: 50,
        },
        {
          // 実車番予備6
          type: 'text',
          id: 'reserve06',
          columnInfo: this.detailInfo.reserve_06,
          maxLength: 50,
        },
        {
          // 実車番予備7
          type: 'text',
          id: 'reserve07',
          columnInfo: this.detailInfo.reserve_07,
          maxLength: 50,
        },
        {
          // 実車番予備8
          type: 'text',
          id: 'reserve08',
          columnInfo: this.detailInfo.reserve_08,
          maxLength: 50,
        },
        {
          // 実車番予備9
          type: 'text',
          id: 'reserve09',
          columnInfo: this.detailInfo.reserve_09,
          maxLength: 50,
        },
        {
          // 実車番予備10
          type: 'text',
          id: 'reserve10',
          columnInfo: this.detailInfo.reserve_10,
          maxLength: 50,
        },
        // {
        //   // 登録日時
        //   type: 'text',
        //   id: 'registrationTimeDisplay',
        //   columnInfo: this.detailInfo.registration_time_display,
        // },
        // {
        //   // 登録ユーザ
        //   type: 'text',
        //   id: 'registrationUser',
        //   columnInfo: this.detailInfo.registration_user,
        // },
        // {
        //   // 更新日時
        //   type: 'text',
        //   id: 'updateTimeDisplay',
        //   columnInfo: this.detailInfo.update_time_display,
        // },
        // {
        //   // 更新ユーザ
        //   type: 'text',
        //   id: 'updateUser',
        //   columnInfo: this.detailInfo.update_user,
        // },
        // {
        //   // バージョン
        //   type: 'text',
        //   id: 'version',
        //   columnInfo: this.detailInfo.version,
        // },
        // {
        //   // トリガ更新日時
        //   type: 'text',
        //   id: 'updateByTrigger',
        //   columnInfo: this.detailInfo.update_by_trigger,
        // },
        //   ],
        // },
      ]
    },

    /**
     * テーブル列定義リスト.
     * @return {Array}
     */
    fieldsBestGeneration() {
      return this.$store.getters['init/getFields'](screenId)(
        this.DOMAIN_NAME.FIELDS
      )
    },

    /**
     * 情報メッセージ.
     * @return {String}
     */
    infoMessage() {
      return this.$store.getters['init/getMessage'](
        'YZ00MG995I',
        this.detailCtrlInfo.register.label
      )
    },
  },

  methods: {
    getListOptions,
    openSubModal,
    goManualPlanning,
    register,
    back,
    setChartInfo,

    onRowSelected(selectedItems) {
      // グラフ初期表示
      setChartInfo(this, selectedItems[0])
    },

    onRowClicked(item, index) {
      console.log('item.evaluation_name===>' + item.evaluation_name)
      console.log('item.index===>' + index)
      this.detailRowIndex = index
    },

    setErrorMsg(msg) {
      this.$emit('set-error-msg', msg)
    },

    setWarnMsg(msg) {
      this.$emit('set-warn-msg', msg)
    },
  },
}
</script>

<style lang="scss" scoped>
.detailForm {
  height: 730px;
  overflow-y: auto;
  overflow-x: hidden;
}

#BaseView {
  height: 730px;
}
</style>
