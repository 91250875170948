export default class planningOrder {
  planningOrderId = null
  orderDetailId = null
  receiptNo = null
  planningSectionNo = null
  planningCargoNo = null
  orginPlanningOrderId = null
  blockCode = null
  packupedId = null
  packupId = null
  packupFlag = null
  deliveryPackupFlag = null
  sectionPartitionFlag = null
  countPartitionFlag = null
  pntchdKind = null
  departureBeginDate = null
  departureBeginTime = null
  departureEndTime = null
  departurePlaceId = null
  departurePlaceCode = null
  departurePlaceName = null
  departurePlaceAssignFlag = null
  departureTimeAssignKind = null
  departureTimeStrict = null
  departurePostalCode = null
  departureAddress2 = null
  arrivalBeginDate = null
  arrivalBeginTime = null
  arrivalEndTime = null
  arrivalPlaceId = null
  arrivalPlaceCode = null
  arrivalPlaceName = null
  arrivalTimeAssignKind = null
  arrivalTimeStrict = null
  arrivalPostalCode = null
  arrivalAddress2 = null
  totalQuantity = 0
  totalWeight = 0
  totalVolume = 0
  planningGroupCode = null
  departureCondition = null
  arrivalCondition = null
  note = null
  planningStatus = null
  assignCarrierId = null
  assignCarrierCode = null
  assignCarrierName = null
  assignVehicleId = null
  assignVehicleCode = null
  assignVehicleName = null
  assignTripNo = null
  assignDropNo = null
  autoPlanningExcludeFlag = null
  orderKind = null
  preloadKind = null
  preloadAssignDate = null
  preloadBeginTime = null
  transportTypeKind = null
  transportDistanceKind = null
  orderDistance = null
  changeKind = null
  oldVehicleId = null
  vehicleCondition01 = null
  vehicleCondition02 = null
  vehicleCondition03 = null
  vehicleCondition04 = null
  vehicleCondition05 = null
  vehicleCondition06 = null
  vehicleCondition07 = null
  vehicleCondition08 = null
  vehicleCondition09 = null
  vehicleCondition10 = null
  vehicleCondition11 = null
  vehicleCondition12 = null
  vehicleCondition13 = null
  vehicleCondition14 = null
  vehicleCondition15 = null
  vehicleCondition16 = null
  vehicleCondition17 = null
  vehicleCondition18 = null
  vehicleCondition19 = null
  vehicleCondition20 = null
  //
  registrationTimeDisplay = null
  registrationUser = null
  updateTimeDisplay = null
  updateUser = null
  ownerName = null
  distributorName = null
  consigneeName = null
  departureCondition = null
  arrivalCondition = null
  reserve01 = null
  reserve02 = null
  reserve03 = null
  reserve04 = null
  reserve05 = null
  reserve06 = null
  reserve07 = null
  reserve08 = null
  reserve09 = null
  reserve10 = null
  reserve11 = null
  reserve12 = null
  reserve13 = null
  reserve14 = null
  reserve15 = null
  reserve16 = null
  reserve17 = null
  reserve18 = null
  reserve19 = null
  reserve20 = null
  maxAvailableEntrySizeName = null
  maxAvailableEntrySize = null
  registrationTimeDisplay = null
  registrationUser = null
  updateTimeDisplay = null
  updateUser = null
  deleteStatus = null
  ownerName = null
  distributorName = null
  consigneeName = null
  departureCondition = null
  arrivalCondition = null
  //orderEntryKind = 0
  //-----------------------------
  //ReceiveOrderDto
  orderReserve01 = null
  orderReserve02 = null
  orderReserve03 = null
  orderReserve04 = null
  orderReserve05 = null
  orderReserve06 = null
  orderReserve07 = null
  orderReserve08 = null
  orderReserve09 = null
  orderReserve10 = null
  orderReserve11 = null
  orderReserve12 = null
  orderReserve13 = null
  orderReserve14 = null
  orderReserve15 = null
  orderReserve16 = null
  orderReserve17 = null
  orderReserve18 = null
  orderReserve19 = null
  orderReserve20 = null
  //-----------------------------
  receiveOrderDto = []
  planningOrderDetailDto = []
}
