<template>
  <div style="overflow-x: hidden">
    <DetailPageLayout
      :breadcrumbItems="breadcrumbItems"
      :buttonItems="buttonItems"
    >
      <template #top>
        <BaseTabGroup
          ref="tab-group"
          :inputModel="TransportRequest"
          :inputComponents="inputComponents"
        >
          <template v-slot:tab2TableDown>
            <div>
              <!-- 配車結果明細テーブル -->
              <BaseTable
                select-mode="single"
                ref="table"
                :fields="fields"
                :items="tableItems"
              />
              <!-- 金額テーブル -->
              <BaseTable
                select-mode="single"
                ref="tableCosts"
                :fields="fieldsCost"
                :items="tableCosts"
              />
              <!-- 後工程のオーダー -->
              <BaseTable
                select-mode="single"
                ref="tableOrders"
                :fields="fieldsOrder"
                :items="tableOrders"
              />
            </div>
          </template>

          <template v-slot:tab3TableDown>
            <div>
              <BaseButton
                class="edit-button"
                variant="success"
                :columnInfo="detailCtrlInfo.add"
                icon="plus-circle"
                @click="add(obj)"
              />
              <BaseButton
                class="edit-button"
                variant="success"
                :disabled="!isSelectedItem"
                :columnInfo="detailCtrlInfo.delete"
                icon="minus-circle"
                @click="remove(obj)"
              />
            </div>
            <!-- 許可認明細テーブル -->
            <BaseTable
              select-mode="single"
              ref="table"
              @row-selected="onRowSelectedItem"
              :fields="fieldsPermit"
              :items="tablePermits"
            />
          </template>
        </BaseTabGroup>
      </template>
    </DetailPageLayout>

    <!-- 実車番マスタモーダル -->
    <RealVehicleModal
      id="real-vehicle-modal-four"
      type="entry"
      displayModals="four"
      :selectedSearchCondition="realVehicleModalSearchCondition"
      @after-close-set="afterCloseSetRealVehicle"
      @after-close-unset="afterCloseUnsetRealVehicle"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />

    <!-- 実車番許可証マスタモーダル -->
    <PassagePermitModal
      id="passage-permit-modal"
      type="entry"
      :selectedSearchCondition="passagePermitModalSearchCondition"
      @after-close-set="afterCloseSetPassagePermit"
      @after-close-unset="afterCloseUnsetPassagePermit"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />

    <!-- 登録モーダル -->
    <ConfirmModal
      id="register-modal"
      :columnInfos="messageInfo"
      :message="registerMessage"
      @confirm="register(obj)"
    />
    <!-- 情報モーダル -->
    <InfoModal
      id="planning-result-info-modal"
      :columnInfos="messageInfo"
      :message="infoMessage"
      @after-close="back(obj)"
    />
    <!-- Excel出力モーダル -->
    <ConfirmModal
      id="download-modal"
      :columnInfos="messageInfo"
      :message="downloadMessage"
      @confirm="excelDownload(obj)"
    />
  </div>
</template>

<script>
import TransportRequestInfo from './TransportRequestInfo'
import { getListOptions, openSubModal } from '@/common/Common.js'
import {
  SLOT_NAME,
  REAL_VEHICLE_KIND_SEARCH,
  INTEGER_MAX_MIN,
} from '@/common/const.js'
import { getBreadcrumbItems, back } from '@/common/screen-common.js'
import {
  init,
  clear,
  goRegister,
  register,
  goRadioChanged,
  add,
  remove,
  setPermitDetail,
  excelDownload,
} from './TransportRequestDetail.js'

export default {
  name: 'transportRequestDetail',
  components: {
    RealVehicleModal: () => import('@/master/realVehicle/RealVehicleModal.vue'),
    PassagePermitModal: () =>
      import('@/master/passagePermit/PassagePermitModal.vue'),
  },

  data() {
    return {
      selectedItem: [], //許可証
      tableItems: [],
      tablePermits: [],
      tableCosts: [],
      tableOrders: [],
      TransportRequest: new TransportRequestInfo(),
      obj: this,
      registerMessage: null,
      oriData: null,
      realVehicleModalKbn: -1,
      realVehicleModalSearchCondition: {},
      passagePermitModalSearchCondition: {},
      reportId: null,
    }
  },

  props: {
    beforeScreenRouteInfo: {
      type: Object,
    },
    planningResultId: {
      type: Number,
    },
    screenKbn: {
      type: Number,
    },
  },

  created() {
    init(this)
  },

  computed: {
    detailCtrlInfo() {
      return this.$store.getters['init/getScreens'](
        this.SCREEN_ID.TRANSPORT_REQUEST_DETAIL
      )('detail_ctrl_info')
    },
    detailInfo() {
      return this.$store.getters['init/getScreens'](
        this.SCREEN_ID.TRANSPORT_REQUEST_DETAIL
      )('detail_info')
    },
    messageInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'message'
      )
    },
    breadcrumbItems() {
      return getBreadcrumbItems(
        this.beforeScreenRouteInfo,
        this.SCREEN_ID.TRANSPORT_REQUEST_DETAIL
      )
    },
    /**
     * ボタン情報リスト.
     * @return {Array}
     */
    buttonItems() {
      return [
        // ボタングループ#1
        [
          // 登録ボタン
          {
            variant: 'success',
            icon: 'edit',
            columnInfo: this.detailCtrlInfo.register,
            onClickCallback: () => {
              goRegister(this)
            },
          },
          // クリアボタン
          {
            variant: 'success',
            icon: 'times-circle',
            columnInfo: this.detailCtrlInfo.clear,
            onClickCallback: () => {
              clear(this)
            },
          },
          // 戻るボタン
          {
            variant: 'success',
            icon: 'times-circle',
            columnInfo: this.detailCtrlInfo.back,
            onClickCallback: () => {
              back(this)
            },
          },
        ],
        // ボタングループ#2
        [
          // 輸送依頼書出力ボタン
          // {
          //   variant: 'primary',
          //   icon: 'download',
          //   columnInfo: this.detailCtrlInfo.excel,
          //   onClickCallback: () => {
          //     this.reportId = 'NP001'
          //     openSubModal(this, 'download-modal')
          //   },
          // },
          // ドライバー作業明細書出力ボタン
          {
            variant: 'primary',
            icon: 'download',
            columnInfo: this.detailCtrlInfo.excel2,
            onClickCallback: () => {
              this.reportId = 'NP002'
              openSubModal(this, 'download-modal')
            },
          },
        ],
      ]
    },

    inputComponents() {
      return [
        {
          // 詳細情報タブ
          label: this.detailInfo.tab1.label,
          inputComponents: [
            {
              // 配車結果番号
              type: 'text',
              id: 'planningResultId',
              columnInfo: this.detailInfo.planning_result_id,
            },
            {
              // 輸送依頼番号
              type: 'text',
              id: 'transportRequestId',
              columnInfo: this.detailInfo.transport_request_id,
            },
            {
              // ブロック
              type: 'text',
              id: 'blockName',
              columnInfo: this.detailInfo.block_name,
            },
            {
              // 出発日時
              type: 'text',
              id: 'departureDateTime',
              columnInfo: this.detailInfo.departure_date_time,
            },
            //order - departure
            {
              // 出発日付 (Begin)
              type: 'date',
              id: 'orderDepartureBeginDate',
              columnInfo: this.detailInfo.order_departure_begin_date,
            },
            {
              // 出発日時 (Begin)
              type: 'time',
              id: 'orderDepartureBeginTime',
              columnInfo: this.detailInfo.order_departure_begin_time,
            },
            {
              // 出発日付 (End)
              type: 'date',
              id: 'orderDepartureEndDate',
              columnInfo: this.detailInfo.order_departure_end_date,
            },
            {
              // 出発日時 (End)
              type: 'time',
              id: 'orderDepartureEndTime',
              columnInfo: this.detailInfo.order_departure_end_time,
            },

            {
              // 出発場所名
              type: 'text',
              id: 'departurePlaceName',
              columnInfo: this.detailInfo.departure_place_name,
            },
            {
              // 出発場所住所
              type: 'text',
              id: 'departurePlaceAddress',
              columnInfo: this.detailInfo.departure_place_address,
            },
            {
              // 到着日時
              type: 'text',
              id: 'arrivalDateTime',
              columnInfo: this.detailInfo.arrival_date_time,
            },
            // order - arrival

            {
              // 到着日付 (Begin)
              type: 'date',
              id: 'orderArrivalBeginDate',
              columnInfo: this.detailInfo.order_arrival_begin_date,
            },
            {
              // 到着日時 (Begin)
              type: 'time',
              id: 'orderArrivalBeginTime',
              columnInfo: this.detailInfo.order_arrival_begin_time,
            },
            {
              // 到着日付 (End)
              type: 'date',
              id: 'orderArrivalEndDate',
              columnInfo: this.detailInfo.order_arrival_end_date,
            },
            {
              // 到着日時 (End)
              type: 'time',
              id: 'orderArrivalEndTime',
              columnInfo: this.detailInfo.order_arrival_end_time,
            },
            //-----------------

            {
              // 到着場所名
              type: 'text',
              id: 'arrivalPlaceName',
              columnInfo: this.detailInfo.arrival_place_name,
            },
            {
              // 到着場所住所
              type: 'text',
              id: 'arrivalPlaceAddress',
              columnInfo: this.detailInfo.arrival_place_address,
            },
            {
              // トリップ番号
              type: 'text',
              id: 'tripNo',
              columnInfo: this.detailInfo.trip_no,
            },
            {
              // ドロップ数
              type: 'text',
              id: 'lastDropNo',
              columnInfo: this.detailInfo.last_drop_no,
            },
            {
              // 輸送距離
              // 配車結果→輸送依頼（transport_distance → transport_request_distance）
              type: 'text',
              id: 'transportRequestDistance',
              columnInfo: this.detailInfo.transport_request_distance,
            },
            {
              // 理由
              type: 'text',
              id: 'reason',
              columnInfo: this.detailInfo.reason,
            },
            {
              // 業者希望距離
              type: 'text',
              id: 'carrierDesiredDistance',
              columnInfo: this.detailInfo.carrier_desired_distance,
              minValue: INTEGER_MAX_MIN.MIN,
              maxValue: INTEGER_MAX_MIN.MAX,
              rules: [
                this.INPUT_TYPE_CHECK.ONLY_NUMERIC,
                this.INPUT_TYPE_CHECK.MINVALUE,
                this.INPUT_TYPE_CHECK.MAXVALUE,
              ],
            },
            {
              // 荷姿名(代表)
              type: 'text',
              id: 'productName',
              columnInfo: this.detailInfo.product_name,
            },
            {
              // 総数量
              type: 'text',
              id: 'totalQuantity',
              columnInfo: this.detailInfo.total_quantity,
            },
            {
              // 総質量
              type: 'text',
              id: 'totalWeight',
              columnInfo: this.detailInfo.total_weight,
            },
            {
              // 総容量
              type: 'text',
              id: 'totalVolume',
              columnInfo: this.detailInfo.total_volume,
            },
            {
              // 配車ステータス
              type: 'text',
              id: 'planningStatusName',
              columnInfo: this.detailInfo.planning_status_name,
            },
            {
              // 仮車区分
              type: 'text',
              id: 'managementVehicleKindName',
              columnInfo: this.detailInfo.management_vehicle_kind_name,
            },
            {
              // 配車対象日
              type: 'text',
              id: 'planningTargetDate',
              columnInfo: this.detailInfo.planning_target_date,
            },
            {
              // 確定日時
              type: 'text',
              id: 'planningDecisionDateTime',
              columnInfo: this.detailInfo.planning_decision_date_time,
            },
            {
              // 計画有無フラグ
              type: 'text',
              id: 'planFlagName',
              columnInfo: this.detailInfo.plan_flag_name,
            },
            {
              // 車両稼働ID
              type: 'text',
              id: 'vehicleOperationId',
              columnInfo: this.detailInfo.vehicle_operation_id,
            },
            {
              // 依頼車両
              type: 'referenceCodeName',
              codeId: 'vehicleName',
              nameId: 'requestVehicleNumberplate',
              codeColumnInfo: this.detailInfo.vehicle_name,
              nameColumnInfo: this.detailInfo.request_vehicle_numberplate,
            },
            {
              // 依頼車型
              type: 'text',
              id: 'vehicleModelName',
              columnInfo: this.detailInfo.vehicle_model_name,
            },
            {
              // 依頼輸送手段カテゴリ
              type: 'text',
              id: 'vehicleCategoryName',
              columnInfo: this.detailInfo.vehicle_category_name,
            },
            {
              // 依頼運送会社
              type: 'text',
              id: 'carrierName',
              columnInfo: this.detailInfo.carrier_name,
            },

            //-------------------->>
            // 状態（輸送依頼状態）
            {
              type: 'text',
              id: 'statusName',
              columnInfo: this.detailInfo.status_name,
            },

            {
              // 承認依頼日時
              type: 'text',
              id: 'approvalRequestDatetimeDisplay',
              columnInfo: this.detailInfo.approval_request_datetime_display,
            },
            {
              // 承認依頼ユーザ
              type: 'referenceCodeName',
              codeId: 'approvalRequestUser',
              nameId: 'approvalRequestUserName',
              codeColumnInfo: this.detailInfo.approval_request_user,
              nameColumnInfo: this.detailInfo.approval_request_user_name,
            },

            // 承諾拒否区分
            // 状態（承諾）-（拒否）
            {
              type: 'radiogroup',
              id: 'permitRefuse',
              columnInfo: this.detailInfo.permit_refuse,
              options: getListOptions(this.MASTER_CODE.PERMIT_REFUSE, false),
              onChangeCallback: () => {
                goRadioChanged(this)
              },
            },

            {
              // 拒否理由コード
              type: 'select',
              id: 'refusalCode',
              columnInfo: this.detailInfo.refusal_code,
              options: getListOptions(this.MASTER_CODE.REFUSAL_CODE),
              onChangeCallback: () => {},
            },
            {
              // 拒否理由
              type: 'text',
              id: 'refusalComment',
              columnInfo: this.detailInfo.refusal_comment,
              maxLength: 255,
            },

            // 実車両(区分)
            // トラック/トレーラー区分
            {},

            // 実車両(TR)
            {
              // トラック/トラクタ (TR)
              type: 'referenceCodeName',
              codeId: 'realVehicleId',
              nameId: 'realVehicleNumberplate',
              codeColumnInfo: this.detailInfo.real_vehicle_id,
              nameColumnInfo: this.detailInfo.real_vehicle_numberplate,
              btnColumnInfo: this.detailInfo.sub_modal_real_vehicle,
              onClickCallback: () => {
                this.realVehicleModalKbn = 1
                this.realVehicleModalSearchCondition = {}
                this.realVehicleModalSearchCondition.realVehicleKind =
                  REAL_VEHICLE_KIND_SEARCH.TRTC
                this.openSubModal(this, 'real-vehicle-modal-four')
              },
            },
            {
              // トラック車型
              type: 'select',
              id: 'realVehicleModel',
              columnInfo: this.detailInfo.real_vehicle_model,
              options: getListOptions(this.MASTER_CODE.VEHICLE_MODEL),
              onChangeCallback: () => {},
            },
            {
              // トラック種類
              type: 'select',
              id: 'realVehicleKind',
              columnInfo: this.detailInfo.real_vehicle_kind,
              options: getListOptions(this.MASTER_CODE.CARGO_VEHICLE_KIND),
              onChangeCallback: () => {},
            },
            // 実車両(TL)
            {
              // トレーラー (TL)
              type: 'referenceCodeName',
              codeId: 'realTrailerId',
              nameId: 'realTrailerNumberplate',
              codeColumnInfo: this.detailInfo.real_trailer_id,
              nameColumnInfo: this.detailInfo.real_trailer_numberplate,
              btnColumnInfo: this.detailInfo.sub_modal_real_trailer,
              onClickCallback: () => {
                this.realVehicleModalKbn = 2
                this.realVehicleModalSearchCondition = {}
                this.realVehicleModalSearchCondition.realVehicleKind =
                  REAL_VEHICLE_KIND_SEARCH.TL
                this.openSubModal(this, 'real-vehicle-modal-four')
              },
            },
            {
              // トレーラー車型
              type: 'select',
              id: 'realTrailerModel',
              columnInfo: this.detailInfo.real_trailer_model,
              options: getListOptions(this.MASTER_CODE.VEHICLE_MODEL),
              onChangeCallback: () => {},
            },
            {
              // トレーラー種類
              type: 'select',
              id: 'realTrailerKind',
              columnInfo: this.detailInfo.real_trailer_kind,
              options: getListOptions(this.MASTER_CODE.CARGO_VEHICLE_KIND),
              onChangeCallback: () => {},
            },
            //--------------------<<
            //配車結果予備１ - 配車結果予備１0
            {
              // 配車結果予備１
              type: 'text',
              id: 'reserve01',
              columnInfo: this.detailInfo.reserve01,
              maxLength: 50,
            },
            {
              // 配車結果予備２
              type: 'text',
              id: 'reserve02',
              columnInfo: this.detailInfo.reserve02,
              maxLength: 50,
            },
            {
              // 配車結果予備３
              type: 'text',
              id: 'reserve03',
              columnInfo: this.detailInfo.reserve03,
              maxLength: 50,
            },
            {
              // 配車結果予備４
              type: 'text',
              id: 'reserve04',
              columnInfo: this.detailInfo.reserve04,
              maxLength: 50,
            },
            {
              // 配車結果予備５
              type: 'text',
              id: 'reserve05',
              columnInfo: this.detailInfo.reserve05,
              maxLength: 50,
            },
            {
              // 配車結果予備６
              type: 'text',
              id: 'reserve06',
              columnInfo: this.detailInfo.reserve06,
              maxLength: 50,
            },
            {
              // 配車結果予備７
              type: 'text',
              id: 'reserve07',
              columnInfo: this.detailInfo.reserve07,
              maxLength: 50,
            },
            {
              // 配車結果予備８
              type: 'text',
              id: 'reserve08',
              columnInfo: this.detailInfo.reserve08,
              maxLength: 50,
            },
            {
              // 配車結果予備９
              type: 'text',
              id: 'reserve09',
              columnInfo: this.detailInfo.reserve09,
              maxLength: 50,
            },
            {
              // 配車結果予備１０
              type: 'text',
              id: 'reserve10',
              columnInfo: this.detailInfo.reserve10,
              maxLength: 50,
            },
            //輸送依頼予備１ - 輸送依頼予備１0
            {
              //輸送依頼予備9
              type: 'select',
              id: 'transportReserve09',
              options: getListOptions(this.MASTER_CODE.PAYMENT_TERMS, false),
              onChangeCallback: () => {},
            },
            {
              //輸送依頼予備１0
              type: 'text',
              id: 'transportReserve10',
              columnInfo: this.detailInfo.transport_reserve10,
              maxLength: 50,
            },
            {
              //輸送依頼予備１
              type: 'text',
              id: 'transportReserve01',
              columnInfo: this.detailInfo.transport_reserve01,
              maxLength: 50,
            },
            {
              //輸送依頼予備2
              type: 'text',
              id: 'transportReserve02',
              columnInfo: this.detailInfo.transport_reserve02,
              maxLength: 50,
            },
            {
              //輸送依頼予備3
              type: 'text',
              id: 'transportReserve03',
              columnInfo: this.detailInfo.transport_reserve03,
              maxLength: 50,
            },
            {
              //輸送依頼予備4
              type: 'text',
              id: 'transportReserve04',
              columnInfo: this.detailInfo.transport_reserve04,
              maxLength: 50,
            },
            {
              //輸送依頼予備5
              type: 'text',
              id: 'transportReserve05',
              columnInfo: this.detailInfo.transport_reserve05,
              maxLength: 50,
            },
            {
              //輸送依頼予備6
              type: 'text',
              id: 'transportReserve06',
              columnInfo: this.detailInfo.transport_reserve06,
              maxLength: 50,
            },
            {
              //輸送依頼予備7
              type: 'text',
              id: 'transportReserve07',
              columnInfo: this.detailInfo.transport_reserve07,
              maxLength: 50,
            },
            {
              //輸送依頼予備8
              type: 'text',
              id: 'transportReserve08',
              columnInfo: this.detailInfo.transport_reserve08,
              maxLength: 50,
            },
            {
              //注意事項[受注]  #2748
              type: 'text',
              id: 'warningMessage',
              columnInfo: this.detailInfo.warning_message,
              maxLength: 50,
            },
            {
              // 登録日時
              type: 'text',
              id: 'registrationTimeDisplay',
              columnInfo: this.detailInfo.registration_time_display,
            },
            {
              // 登録ユーザ
              type: 'text',
              id: 'registrationUser',
              columnInfo: this.detailInfo.registration_user,
            },
            {
              // 更新日時
              type: 'text',
              id: 'updateTimeDisplay',
              columnInfo: this.detailInfo.update_time_display,
            },
            {
              // 更新ユーザ
              type: 'text',
              id: 'updateUser',
              columnInfo: this.detailInfo.update_user,
            },
          ],
        },
        {
          // 配車結果明細タブ
          label: this.detailInfo.tab2.label,
          slot: SLOT_NAME.TAB2_TABLE_DOWN,
          inputComponents: [],
        },
        {
          // 許可証タブ
          label: this.detailInfo.tab3.label,
          slot: SLOT_NAME.TAB3_TABLE_DOWN,
          inputComponents: [],
        },
      ]
    },
    /**
     * テーブル列定義リスト.
     * @return {Array}
     */
    fields() {
      return this.$store.getters['init/getFields'](
        this.SCREEN_ID.TRANSPORT_REQUEST_DETAIL
      )(this.DOMAIN_NAME.FIELDS)
    },
    fieldsPermit() {
      return this.$store.getters['init/getFields'](
        this.SCREEN_ID.TRANSPORT_REQUEST_DETAIL
      )(this.DOMAIN_NAME.FIELDS + '_permit')
    },
    fieldsCost() {
      return this.$store.getters['init/getFields'](
        this.SCREEN_ID.TRANSPORT_REQUEST_DETAIL
      )(this.DOMAIN_NAME.FIELDS + '_cost')
    },
    fieldsOrder() {
      return this.$store.getters['init/getFields'](
        this.SCREEN_ID.TRANSPORT_REQUEST_DETAIL
      )(this.DOMAIN_NAME.FIELDS + '_order')
    },

    /**
     * 単数選択しているか.
     * @return {Boolean}
     */
    isSelectedItem() {
      return this.selectedItem.length > 0
    },

    /**
     * 情報メッセージ.
     * @return {String}
     */
    infoMessage() {
      return this.$store.getters['init/getMessage'](
        'YZ00MG995I',
        this.detailCtrlInfo.register.label
      )
    },
    /**
     * メッセージ.
     * @return {String}
     */
    downloadMessage() {
      let title = null
      if (this.reportId == 'NP001') {
        title = this.detailCtrlInfo.excel.label
      } else {
        title = this.detailCtrlInfo.excel2.label
      }
      return this.$store.getters['init/getMessage']('YZ00MG994Q', title)
    },
  },

  methods: {
    getListOptions,
    openSubModal,
    register,
    back,
    add,
    remove,
    excelDownload,

    /**
     * 実車番マスタから取得データ設定
     */
    afterCloseSetRealVehicle(selectedItem) {
      let comment = this.TransportRequest.refusalComment
      this.TransportRequest.refusalComment = ''

      // formData->TransportRequest
      console.log('【afterCloseSetRealVehicle】')
      console.log('realVehicleModalKbn=>' + this.realVehicleModalKbn)
      if (this.realVehicleModalKbn == 1) {
        //トラック/トラクタ(TR)
        this.TransportRequest.realVehicleId = selectedItem.real_vehicle_id
        this.TransportRequest.realVehicleNumberplate = selectedItem.numberplate
        this.TransportRequest.realVehicleModel = selectedItem.vehicle_model
        this.TransportRequest.realVehicleKind = selectedItem.real_vehicle_kind

        console.log('realVehicleId=>' + selectedItem.real_vehicle_id)
        console.log('realVehicleNumberplate=>' + selectedItem.numberplate)
      } else if (this.realVehicleModalKbn == 2) {
        //トレーラー (TL)
        this.TransportRequest.realTrailerId = selectedItem.real_vehicle_id
        this.TransportRequest.realTrailerNumberplate = selectedItem.numberplate
        this.TransportRequest.realTrailerModel = selectedItem.vehicle_model
        this.TransportRequest.realTrailerKind = selectedItem.real_vehicle_kind

        console.log('realTrailerId=>' + selectedItem.real_vehicle_id)
        console.log('realTrailerNumberplate=>' + selectedItem.numberplate)
      }

      // ※参照情報反映が画面に反映されないバグ対応⇒配列の更新反映
      this.TransportRequest.refusalComment = comment
    },
    afterCloseUnsetRealVehicle() {
      let comment = this.TransportRequest.refusalComment
      this.TransportRequest.refusalComment = ''

      // formData->TransportRequest
      if (this.realVehicleModalKbn == 1) {
        //トラック/トラクタ(TR)
        this.TransportRequest.realVehicleId = null
        this.TransportRequest.realVehicleNumberplate = null
      } else if (this.realVehicleModalKbn == 2) {
        //トレーラー (TL)
        this.TransportRequest.realTrailerId = null
        this.TransportRequest.realTrailerNumberplate = null
      }
      // ※参照情報反映が画面に反映されないバグ対応⇒配列の更新反映
      this.TransportRequest.refusalComment = comment
    },

    /**
     * 実車番マスタから取得データ設定
     */
    afterCloseSetPassagePermit(selectedItem) {
      setPermitDetail(this.obj, selectedItem)
    },
    afterCloseUnsetPassagePermit() {
      //this.searchCondition.realVehicleId = null
      //this.searchCondition.numberplate = null
    },

    setErrorMsg(msg) {
      this.$emit('set-error-msg', msg)
    },

    setWarnMsg(msg) {
      this.$emit('set-warn-msg', msg)
    },

    onRowSelectedItem(selectedItem) {
      this.selectedItem = selectedItem
    },
  },
}
</script>

<style lang="scss" scoped>
.edit-button {
  margin-right: 8px;
  margin-bottom: 5px;
}
</style>
