import {
  createAuthenticaionService,
  createAuthenticaionServiceForExcel,
  makeQueryParams,
} from '@/services/index'

const BASE_URL = `${process.env.VUE_APP_API_URL}placeorder/`
class PlaceOrderService {
  getAll() {
    return createAuthenticaionService(BASE_URL).get('/')
  }

  get(id) {
    return createAuthenticaionService(BASE_URL).get(`/${id}`)
  }

  update(id, data) {
    return createAuthenticaionService(BASE_URL).put(`/${id}`, data)
  }

  search(searchCondition) {
    const param = makeQueryParams(searchCondition)
    return createAuthenticaionService(`${BASE_URL}search/`).get(`/?${param}`)
  }

  //発注
  placeOrder(data) {
    return createAuthenticaionService(`${BASE_URL}placeorder/`).post('/', data)
  }
  placeOrderDetail(data) {
    return createAuthenticaionService(`${BASE_URL}placeorderdetail/`).post(
      '/',
      data
    )
  }

  //承認
  approve(data) {
    return createAuthenticaionService(`${BASE_URL}approve/`).post('/', data)
  }
  approveDetail(data) {
    return createAuthenticaionService(`${BASE_URL}approvedetail/`).post(
      '/',
      data
    )
  }
  //拒否
  rejectDetail(data) {
    return createAuthenticaionService(`${BASE_URL}rejectdetail/`).post(
      '/',
      data
    )
  }
  //発注依頼の運賃計算
  // get処理
  // fareCalculateDetail(id, distance) {
  //   return createAuthenticaionService(BASE_URL).get(
  //     `/farecalculatedetail/${id}/${distance}`
  //   )
  // }
  fareCalculateDetail(id, keyDatas) {
    const param = makeQueryParams(keyDatas)
    return createAuthenticaionService(BASE_URL).get(
      `/farecalculatedetail/${id}?${param}`
    )
  }

  //return createAuthenticaionService(`${BASE_URL}search/`).get(`/?${param}`)

  //特車許可了承
  manualPermit(data) {
    return createAuthenticaionService(`${BASE_URL}manualpermit/`).post(
      '/',
      data
    )
  }

  //輸送依頼書出力
  download(id, reportId) {
    return createAuthenticaionServiceForExcel(BASE_URL).get(
      `/download/${id}/${reportId}`
    )
  }
}

export default new PlaceOrderService()
