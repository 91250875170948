import {
  openMsgModal,
  openSubModal,
  convertSnakeCace,
  convertCamelCace,
} from '@/common/Common.js'
import {
  MSG_TYPE,
  DETAIL_SCREEN_KBN,
  PLANNING_PARAMETER_TYPE,
} from '@/common/const.js'
import PlanningPatternService from './PlanningPatternService'
import _ from 'lodash'

export function init(obj) {
  if (obj.screenKbn != DETAIL_SCREEN_KBN.NEW) {
    obj.$store.dispatch('init/setServiceLoading')
    PlanningPatternService.get(obj.planningPatternId)
      .then(
        (response) => {
          obj.PlanningPattern = response.data

          // parameters
          var planningParameters = convertSnakeCace(
            obj.PlanningPattern.planningParameters
          )

          planningParameters.sort(function (a, b) {
            return a.display_order - b.display_order
          })
          obj.PlanningPattern.planningParameters = planningParameters

          // constraints
          var planningConstraints = convertSnakeCace(
            obj.PlanningPattern.planningConstraints
          )

          planningConstraints.sort(function (a, b) {
            return a.display_order - b.display_order
          })
          obj.PlanningPattern.planningConstraints = planningConstraints

          if (obj.screenKbn == DETAIL_SCREEN_KBN.COPY) {
            obj.PlanningPattern.planningPatternId = null
            obj.PlanningPattern.registrationTimeDisplay = null
            obj.PlanningPattern.registrationUser = null
            obj.PlanningPattern.updateTimeDisplay = null
            obj.PlanningPattern.updateUser = null
          } else {
            obj.PlanningPattern.planningPatternId = obj.planningPatternId
          }
          obj.planningParameterItems = obj.PlanningPattern.planningParameters
          obj.planningConstraintItems = obj.PlanningPattern.planningConstraints

          obj.oriData = _.cloneDeep(obj.PlanningPattern)
          obj.oriPlanningParameterItems = _.cloneDeep(
            obj.planningParameterItems
          )
          obj.oriPlanningConstraintItems = _.cloneDeep(
            obj.planningConstraintItems
          )
        },
        (e) => {
          // エラーモーダル表示
          var msg
          if (e.response == undefined) {
            msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
          } else if (e.response.status == 404) {
            msg = obj.$store.getters['init/getMessage']('YZ00MG996E')
          } else {
            msg = e.response.data.message
          }
          openMsgModal(obj, MSG_TYPE.E, msg)
        }
      )
      .finally(() => {
        obj.$store.dispatch('init/setServiceUnLoading')
      })
  }
}

export async function goRegister(obj) {
  const validated = await obj.$refs['tab-group'].$refs[
    'base-tab-group'
  ].validate()
  if (!validated) return

  obj.registerMessage = obj.$store.getters['init/getMessage'](
    'YZ00MG994Q',
    obj.detailCtrlInfo.register.label
  )
  openSubModal(obj, 'register-modal')
}

export async function register(obj) {
  obj.$bvModal.hide('register-modal')

  const validated = await obj.$refs['tab-group'].$refs[
    'base-tab-group'
  ].validate()
  if (!validated) return

  let registerData = _.cloneDeep(obj.PlanningPattern)

  // 4桁未満の文字列：「0」埋め
  registerData.planningParameters
    .filter((e) => e.planning_parameter_type === PLANNING_PARAMETER_TYPE.STRING)
    .forEach(
      (e) =>
        (e.planning_parameter_define_value = e.planning_parameter_define_value.padStart(
          4,
          '0'
        ))
    )

  registerData.planningParameters = changeToCamelCaseKey(
    registerData.planningParameters
  )
  registerData.planningConstraints = changeToCamelCaseKey(
    registerData.planningConstraints
  )

  var callApi
  if (obj.screenKbn != DETAIL_SCREEN_KBN.DETAIL) {
    // 新規
    callApi = PlanningPatternService.create(registerData)
  } else {
    // 詳細
    callApi = PlanningPatternService.update(
      registerData.planningPatternId,
      registerData
    )
  }

  obj.$store.dispatch('init/setServiceLoading')
  callApi
    .then(
      () => {
        // 情報モーダル表示
        openSubModal(obj, 'planning-pattern-detail-info-modal')
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message + e.response.data.messageDetail
        }
        openMsgModal(this, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

export function clear(obj) {
  obj.PlanningPattern = _.cloneDeep(obj.oriData)
  obj.planningParameterItems = _.cloneDeep(obj.oriPlanningParameterItems)
  obj.planningConstraintItems = _.cloneDeep(obj.oriPlanningConstraintItems)
}

function changeToCamelCaseKey(items) {
  var camelItems = convertCamelCace(items)

  return camelItems
}
