<template>
  <div>
    <DetailPageLayout
      :breadcrumbItems="breadcrumbItems"
      :buttonItems="buttonItems"
    >
      <template #top>
        <BaseTabGroup
          ref="tab-group"
          :inputModel="WorkRecord"
          :inputComponents="inputComponents"
        ></BaseTabGroup>
      </template>

      <template #bottom>
        <div id="BaseView">
          <section data-class="work-list-table">
            <!-- テーブル -->
            <BaseTable
              select-mode="single"
              :fields="fields"
              :items="tableItems"
            />
          </section>
        </div>
      </template>
    </DetailPageLayout>

    <!-- 登録モーダル -->
    <ConfirmModal
      id="register-modal"
      :columnInfos="messageInfo"
      :message="registerMessage"
      @confirm="register(obj)"
    />
    <!-- 情報モーダル -->
    <InfoModal
      id="work-record-info-modal"
      :columnInfos="messageInfo"
      :message="infoMessage"
      @after-close="back(obj)"
    />
  </div>
</template>

<script>
import WorkRecordInfo from './WorkRecordInfo'
import { getListOptions, openSubModal } from '@/common/Common.js'
import { SCREEN_ID } from '@/common/const.js'
import { getBreadcrumbItems, back } from '@/common/screen-common.js'
import { init, clear, goRegister, register } from './WorkRecordDetail.js'

const screenId = SCREEN_ID.WORK_RECORD_DETAIL

export default {
  name: 'workRecordDetail',
  components: {},

  data() {
    return {
      tableItems: [],
      WorkRecord: new WorkRecordInfo(),
      obj: this,
      registerMessage: null,
      oriData: null,
    }
  },

  props: {
    beforeScreenRouteInfo: {
      type: Object,
    },
    planningResultId: {
      type: Number,
    },
    planningResultPlaceId: {
      type: Number,
    },
    screenKbn: {
      type: Number,
    },
  },

  created() {
    init(this)
  },

  computed: {
    detailCtrlInfo() {
      return this.$store.getters['init/getScreens'](screenId)(
        'detail_ctrl_info'
      )
    },
    detailInfo() {
      return this.$store.getters['init/getScreens'](screenId)('detail_info')
    },
    messageInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'message'
      )
    },
    breadcrumbItems() {
      return getBreadcrumbItems(this.beforeScreenRouteInfo, screenId)
    },
    /**
     * ボタン情報リスト.
     * @return {Array}
     */
    buttonItems() {
      return [
        // ボタングループ#1
        [
          // 登録ボタン
          {
            variant: 'success',
            icon: 'edit',
            columnInfo: this.detailCtrlInfo.register,
            onClickCallback: () => {
              goRegister(this)
            },
          },
          // クリアボタン
          {
            variant: 'success',
            icon: 'times-circle',
            columnInfo: this.detailCtrlInfo.clear,
            onClickCallback: () => {
              clear(this)
            },
          },
          // 戻るボタン
          {
            variant: 'success',
            icon: 'times-circle',
            columnInfo: this.detailCtrlInfo.back,
            onClickCallback: () => {
              back(this)
            },
          },
        ],
        // ボタングループ#2
        [],
      ]
    },

    inputComponents() {
      return [
        {
          // 詳細情報タブ
          label: this.detailInfo.tab1.label,
          inputComponents: [
            {
              // 配車結果訪問先番号
              type: 'text',
              id: 'planningResultPlaceId',
              columnInfo: this.detailInfo.planning_result_place_id,
            },
            {
              // 配車結果番号
              type: 'text',
              id: 'planningResultId',
              columnInfo: this.detailInfo.planning_result_id,
            },
            // {
            //   // ブロック
            //   type: 'text',
            //   id: 'blockName',
            //   columnInfo: this.detailInfo.block_name,
            // },
            {
              // 作業区分
              type: 'text',
              id: 'workKindName',
              columnInfo: this.detailInfo.work_kind_name,
            },
            {
              // 出発日時
              type: 'text',
              id: 'departureDateTime',
              columnInfo: this.detailInfo.departure_date_time,
            },
            {
              // 出発場所名
              type: 'text',
              id: 'departurePlaceName',
              columnInfo: this.detailInfo.departure_place_name,
            },
            {
              // 出発場所住所
              type: 'text',
              id: 'departurePlaceAddress',
              columnInfo: this.detailInfo.departure_place_address,
            },
            {
              // 到着日時
              type: 'text',
              id: 'arrivalDateTime',
              columnInfo: this.detailInfo.arrival_date_time,
            },
            {
              // 到着場所名
              type: 'text',
              id: 'arrivalPlaceName',
              columnInfo: this.detailInfo.arrival_place_name,
            },
            {
              // 到着場所住所
              type: 'text',
              id: 'arrivalPlaceAddress',
              columnInfo: this.detailInfo.arrival_place_address,
            },
            {
              // 依頼運送会社
              type: 'text',
              id: 'carrierName',
              columnInfo: this.detailInfo.carrier_name,
            },

            //-------------------->>
            // 状態（輸送依頼状態）
            {
              type: 'text',
              id: 'statusName',
              columnInfo: this.detailInfo.status_name,
            },

            //--------------------<<

            // 荷積予定日時, 荷卸予定日時 → 表示のみ
            // 荷積実績日時, 荷卸実績日時 → 入力可能
            {
              // 荷積予定日時
              type: 'text',
              id: 'departureWorkActTime',
              columnInfo: this.detailInfo.departure_work_act_time,
            },
            {
              // 荷卸予定日時
              type: 'text',
              id: 'arrivalWorkActTime',
              columnInfo: this.detailInfo.arrival_work_act_time,
            },

            {
              // 荷積実績日時
              type: 'dateTime',
              dateId: 'departureRealWorkActDate',
              timeId: 'departureRealWorkActTime',
              dateColumnInfo: this.detailInfo.departure_real_work_act_date,
              timeColumnInfo: this.detailInfo.departure_real_work_act_time,
            },
            {
              // 荷卸実績日時
              type: 'dateTime',
              dateId: 'arrivalRealWorkActDate',
              timeId: 'arrivalRealWorkActTime',
              dateColumnInfo: this.detailInfo.arrival_real_work_act_date,
              timeColumnInfo: this.detailInfo.arrival_real_work_act_time,
            },
          ],
        },
      ]
    },
    /**
     * テーブル列定義リスト.
     * @return {Array}
     */
    fields() {
      return this.$store.getters['init/getFields'](screenId)(
        this.DOMAIN_NAME.FIELDS
      )
    },

    /**
     * 情報メッセージ.
     * @return {String}
     */
    infoMessage() {
      return this.$store.getters['init/getMessage'](
        'YZ00MG995I',
        this.detailCtrlInfo.register.label
      )
    },
  },

  methods: {
    getListOptions,
    openSubModal,
    register,
    back,

    setErrorMsg(msg) {
      this.$emit('set-error-msg', msg)
    },

    setWarnMsg(msg) {
      this.$emit('set-warn-msg', msg)
    },
  },
}
</script>
