import {
  openMsgModal,
  openSubModal,
  downloadCSV,
  formatYYYYMMDD,
  csvFileName,
  convertCamelCace,
} from '@/common/Common.js'
import {
  SCREEN_ID,
  MSG_TYPE,
  DETAIL_SCREEN_KBN,
  PACKUP_FLAG,
  DELETE_STATUS,
  PLANNING_STATUS,
  ENTRY_KIND,
  COUNT_PARTITION_FLAG,
  TABLE_OPTION,
} from '@/common/const.js'
import router from '@/router'
import PlanningOrderService from './PlanningOrderService'

const screenId = SCREEN_ID.PLANNING_ORDER_LIST
const detailScreenId = SCREEN_ID.PLANNING_ORDER_DETAIL

export function init(obj) {
  // サーバーの処理後再検索
  if (obj.isReSearch == true) {
    reSearch(obj)
  }
}

export function reSearch(obj) {
  var searchCondition = obj.$store.getters['search/getSearchCondition'](
    screenId
  )
  if (searchCondition != null) {
    obj.searchCondition = JSON.parse(searchCondition)
    search(obj)
  }
}

/**
 * 検索します.
 * @param {*} obj
 */
export function search(obj) {
  obj.searchCondition.periodDateFrom = formatYYYYMMDD(
    obj,
    obj.searchCondition.periodDateFrom
  )
  obj.searchCondition.periodDateTo = formatYYYYMMDD(
    obj,
    obj.searchCondition.periodDateTo
  )

  obj.$store.dispatch('init/setServiceLoading')
  PlanningOrderService.search(obj.searchCondition)
    .then(
      (response) => {
        //obj.tableItems = response.data.resultList
        obj.tableItems = response.data.resultList.map((element) => {
          // 配車オーダーステータス
          if (element.delete_status == DELETE_STATUS.WAIT_FOR_UNDELETE) {
            // 20 解除待ち ->背景を緑色
            element._rowVariant = TABLE_OPTION.SUCCESS_ROW_VARIANT
          }
          if (element.delete_status == DELETE_STATUS.SASIKAE) {
            // 40 差替済み -> 背景を紫色
            element._rowVariant = TABLE_OPTION.WARNING_ROW_VARIANT
          }
          if (element.user_id != null) {
            //手動配車画面編集中（ガント編集用オーダ排他テーブルあり） 灰色
            element._rowVariant = TABLE_OPTION.EXCLUSIVE_ROW_VARIANT
          }
          return element
        })

        var tWeight = 0
        obj.tableItems.forEach((element) => {
          tWeight += element.weight
        })
        obj.totalWeight = tWeight
        if (obj.tableItems.length < 1) {
          // 警告モーダル表示
          openMsgModal(
            obj,
            MSG_TYPE.W,
            obj.$store.getters['init/getMessage']('YZ00MG907I')
          )
        }
      },
      (e) => {
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message
        }
        // エラーモーダル表示
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })

  var payload = {
    viewId: screenId,
    params: JSON.stringify(obj.searchCondition),
  }
  obj.$store.dispatch('search/setSearchCondition', payload)
}

/**
 * 検索項目をクリアします.
 */
export function clear(obj) {
  obj.searchCondition = obj.defaultSearchCondition()
  var payload = {
    viewId: screenId,
    params: JSON.stringify(obj.searchCondition),
  }
  obj.$store.dispatch('search/setSearchCondition', payload)
}

// 「詳細」ボタンクリック
export function goDetail(obj) {
  if (obj.selectedItem.length != 1) {
    openMsgModal(
      obj,
      MSG_TYPE.E,
      obj.$store.getters['init/getMessage']('YZ00MG901E')
    )
  } else {
    router.push({
      name: detailScreenId,
      params: {
        planningOrderId: obj.selectedItem[0].planning_order_id,
        screenKbn: DETAIL_SCREEN_KBN.DETAIL,
        beforeScreenRouteInfo: {
          routeInfo: {
            name: screenId,
            params: { isReSearch: true },
          },
        },
      },
    })
  }
}

// 「新規」ボタンクリック
export function goNew() {
  router.push({
    name: detailScreenId,
    params: {
      screenKbn: DETAIL_SCREEN_KBN.NEW,
      beforeScreenRouteInfo: {
        routeInfo: {
          name: screenId,
          params: { isReSearch: true },
        },
      },
    },
  })
}

/**
 * 選択した作業データを削除します.
 */
export async function remove(obj) {
  try {
    obj.$bvModal.hide('remove-modal')

    let isError = false
    let removeList = []
    for (let index = 0; index < obj.selectedItem.length; index++) {
      var planningOrderId = obj.selectedItem[index].planning_order_id
      obj.$store.dispatch('init/setServiceLoading')
      await PlanningOrderService.get(planningOrderId)
        .then(
          (response) => {
            removeList.push(response.data)
          },
          (e) => {
            // エラーモーダル表示
            var msg
            if (e.response == undefined) {
              msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
            } else {
              msg = e.response.data.message
            }
            openMsgModal(obj, MSG_TYPE.E, msg)
            isError = true
          }
        )
        .finally(() => {
          obj.$store.dispatch('init/setServiceUnLoading')
        })
      if (isError) {
        break
      }
    }
    if (isError == false && removeList.length == obj.selectedItem.length) {
      obj.$store.dispatch('init/setServiceLoading')
      await PlanningOrderService.removeList(removeList)
        .then(
          () => {
            // 警告モーダル表示
            openMsgModal(
              obj,
              MSG_TYPE.I,
              obj.$store.getters['init/getMessage'](
                'YZ00MG995I',
                obj.resultControlInfo.delete.label
              )
            )
          },
          (e) => {
            // エラーモーダル表示
            var msg
            if (e.response == undefined) {
              msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
            } else {
              msg = e.response.data.message
            }
            openMsgModal(obj, MSG_TYPE.E, msg)
          }
        )
        .finally(() => {
          obj.$store.dispatch('init/setServiceUnLoading')
        })
    }
  } catch (err) {
    console.error(err)
  } finally {
    var searchCondition = obj.$store.getters['search/getSearchCondition'](
      screenId
    )
    obj.searchCondition = JSON.parse(searchCondition)
    search(obj)
  }
}

// 「CSV出力」ボタンクリック
export function download(obj) {
  obj.$bvModal.hide('download-modal')

  obj.searchCondition.periodDateFrom = formatYYYYMMDD(
    obj,
    obj.searchCondition.periodDateFrom
  )
  obj.searchCondition.periodDateTo = formatYYYYMMDD(
    obj,
    obj.searchCondition.periodDateTo
  )

  let newCsvName = csvFileName(obj, screenId)
  obj.searchCondition.filename = newCsvName

  obj.$store.dispatch('init/setServiceLoading')
  PlanningOrderService.download(obj.searchCondition)
    .then(
      (response) => {
        if (response.data == '') {
          // 警告モーダル表示
          openMsgModal(
            obj,
            MSG_TYPE.W,
            obj.$store.getters['init/getMessage']('YZ00MG907I')
          )
        } else {
          downloadCSV(obj.searchCondition.filename, response.data)
        }
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message
        }
        openMsgModal(obj, obj.MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

/**
 * 「荷纏め」ボタンクリック
 * @param {*} obj
 */
export function goPackUp(obj) {
  var errorMsg = nimatomeCheck(obj)
  if (errorMsg != null) {
    openMsgModal(obj, MSG_TYPE.E, errorMsg)
  } else {
    obj.selectedOrderIds = []

    obj.selectedItem.forEach((element) => {
      if (element.planning_order_id != null) {
        let planning_order_id = element.planning_order_id
        obj.selectedOrderIds.push(planning_order_id)
      }
    })
    openSubModal(obj, 'packup-modal')
  }
}

/**
 * 「配送纏め」ボタンクリック
 * @param {*} obj
 */
export function goPlanningPackUp(obj) {
  var errorMsg = haisomatomeCheck(obj)
  if (errorMsg != null) {
    openMsgModal(obj, MSG_TYPE.E, errorMsg)
  } else {
    obj.selectedPlanningOrderIds = []

    obj.selectedItem.forEach((element) => {
      if (element.planning_order_id != null) {
        let planning_order_id = element.planning_order_id
        obj.selectedPlanningOrderIds.push(planning_order_id)
      }
    })
    openSubModal(obj, 'planning-packup-modal')
  }
}

/**
 * 「数量分割」「数量分割編集」ボタンクリック
 * @param {*} obj
 */
export function goOrderCountSplit(obj, entryKind) {
  if (obj.selectedItem.length != 1) {
    openMsgModal(
      obj,
      MSG_TYPE.E,
      obj.$store.getters['init/getMessage']('YZ00MG901E')
    )
  } else {
    var errorMsg = checkSuryouBunkatu(obj, entryKind)
    if (errorMsg != null) {
      openMsgModal(obj, MSG_TYPE.E, errorMsg)
    } else {
      obj.selectedOrderSplitIds = []
      obj.selectedOrderSplitIds.push(entryKind)
      obj.selectedItem.forEach((element) => {
        if (element.planning_order_id != null) {
          let planning_order_id = element.planning_order_id
          obj.selectedOrderSplitIds.push(planning_order_id)
        }
      })
      openSubModal(obj, 'order-count-split-modal')
    }
  }
}

/**
 * 数量分割のチェック処理
 * @param {} obj
 * @param {*} entryKind
 * @param {*} haishazumiCheck
 */
function checkSuryouBunkatu(obj, entryKind, haishazumiCheck = true) {
  var errorMsg = null
  if (entryKind == ENTRY_KIND.INSERT) {
    //-----<<数量分割>>-------

    /**
     * 数量分割の制限解除対応で、配車済チェック処理をコメント
     * if (haishazumiCheck) {
      obj.selectedItem.forEach((element) => {
        if (
          element.planning_status == PLANNING_STATUS.ASSIGNED ||
          element.planning_status == PLANNING_STATUS.COMPLETE
        ) {
          errorMsg = obj.$store.getters['init/getMessage'](
            'EB01S001_MG007E',
            '割付済み'
          )
          return errorMsg
        }
      })
    }*/

    //③選択行が配送纏めフラグ=配送纏め(1)のレコードかチェック
    obj.selectedItem.forEach((element) => {
      if (element.delivery_packup_flag == PACKUP_FLAG.YES) {
        errorMsg = obj.$store.getters['init/getMessage'](
          'EB01S001_MG007E',
          '配送纏めオーダ'
        )
        return errorMsg
      }
    })

    //④選択行が荷纏めフラグ=荷纏め(1)のレコードかチェック
    obj.selectedItem.forEach((element) => {
      if (element.packup_flag == PACKUP_FLAG.YES) {
        errorMsg = obj.$store.getters['init/getMessage'](
          'EB01S001_MG007E',
          '荷纏めオーダ'
        )
        return errorMsg
      }
    })

    //⑤選択行に削除ステータス=通常(10)以外のレコードを含んでいるかチェック
    obj.selectedItem.forEach((element) => {
      if (
        element.delete_status == DELETE_STATUS.WAIT_FOR_UNDELETE ||
        element.delete_status == DELETE_STATUS.WAIT_FOR_DELETE
      ) {
        errorMsg = obj.$store.getters['init/getMessage'](
          'EB01S001_MG007E',
          '解除待ち'
        )
        return errorMsg
      }
    })

    //⑥選択行にガント排他中のレコードを含んでいるかチェック
    if (haishazumiCheck) {
      obj.selectedItem.forEach((element) => {
        if (element.user_id != null) {
          errorMsg = obj.$store.getters['init/getMessage'](
            'EB01S001_MG007E',
            '配車中のオーダ'
          )
          return errorMsg
        }
      })
    }
  } else {
    //------ 数量分割編集------

    /**
     * 数量分割の制限解除対応で、配車済チェック処理をコメント
     * ②選択行に配車ステータスが20(配車済)以上のレコードを含んでいるかチェック
    if (haishazumiCheck) {
      obj.selectedItem.forEach((element) => {
        if (
          element.planning_status == PLANNING_STATUS.ASSIGNED ||
          element.planning_status == PLANNING_STATUS.COMPLETE
        ) {
          errorMsg = obj.$store.getters['init/getMessage'](
            'EB01S001_MG008E',
            '割付済み'
          )
          return errorMsg
        }
      })
    }*/

    //③選択行に削除ステータス=通常(10)以外のレコードを含んでいるかチェック
    obj.selectedItem.forEach((element) => {
      if (
        element.delete_status == DELETE_STATUS.WAIT_FOR_UNDELETE ||
        element.delete_status == DELETE_STATUS.WAIT_FOR_DELETE
      ) {
        errorMsg = obj.$store.getters['init/getMessage'](
          'EB01S001_MG008E',
          '解除待ち'
        )
        return errorMsg
      }
    })

    //④選択行に数量分割フラグ=分割あり(1)のレコードかチェック
    obj.selectedItem.forEach((element) => {
      if (element.count_partition_flag == COUNT_PARTITION_FLAG.NO) {
        errorMsg = obj.$store.getters['init/getMessage'](
          'EB01S001_MG008E',
          '数量分割されていないオーダ'
        )
        return errorMsg
      }
    })

    //⑥選択行にガント排他中のレコードを含んでいるかチェック
    if (haishazumiCheck) {
      obj.selectedItem.forEach((element) => {
        if (element.user_id != null) {
          errorMsg = obj.$store.getters['init/getMessage'](
            'EB01S001_MG008E',
            '配車中のオーダ'
          )
          return errorMsg
        }
      })
    }
  }

  return errorMsg
}

/**
 * 区間分割のチェック処理
 * @param {} obj
 */
function checkKukanBunkatu(obj) {
  var errorMsg = null
  //-----<<区間分割>>-------

  //-->>
  //[Redmine.2616]
  //区間分割画面への遷移は配車オーダーのステータスを見ない
  //--<<
  // if (haishazumiCheck) {
  //   obj.selectedItem.forEach((element) => {
  //     if (
  //       element.planning_status == PLANNING_STATUS.ASSIGNED ||
  //       element.planning_status == PLANNING_STATUS.COMPLETE
  //     ) {
  //       errorMsg = obj.$store.getters['init/getMessage'](
  //         'EB01S001_MG007E',
  //         '割付済み'
  //       )
  //       return errorMsg
  //     }
  //   })
  // }
  //--<<

  // 「数量分割フラグ＝１」の場合でも区間分割画面へ遷移できるように。
  // //②選択行が数量分割フラグ=分割(1)のレコードかチェック
  // obj.selectedItem.forEach((element) => {
  //   if (element.count_partition_flag == COUNT_PARTITION_FLAG.YES) {
  //     errorMsg = obj.$store.getters['init/getMessage'](
  //       'EB01S001_MG007E',
  //       '数量分割オーダ'
  //     )
  //     return errorMsg
  //   }
  // })

  //③選択行が配送纏めフラグ=配送纏め(1)のレコードかチェック
  obj.selectedItem.forEach((element) => {
    if (element.delivery_packup_flag == PACKUP_FLAG.YES) {
      errorMsg = obj.$store.getters['init/getMessage'](
        'EB01S001_MG007E',
        '配送纏めオーダ'
      )
      return errorMsg
    }
  })

  //④選択行が荷纏めフラグ=荷纏め(1)のレコードかチェック
  obj.selectedItem.forEach((element) => {
    if (element.packup_flag == PACKUP_FLAG.YES) {
      errorMsg = obj.$store.getters['init/getMessage'](
        'EB01S001_MG007E',
        '荷纏めオーダ'
      )
      return errorMsg
    }
  })

  //⑤選択行に削除ステータス=通常(10)以外のレコードを含んでいるかチェック
  obj.selectedItem.forEach((element) => {
    if (
      element.delete_status == DELETE_STATUS.WAIT_FOR_UNDELETE ||
      element.delete_status == DELETE_STATUS.WAIT_FOR_DELETE
    ) {
      errorMsg = obj.$store.getters['init/getMessage'](
        'EB01S001_MG007E',
        '解除待ち'
      )
      return errorMsg
    }
  })

  //-->>
  //[Redmine.2616]
  //区間分割画面への遷移は配車オーダーのステータスを見ない
  //--<<
  // //⑥選択行にガント排他中のレコードを含んでいるかチェック
  // if (haishazumiCheck) {
  //   obj.selectedItem.forEach((element) => {
  //     if (element.user_id != null) {
  //       errorMsg = obj.$store.getters['init/getMessage'](
  //         'EB01S001_MG007E',
  //         '配車中のオーダ'
  //       )
  //       return errorMsg
  //     }
  //   })
  // }
  //--<<

  return errorMsg
}

/**
 * *配送纏めボタン押下
    →整合性チェック
 * @param {} obj
 */
function haisomatomeCheck(obj, haishazumiCheck = true) {
  var errorMsg = null
  //2.選択行が1行で、かつ選択行が配送纏めされていない場合(※1)、チェック
  if (obj.selectedItem.length == 1) {
    if (obj.selectedItem[0].delivery_packup_flag == PACKUP_FLAG.NO) {
      errorMsg = obj.$store.getters['init/getMessage']('EB01S001_MG002E')
      return errorMsg
    }
  }
  //3選択行に配車ステータスが20(配車済)以上のレコードを含んでいるかチェック
  if (haishazumiCheck) {
    obj.selectedItem.forEach((element) => {
      if (
        element.planning_status == PLANNING_STATUS.ASSIGNED ||
        element.planning_status == PLANNING_STATUS.COMPLETE
      ) {
        errorMsg = obj.$store.getters['init/getMessage'](
          'EB01S001_MG003E',
          '割付済み'
        )
        return errorMsg
      }
    })
  }
  // 削除ステータスが「20：解除待ち」のみは遷移可能とする。
  // 10：通常,20：解除待ち,30：削除待ち
  obj.selectedItem.forEach((element) => {
    if (
      element.delete_status == DELETE_STATUS.WAIT_FOR_UNDELETE ||
      element.delete_status == DELETE_STATUS.WAIT_FOR_DELETE
    ) {
      errorMsg = obj.$store.getters['init/getMessage'](
        'EB01S001_MG003E',
        '解除待ち'
      )
      return errorMsg
    }
  })

  //5.選択行にガント排他中のレコードを含んでいるかチェック
  if (haishazumiCheck) {
    obj.selectedItem.forEach((element) => {
      if (element.user_id != null) {
        errorMsg = obj.$store.getters['init/getMessage'](
          'EB01S001_MG003E',
          '配車中のオーダ'
        )
        return errorMsg
      }
    })
  }

  var sBlock_code = obj.selectedItem[0].block_code
  //var sPlanning_group_code = obj.selectedItem[0].planning_group_code
  var sDeparture_begin_date = obj.selectedItem[0].departure_begin_date
  var sArrival_begin_date = obj.selectedItem[0].arrival_begin_date

  //var sOwner_code = obj.selectedItem[0].owner_code
  //var sDeparture_begin_time = obj.selectedItem[0].departure_begin_time
  //var sArrival_begin_time = obj.selectedItem[0].arrival_begin_time

  //6.選択行すべての出発開始日が等しいことをチェック
  obj.selectedItem.forEach((element) => {
    if (element.departure_begin_date != sDeparture_begin_date) {
      errorMsg = obj.$store.getters['init/getMessage'](
        'EB01S001_MG003E',
        '異なる出発開始日'
      )
      return errorMsg
    }
  })

  //7.選択行すべての到着開始日が等しいことをチェック
  obj.selectedItem.forEach((element) => {
    if (element.arrival_begin_date != sArrival_begin_date) {
      errorMsg = obj.$store.getters['init/getMessage'](
        'EB01S001_MG003E',
        '異なる到着開始日'
      )
      return errorMsg
    }
  })

  //8.選択行すべてのブロックコードが等しいことをチェック
  obj.selectedItem.forEach((element) => {
    if (element.block_code != sBlock_code) {
      errorMsg = obj.$store.getters['init/getMessage'](
        'EB01S001_MG003E',
        '異なるブロックコード'
      )
      return errorMsg
    }
  })

  return errorMsg
}

/**
 * *荷纏めボタン押下
    →整合性チェック
 * @param {} obj
 */
function nimatomeCheck(obj, haishazumiCheck = true) {
  var errorMsg = null
  //2.選択行が1行で、かつ選択行が荷纏めされていない場合(※1)、チェック
  if (obj.selectedItem.length == 1) {
    if (obj.selectedItem[0].packup_flag == PACKUP_FLAG.NO) {
      errorMsg = obj.$store.getters['init/getMessage']('EB01S001_MG002E')
      return errorMsg
    }
  }
  //3.選択行に配車ステータスが20(配車済)以上のレコードを含んでいるかチェック
  var bKaijoMachi = false
  obj.selectedItem.forEach((element) => {
    // 削除ステータスが「20：解除待ち」のみは遷移可能とする。
    // 10：通常,20：解除待ち,30：削除待ち
    if (element.delete_status == DELETE_STATUS.WAIT_FOR_UNDELETE) {
      bKaijoMachi = true
      //continue
    }
    if (haishazumiCheck) {
      if (
        element.planning_status == PLANNING_STATUS.ASSIGNED ||
        element.planning_status == PLANNING_STATUS.COMPLETE
      ) {
        errorMsg = obj.$store.getters['init/getMessage'](
          'EB01S001_MG003E',
          '割付済み'
        )
        return errorMsg
      }
    }
  })

  // 3-1
  // 解除待ちのレコードを選択した場合
  // ほかの削除ステータスも「解除待ち」であること。
  if (bKaijoMachi) {
    obj.selectedItem.forEach((element) => {
      if (element.delete_status == DELETE_STATUS.WAIT_FOR_UNDELETE) {
        //continue
      } else {
        errorMsg = obj.$store.getters['init/getMessage'](
          'EB01S001_MG003E',
          '解除待ち以外の削除ステータス'
        )
        return errorMsg
      }
    })
  }

  //4.選択行に削除ステータス=通常(10)以外のレコードを含んでいるかチェック
  // 削除ステータスが「20：解除待ち」のみは遷移可能とする。
  // '10：通常,20：解除待ち,30：削除待ち
  obj.selectedItem.forEach((element) => {
    if (element.delete_status == DELETE_STATUS.WAIT_FOR_DELETE) {
      errorMsg = obj.$store.getters['init/getMessage'](
        'EB01S001_MG003E',
        '解除待ち'
      )
      return errorMsg
    }
  })

  //5.選択行にガント排他中のレコードを含んでいるかチェック
  if (haishazumiCheck) {
    obj.selectedItem.forEach((element) => {
      if (element.user_id != null) {
        errorMsg = obj.$store.getters['init/getMessage'](
          'EB01S001_MG003E',
          '配車中のオーダ'
        )
        return errorMsg
      }
    })
  }
  //6.選択行に配送纏めIDフラグ=1(有り)が存在しているかチェック
  obj.selectedItem.forEach((element) => {
    if (element.delivery_packup == PACKUP_FLAG.YES) {
      errorMsg = obj.$store.getters['init/getMessage'](
        'EB01S001_MG003E',
        '異なる配送纏めグループ'
      )
      return errorMsg
    }
  })

  var sBlock_code = obj.selectedItem[0].block_code
  //var sPlanning_group_code = obj.selectedItem[0].planning_group_code
  var sDeparture_begin_date = obj.selectedItem[0].departure_begin_date
  var sArrival_begin_date = obj.selectedItem[0].arrival_begin_date
  var sDeparture_representative_place_id =
    obj.selectedItem[0].departure_representative_place_id
  var sArrival_representative_place_id =
    obj.selectedItem[0].arrival_representative_place_id
  var sOwner_code = obj.selectedItem[0].owner_code
  //var sDeparture_begin_time = obj.selectedItem[0].departure_begin_time
  //var sArrival_begin_time = obj.selectedItem[0].arrival_begin_time

  //x.選択行すべての荷主をチェック
  obj.selectedItem.forEach((element) => {
    if (element.owner_code != sOwner_code) {
      errorMsg = obj.$store.getters['init/getMessage'](
        'EB01S001_MG003E',
        '異なる荷主'
      )
      return errorMsg
    }
  })

  //7.選択行すべての出発開始日が等しいことをチェック
  obj.selectedItem.forEach((element) => {
    if (element.departure_begin_date != sDeparture_begin_date) {
      errorMsg = obj.$store.getters['init/getMessage'](
        'EB01S001_MG003E',
        '異なる出発開始日'
      )
      return errorMsg
    }
  })

  //8.選択行すべての到着開始日が等しいことをチェック
  obj.selectedItem.forEach((element) => {
    if (element.arrival_begin_date != sArrival_begin_date) {
      errorMsg = obj.$store.getters['init/getMessage'](
        'EB01S001_MG003E',
        '異なる到着開始日'
      )
      return errorMsg
    }
  })
  //9.選択行すべての出発場所ID（代表)が等しいことをチェック(※2)
  obj.selectedItem.forEach((element) => {
    if (
      element.departure_representative_place_id !=
      sDeparture_representative_place_id
    ) {
      errorMsg = obj.$store.getters['init/getMessage'](
        'EB01S001_MG003E',
        '異なる出発場所'
      )
      return errorMsg
    }
  })
  //10.選択行すべての到着場所ID（代表)が等しいことをチェック(※2)
  obj.selectedItem.forEach((element) => {
    if (
      element.arrival_representative_place_id !=
      sArrival_representative_place_id
    ) {
      errorMsg = obj.$store.getters['init/getMessage'](
        'EB01S001_MG003E',
        '異なる到着場所'
      )
      return errorMsg
    }
  })
  //11.選択行すべてのブロックコードが等しいことをチェック
  obj.selectedItem.forEach((element) => {
    if (element.block_code != sBlock_code) {
      errorMsg = obj.$store.getters['init/getMessage'](
        'EB01S001_MG003E',
        '異なるブロックコード'
      )
      return errorMsg
    }
  })

  return errorMsg
}

/**
 * 「区間分割」ボタンクリック
 * @param {*} obj
 */
export function goOrderSectionSplit(obj) {
  let entryKind = ENTRY_KIND.INSERT
  if (obj.selectedItem.length != 1) {
    openMsgModal(
      obj,
      MSG_TYPE.E,
      obj.$store.getters['init/getMessage']('YZ00MG901E')
    )
  } else {
    // 数量分割のチェック処理を利用し、区間分割のチェックを行う。
    // entryKind = ENTRY_KIND.INSERT
    var errorMsg = checkKukanBunkatu(obj)
    if (errorMsg != null) {
      openMsgModal(obj, MSG_TYPE.E, errorMsg)
    } else {
      // obj.selectedOrderSectionSplitIds = []
      // obj.selectedOrderSectionSplitIds.push(entryKind)
      // obj.selectedItem.forEach((element) => {
      //   if (element.planning_order_id != null) {
      //     let planning_order_id = element.planning_order_id
      //     obj.selectedOrderSectionSplitIds.push(planning_order_id)
      //   }
      // })
      let modalCondition = {
        entryKind: entryKind,
        planningOrderId: obj.selectedItem[0].planning_order_id,
      }
      obj.orderSectionSplitModalCondition = modalCondition
      openSubModal(obj, 'order-section-split-modal')
    }
  }
}

/**
 * 「ロック」ボタンクリック
 * @param {*} obj
 */
export function goLock(obj) {
  if (obj.selectedItem.length < 1) {
    openMsgModal(
      obj,
      MSG_TYPE.E,
      obj.$store.getters['init/getMessage']('YZ00MG901E')
    )
  } else {
    obj.lockMessage = obj.$store.getters['init/getMessage'](
      'YZ00MG994Q',
      obj.resultControlInfo.lock.label
    )
    openSubModal(obj, 'lock-modal')
  }
}

/**
 * 選択したデータをロックします.
 */
export async function lock(obj) {
  obj.$bvModal.hide('lock-modal')

  let datas = convertCamelCace(obj.selectedItem)

  obj.$store.dispatch('init/setServiceLoading')
  await PlanningOrderService.lock(datas)
    .then(
      () => {
        var searchCondition = obj.$store.getters['search/getSearchCondition'](
          screenId
        )
        obj.searchCondition = JSON.parse(searchCondition)
        search(obj)

        openMsgModal(
          obj,
          MSG_TYPE.I,
          obj.$store.getters['init/getMessage'](
            'YZ00MG995I',
            obj.resultControlInfo.lock.label
          )
        )
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message
        }
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

/**
 * 「ロック解除」ボタンクリック
 * @param {*} obj
 */
export function goUnlock(obj) {
  if (obj.selectedItem.length < 1) {
    openMsgModal(
      obj,
      MSG_TYPE.E,
      obj.$store.getters['init/getMessage']('YZ00MG901E')
    )
  } else {
    obj.lockMessage = obj.$store.getters['init/getMessage'](
      'YZ00MG994Q',
      obj.resultControlInfo.unlock.label
    )
    openSubModal(obj, 'unlock-modal')
  }
}

/**
 * 選択したデータをロック解除します.
 */
export async function unlock(obj) {
  obj.$bvModal.hide('unlock-modal')

  let datas = convertCamelCace(obj.selectedItem)

  obj.$store.dispatch('init/setServiceLoading')
  await PlanningOrderService.unlock(datas)
    .then(
      () => {
        var searchCondition = obj.$store.getters['search/getSearchCondition'](
          screenId
        )
        obj.searchCondition = JSON.parse(searchCondition)
        search(obj)

        openMsgModal(
          obj,
          MSG_TYPE.I,
          obj.$store.getters['init/getMessage'](
            'YZ00MG995I',
            obj.resultControlInfo.unlock.label
          )
        )
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message
        }
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}
