/**
 * 配車結果情報データ.
 * @class
 * @exports
 */
export default class TransportRequestInfo {
  //placeOrderId
  planningResultId
  transportRequestId
  blockName
  departureDateTime
  departurePlaceCode
  departurePlaceName
  departurePlaceAddress
  arrivalDateTime
  arrivalPlaceCode
  arrivalPlaceName
  arrivalPlaceAddress
  tripNo
  lastDropNo
  transportRequestDistance //transportDistance
  reason
  carrierDesiredDistance
  evaluationCharge
  productName
  totalQuantity
  totalWeight
  totalVolume
  planningStatusName
  managementVehicleKindName
  planningTargetDate
  planningDecisionDate
  planFlagName
  vehicleOperationId
  vehicleId
  vehicleName
  vehicleModelName
  vehicleCategoryName
  carrierName

  //--------------------
  // 運送依頼情報
  //--------------------
  //承諾拒否区分
  permitRefuse

  status
  statusName
  //①
  // 承認依頼
  approvalRequestComment
  approvalRequestUser
  approvalRequestUserName
  approvalRequestDatetime
  approvalRequestDatetimeDisplay
  //②
  // 承認
  approvedUser
  approvedUserName
  approvedDatetime
  approvedDatetimeDisplay
  //③
  //承認差戻し
  remandCode
  remandName
  remandComment
  //④
  // 承諾(回答)
  answerUser
  answerUserName
  answerDatetime
  answerDatetimeDisplay
  //⑤
  // 依頼拒否
  refusalCode
  refusalComment

  transportReserve01
  transportReserve02
  transportReserve03
  transportReserve04
  transportReserve05
  transportReserve06
  transportReserve07
  transportReserve08
  transportReserve09
  transportReserve10

  //--------------------
  // 実車情報
  //--------------------
  //実車番ID(トラック・トラクタID)
  realVehicleId = null
  //車番(トラック・トラクタ)
  realVehicleNumberplate = null
  // //車型(トラック・トラクタ)
  realVehicleModel = null
  // realVehicleModelName = null
  // //車種(トラック・トラクタ)
  realVehicleKind = null
  // realVehicleKindName = null

  //実車番ID(トレーラID)
  realTrailerId = null
  //車番(トレーラ)
  realTrailerNumberplate = null
  // //車型(トレーラ)
  realTrailerModel = null
  // realTrailerModelName = null
  // //車種(トレーラ)
  realTrailerKind = null
  // realTrailerKindName = null

  // realVehicleEntryStatusName
  // realVehicleRequestTime
  // realVehicleEntryTime
  // realVehicleId
  // realVehicleName
  // realTel
  // resignReasonKindName
  // realVehicleComment
  // realVehicleRequestUserName
  // realVehicleEntryUserName

  // 配車結果予備１~１０
  reserve01 = null
  reserve02 = null
  reserve03 = null
  reserve04 = null
  reserve05 = null
  reserve06 = null
  reserve07 = null
  reserve08 = null
  reserve09 = null
  reserve10 = null
  registrationTimeDisplay = null
  registrationUser = null
  updateTimeDisplay = null
  updateUser = null
  version = null
  updateByTrigger = null

  //---------------------------------
  // 配車結果明細タブ
  //---------------------------------
  // 配車結果明細 List<PlanningResultDetailDto>
  planningResults = []
  // 金額
  costDetails = []
  orderResults = []
  //--------------------
  // 許可証タブ
  //--------------------
  // 許可認明細 List<PermitRequestDto>
  permitRequests = []
}
