/**
 * 自動配車結果
 */
export default class autoPlanning {
  requestId
  blockCode = null
  blockName = null

  //ユーザＩＤ
  userId
  //ユーザ名
  userName = null
  //要求日時
  requestTime = null
  //立案区分
  planKind = null
  planKindName = null
  //立案開始日時
  planBeginTime = null
  //立案終了日時
  planEndTime = null
  //計画開始日
  planBeginDate = null
  //計画終了日
  planEndDate = null
  //配車評価パターンID
  planningEvaluationPatternId
  //配車評価パターン名
  planningEvaluationPatternName = null
  //配車計画パターンID
  planningPatternId
  //配車計画パターン名
  planningPatternName = null
  //最大計画回数
  maxPlanCount
  //最大計画時間
  maxPlanMin
  //立案指示区分
  planRequestKind
  planRequestKindName = null
  //配車パラメータID
  planningParameterId
  //立案状態
  planStatus = null
  planStatusName = null
  //---------------------------------------------------------------
  //対象輸送手段数
  targetVehicleCount
  //対象オーダ数
  targetOrderCount
  //割付輸送手段数
  assignVehicleCount
  //割付オーダ数
  assignOrderCount
  //最大計画回数
  systemMaxPlanCount
  //最大計画時間
  systemMaxPlanMin
  //要求日時
  systemRequestTime
  //経過時間
  passedTime
  //---------------------------------------------------------------
  //最良世代
  bestGeneration
  //最良世代の結果
  // List<PlanningEvaluationDto>
  bestGenerationDtos = []
  //---------------------------------------------------------------
  //平価結果ーヘーダ
  domainColumns = []
  //平価結果ー実績
  resultList = []
  //---------------------------------------------------------------
  // 評価結果表示
  //List<PlanningEvaluationDto>
  evaluationDtos = []
  // 抽出/実行条件(オーだ)
  //List<PlanningRequestOrderConditionDto>
  orderConditionDtos = []
  //抽出/実行条件(車両)
  //List<PlanningRequestVehicleConditionDto>
  vehicleConditionDtos = []
}
