import {
  openMsgModal,
  openSubModal,
  formatYYYYMMDD,
  formatHHmmss,
  displaYyyymmdd,
  displayHHmmss,
  //getListOptions,
  displayYYYYYMMDDHHmm,
  formatYYYYYMMDDHHmm,
  convertCamelCace,
} from '@/common/Common.js'
import {
  SCREEN_ID,
  MSG_TYPE,
  DETAIL_SCREEN_KBN,
  PLANNING_STATUS,
  //MASTER_CODE,
} from '@/common/const.js'
import router from '@/router'
import VehicleOperationService from './VehicleOperationService'
import AreaService from '@/master/area/AreaService'
import { isNil, isEmpty } from 'lodash'
import _ from 'lodash'

const screenId = SCREEN_ID.VEHICLE_OPERATION_LIST
const detailScreenId = SCREEN_ID.VEHICLE_OPERATION_DETAIL

export function init(obj) {
  // 方面名コンボボックスの設定
  const areaSearchCondition = {
    screenId: obj.SCREEN_ID.AREA_LIST,
    domainName: 'result_fields',
  }
  obj.$store.dispatch('init/setServiceLoading')
  AreaService.search(areaSearchCondition)
    .then(
      (response) => {
        response.data.resultList.forEach((element) => {
          // ブロックコンボボックスリスト作成処理
          obj.areaOptions.push({
            ['value']: element.area_id + '',
            ['text']: element.area_name,
          })
        })
      },
      (e) => {
        console.log(e)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
      // サーバーの処理後再検索
      if (obj.isReSearch == true) {
        reSearch(obj)
      }
    })
}

export function reSearch(obj) {
  var searchCondition = obj.$store.getters['search/getSearchCondition'](
    screenId
  )
  if (searchCondition != null) {
    obj.searchCondition = JSON.parse(searchCondition)
    search(obj)
  }
}

export function showCheckModal(obj, event) {
  if (obj.btnFlg) {
    obj.event = event
    openSubModal(obj, 'check-modal')
  } else {
    switch (event) {
      case 'search':
        search(obj)
        break
      case 'new':
        goNew(obj)
        break
      case 'detail':
        goDetail(obj)
        break
      case 'copy':
        goCopy(obj)
        break
      case 'divide':
        divide(obj)
        break
      case 'merge':
        merge(obj)
        break
      case 'generate':
        generate(obj)
        break
    }
  }
}

/**
 * 場所を検索します.
 * @param {*} obj
 */
export function search(obj) {
  obj.$bvModal.hide('check-modal')
  obj.btnFlg = false

  obj.searchCondition.scheduleBeginDate = formatYYYYMMDD(
    obj,
    obj.searchCondition.scheduleBeginDate
  )
  obj.searchCondition.scheduleEndDate = formatYYYYMMDD(
    obj,
    obj.searchCondition.scheduleEndDate
  )

  obj.$store.dispatch('init/setServiceLoading')
  VehicleOperationService.search(obj.searchCondition)
    .then(
      (response) => {
        obj.tableItems = response.data.resultList
        obj.tableItems.forEach((element) => {
          element.schedule_begin_date = displaYyyymmdd(
            obj,
            element.schedule_begin_date
          )
          element.schedule_begin_time = displayHHmmss(
            element.schedule_begin_time
          )
          element.schedule_end_date = displaYyyymmdd(
            obj,
            element.schedule_end_date
          )
          element.schedule_end_time = displayHHmmss(element.schedule_end_time)
          element.plan_begin_time = displayYYYYYMMDDHHmm(
            obj,
            element.plan_begin_time
          )
          element.plan_end_time = displayYYYYYMMDDHHmm(
            obj,
            element.plan_end_time
          )
        })
        if (obj.tableItems.length < 1) {
          // 警告モーダル表示
          openMsgModal(
            obj,
            MSG_TYPE.W,
            obj.$store.getters['init/getMessage']('YZ00MG907I')
          )
        }
      },
      (e) => {
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message
        }
        // エラーモーダル表示
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })

  var payload = {
    viewId: screenId,
    params: JSON.stringify(obj.searchCondition),
  }
  obj.$store.dispatch('search/setSearchCondition', payload)
}

/**
 * 検索項目をクリアします.
 */
export function clear(obj) {
  obj.searchCondition = obj.defaultSearchCondition()
  var payload = {
    viewId: screenId,
    params: JSON.stringify(obj.searchCondition),
  }
  obj.$store.dispatch('search/setSearchCondition', payload)
}

// 「詳細」ボタンクリック
export async function goDetail(obj) {
  obj.$bvModal.hide('check-modal')
  if (obj.selectedItem.length != 1) {
    openMsgModal(
      obj,
      MSG_TYPE.E,
      obj.$store.getters['init/getMessage']('YZ00MG901E')
    )
  } else {
    // ガント排他テーブルを検索し、ガント編集中の場合は登録エラーとする
    if (await checkRelation(obj)) {
      router.push({
        name: detailScreenId,
        params: {
          vehicleOperationId: obj.selectedItem[0].vehicle_operation_id,
          screenKbn: DETAIL_SCREEN_KBN.DETAIL,
          beforeScreenRouteInfo: {
            routeInfo: {
              name: screenId,
              params: { isReSearch: true },
            },
          },
        },
      })
    }
  }
}

// 「新規」ボタンクリック
export function goNew(obj) {
  obj.$bvModal.hide('check-modal')
  router.push({
    name: detailScreenId,
    params: {
      screenKbn: DETAIL_SCREEN_KBN.NEW,
      beforeScreenRouteInfo: {
        routeInfo: {
          name: screenId,
          params: { isReSearch: true },
        },
      },
    },
  })
}

// 「コピー」ボタンクリック
export function goCopy(obj) {
  obj.$bvModal.hide('check-modal')
  if (obj.selectedItem.length != 1) {
    openMsgModal(
      obj,
      MSG_TYPE.E,
      obj.$store.getters['init/getMessage']('YZ00MG901E')
    )
  } else {
    router.push({
      name: detailScreenId,
      params: {
        vehicleOperationId: obj.selectedItem[0].vehicle_operation_id,
        screenKbn: DETAIL_SCREEN_KBN.COPY,
        beforeScreenRouteInfo: {
          routeInfo: {
            name: screenId,
            params: { isReSearch: true },
          },
        },
      },
    })
  }
}

export async function divide(obj) {
  obj.$bvModal.hide('check-modal')
  if (obj.selectedItem.length != 1) {
    openMsgModal(
      obj,
      MSG_TYPE.E,
      obj.$store.getters['init/getMessage']('YZ00MG901E')
    )
  } else {
    // ガント排他テーブルを検索し、ガント編集中の場合は登録エラーとする
    if (await checkRelation(obj)) {
      openSubModal(obj, 'divide-sub-modal')
    }
  }
}

export function doDivide(obj, divideDate, divideHhmmss) {
  let divideTime = divideDate + ' ' + divideHhmmss
  let ee0101 = obj.selectedItem[0]
  //分割日時が選択した稼動情報の「計画開始日時」～「計画終了日時」の間にある場合はエラー
  if (
    !isNil(ee0101.plan_begin_time) &&
    !isEmpty(ee0101.plan_begin_time) &&
    !isNil(ee0101.plan_end_time) &&
    !isEmpty(ee0101.plan_end_time)
  ) {
    if (
      obj.$moment(ee0101.plan_begin_time).isBefore(obj.$moment(divideTime)) &&
      obj.$moment(divideTime).isBefore(obj.$moment(ee0101.plan_end_time))
    ) {
      // 既に割付済の時間帯のため、分割出来ません。
      openMsgModal(
        obj,
        MSG_TYPE.E,
        obj.$store.getters['init/getMessage']('EE01S001_MG001E')
      )
      return
    }
  }
  //・「入力日時」＞「予定開始日時」かつ「入力日時」＜ 「予定終了日時」
  // 上記以外の場合はエラー
  let scheduleBeginTime =
    ee0101.schedule_begin_date + ' ' + ee0101.schedule_begin_time
  let scheduleEndTime =
    ee0101.schedule_end_date + ' ' + ee0101.schedule_end_time
  if (
    obj.$moment(scheduleBeginTime).isBefore(obj.$moment(divideTime)) &&
    obj.$moment(divideTime).isBefore(obj.$moment(scheduleEndTime))
  ) {
    // OK
  } else {
    // 予定開始日時～終了日時内の日時を設定してください。
    openMsgModal(
      obj,
      MSG_TYPE.E,
      obj.$store.getters['init/getMessage']('EE01S001_MG006E')
    )
    return
  }

  ee0101.schedule_begin_date = formatYYYYMMDD(obj, ee0101.schedule_begin_date)
  ee0101.schedule_begin_time = formatHHmmss(ee0101.schedule_begin_time)
  ee0101.schedule_end_date = formatYYYYMMDD(obj, ee0101.schedule_end_date)
  ee0101.schedule_end_time = formatHHmmss(ee0101.schedule_end_time)

  var detail = []
  let row = []
  for (let key in ee0101) {
    row.push({
      [_.camelCase(key)]: ee0101[key],
    })
  }
  detail.push(row.reduce((l, r) => Object.assign(l, r), {}))

  obj.$store.dispatch('init/setServiceLoading')
  VehicleOperationService.divideData(
    formatYYYYMMDD(obj, divideDate),
    formatHHmmss(divideHhmmss),
    detail[0]
  )
    .then(
      () => {
        obj.infoMessage = obj.$store.getters['init/getMessage'](
          'YZ00MG995I',
          obj.resultControlInfo.division.label
        )
        // 情報モーダル表示
        openSubModal(obj, 'vehicle-operation-list-info-modal')
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message + e.response.data.messageDetail
        }
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

export function merge(obj) {
  obj.$bvModal.hide('check-modal')

  // {0}処理を行います。よろしいですか？
  obj.registerMessage = obj.$store.getters['init/getMessage'](
    'YZ00MG994Q',
    obj.resultControlInfo.merge.label
  )

  obj.event = 'merge'
  openSubModal(obj, 'register-modal')
}

export async function doMerge(obj) {
  obj.$bvModal.hide('register-modal')

  if (obj.selectedItem.length != 2) {
    // 一覧より2行を選択してください。
    openMsgModal(
      obj,
      MSG_TYPE.E,
      obj.$store.getters['init/getMessage']('YZ00MG905E')
    )
    return
  }

  // ガント排他テーブルを検索し、ガント編集中の場合は登録エラーとする
  if ((await checkRelation(obj)) == false) {
    return
  }

  if (obj.selectedItem.length == 2) {
    // ①選択行の車両IDが等しいことをチェック
    if (obj.selectedItem[0].vehicle_id != obj.selectedItem[1].vehicle_id) {
      // 異なる車両はマージ出来ません。
      openMsgModal(
        obj,
        MSG_TYPE.E,
        obj.$store.getters['init/getMessage']('EE01S001_MG002E')
      )
      return
    }

    // ②選択行の配車ステータスがどちらも20(割付済)以上であるかチェック
    if (
      Number(obj.selectedItem[0].planning_status) >=
        Number(PLANNING_STATUS.ASSIGNED) &&
      Number(obj.selectedItem[1].planning_status) >=
        Number(PLANNING_STATUS.ASSIGNED)
    ) {
      // どちらも割付済のためマージ出来ません。
      openMsgModal(
        obj,
        MSG_TYPE.E,
        obj.$store.getters['init/getMessage']('EE01S001_MG003E')
      )
      return
    }
  }

  let updateItems = _.cloneDeep(obj.selectedItem)

  updateItems.forEach((element) => {
    element.schedule_begin_date = formatYYYYMMDD(
      obj,
      element.schedule_begin_date
    )
    element.schedule_begin_time = formatHHmmss(element.schedule_begin_time)
    element.schedule_end_date = formatYYYYMMDD(obj, element.schedule_end_date)
    element.schedule_end_time = formatHHmmss(element.schedule_end_time)
    element.plan_begin_time = formatYYYYYMMDDHHmm(element.plan_begin_time)
    element.plan_end_time = formatYYYYYMMDDHHmm(element.plan_end_time)
  })

  var details = convertCamelCace(updateItems)

  obj.$store.dispatch('init/setServiceLoading')
  VehicleOperationService.mergeData(details)
    .then(
      () => {
        obj.infoMessage = obj.$store.getters['init/getMessage'](
          'YZ00MG995I',
          obj.resultControlInfo.merge.label
        )
        // 情報モーダル表示
        openSubModal(obj, 'vehicle-operation-list-info-modal')
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message + e.response.data.messageDetail
        }
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

export function generate(obj) {
  obj.$bvModal.hide('check-modal')

  // {0}から{1}の稼動情報を再作成します。よろしいですか？
  obj.registerMessage = obj.$store.getters['init/getMessage'](
    'EE01S001_MG001Q',
    obj.searchCondition.scheduleBeginDate,
    obj.searchCondition.scheduleEndDate
  )

  obj.event = 'generate'
  openSubModal(obj, 'register-modal')
}

export function doGenerate(obj) {
  obj.$bvModal.hide('register-modal')

  obj.searchCondition.scheduleBeginDate = formatYYYYMMDD(
    obj,
    obj.searchCondition.scheduleBeginDate
  )
  obj.searchCondition.scheduleEndDate = formatYYYYMMDD(
    obj,
    obj.searchCondition.scheduleEndDate
  )

  obj.$store.dispatch('init/setServiceLoading')
  VehicleOperationService.executeBatchData(obj.searchCondition)
    .then(
      () => {
        obj.infoMessage = obj.$store.getters['init/getMessage'](
          'YZ00MG995I',
          obj.resultControlInfo.all_generate.label
        )
        // 情報モーダル表示
        openSubModal(obj, 'vehicle-operation-list-info-modal')
      },
      (e) => {
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message
        }
        // エラーモーダル表示
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

export function goRegister(obj) {
  if (obj.tableItems.length == 0) {
    // 一覧より行を選択してください。
    openMsgModal(
      obj,
      MSG_TYPE.E,
      obj.$store.getters['init/getMessage']('YZ00MG901E')
    )
    return
  }

  obj.event = 'confirm'
  // {0}処理を行います。よろしいですか？
  obj.registerMessage = obj.$store.getters['init/getMessage'](
    'YZ00MG994Q',
    obj.resultControlInfo.register.label
  )
  openSubModal(obj, 'register-modal')
}

export function register(obj) {
  obj.$bvModal.hide('register-modal')

  let targetDetails = obj.tableItems.filter((e) => e.modify_flag != null)
  let newDetails = _.cloneDeep(targetDetails)

  // 予定開始日・予定終了日の整合性チェック
  let result = true
  newDetails.some((element) => {
    let scheduleBeginDateTime =
      element.schedule_begin_date + ' ' + element.schedule_begin_time
    let scheduleEndDateTime =
      element.schedule_end_date + ' ' + element.schedule_end_time

    let startDate = obj
      .$moment(scheduleBeginDateTime)
      .format('YYYY-MM-DD HH:mm:ss')
    let endDate = obj.$moment(scheduleEndDateTime).format('YYYY-MM-DD HH:mm:ss')

    if (
      obj.$moment(startDate).isSame(obj.$moment(endDate)) == false &&
      obj.$moment(startDate).isBefore(obj.$moment(endDate)) == false
    ) {
      // ①同一レコードの開始日と終了日チェック異常
      result = false
      return true
    }
  })

  if (result == false) {
    // 期間日付の入力に不整合があります。
    openMsgModal(
      obj,
      MSG_TYPE.E,
      obj.$store.getters['init/getMessage']('YZ00MG992E')
    )
    return
  }

  newDetails.forEach((element) => {
    element.schedule_begin_date = formatYYYYMMDD(
      obj,
      element.schedule_begin_date
    )
    element.schedule_begin_time = formatHHmmss(element.schedule_begin_time)
    element.schedule_end_date = formatYYYYMMDD(obj, element.schedule_end_date)
    element.schedule_end_time = formatHHmmss(element.schedule_end_time)
    element.plan_begin_time = formatYYYYYMMDDHHmm(element.plan_begin_time)
    element.plan_end_time = formatYYYYYMMDDHHmm(element.plan_end_time)
  })

  var details = convertCamelCace(newDetails)

  obj.$store.dispatch('init/setServiceLoading')
  VehicleOperationService.registerList(details)
    .then(
      () => {
        obj.infoMessage = obj.$store.getters['init/getMessage'](
          'YZ00MG995I',
          obj.resultControlInfo.register.label
        )
        // 情報モーダル表示
        openSubModal(obj, 'vehicle-operation-list-info-modal')
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message + e.response.data.messageDetail
        }
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

async function checkRelation(obj) {
  let result = true
  // ガント排他テーブルを検索し、ガント編集中の場合は登録エラーとする
  obj.$store.dispatch('init/setServiceLoading')
  await VehicleOperationService.getGntEx(
    obj.selectedItem[0].vehicle_operation_id
  )
    .then(
      (response) => {
        if (response.data != null) {
          // 立案処理中の車両のため、処理を継続できません。
          openMsgModal(
            obj,
            MSG_TYPE.E,
            obj.$store.getters['init/getMessage']('EE01S001_MG005E')
          )

          result = false
        }
      },
      (e) => {
        if (e.response.status != 404) {
          // エラーモーダル表示
          var msg
          if (e.response == undefined) {
            msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
          } else {
            msg = e.response.data.message + e.response.data.messageDetail
          }
          openMsgModal(obj, MSG_TYPE.E, msg)
          result = false
        }
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })

  return result
}
