/**
 * 配車結果情報データ.
 * @class
 * @exports
 */
export default class WorkRecordInfo {
  //placeOrderId
  planningResultId
  blockName
  departureDateTime
  departurePlaceName
  departurePlaceAddress
  arrivalDateTime
  arrivalPlaceName
  arrivalPlaceAddress
  tripNo
  lastDropNo
  transportDistance
  evaluationCharge
  productName
  totalQuantity
  totalWeight
  totalVolume
  planningStatusName
  managementVehicleKindName
  planningTargetDate
  planningDecisionDate
  planFlagName
  vehicleOperationId
  vehicleId
  vehicleName
  vehicleModelName
  vehicleCategoryName
  carrierName

  //--------------------
  // 運送依頼情報
  //--------------------
  permitRefuse
  status
  statusName
  refusalComment

  //--------------------
  // 実車情報
  //--------------------
  //実車番ID(トラック・トラクタID)
  realVehicleId = null
  //車番(トラック・トラクタ)
  realVehicleNumberplate = null
  // //車型(トラック・トラクタ)
  // realVehicleModel = null
  // realVehicleModelName = null
  // //車種(トラック・トラクタ)
  // realVehicleKind = null
  // realVehicleKindName = null

  //実車番ID(トレーラID)
  realTrailerId = null
  //車番(トレーラ)
  realTrailerNumberplate = null
  // //車型(トレーラ)
  // realTrailerModel = null
  // realTrailerModelName = null
  // //車種(トレーラ)
  // realTrailerKind = null
  // realTrailerKindName = null

  // realVehicleEntryStatusName
  // realVehicleRequestTime
  // realVehicleEntryTime
  // realVehicleId
  // realVehicleName
  // realTel
  // resignReasonKindName
  // realVehicleComment
  // realVehicleRequestUserName
  // realVehicleEntryUserName
  planningResult01
  planningResult02
  planningResult03
  planningResult04
  planningResult05
  planningResult06
  planningResult07
  planningResult08
  planningResult09
  planningResult10
  registrationTimeDisplay
  registrationUser
  updateTimeDisplay
  updateUser
  planningResults = []
  //costDetails = []
}
