import { createAuthenticaionService, makeQueryParams } from '@/services/index'

const BASE_URL = `${process.env.VUE_APP_API_URL}vehicleoperation/`

class VehicleOperationService {
  getAll() {
    return createAuthenticaionService(BASE_URL).get('/')
  }

  get(id) {
    return createAuthenticaionService(BASE_URL).get(`/${id}`)
  }

  create(data) {
    return createAuthenticaionService(BASE_URL).post('/', data)
  }

  update(id, data) {
    return createAuthenticaionService(BASE_URL).put(`/${id}`, data)
  }

  deleteLogical(id, data) {
    return createAuthenticaionService(`${BASE_URL}delete/`).put(`/${id}`, data)
  }

  delete(id) {
    return createAuthenticaionService(BASE_URL).delete(`/${id}`)
  }

  deleteAll() {
    return createAuthenticaionService(BASE_URL).delete('/')
  }

  removeList(data) {
    return createAuthenticaionService(`${BASE_URL}removelist/`).put('/', data)
  }

  search(searchCondition) {
    const param = makeQueryParams(searchCondition)
    return createAuthenticaionService(`${BASE_URL}search/`).get(`/?${param}`)
  }

  getGntEx(id) {
    return createAuthenticaionService(`${BASE_URL}ganntexinfo/`).get(`/${id}`)
  }

  getCountKadouChohuku(searchCondition) {
    return createAuthenticaionService(`${BASE_URL}countduplicate/`).get(
      searchCondition
    )
  }

  divideData(date, time, data) {
    return createAuthenticaionService(`${BASE_URL}dividedata/`).post(
      `/?scheduleBeginDate=${date}&scheduleBeginTime=${time}`,
      data
    )
  }

  mergeData(data) {
    return createAuthenticaionService(`${BASE_URL}mergedata/`).post('/', data)
  }

  executeBatchData(searchCondition) {
    const param = makeQueryParams(searchCondition)
    return createAuthenticaionService(`${BASE_URL}executebatchdata/`).post(
      '/',
      param
    )
  }

  registerList(data) {
    return createAuthenticaionService(`${BASE_URL}registerlist/`).post(
      '/',
      data
    )
  }
}

export default new VehicleOperationService()
