<template>
  <div data-class="list">
    <BaseSearchListPage
      :breadcrumbItems="breadcrumbItems"
      :searchCondition="searchCondition"
      :searchConditionInfo="searchConditionInfo"
      :buttonItems="buttonItems"
      :tableItems="tableItems"
      :fields="fields"
      selectMode="range"
      @row-selected="onRowSelected"
      @max-count-changed="onMaxCountChanged"
      @clear="clear(obj)"
      @search="search(obj)"
    >
      <template #list-header>
        <p>{{ resultListHeaderInfo.total_weight.label }}: {{ totalWeight }}</p>
      </template>
    </BaseSearchListPage>

    <!-- CSV出力モーダル -->
    <ConfirmModal
      id="download-modal"
      :columnInfos="messageInfo"
      :message="downloadMessage"
      @confirm="download(obj)"
    />

    <!-- 部署マスタモーダル -->
    <DepartmentModal
      id="department-modal"
      type="entry"
      selectMode="single"
      :selectedSearchCondition="departmentModalSearchCondition"
      @after-close-set="afterCloseSetDepartment"
      @after-close-unset="afterCloseUnsetDepartment"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />
    <!-- 場所モーダル -->
    <PlaceModal
      id="place-modal"
      type="entry"
      selectMode="single"
      @after-close-set="afterCloseSetPlace"
      @after-close-unset="afterCloseUnsetPlace"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />

    <!-- 荷纏めモーダル -->
    <OrderPackUpModal
      id="packup-modal"
      type="entry"
      selectMode="single"
      :selectedOrderIds.sync="selectedOrderIds"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
      @set-info-msg="setInfoMsg"
      @after-close-set="reSearch(obj)"
    />

    <!-- 配送纏めモーダル -->
    <PlanningOrderPackUpModal
      id="planning-packup-modal"
      type="entry"
      selectMode="single"
      :selectedPlanningOrderIds.sync="selectedPlanningOrderIds"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
      @set-info-msg="setInfoMsg"
      @after-close-set="reSearch(obj)"
    />

    <!-- 数量分割 / 数量分割編集 モーダル -->
    <OrderCountSplitModal
      id="order-count-split-modal"
      type="entry"
      selectMode="single"
      :selectedOrderSplitIds.sync="selectedOrderSplitIds"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
      @set-info-msg="setInfoMsg"
      @after-close-set="reSearch(obj)"
    />

    <!-- 区間分割 モーダル -->
    <OrderSectionSplitModal
      id="order-section-split-modal"
      type="entry"
      selectMode="single"
      :selectedOrderSectionSplitIds.sync="orderSectionSplitModalCondition"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
      @set-info-msg="setInfoMsg"
      @after-close-set="reSearch(obj)"
    />

    <!-- 削除モーダル -->
    <ConfirmModal
      id="remove-modal"
      :columnInfos="messageInfo"
      :message="removeMessage"
      @confirm="remove(obj)"
    />

    <!-- ロックモーダル -->
    <ConfirmModal
      id="lock-modal"
      :columnInfos="messageInfo"
      :message="lockMessage"
      @confirm="lock(obj)"
    />

    <!-- ロック解除モーダル -->
    <ConfirmModal
      id="unlock-modal"
      :columnInfos="messageInfo"
      :message="lockMessage"
      @confirm="unlock(obj)"
    />
  </div>
</template>

<script>
import {
  getListOptions,
  getSearchMaxCount,
  openSubModal,
} from '@/common/Common.js'
import { SCREEN_ID, DEPARTMENT_KIND } from '@/common/const.js'
import { ENTRY_KIND } from '@/common/const.js'
import {
  init,
  reSearch,
  search,
  clear,
  goDetail,
  goPackUp,
  goPlanningPackUp,
  goOrderCountSplit,
  goOrderSectionSplit,
  goLock,
  goUnlock,
  lock,
  unlock,
  remove,
  download,
} from '@/plan/planningOrder/PlanningOrderList.js'

const screenId = SCREEN_ID.PLANNING_ORDER_LIST

export default {
  components: {
    PlaceModal: () => import('@/master/place/PlaceModal.vue'),
    DepartmentModal: () => import('@/master/department/DepartmentModal.vue'),
    OrderPackUpModal: () => import('@/plan/planningOrder/OrderPackUpModal.vue'),
    PlanningOrderPackUpModal: () =>
      import('@/plan/planningOrder/PlanningOrderPackUpModal.vue'),
    OrderCountSplitModal: () =>
      import('@/plan/planningOrder/OrderCountSplitModal.vue'),
    OrderSectionSplitModal: () =>
      import('@/plan/planningOrder/OrderSectionSplitModal.vue'),
  },

  props: {
    isReSearch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tableItems: [],
      selectedItem: [],
      /**
       * 検索パラメータ.
       * @type {Object}
       */
      searchCondition: this.defaultSearchCondition(),
      obj: this,
      totalWeight: 0,
      placeModalKbn: -1,
      departmentModalKbn: -1,
      removeMessage: null,
      lockMessage: null,
      selectedOrderIds: [],
      selectedPlanningOrderIds: [],
      selectedOrderSplitIds: [],
      selectedOrderSectionSplitIds: [],
      entryKind: '0',
      departmentModalSearchCondition: {},
      // 区間分割パラメーター
      orderSectionSplitModalCondition: {},
    }
  },

  created() {
    init(this)
  },

  computed: {
    locale() {
      let lang = this.$store.getters['authentication/getLanguage']
      return lang
    },

    searchConditionInfo() {
      const searchConditionInfo = this.$store.getters['init/getScreens'](
        screenId
      )('search_condition')
      return {
        header: searchConditionInfo.title.label,
        clear: searchConditionInfo.clear,
        search: searchConditionInfo.search,
        inputComponents: [
          {
            // 期間
            type: 'select',
            id: 'periodCode',
            columnInfo: searchConditionInfo.period_code,
            options: getListOptions(this.MASTER_CODE.PERIOD_CODE),
            rules: [this.INPUT_TYPE_CHECK.REQUIRED],
            onChangeCallback: () => {},
          },
          {
            // 期間
            type: 'dateFromTo',
            fromId: 'periodDateFrom',
            toId: 'periodDateTo',
            fromColumnInfo: searchConditionInfo.period_date_from,
            toColumnInfo: searchConditionInfo.period_date_to,
            locale: this.locale,
            fromRules: [
              this.INPUT_TYPE_CHECK.REQUIRED,
              this.INPUT_TYPE_CHECK.DATERANGE,
            ],
          },
          {
            // 受付番号
            type: 'text',
            id: 'receiptNo',
            columnInfo: searchConditionInfo.receipt_no,
            rules: [this.INPUT_TYPE_CHECK.ALPHANUMERIC_CODE],
            maxLength: 30,
          },
          {
            // 荷主
            type: 'referenceCodeName',
            codeId: 'ownerCode',
            nameId: 'ownerName',
            codeColumnInfo: searchConditionInfo.owner_code,
            nameColumnInfo: searchConditionInfo.owner_name,
            btnColumnInfo: searchConditionInfo.sub_modal_owner,
            codeRules: [this.INPUT_TYPE_CHECK.ALPHANUMERIC_CODE],
            onClickCallback: () => {
              this.departmentModalKbn = 1
              this.departmentModalSearchCondition = {}
              this.departmentModalSearchCondition.departmentKind =
                DEPARTMENT_KIND.OWNER
              this.openSubModal(this, 'department-modal')
            },
          },
          {
            // 出荷場所
            type: 'referenceCodeName',
            codeId: 'departurePlaceCode',
            nameId: 'departurePlaceName',
            codeColumnInfo: searchConditionInfo.departure_place_code,
            nameColumnInfo: searchConditionInfo.departure_place_name,
            btnColumnInfo: searchConditionInfo.sub_modal_departure,
            codeRules: [this.INPUT_TYPE_CHECK.ALPHANUMERIC_CODE],
            onClickCallback: () => {
              //...ボタン
              this.placeModalKbn = 1
              this.openSubModal(this, 'place-modal')
            },
          },
          {
            // 搬入場所
            type: 'referenceCodeName',
            codeId: 'arrivalPlaceCode',
            nameId: 'arrivalPlaceName',
            codeColumnInfo: searchConditionInfo.arrival_place_code,
            nameColumnInfo: searchConditionInfo.arrival_place_name,
            btnColumnInfo: searchConditionInfo.sub_modal_arrival,
            codeRules: [this.INPUT_TYPE_CHECK.ALPHANUMERIC_CODE],
            onClickCallback: () => {
              //...ボタン
              this.placeModalKbn = 2
              this.openSubModal(this, 'place-modal')
            },
          },
          {
            // 特約店
            type: 'referenceCodeName',
            codeId: 'distributorCode',
            nameId: 'distributorName',
            codeColumnInfo: searchConditionInfo.distributor_code,
            nameColumnInfo: searchConditionInfo.distributor_name,
            btnColumnInfo: searchConditionInfo.sub_modal_distributor,
            codeRules: [this.INPUT_TYPE_CHECK.ALPHANUMERIC_CODE],
            onClickCallback: () => {
              this.departmentModalKbn = 2
              this.departmentModalSearchCondition = {}
              this.departmentModalSearchCondition.departmentKind =
                DEPARTMENT_KIND.DISTRIBUTOR
              this.openSubModal(this, 'department-modal')
            },
          },
          {
            // 荷受先
            type: 'referenceCodeName',
            codeId: 'consigneeCode',
            nameId: 'consigneeName',
            codeColumnInfo: searchConditionInfo.consignee_code,
            nameColumnInfo: searchConditionInfo.consignee_name,
            btnColumnInfo: searchConditionInfo.sub_modal_consignee,
            codeRules: [this.INPUT_TYPE_CHECK.ALPHANUMERIC_CODE],
            onClickCallback: () => {
              this.departmentModalKbn = 3
              this.departmentModalSearchCondition = {}
              this.departmentModalSearchCondition.departmentKind =
                DEPARTMENT_KIND.CONSIGNEE
              this.openSubModal(this, 'department-modal')
            },
          },
          {
            // 配車ステータス
            type: 'checkboxgroup',
            id: 'planningStatus',
            columnInfo: searchConditionInfo.planning_status,
            options: getListOptions(this.MASTER_CODE.PLANNING_STATUS, false),
          },
          {
            // 受付元
            type: 'text',
            id: 'receptProcess',
            columnInfo: searchConditionInfo.recept_process,
          },
          {
            // 摘要01
            type: 'text',
            id: 'description01',
            columnInfo: searchConditionInfo.description01,
          },
          {
            // 摘要02
            type: 'text',
            id: 'description02',
            columnInfo: searchConditionInfo.description02,
          },
          {
            // 摘要03
            type: 'text',
            id: 'description03',
            columnInfo: searchConditionInfo.description03,
          },
          {
            // 摘要04
            type: 'text',
            id: 'description04',
            columnInfo: searchConditionInfo.description04,
          },
          {
            // 摘要05
            type: 'text',
            id: 'description05',
            columnInfo: searchConditionInfo.description05,
          },
        ],
      }
    },
    resultControlInfo() {
      return this.$store.getters['init/getScreens'](screenId)(
        'result_ctrl_info'
      )
    },

    resultListHeaderInfo() {
      return this.$store.getters['init/getScreens'](screenId)(
        'result_list_header'
      )
    },
    fileInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'file'
      )
    },
    messageInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'message'
      )
    },
    breadcrumbItems() {
      return [
        {
          name: this.$store.getters['init/getScreenName'](screenId),
        },
      ]
    },
    /**
     * テーブル列定義リスト.
     * @return {Array}
     */
    fields() {
      return this.$store.getters['init/getFields'](screenId)(
        this.DOMAIN_NAME.FIELDS
      )
    },

    /**
     * ボタン情報リスト.
     * @return {Array}
     */
    buttonItems() {
      return [
        // ボタングループ#1
        [
          // 詳細ボタン
          {
            variant: 'primary',
            icon: 'list-alt',
            columnInfo: this.resultControlInfo.detail,
            disabled: !this.isSelected,
            onClickCallback: () => {
              goDetail(this)
            },
          },
        ],
        // ボタングループ#2
        [
          // 荷纏め
          {
            variant: 'primary',
            icon: 'briefcase',
            columnInfo: this.resultControlInfo.packup,
            disabled: !this.isSelected,
            onClickCallback: () => {
              goPackUp(this)
            },
          },
          // 配送纏め
          {
            variant: 'primary',
            icon: 'cart-arrow-down',
            columnInfo: this.resultControlInfo.delivery_packup,
            disabled: !this.isSelected,
            onClickCallback: () => {
              goPlanningPackUp(this)
            },
          },
          // 数量分割
          {
            variant: 'primary',
            icon: 'balance-scale',
            columnInfo: this.resultControlInfo.count_partition,
            disabled: !this.isSelectedOne,
            onClickCallback: () => {
              goOrderCountSplit(this, ENTRY_KIND.INSERT)
            },
          },
          // 数量分割の制限解除対応で追加「数量分割」
          {
            variant: 'primary',
            icon: 'balance-scale',
            columnInfo: this.resultControlInfo.count_partition_new,
            disabled: !this.isSelectedOne,
            onClickCallback: () => {
              goOrderCountSplit(this, ENTRY_KIND.INSERT)
            },
          },
          // 数量分割編集
          {
            variant: 'primary',
            icon: 'edit',
            columnInfo: this.resultControlInfo.count_partition_edit,
            disabled: !this.isSelectedOne,
            onClickCallback: () => {
              goOrderCountSplit(this, ENTRY_KIND.UPDATE)
            },
          },
          // 数量分割の制限解除対応で追加「数量分割編集」
          {
            variant: 'primary',
            icon: 'edit',
            columnInfo: this.resultControlInfo.count_partition_edit_new,
            disabled: !this.isSelectedOne,
            onClickCallback: () => {
              goOrderCountSplit(this, ENTRY_KIND.UPDATE)
            },
          },
          // 区間分割
          {
            variant: 'primary',
            icon: 'sign',
            columnInfo: this.resultControlInfo.section_partition,
            disabled: !this.isSelectedOne,
            onClickCallback: () => {
              goOrderSectionSplit(this)
            },
          },
          // ロック
          {
            variant: 'primary',
            icon: 'lock',
            columnInfo: this.resultControlInfo.lock,
            disabled: !this.isSelected,
            onClickCallback: () => {
              goLock(this)
            },
          },
          // ロック解除
          {
            variant: 'primary',
            icon: 'lock-open',
            columnInfo: this.resultControlInfo.unlock,
            disabled: !this.isSelected,
            onClickCallback: () => {
              goUnlock(this)
            },
          },
          // ﾌｧｲﾙ出力ボタン
          {
            variant: 'primary',
            icon: 'download',
            columnInfo: this.resultControlInfo.file_print,
            onClickCallback: () => {
              openSubModal(this, 'download-modal')
            },
          },
        ],
      ]
    },

    /**
     * メッセージ.
     * @return {String}
     */
    downloadMessage() {
      return this.$store.getters['init/getMessage'](
        'YZ00MG994Q',
        this.resultControlInfo.file_print.label
      )
    },

    /**
     * 単数選択しているか.
     * @return {Boolean}
     */
    // 荷纏め、配送纏め
    isSelected() {
      return this.selectedItem.length > 0
    },

    // 数量分割、数量分割編集、区間分割
    isSelectedOne() {
      return this.selectedItem.length === 1
    },
  },

  methods: {
    getListOptions,
    openSubModal,
    goPlanningPackUp,
    reSearch,
    search,
    clear,
    goPackUp,
    goLock,
    goUnlock,
    lock,
    unlock,
    remove,
    download,

    /**
     * デフォルト検索パラメータを返します.
     * @return {Object}
     */
    defaultSearchCondition() {
      let today = this.$moment(new Date()).format('YYYY-MM-DD')
      return {
        periodCode: '10', //出荷(10)
        periodDateFrom: today,
        periodDateTo: today,
        receiptNo: null,
        ownerCode: null,
        ownerName: null,
        departurePlaceCode: null,
        departurePlaceName: null,
        arrivalPlaceCode: null,
        arrivalPlaceName: null,
        distributorCode: null,
        distributorName: null,
        consigneeCode: null,
        consigneeName: null,
        planningStatus: [],
        receptProcess: null,
        planningGroupCode: [],
        description01: null,
        description02: null,
        description03: null,
        description04: null,
        description05: null,
        size: getSearchMaxCount(),
        screenId: screenId,
        domainName: 'result_fields',
      }
    },

    /**
     * 部署マスタから取得データ設定
     */
    afterCloseSetDepartment(id, code, name) {
      if (this.departmentModalKbn == 1) {
        this.searchCondition.ownerCode = code
        this.searchCondition.ownerName = name
      } else if (this.departmentModalKbn == 2) {
        this.searchCondition.distributorCode = code
        this.searchCondition.distributorName = name
      } else {
        this.searchCondition.consigneeCode = code
        this.searchCondition.consigneeName = name
      }
    },
    /**
     * 部署情報解除
     */
    afterCloseUnsetDepartment() {
      if (this.departmentModalKbn == 1) {
        this.searchCondition.ownerCode = null
        this.searchCondition.ownerName = null
      } else if (this.departmentModalKbn == 2) {
        this.searchCondition.distributorCode = null
        this.searchCondition.distributorName = null
      } else {
        this.searchCondition.consigneeCode = null
        this.searchCondition.consigneeName = null
      }
    },
    /**
     * 場所マスタから取得データ設定
     */
    afterCloseSetPlace(selectedItem) {
      if (this.placeModalKbn == 1) {
        this.searchCondition.departurePlaceCode = selectedItem.place_code
        this.searchCondition.departurePlaceName = selectedItem.place_name
      } else {
        this.searchCondition.arrivalPlaceCode = selectedItem.place_code
        this.searchCondition.arrivalPlaceName = selectedItem.place_name
      }
    },
    /**
     * 場所情報解除
     */
    afterCloseUnsetPlace() {
      if (this.placeModalKbn == 1) {
        this.searchCondition.departurePlaceCode = null
        this.searchCondition.departurePlaceName = null
      } else {
        this.searchCondition.arrivalPlaceCode = null
        this.searchCondition.arrivalPlaceName = null
      }
    },

    setErrorMsg(msg) {
      this.$emit('set-error-msg', msg)
    },

    setWarnMsg(msg) {
      this.$emit('set-warn-msg', msg)
    },

    setInfoMsg(msg) {
      this.$emit('set-info-msg', msg)
    },

    onRowSelected(selectedItems) {
      this.selectedItem = selectedItems
    },

    onMaxCountChanged(maxCount) {
      this.searchCondition.size = maxCount
      // this.retrieveReceiveOrders()
    },
  },
}
</script>

<style scoped>
#footer {
  bottom: 0;
  position: fixed;
  width: 96.8%;
  height: 50px;
  margin-bottom: 20px;
  box-sizing: border-box;
  color: white;
  /* margin-left:300px; */
  text-align: center;
}
</style>
