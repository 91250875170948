import { createAuthenticaionService, makeQueryParams } from '@/services/index'

const BASE_URL = `${process.env.VUE_APP_API_URL}workrecord/`

class WorkRecordService {
  getAll() {
    return createAuthenticaionService(BASE_URL).get('/')
  }

  get(id) {
    return createAuthenticaionService(BASE_URL).get(`/${id}`)
  }

  update(id, data) {
    return createAuthenticaionService(BASE_URL).put(`/${id}`, data)
  }

  search(searchCondition) {
    const param = makeQueryParams(searchCondition)
    return createAuthenticaionService(`${BASE_URL}search/`).get(`/?${param}`)
  }

  //一括登録（荷積荷卸の実績情報登録）
  allregister(date, time, data) {
    return createAuthenticaionService(`${BASE_URL}allregister/`).post(
      `/?workActDate=${date}&workActTime=${time}`,
      data
    )
  }
}

export default new WorkRecordService()
