<template>
  <div data-class="list">
    <BaseSearchListPage
      :breadcrumbItems="breadcrumbItems"
      :searchCondition="searchCondition"
      :searchConditionInfo="searchConditionInfo"
      :buttonItems="buttonItems"
      :tableItems="tableItems"
      :fields="fields"
      selectMode="range"
      :screenId="SCREEN_ID.VEHICLE_OPERATION_LIST"
      @row-selected="onRowSelected"
      @max-count-changed="onMaxCountChanged"
      @clear="clear(obj)"
      @search="showCheckModal(obj, 'search')"
    >
    </BaseSearchListPage>

    <!-- 部署マスタモーダル -->
    <DepartmentModal
      id="department-modal"
      type="entry"
      :selectedSearchCondition="departmentModalSearchCondition"
      @after-close-set="afterCloseSetDepartment"
      @after-close-unset="afterCloseUnsetDepartment"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />

    <!-- 輸送手段マスタモーダル -->
    <VehicleModal
      id="vehicle-modal"
      type="entry"
      selectMode="single"
      @after-close-set="afterCloseSetVehicle"
      @after-close-unset="afterCloseUnsetVehicle"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />
    <!-- チェックモーダル -->
    <ConfirmModal
      id="check-modal"
      :columnInfos="messageInfo"
      :message="checkMessage"
      :event="event"
      @search="search(obj)"
      @new="goNew(obj)"
      @detail="goDetail(obj)"
      @copy="goCopy(obj)"
      @divide="divide(obj)"
      @merge="merge(obj)"
      @generate="generate(obj)"
    />

    <!-- 登録モーダル -->
    <ConfirmModal
      id="register-modal"
      :columnInfos="messageInfo"
      :message="registerMessage"
      :event="event"
      @confirm="register(obj)"
      @merge="doMerge(obj)"
      @generate="doGenerate(obj)"
    />

    <!-- 情報モーダル -->
    <InfoModal
      id="vehicle-operation-list-info-modal"
      :columnInfos="messageInfo"
      :message="infoMessage"
      @after-close="reSearch(obj)"
    />

    <!-- 分割条件指定モーダル -->
    <VehicleOperationSub
      id="divide-sub-modal"
      type="entry"
      @after-close-set="afterCloseSetDivide"
    />
  </div>
</template>

<script>
import {
  getListOptions,
  getSearchMaxCount,
  openSubModal,
} from '@/common/Common.js'
import { SCREEN_ID, DEPARTMENT_KIND } from '@/common/const.js'
import {
  init,
  reSearch,
  showCheckModal,
  search,
  clear,
  goDetail,
  goNew,
  goCopy,
  divide,
  doDivide,
  merge,
  doMerge,
  generate,
  doGenerate,
  goRegister,
  register,
} from './VehicleOperationList.js'

const screenId = SCREEN_ID.VEHICLE_OPERATION_LIST

export default {
  components: {
    DepartmentModal: () => import('@/master/department/DepartmentModal.vue'),
    VehicleModal: () => import('@/master/vehicle/VehicleModal.vue'),
    VehicleOperationSub: () => import('./VehicleOperationSub.vue'),
  },

  props: {
    isReSearch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tableItems: [],
      selectedItem: [],
      /**
       * 検索パラメータ.
       * @type {Object}
       */
      searchCondition: this.defaultSearchCondition(),
      obj: this,
      areaOptions: [],
      btnFlg: false,
      event: 'confirm',
      infoMessage: null,
      registerMessage: null,
      departmentModalSearchCondition: {},
    }
  },

  created() {
    init(this)
  },

  computed: {
    locale() {
      let lang = this.$store.getters['authentication/getLanguage']
      return lang
    },
    searchConditionInfo() {
      const searchConditionInfo = this.$store.getters['init/getScreens'](
        screenId
      )('search_condition')
      return {
        header: searchConditionInfo.title.label,
        clear: searchConditionInfo.clear,
        search: searchConditionInfo.search,
        inputComponents: [
          {
            // 稼動日
            type: 'dateFromTo',
            fromId: 'scheduleBeginDate',
            toId: 'scheduleEndDate',
            fromColumnInfo: searchConditionInfo.schedule_begin_date,
            toColumnInfo: searchConditionInfo.schedule_end_date,
            locale: this.locale,
            fromRules: [this.INPUT_TYPE_CHECK.DATERANGE],
          },
          {
            // 運送会社
            type: 'referenceCodeName',
            codeId: 'carrierCode',
            nameId: 'carrierName',
            codeColumnInfo: searchConditionInfo.carrier_code,
            nameColumnInfo: searchConditionInfo.carrier_name,
            btnColumnInfo: searchConditionInfo.sub_modal_carrier,
            codeRules: [this.INPUT_TYPE_CHECK.ALPHANUMERIC_CODE],
            codeMaxLength: 10,
            nameMaxLength: 30,
            onClickCallback: () => {
              this.departmentModalSearchCondition = {}
              this.departmentModalSearchCondition.departmentKind =
                DEPARTMENT_KIND.CARRIER
              this.openSubModal(this, 'department-modal')
            },
          },
          {
            // 車両
            type: 'referenceCodeName',
            codeId: 'vehicleCode',
            nameId: 'vehicleName',
            codeColumnInfo: searchConditionInfo.vehicle_code,
            nameColumnInfo: searchConditionInfo.vehicle_name,
            btnColumnInfo: searchConditionInfo.sub_modal_vehicle,
            codeRules: [this.INPUT_TYPE_CHECK.ALPHANUMERIC_CODE],
            codeMaxLength: 10,
            nameMaxLength: 30,
            onClickCallback: () => {
              this.openSubModal(this, 'vehicle-modal')
            },
          },
          {
            // 車型
            type: 'checkboxgroup',
            id: 'vehicleModel',
            columnInfo: searchConditionInfo.vehicle_model,
            options: getListOptions(this.MASTER_CODE.VEHICLE_MODEL, false),
          },
          {
            // 輸送手段カテゴリ
            type: 'checkboxgroup',
            id: 'vehicleCategory',
            columnInfo: searchConditionInfo.vehicle_category,
            options: getListOptions(this.MASTER_CODE.VEHICLE_CATEGORY, false),
          },
          {
            // 方面名
            type: 'select',
            id: 'tantoArea',
            columnInfo: searchConditionInfo.tanto_area,
            options: this.getListOptionsArea,
            onChangeCallback: () => {},
          },
          {
            // 稼動区分
            type: 'select',
            id: 'vehicleOperationKind',
            columnInfo: searchConditionInfo.vehicle_operation_kind,
            options: getListOptions(this.MASTER_CODE.VEHICLE_OPERATION_KIND),
            onChangeCallback: () => {},
          },
        ],
      }
    },
    resultControlInfo() {
      return this.$store.getters['init/getScreens'](screenId)(
        'result_ctrl_info'
      )
    },
    fileInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'file'
      )
    },
    messageInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'message'
      )
    },
    breadcrumbItems() {
      return [
        {
          name: this.$store.getters['init/getScreenName'](screenId),
        },
      ]
    },
    /**
     * テーブル列定義リスト.
     * @return {Array}
     */
    fields() {
      return this.$store.getters['init/getFields'](screenId)(
        this.DOMAIN_NAME.FIELDS
      )
    },

    getListOptionsArea() {
      return this.areaOptions
    },

    /**
     * ボタン情報リスト.
     * @return {Array}
     */
    buttonItems() {
      return [
        // ボタングループ#1
        [
          // 詳細ボタン
          {
            variant: 'primary',
            icon: 'list-alt',
            columnInfo: this.resultControlInfo.detail,
            disabled: !this.isSelected,
            onClickCallback: () => {
              showCheckModal(this, 'detail')
            },
          },
          // 新規ボタン
          {
            variant: 'primary',
            icon: 'list-alt',
            columnInfo: this.resultControlInfo.new,
            onClickCallback: () => {
              showCheckModal(this, 'new')
            },
          },
          // コピーボタン
          {
            variant: 'primary',
            icon: 'copy',
            columnInfo: this.resultControlInfo.copy,
            disabled: !this.isSelected,
            onClickCallback: () => {
              showCheckModal(this, 'copy')
            },
          },
          // 分割ボタン
          {
            variant: 'primary',
            icon: 'columns',
            columnInfo: this.resultControlInfo.division,
            disabled: !this.isSelected,
            onClickCallback: () => {
              showCheckModal(this, 'divide')
            },
          },
          // マージボタン
          {
            variant: 'primary',
            icon: 'object-group',
            columnInfo: this.resultControlInfo.merge,
            disabled: !this.isSelected,
            onClickCallback: () => {
              showCheckModal(this, 'merge')
            },
          },
        ],
        // ボタングループ#2
        [
          // 一括生成ボタン
          {
            variant: 'primary',
            icon: 'folder-plus',
            columnInfo: this.resultControlInfo.all_generate,
            onClickCallback: () => {
              showCheckModal(this, 'generate')
            },
          },
          // 登録ボタン
          {
            variant: 'primary',
            icon: 'edit',
            columnInfo: this.resultControlInfo.register,
            disabled: this.tableItems.length < 1,
            onClickCallback: () => {
              goRegister(this)
            },
          },
        ],
      ]
    },

    /**
     * 単数選択しているか.
     * @return {Boolean}
     */
    isSelected() {
      return this.selectedItem.length > 0
    },

    checkMessage() {
      return this.$store.getters['init/getMessage']('EE01S001_MG007Q')
    },
  },

  methods: {
    getListOptions,
    openSubModal,
    showCheckModal,
    reSearch,
    search,
    clear,
    goDetail,
    goNew,
    goCopy,
    divide,
    merge,
    doMerge,
    generate,
    doGenerate,
    register,
    /**
     * デフォルト検索パラメータを返します.
     * @return {Object}
     */
    defaultSearchCondition() {
      let today = this.$moment(new Date()).format('YYYY-MM-DD')
      return {
        scheduleBeginDate: today,
        scheduleEndDate: today,
        carrierCode: null,
        carrierName: null,
        vehicleCode: null,
        vehicleName: null,
        vehicleModel: [],
        vehicleCategory: [],
        tantoArea: null,
        vehicleOperationKind: null,
        size: getSearchMaxCount(),
        screenId: screenId,
        domainName: 'result_fields',
      }
    },

    /**
     * 部署マスタから取得データ設定
     */
    afterCloseSetDepartment(id, code, name) {
      this.searchCondition.carrierCode = code
      this.searchCondition.carrierName = name
    },
    /**
     * 部署情報解除
     */
    afterCloseUnsetDepartment() {
      this.searchCondition.carrierCode = null
      this.searchCondition.carrierName = null
    },

    /**
     * 輸送手段参照から取得データ設定
     */
    afterCloseSetVehicle(selectedItem) {
      this.searchCondition.vehicleCode = selectedItem.vehicle_code
      this.searchCondition.vehicleName = selectedItem.vehicle_name
    },
    /**
     * 輸送手段情報解除
     */
    afterCloseUnsetVehicle() {
      this.searchCondition.vehicleCode = null
      this.searchCondition.vehicleName = null
    },

    /**
     * 分割条件指定参照から取得データ設定
     */
    afterCloseSetDivide(divideDate, divideHhmmss) {
      doDivide(this, divideDate, divideHhmmss)
    },

    setErrorMsg(msg) {
      this.$emit('set-error-msg', msg)
    },

    setWarnMsg(msg) {
      this.$emit('set-warn-msg', msg)
    },

    setInfoMsg(msg) {
      this.$emit('set-info-msg', msg)
    },

    onRowSelected(selectedItems) {
      this.selectedItem = selectedItems
    },

    onMaxCountChanged(maxCount) {
      this.searchCondition.size = maxCount
    },
  },
}
</script>

<style scoped>
#footer {
  bottom: 0;
  position: fixed;
  width: 96.8%;
  height: 50px;
  margin-bottom: 20px;
  box-sizing: border-box;
  color: white;
  /* margin-left:300px; */
  text-align: center;
}
</style>
