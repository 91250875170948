import { createAuthenticaionService, makeQueryParams } from '@/services/index'

const BASE_URL = `${process.env.VUE_APP_API_URL}planning-request/`

class AutoPlanningService {
  getAll() {
    return createAuthenticaionService(BASE_URL).get('/')
  }

  get(id) {
    return createAuthenticaionService(BASE_URL).get(`/${id}`)
  }
  /**
   * 中止
   * @param {*} id
   */
  stop(id) {
    return createAuthenticaionService(`${BASE_URL}stop/`).post(`/${id}`)
  }
  /**
   * 開始
   * @param {*} id
   */
  start(data) {
    return createAuthenticaionService(`${BASE_URL}start/`).post('/', data)
  }

  /**
   * 輸送手段グループ情報取得
   * @param {*} id
   */
  getVehicleGroup(id) {
    return createAuthenticaionService(`${BASE_URL}vehicle/`).get(`/${id}`)
  }

  // create(data) {
  //   return createAuthenticaionService(BASE_URL).post('/', data)
  // }

  // update(id, data) {
  //   return createAuthenticaionService(BASE_URL).put(`/${id}`, data)
  // }

  // deleteLogical(id, data) {
  //   return createAuthenticaionService(`${BASE_URL}delete/`).put(`/${id}`, data)
  // }

  // delete(id) {
  //   return createAuthenticaionService(BASE_URL).delete(`/${id}`)
  // }

  // deleteAll() {
  //   return createAuthenticaionService(BASE_URL).delete('/')
  // }

  // removeList(data) {
  //   return createAuthenticaionService(`${BASE_URL}removelist/`).put('/', data)
  // }

  search(searchCondition) {
    const param = makeQueryParams(searchCondition)
    return createAuthenticaionService(`${BASE_URL}search/`).get(`/?${param}`)
  }
}

export default new AutoPlanningService()
