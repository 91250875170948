<template>
  <div>
    <DetailPageLayout
      :breadcrumbItems="breadcrumbItems"
      :buttonItems="buttonItems"
    >
      <template #top>
        <BaseTabGroup
          ref="tab-group"
          :inputModel="formData.searchCondition"
          :inputComponents="inputComponents"
        >
          <template v-slot:tab1TableDown>
            <div id="BaseView" style="margin-top: 20px; min-height: 230px">
              <div>
                <BaseRadio
                  v-model="formData.optValue"
                  :columnInfo="searchConditionInfo.condition"
                  selectedValue="0"
                  :displayLabel="true"
                  @input="setControlEnableStatus(obj)"
                />
                <BaseReferenceCodeName
                  :labelVisible="true"
                  :codeValue.sync="formData.searchCondition.placeCode"
                  :nameValue.sync="formData.searchCondition.placeName"
                  :codeColumnInfo="searchConditionInfo.plan_begin_place_code"
                  :nameColumnInfo="searchConditionInfo.plan_begin_place_name"
                  :btnColumnInfo="searchConditionInfo.sub_plan_begin_place"
                  :codeRules="[INPUT_TYPE_CHECK.ALPHANUMERIC_CODE]"
                  codeMaxLength="10"
                  nameMaxLength="30"
                  @click="() => openSubModal(obj, 'place-modal')"
                />
                <BaseReferenceCodeName
                  :labelVisible="true"
                  :codeValue.sync="formData.searchCondition.carrierCode"
                  :nameValue.sync="formData.searchCondition.carrierName"
                  :codeColumnInfo="searchConditionInfo.carrier_code"
                  :nameColumnInfo="searchConditionInfo.carrier_name"
                  :btnColumnInfo="searchConditionInfo.sub_carrier"
                  :codeRules="[INPUT_TYPE_CHECK.ALPHANUMERIC_CODE]"
                  codeMaxLength="10"
                  nameMaxLength="30"
                  @click="
                    () => {
                      departmentModalSearchCondition = {}
                      departmentModalSearchCondition.departmentKind =
                        DEPARTMENT_KIND.CARRIER
                      openSubModal(obj, 'department-modal')
                    }
                  "
                />
              </div>
              <div style="margin-top: 10px">
                <BaseRadio
                  v-model="formData.optValue"
                  :columnInfo="searchConditionInfo.individual"
                  selectedValue="1"
                  :displayLabel="true"
                  @input="setControlEnableStatus(obj)"
                />
                <div class="row ml-1" v-if="ctrlItems">
                  <BaseButton
                    v-for="(item1, index1) in ctrlItems[0]"
                    :key="index1"
                    class="mr-2"
                    :variant="item1.variant"
                    :icon="item1.icon"
                    :columnInfo="item1.columnInfo"
                    :disabled="item1.disabled"
                    @click="item1.onClickCallback"
                  />
                  <div class="row ml-5">
                    <BaseSelect
                      v-model="selectedVehiclePattern"
                      :columnInfo="resultControlInfo.vehicle_pattern"
                      :options="formData.vehicleGroupOptions"
                    />
                    <BaseButton
                      v-for="(item2, index2) in ctrlItems[1]"
                      :key="index2"
                      class="mr-2"
                      :variant="item2.variant"
                      :icon="item2.icon"
                      :columnInfo="item2.columnInfo"
                      :disabled="item2.disabled"
                      @click="item2.onClickCallback"
                    />
                  </div>
                </div>
                <section data-class="work-list-table" style="margin-top: 5px">
                  <!-- テーブル -->
                  <BaseTable
                    select-mode="range"
                    :fields="fields"
                    :items="formData.tableItems"
                    @row-selected="onRowSelected"
                  />
                </section>
              </div>
            </div>
          </template>
        </BaseTabGroup>
      </template>
    </DetailPageLayout>
    <!-- 部署マスタモーダル -->
    <DepartmentModal
      id="department-modal"
      type="entry"
      selectMode="single"
      :selectedSearchCondition="departmentModalSearchCondition"
      @after-close-set="afterCloseSetDepartment"
      @after-close-unset="afterCloseUnsetDepartment"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />
    <!-- 場所モーダル -->
    <PlaceModal
      id="place-modal"
      type="entry"
      selectMode="single"
      @after-close-set="afterCloseSetPlace"
      @after-close-unset="afterCloseUnsetPlace"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />
    <!-- 輸送手段マスタモーダル -->
    <VehicleModal
      id="vehicle-modal-edit"
      type="entry"
      selectMode="range"
      displayModals="two"
      :useUnset="false"
      @after-close-set="addVehicle"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />
    <!-- 車両パターン登録モーダル -->
    <ManualPlanningVehicleGroupReg
      id="manual-planning-reg-modal"
      type="entry"
      @after-close-set="registerVehicleGroup"
      @set-error-msg="setErrorMsg"
      @set-warn-msg="setWarnMsg"
    />
    <!-- 情報モーダル -->
    <InfoModal
      id="manual-planning-search-info-modal"
      :columnInfos="messageInfo"
      :message="infoMessage"
    />
  </div>
</template>

<script>
import { openSubModal, getListOptions } from '@/common/Common.js'
import { SLOT_NAME } from '@/common/const.js'
import { getBreadcrumbItems, back } from '@/common/screen-common.js'
import {
  init,
  clear,
  search,
  addVehicle,
  remove,
  addVehiclePattern,
  registerVehicleGroup,
  setControlEnableStatus,
} from './ManualPlanningSearch.js'

export default {
  components: {
    DepartmentModal: () => import('@/master/department/DepartmentModal.vue'),
    PlaceModal: () => import('@/master/place/PlaceModal.vue'),
    VehicleModal: () => import('@/master/vehicle/VehicleModal.vue'),
    ManualPlanningVehicleGroupReg: () =>
      import('./ManualPlanningVehicleGroupReg.vue'),
  },

  data() {
    return {
      formData: {
        tableItems: [],
        searchCondition: this.defaultSearchCondition(),
        optValue: '0',
        vehicleGroupOptions: [],
      },
      oriData: {
        tableItems: [],
        searchCondition: this.defaultSearchCondition(),
        optValue: '0',
        vehicleGroupOptions: [],
      },
      selectedItems: [],
      obj: this,
      selectedVehiclePattern: null,
      departmentModalSearchCondition: {},
    }
  },

  props: {
    beforeScreenRouteInfo: {
      type: Object,
    },
    isReSearch: {
      type: Boolean,
      default: false,
    },
    searchDate: {
      type: String,
    },
    searchVehicleIdList: {
      type: Array,
    },
  },

  created() {
    init(this)
  },

  computed: {
    searchConditionInfo() {
      return this.$store.getters['init/getScreens'](
        this.SCREEN_ID.MANUAL_PLANNING_SEARCH
      )('search_condition')
    },
    inputComponents() {
      return [
        {
          // 手動配車検索タブ
          label: this.searchConditionInfo.title.label,
          slot: SLOT_NAME.TAB1_TABLE_DOWN,
          inputComponents: [
            {
              // 稼動日
              type: 'dateFromTo',
              fromId: 'searchBeginDate',
              toId: 'searchEndDate',
              fromColumnInfo: this.searchConditionInfo.search_begin_date,
              toColumnInfo: this.searchConditionInfo.search_end_date,
              locale: this.locale,
              fromRules: [
                this.INPUT_TYPE_CHECK.REQUIRED,
                this.INPUT_TYPE_CHECK.DATERANGE,
              ],
              toRules: [this.INPUT_TYPE_CHECK.REQUIRED],
            },
            // {
            //   // 配車計画グループ
            //   type: 'select',
            //   id: 'planningGroupCode',
            //   columnInfo: this.searchConditionInfo.planning_group_code,
            //   options: getListOptions(this.MASTER_CODE.PLANNING_GROUP),
            //   onChangeCallback: () => {},
            // },
            // {
            //   // 変更通知ステータス
            //   type: 'select',
            //   id: 'changeNoticeStatus',
            //   columnInfo: this.searchConditionInfo.change_notice_status,
            //   options: getListOptions(this.MASTER_CODE.CHANGE_NOTICE_STATUS),
            //   onChangeCallback: () => {},
            // },
            {
              // 配車ステータス
              type: 'checkboxgroup',
              id: 'planningStatus',
              columnInfo: this.searchConditionInfo.planning_status,
              options: getListOptions(this.MASTER_CODE.PLANNING_STATUS, false),
            },
          ],
        },
      ]
    },
    resultControlInfo() {
      return this.$store.getters['init/getScreens'](
        this.SCREEN_ID.MANUAL_PLANNING_SEARCH
      )('result_ctrl_info')
    },
    messageInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'message'
      )
    },
    breadcrumbItems() {
      return getBreadcrumbItems(
        this.beforeScreenRouteInfo,
        this.SCREEN_ID.MANUAL_PLANNING_SEARCH
      )
    },
    /**
     * ボタン情報リスト.
     * @return {Array}
     */
    buttonItems() {
      let buttonGroup1 = []
      buttonGroup1.push(
        // 検索ボタン
        {
          variant: 'success',
          icon: 'edit',
          columnInfo: this.resultControlInfo.search,
          onClickCallback: () => {
            search(this)
          },
        }
      )
      buttonGroup1.push(
        // クリアボタン
        {
          variant: 'success',
          icon: 'times-circle',
          columnInfo: this.resultControlInfo.clear,
          disabled: this.isDisabled,
          onClickCallback: () => {
            clear(this)
          },
        }
      )
      if (this.beforeScreenRouteInfo) {
        buttonGroup1.push(
          // 戻るボタン
          {
            variant: 'success',
            icon: 'times-circle',
            columnInfo: this.resultControlInfo.back,
            onClickCallback: () => {
              back(this)
            },
          }
        )
      }

      return [
        // ボタングループ#1
        buttonGroup1,
        // ボタングループ#2
        [],
      ]
    },

    ctrlItems() {
      return [
        [
          // 車両追加ボタン
          {
            variant: 'success',
            icon: 'plus-circle',
            columnInfo: this.resultControlInfo.add_vehicle,
            onClickCallback: () => {
              openSubModal(this, 'vehicle-modal-edit')
            },
          },
          // 車両削除ボタン
          {
            variant: 'success',
            icon: 'minus-circle',
            disabled: !(this.isVehicleMode && this.isVehicleSelected),
            columnInfo: this.resultControlInfo.remove_vehicle,
            onClickCallback: () => {
              remove(this)
            },
          },
        ],
        [
          // 車両パターン追加ボタン
          {
            variant: 'success',
            icon: 'plus-circle',
            columnInfo: this.resultControlInfo.add_vehicle_pattern,
            disabled: !(this.isVehicleMode && this.isPatternSelected),
            onClickCallback: () => {
              addVehiclePattern(this)
            },
          },
          // 車両パターン登録ボタン
          {
            variant: 'success',
            icon: 'edit',
            disabled: !(this.isVehicleMode && this.isVehicleExist),
            columnInfo: this.resultControlInfo.register_vehicle_pattern,
            onClickCallback: () => {
              if (this.formData.tableItems.length > 0) {
                openSubModal(this, 'manual-planning-reg-modal')
              }
            },
          },
        ],
      ]
    },

    /**
     * テーブル列定義リスト.
     * @return {Array}
     */
    fields() {
      return this.$store.getters['init/getFields'](
        this.SCREEN_ID.MANUAL_PLANNING_SEARCH
      )(this.DOMAIN_NAME.FIELDS)
    },

    /**
     * 情報メッセージ.
     * @return {String}
     */
    infoMessage() {
      return this.$store.getters['init/getMessage'](
        'YZ00MG995I',
        this.resultControlInfo.register_vehicle_pattern.label
      )
    },

    isVehicleMode() {
      return this.formData.optValue === '1'
    },

    isVehicleExist() {
      return this.formData.tableItems.length > 0
    },

    isVehicleSelected() {
      return this.selectedItems.length > 0
    },

    isPatternSelected() {
      return this.selectedVehiclePattern
    },
  },

  methods: {
    openSubModal,
    getListOptions,
    setControlEnableStatus,
    /**
     * デフォルト検索パラメータを返します.
     * @return {Object}
     */
    defaultSearchCondition() {
      let beginDate = this.$moment(new Date()).format('YYYY-MM-DD')
      if (this.searchDate != null) {
        beginDate = this.searchDate
      }
      return {
        searchBeginDate: beginDate,
        searchEndDate: beginDate,
        planningGroupCode: null,
        changeNoticeStatus: null,
        planningStatus: [],
        placeCode: null,
        placeName: null,
        carrierCode: null,
        carrierName: null,
        vehicleIdList: [],
        screenId: this.SCREEN_ID.MANUAL_PLANNING_SEARCH,
        domainName: 'result_fields',
        // 確定、確定解除後の検索処理
        requestId: null, //String Id
      }
    },

    setErrorMsg(msg) {
      this.$emit('set-error-msg', msg)
    },

    setWarnMsg(msg) {
      this.$emit('set-warn-msg', msg)
    },

    onRowSelected(selectedItems) {
      this.selectedItems = selectedItems
    },

    /**
     * 部署マスタから取得データ設定
     */
    afterCloseSetDepartment(id, code, name) {
      this.formData.searchCondition.carrierCode = code
      this.formData.searchCondition.carrierName = name
    },
    /**
     * 部署情報解除
     */
    afterCloseUnsetDepartment() {
      this.formData.searchCondition.carrierCode = null
      this.formData.searchCondition.carrierName = null
    },

    /**
     * 場所マスタから取得データ設定
     */
    afterCloseSetPlace(selectedItem) {
      this.formData.searchCondition.placeCode = selectedItem.place_code
      this.formData.searchCondition.placeName = selectedItem.place_name
    },
    /**
     * 場所情報解除
     */
    afterCloseUnsetPlace() {
      this.formData.searchCondition.placeCode = null
      this.formData.searchCondition.placeName = null
    },

    addVehicle(selectedItems) {
      addVehicle(this, selectedItems)
    },

    registerVehicleGroup(vehicleGroupName, note) {
      registerVehicleGroup(this, vehicleGroupName, note)
    },
  },
}
</script>
