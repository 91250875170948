import {
  openMsgModal,
  openSubModal,
  displayYYYYYMMDDHHmm,
  displaYyyymmdd,
  displayHHmmss,
  formatYYYYYMMDDHHmm,
  formatYYYYMMDD,
  formatHHmmss,
  convertSnakeCace,
  convertCamelCace,
} from '@/common/Common.js'
import { MSG_TYPE, RECORD_WORK_KIND, VISIBILITY } from '@/common/const.js'
import WorkRecordService from './WorkRecordService'
import _ from 'lodash'

export function init(obj) {
  //planningResultId -> planningResultPlaceId
  obj.$store.dispatch('init/setServiceLoading')
  WorkRecordService.get(obj.planningResultPlaceId)
    .then(
      (response) => {
        obj.WorkRecord = response.data

        obj.WorkRecord.departureDateTime = displayYYYYYMMDDHHmm(
          obj,
          obj.WorkRecord.departureDateTime
        )
        obj.WorkRecord.arrivalDateTime = displayYYYYYMMDDHHmm(
          obj,
          obj.WorkRecord.arrivalDateTime
        )

        // 荷積予定日時
        obj.WorkRecord.departureWorkActTime = displayYYYYYMMDDHHmm(
          obj,
          obj.WorkRecord.departureWorkActTime
        )
        // 荷卸予定日時
        obj.WorkRecord.arrivalWorkActTime = displayYYYYYMMDDHHmm(
          obj,
          obj.WorkRecord.arrivalWorkActTime
        )

        // 荷積実績日時
        obj.WorkRecord.departureRealWorkActDate = displaYyyymmdd(
          obj,
          obj.WorkRecord.departureRealWorkActDate
        )
        obj.WorkRecord.departureRealWorkActTime = displayHHmmss(
          obj.WorkRecord.departureRealWorkActTime
        )
        // 荷卸実績日時
        obj.WorkRecord.arrivalRealWorkActDate = displaYyyymmdd(
          obj,
          obj.WorkRecord.arrivalRealWorkActDate
        )
        obj.WorkRecord.arrivalRealWorkActTime = displayHHmmss(
          obj.WorkRecord.arrivalRealWorkActTime
        )

        // 実績作業区分
        if (obj.WorkRecord.workKind == RECORD_WORK_KIND.LOAD) {
          obj.detailInfo.arrival_real_work_act_date.visibility =
            VISIBILITY.DISABLE
          obj.detailInfo.arrival_real_work_act_time.visibility =
            VISIBILITY.DISABLE
        } else {
          obj.detailInfo.departure_real_work_act_date.visibility =
            VISIBILITY.DISABLE
          obj.detailInfo.departure_real_work_act_time.visibility =
            VISIBILITY.DISABLE
        }

        if (obj.WorkRecord.departureRealWorkActDate != null) {
          obj.detailInfo.departure_real_work_act_date.visibility =
            VISIBILITY.DISABLE
        }
        if (obj.WorkRecord.departureRealWorkActTime != null) {
          obj.detailInfo.departure_real_work_act_time.visibility =
            VISIBILITY.DISABLE
        }
        if (obj.WorkRecord.arrivalRealWorkActDate != null) {
          obj.detailInfo.arrival_real_work_act_date.visibility =
            VISIBILITY.DISABLE
        }
        if (obj.WorkRecord.arrivalRealWorkActTime != null) {
          obj.detailInfo.arrival_real_work_act_time.visibility =
            VISIBILITY.DISABLE
        }

        // details
        var details = convertSnakeCace(obj.WorkRecord.planningResults)

        details.forEach((element) => {
          element.departure_begin_date = displaYyyymmdd(
            obj,
            element.departure_begin_date
          )
          element.departure_begin_time = displayHHmmss(
            element.departure_begin_time
          )
          element.departure_end_date = displaYyyymmdd(
            obj,
            element.departure_end_date
          )
          element.departure_end_time = displayHHmmss(element.departure_end_time)
          element.arrival_begin_date = displaYyyymmdd(
            obj,
            element.arrival_begin_date
          )
          element.arrival_begin_time = displayHHmmss(element.arrival_begin_time)
          element.arrival_end_date = displaYyyymmdd(
            obj,
            element.arrival_end_date
          )
          element.arrival_end_time = displayHHmmss(element.arrival_end_time)

          // 荷積実績日時
          element.departure_work_act_time = displayYYYYYMMDDHHmm(
            obj,
            element.departure_work_act_time
          )
          // 荷卸実績日時
          element.arrival_work_act_time = displayYYYYYMMDDHHmm(
            obj,
            element.arrival_work_act_time
          )
        })

        obj.WorkRecord.planningResults = details
        obj.tableItems = details

        obj.oriData = _.cloneDeep(obj.WorkRecord)
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else if (e.response.status == 404) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG996E')
        } else {
          msg = e.response.data.message
        }
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

// 登録
export async function goRegister(obj) {
  const validated = await obj.$refs['tab-group'].$refs[
    'base-tab-group'
  ].validate()
  if (!validated) return

  var errorMsg = checkRegisterItem(obj)
  if (errorMsg != null) {
    openMsgModal(obj, MSG_TYPE.E, errorMsg)
  } else {
    obj.registerMessage = obj.$store.getters['init/getMessage'](
      'YZ00MG994Q',
      obj.detailCtrlInfo.register.label
    )

    if (obj.WorkRecord.deleted == '1') {
      obj.registerMessage = obj.$store.getters['init/getMessage'](
        'YZ00MG905Q',
        obj.detailCtrlInfo.register.label
      )
    }
    openSubModal(obj, 'register-modal')
  }
}

export async function register(obj) {
  obj.$bvModal.hide('register-modal')

  const validated = await obj.$refs['tab-group'].$refs[
    'base-tab-group'
  ].validate()
  if (!validated) return

  let registerData = _.cloneDeep(obj.WorkRecord)

  if (registerData.deleted == '1') {
    registerData.deleted = '0'
  }

  // planningResult
  registerData.planningResults = changeToCamelCaseKey(
    registerData.planningResults
  )

  registerData.departureDateTime = formatYYYYYMMDDHHmm(
    registerData.departureDateTime
  )
  registerData.arrivalDateTime = formatYYYYYMMDDHHmm(
    registerData.arrivalDateTime
  )

  // 荷積予定日時
  registerData.departureWorkActTime = formatYYYYYMMDDHHmm(
    registerData.departureWorkActTime
  )
  // 荷卸予定日時
  registerData.arrivalWorkActTime = formatYYYYYMMDDHHmm(
    registerData.arrivalWorkActTime
  )

  // 荷積実績日時
  registerData.departureRealWorkActDate = formatYYYYMMDD(
    obj,
    registerData.departureRealWorkActDate
  )
  registerData.departureRealWorkActTime = formatHHmmss(
    registerData.departureRealWorkActTime
  )
  // 荷卸実績日時
  registerData.arrivalRealWorkActDate = formatYYYYMMDD(
    obj,
    registerData.arrivalRealWorkActDate
  )
  registerData.arrivalRealWorkActTime = formatHHmmss(
    registerData.arrivalRealWorkActTime
  )

  // //planningResultId -> planningResultPlaceId
  WorkRecordService.update(obj.planningResultPlaceId, registerData).then(
    () => {
      // 情報モーダル表示
      openSubModal(obj, 'work-record-info-modal')
    },
    (e) => {
      // エラーモーダル表示
      var msg
      if (e.response == undefined) {
        msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
      } else {
        msg = e.response.data.message + e.response.data.messageDetail
      }
      openMsgModal(obj, MSG_TYPE.E, msg)
    }
  )
}

export function clear(obj) {
  obj.WorkRecord = _.cloneDeep(obj.oriData)

  // validate reset
  obj.$refs['tab-group'].$refs['base-tab-group'].reset()

  // 配車結果情報
  obj.tableItems = obj.WorkRecord.planningResults || []
}

function changeToCamelCaseKey(items) {
  var camelItems = convertCamelCace(items)

  return camelItems
}

/**
 * 登録ボタン押下 →整合性チェック
 * @param {} obj
 */
function checkRegisterItem(obj) {
  var errorMsg = null

  // 荷積・荷卸区分による実績日付の必須チェック
  var workActDate = null
  var workActDateLabel = null
  if (obj.WorkRecord.workKind == RECORD_WORK_KIND.LOAD) {
    // 荷積実績日時
    workActDate = obj.WorkRecord.departureRealWorkActDate
    workActDateLabel = obj.detailInfo.departure_real_work_act_date.label
  } else {
    // 荷卸実績日時
    workActDate = obj.WorkRecord.arrivalRealWorkActDate
    workActDateLabel = obj.detailInfo.arrival_real_work_act_date.label
  }

  //console.log('workActDate:', workActDate)
  //console.log('workActDateLabel:', workActDateLabel)

  if (!workActDate) {
    //%sを入力してください。
    errorMsg = obj.$store.getters['init/getMessage'](
      'YZ00MG984E',
      workActDateLabel
    )
  }

  return errorMsg
}
