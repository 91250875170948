/**
 * 配車結果情報データ.
 * @class
 * @exports
 */
export default class PlaceOrderInfo {
  //placeOrderId
  planningResultId
  blockName
  departureDateTime
  departurePlaceName
  departurePlaceAddress
  arrivalDateTime
  arrivalPlaceName
  arrivalPlaceAddress
  tripNo
  lastDropNo
  transportRequestDistance //transportDistance
  reason
  needPermitCheck
  evaluationCharge
  productName
  totalQuantity
  totalWeight
  totalVolume
  planningStatusName
  managementVehicleKindName
  planningTargetDate
  planningDecisionDate
  planFlagName
  vehicleOperationId
  vehicleId
  vehicleName
  vehicleModelName
  vehicleCategoryName
  carrierName
  status
  statusName
  //①
  // 承認依頼
  approvalRequestComment
  approvalRequestUser
  approvalRequestUserName
  approvalRequestDatetime
  approvalRequestDatetimeDisplay
  //②
  // 承認
  approvedUser
  approvedUserName
  approvedDatetime
  approvedDatetimeDisplay
  //③
  //承認差戻し
  remandCode
  remandName
  remandComment
  //④
  // 承諾(回答)
  answerUser
  answerUserName
  answerDatetime
  answerDatetimeDisplay
  //⑤
  // 依頼拒否
  refusalCode
  refusalComment

  transportReserve01
  transportReserve02
  transportReserve03
  transportReserve04
  transportReserve05
  transportReserve06
  transportReserve07
  transportReserve08
  transportReserve09
  transportReserve10

  needPermitCheck = null // 許可証チェック要否(0:実施しない,1:実施する)

  //--------------------
  // 実車情報
  //--------------------
  //実車番ID(トラック・トラクタID)
  realVehicleId = null
  //車番(トラック・トラクタ)
  realVehicleNumberplate = null

  //実車番ID(トレーラID)
  realTrailerId = null
  //車番(トレーラ)
  realTrailerNumberplate = null

  planningResult01
  planningResult02
  planningResult03
  planningResult04
  planningResult05
  planningResult06
  planningResult07
  planningResult08
  planningResult09
  planningResult10
  registrationTimeDisplay
  registrationUser
  updateTimeDisplay
  updateUser
  distanceSourceName
  planningResults = []
  costDetails = []
  //許可証
  // 許可証明細 List<PermitRequestDto>
  permitRequests = []
  // 許可証車両 List<PermitVehicleDto>
  permitVehicles = []
  // 許可証場所 List<PermitRoutePlaceDto>
  permitRoutePlaces = []
}
