import {
  openMsgModal,
  openSubModal,
  formatYYYYMMDD,
  formatHHmmss,
  formatYYYYYMMDDHHmm,
  displaYyyymmdd,
  displayHHmmss,
  displayYYYYYMMDDHHmm,
  convertCamelCace,
} from '@/common/Common.js'
import {
  SCREEN_ID,
  MSG_TYPE,
  DETAIL_SCREEN_KBN,
  TRANSPORT_STATUS,
} from '@/common/const.js'
import router from '@/router'
import PlaceOrderService from './PlaceOrderService'
import _ from 'lodash'

const screenId = SCREEN_ID.PLACE_ORDER_LIST
const detailScreenId = SCREEN_ID.PLACE_ORDER_DETAIL

export function init(obj) {
  // サーバーの処理後再検索
  if (obj.isReSearch == true) {
    reSearch(obj)
  }
}

export function reSearch(obj) {
  var searchCondition = obj.$store.getters['search/getSearchCondition'](
    screenId
  )
  if (searchCondition != null) {
    obj.searchCondition = JSON.parse(searchCondition)
    search(obj)
  }
}

/**
 * 場所を検索します.
 * @param {*} obj
 */
export function search(obj) {
  obj.searchCondition.departureBeginDate = formatYYYYMMDD(
    obj,
    obj.searchCondition.departureBeginDate
  )
  obj.searchCondition.departureEndDate = formatYYYYMMDD(
    obj,
    obj.searchCondition.departureEndDate
  )

  obj.searchCondition.arrivalBeginDate = formatYYYYMMDD(
    obj,
    obj.searchCondition.arrivalBeginDate
  )
  obj.searchCondition.arrivalEndDate = formatYYYYMMDD(
    obj,
    obj.searchCondition.arrivalEndDate
  )

  obj.$store.dispatch('init/setServiceLoading')
  PlaceOrderService.search(obj.searchCondition)
    .then(
      (response) => {
        obj.tableItems = response.data.resultList

        obj.tableItems.forEach((element) => {
          element.departure_date = displaYyyymmdd(obj, element.departure_date)
          element.departure_time = displayHHmmss(element.departure_time)
          element.departure_date_time = displayYYYYYMMDDHHmm(
            obj,
            element.departure_date_time
          )
          element.arrival_date = displaYyyymmdd(obj, element.arrival_date)
          element.arrival_time = displayHHmmss(element.arrival_time)
          element.arrival_date_time = displayYYYYYMMDDHHmm(
            obj,
            element.arrival_date_time
          )
          element.real_vehicle_request_time = displayYYYYYMMDDHHmm(
            obj,
            element.real_vehicle_request_time
          )
          element.real_vehicle_entry_time = displayYYYYYMMDDHHmm(
            obj,
            element.real_vehicle_entry_time
          )
          element.planning_target_date = displaYyyymmdd(
            obj,
            element.planning_target_date
          )
          element.planning_decision_date = displaYyyymmdd(
            obj,
            element.planning_decision_date
          )
          element.planning_decision_time = displayHHmmss(
            element.planning_decision_time
          )

          //planningorder
          element.order_departure_begin_date = displaYyyymmdd(
            obj,
            element.order_departure_begin_date
          )
          element.order_departure_begin_time = displayHHmmss(
            element.order_departure_begin_time
          )
          element.order_departure_end_date = displaYyyymmdd(
            obj,
            element.order_departure_end_date
          )
          element.order_departure_end_time = displayHHmmss(
            element.order_departure_end_time
          )

          element.order_arrival_begin_date = displaYyyymmdd(
            obj,
            element.order_arrival_begin_date
          )
          element.order_arrival_begin_time = displayHHmmss(
            element.order_arrival_begin_time
          )
          element.order_arrival_end_date = displaYyyymmdd(
            obj,
            element.order_arrival_end_date
          )
          element.order_arrival_end_time = displayHHmmss(
            element.order_arrival_end_time
          )
        })

        if (obj.tableItems.length < 1) {
          // 警告モーダル表示
          openMsgModal(
            obj,
            MSG_TYPE.W,
            obj.$store.getters['init/getMessage']('YZ00MG907I')
          )
        }
      },
      (e) => {
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message
        }
        // エラーモーダル表示
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })

  var payload = {
    viewId: screenId,
    params: JSON.stringify(obj.searchCondition),
  }
  obj.$store.dispatch('search/setSearchCondition', payload)
}

/**
 * 検索項目をクリアします.
 */
export function clear(obj) {
  obj.searchCondition = obj.defaultSearchCondition()

  var payload = {
    viewId: screenId,
    params: JSON.stringify(obj.searchCondition),
  }
  obj.$store.dispatch('search/setSearchCondition', payload)
}

// 「詳細」ボタンクリック
export function goDetail(obj) {
  if (obj.selectedItem.length != 1) {
    openMsgModal(
      obj,
      MSG_TYPE.E,
      obj.$store.getters['init/getMessage']('YZ00MG901E')
    )
  } else {
    router.push({
      name: detailScreenId,
      params: {
        planningResultId: obj.selectedItem[0].planning_result_id,
        screenKbn: DETAIL_SCREEN_KBN.DETAIL,
        beforeScreenRouteInfo: {
          routeInfo: {
            name: screenId,
            params: { isReSearch: true },
          },
        },
      },
    })
  }
}

// 発注チェック
export function goPlaceOrder(obj) {
  // 複合条件必須チェック
  if (obj.selectedItem.length === 0) {
    openMsgModal(
      obj,
      MSG_TYPE.E,
      obj.$store.getters['init/getMessage']('YZ00MG901E')
    )
    return
  }

  let result = true
  // 論理性(その他)チェック
  obj.selectedItem.some((element) => {
    // 未発注の状態のみ押下可能。
    // 選択行の状態が輸送依頼データが無い場合 OR 輸送依頼データの状態が「拒否（否認）:11」の場合
    //  OR 輸送依頼データの状態が「承認差し戻し:10」の場合
    //「13：自動運賃計算済み」 (add)
    //「14：自動運賃計算失敗」 (add)
    if (
      element.status == null ||
      element.status == TRANSPORT_STATUS.REQUEST_REJECT ||
      element.status == TRANSPORT_STATUS.APPROVE_BACK ||
      element.status == TRANSPORT_STATUS.AUTO_FARE_CALC_DONE ||
      element.status == TRANSPORT_STATUS.AUTO_FARE_CALC_FAIL
    ) {
      // run
    } else {
      //発注済みのため、発注依頼できません。
      openMsgModal(
        obj,
        MSG_TYPE.E,
        obj.$store.getters['init/getMessage']('EI01S001_MG031E')
      )
      result = false
      return true
    }
  })

  if (result) {
    //%s処理を行います。よろしいですか？
    obj.registerMessage = obj.$store.getters['init/getMessage'](
      'YZ00MG994Q',
      obj.resultControlInfo.place_order.label
    )
    obj.event = 'placeOrder'
    openSubModal(obj, 'register-modal')
  }

  if (!result) {
    return
  }
}

// 発注
export function placeOrder(obj) {
  obj.$bvModal.hide('register-modal')

  obj.$store.dispatch('init/setServiceLoading')
  PlaceOrderService.placeOrder(
    changeCamelCase(formatDateTime(obj, obj.selectedItem))
  )
    .then(
      () => {
        //%s件の発注依頼を登録しました。
        obj.infoMessage = obj.$store.getters['init/getMessage'](
          'EI01S001_MG032I',
          obj.selectedItem.length
        )
        // 情報モーダル表示
        openSubModal(obj, 'planning-result-list-info-modal')
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message + e.response.data.messageDetail
        }
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

// 承認チェック
export function goApprove(obj) {
  // 複合条件必須チェック
  if (obj.selectedItem.length != 1) {
    openMsgModal(
      obj,
      MSG_TYPE.E,
      obj.$store.getters['init/getMessage']('YZ00MG901E')
    )
    return
  }

  let result = true
  // 論理性(その他)チェック
  obj.selectedItem.some((element) => {
    // 選択行の状態 = 20(承認待ち(依頼))以外の場合、エラーを出力して以降の処理を実施しない
    if (element.status != TRANSPORT_STATUS.APPROVE_WAIT) {
      //承認依頼ではないため、%sできません。
      openMsgModal(
        obj,
        MSG_TYPE.E,
        obj.$store.getters['init/getMessage'](
          'EI01S001_MG035E',
          obj.resultControlInfo.approve.label
        )
      )
      result = false
      return true
    }
  })

  if (result) {
    //%s処理を行います。よろしいですか？
    obj.registerMessage = obj.$store.getters['init/getMessage'](
      'YZ00MG994Q',
      obj.resultControlInfo.approve.label
    )
    obj.event = 'approve'
    openSubModal(obj, 'register-modal')
  }
}

// 承認
export function approve(obj) {
  obj.$bvModal.hide('register-modal')

  obj.$store.dispatch('init/setServiceLoading')
  PlaceOrderService.approve(
    changeCamelCase(formatDateTime(obj, obj.selectedItem))
  )
    .then(
      () => {
        //%s件の承認を登録しました。
        obj.infoMessage = obj.$store.getters['init/getMessage'](
          'EI01S001_MG036I',
          obj.selectedItem.length
        )
        // 情報モーダル表示
        openSubModal(obj, 'planning-result-list-info-modal')
      },
      (e) => {
        // エラーモーダル表示
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message + e.response.data.messageDetail
        }
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })
}

function changeCamelCase(items) {
  var details = convertCamelCace(items)

  return details
}

/**
 * 値のフォーマット設定
 * @param {*} obj
 * @param {*} items
 */
function formatDateTime(obj, items) {
  let xxItems = _.cloneDeep(items)

  xxItems.forEach((element) => {
    element.departure_date = formatYYYYMMDD(obj, element.departure_date)
    element.departure_time = formatHHmmss(element.departure_time)
    element.departure_date_time = formatYYYYYMMDDHHmm(
      element.departure_date_time
    )
    element.arrival_date = formatYYYYMMDD(obj, element.arrival_date)
    element.arrival_time = formatHHmmss(element.arrival_time)
    element.arrival_date_time = formatYYYYYMMDDHHmm(element.arrival_date_time)
    element.real_vehicle_request_time = formatYYYYYMMDDHHmm(
      element.real_vehicle_request_time
    )
    element.real_vehicle_entry_time = formatYYYYYMMDDHHmm(
      element.real_vehicle_entry_time
    )
    element.planning_target_date = formatYYYYMMDD(
      obj,
      element.planning_target_date
    )
    element.planning_decision_date = formatYYYYMMDD(
      obj,
      element.planning_decision_date
    )
    element.planning_decision_time = formatHHmmss(
      element.planning_decision_time
    )

    //planningOrder
    element.order_departure_begin_date = formatYYYYMMDD(
      obj,
      element.order_departure_begin_date
    )
    element.order_departure_begin_time = formatHHmmss(
      element.order_departure_begin_time
    )
    element.order_departure_end_date = formatYYYYMMDD(
      obj,
      element.order_departure_end_date
    )
    element.order_departure_end_time = formatHHmmss(
      element.order_departure_end_time
    )

    element.order_arrival_begin_date = formatYYYYMMDD(
      obj,
      element.order_arrival_begin_date
    )
    element.order_arrival_begin_time = formatHHmmss(
      element.order_arrival_begin_time
    )
    element.order_arrival_end_date = formatYYYYMMDD(
      obj,
      element.order_arrival_end_date
    )
    element.order_arrival_end_time = formatHHmmss(
      element.order_arrival_end_time
    )
  })

  return xxItems
}
