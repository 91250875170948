<template>
  <div>
    <b-sidebar
      id="sidebar-right"
      right
      shadow
      width="150px"
      v-model="isSidebarOpened"
    >
      <div class="px-3 py-2">
        <b-form-checkbox v-model="isContinuousMode">
          連続モード
        </b-form-checkbox>
        <!-- 割付 -->
        <b-button
          v-if="getVisibleFromColumnInfo(detailCtrlInfo.assign_mode)"
          :disabled="
            getDisableFromColumnInfo(detailCtrlInfo.assign_mode) ||
            !isUnassignedOrderExist
          "
          class="base-button edit-button"
          :pressed.sync="_isAssignMode"
          :variant="toggleAssignVariant"
          @click="editButtonClicked()"
          >{{ detailCtrlInfo.assign_mode.label }}</b-button
        >
        <!-- 割付解除 -->
        <b-button
          v-if="getVisibleFromColumnInfo(detailCtrlInfo.unassign_mode)"
          :disabled="
            getDisableFromColumnInfo(detailCtrlInfo.unassign_mode) ||
            !isDataExist
          "
          class="base-button edit-button"
          :pressed.sync="_isUnassignMode"
          :variant="toggleUnassignVariant"
          @click="editButtonClicked()"
          >{{ detailCtrlInfo.unassign_mode.label }}</b-button
        >
        <!-- 移動 -->
        <b-button
          v-if="getVisibleFromColumnInfo(detailCtrlInfo.move_mode)"
          :disabled="
            getDisableFromColumnInfo(detailCtrlInfo.move_mode) || !isDataExist
          "
          class="base-button edit-button mt-3"
          :pressed.sync="_isMoveMode"
          :variant="toggleMoveVariant"
          @click="editButtonClicked()"
          >{{ detailCtrlInfo.move_mode.label }}</b-button
        >
        <!-- 個別確定 -->
        <b-button
          v-if="getVisibleFromColumnInfo(detailCtrlInfo.fix_mode)"
          :disabled="
            getDisableFromColumnInfo(detailCtrlInfo.fix_mode) || !isDataExist
          "
          class="base-button edit-button mt-3"
          :pressed.sync="_isFixMode"
          :variant="toggleFixVariant"
          @click="editButtonClicked()"
          >{{ detailCtrlInfo.fix_mode.label }}</b-button
        >
        <!-- 個別確定解除 -->
        <b-button
          v-if="getVisibleFromColumnInfo(detailCtrlInfo.unfix_mode)"
          :disabled="
            getDisableFromColumnInfo(detailCtrlInfo.unfix_mode) || !isDataExist
          "
          class="base-button edit-button"
          :pressed.sync="_isUnfixMode"
          :variant="toggleUnfixVariant"
          @click="editButtonClicked()"
          >{{ detailCtrlInfo.unfix_mode.label }}</b-button
        >
        <div class="mt-3" style="color: blue">
          <span>{{ editMessage1 }}</span>
        </div>
        <div class="mt-3" style="color: blue">
          <span>{{ editMessage2 }}</span>
        </div>
        <div class="mt-3">
          <span style="color: red">{{ editErrorMessage }}</span>
        </div>
      </div>
    </b-sidebar>
    <DetailPageLayout
      :breadcrumbItems="breadcrumbItems"
      :buttonItems="buttonItems"
    >
    </DetailPageLayout>
    <div
      v-if="!isMapMode && !isGanttMode"
      :class="{
        chart: true,
        'chart-sidebar-on': this.isSidebarOpened,
        'chart-sidebar-off': !this.isSidebarOpened,
      }"
    >
      <b-card no-body style="padding: 10px">
        <b-form-row class="mb-2 justify-content-between">
          <b-col style="max-width: 480px">
            <BaseDateFromTo
              :labelVisible="true"
              :fromValue="searchCondition.searchBeginDate"
              :toValue="searchCondition.searchEndDate"
              :fromColumnInfo="detailInfo.begin_date"
              :toColumnInfo="detailInfo.end_date"
            />
          </b-col>
          <b-col>
            <!-- 一括選択 -->
            <BaseButton
              class="mr-1"
              variant="primary"
              :disabled="!isDataExist"
              :columnInfo="detailCtrlInfo.select_all"
              @click="checkAll"
            />
            <!-- 一括解除 -->
            <BaseButton
              variant="primary"
              :disabled="!isDataExist"
              :columnInfo="detailCtrlInfo.unselect_all"
              @click="uncheckAll"
            />
            <!-- ソート条件 -->
            <BaseButton
              class="ml-1"
              variant="success"
              :columnInfo="detailCtrlInfo.sort"
              icon="sort-amount-down"
              :disabled="!isDataExist"
              @click="openSort()"
            />
          </b-col>
          <b-col style="max-width: 120px">
            <div
              :class="{
                'state-label': true,
                editing: this.isEditing,
                checked: this.isChecked,
              }"
            >
              <span>
                {{ editStateName }}
              </span>
            </div>
          </b-col>
          <b-col style="max-width: 80px">
            <!-- 編集 -->
            <BaseButton
              v-b-toggle.sidebar-right
              variant="success"
              :columnInfo="detailCtrlInfo.edit"
              icon="list-alt"
              :disabled="!isDataExist"
              style="float: right"
              @click="clearEditMode"
            />
          </b-col>
        </b-form-row>
        <b-form-row class="ml-1 mb-1"> </b-form-row>
        <b-form-row>
          <b-col>
            <div class="lists">
              <div
                class="listSt"
                v-for="(vehicle, index) in vehicleNodeList"
                v-bind:key="vehicle.id"
              >
                <div class="firStyle">
                  <BoxChartCheckBox
                    class="checkSt"
                    v-model="vehicle.checked"
                    :label="index + 1"
                  />
                </div>
                <div class="list-group">
                  <div style="display: inline">
                    <BoxChartItem
                      :boxInfo="vehicle"
                      @click="(obj) => onBoxSelected(obj)"
                    />
                    <div v-for="trip in vehicle.trips" v-bind:key="trip.id">
                      <BoxChartItem
                        v-show="trip.gantt.tripNo !== 0"
                        v-bind:boxInfo="trip"
                        @click="(obj) => onBoxSelected(obj)"
                      />
                      <div v-for="item in trip.items" v-bind:key="item.id">
                        <BoxChartItem
                          :boxInfo="item"
                          @click="(obj) => onBoxSelected(obj)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </b-col>
        </b-form-row>
        <b-form-row style="margin-top: 20px">
          <b-col>
            <!-- 未割付一覧 -->
            <div>
              <a v-b-toggle href="#unassigned-orders-collapse" @click.prevent>{{
                this.detailInfo.unassigned_order.label
              }}</a>
            </div>
            <b-collapse visible id="unassigned-orders-collapse">
              <BaseButton
                class="mb-1"
                style="float: right"
                variant="success"
                :disabled="!isDataExist"
                :columnInfo="detailCtrlInfo.unassigned_order_search"
                @click="openUnassignedOrder()"
              />
              <b-table
                ref="unassigned_order_list"
                class="table table-bordered detail-table"
                bordered
                hover
                no-border-collapse
                responsive
                selectable
                small
                sticky-header
                outlined
                selectMode="range"
                :items="unassignedOrders"
                :fields="unassignedOrderFieldInfo"
                @row-selected="onUnassignedOrderRowSelected"
              >
              </b-table>
            </b-collapse>
            <!-- 割付詳細 -->
            <div>
              <a v-b-toggle href="#assigned-orders-collapse" @click.prevent>{{
                this.detailInfo.detail_order.label
              }}</a>
            </div>
            <b-collapse visible id="assigned-orders-collapse">
              <b-table
                ref="assigned_order_list"
                class="table table-bordered detail-table"
                bordered
                hover
                no-border-collapse
                responsive
                selectable
                small
                sticky-header
                outlined
                selectMode="range"
                :items="assignedOrders"
                :fields="assignedOrderFieldInfo"
                @row-selected="onAssignedOrderRowSelected"
              >
              </b-table>
            </b-collapse>
            <div>{{ this.detailInfo.warning.label }}</div>
            <b-table
              class="table table-bordered detail-table"
              bordered
              hover
              no-border-collapse
              responsive
              selectable
              small
              sticky-header
              outlined
              selectMode="single"
              :items="warnings"
              :fields="warningFieldInfo"
            >
            </b-table>
          </b-col>
          <b-col> </b-col>
        </b-form-row>
      </b-card>
    </div>
    <div v-if="isMapMode" style="padding: 5px">
      <RouteMap :targetVehicleList.sync="targetMapVehicleList"></RouteMap>
    </div>
    <div v-if="isGanttMode" style="padding: 5px">
      <GanttView
        :startDate="formData.startDate"
        :endDate="formData.endDate"
        :ganttChartVehicleList.sync="targetGanttChartVehicleList"
      ></GanttView>
    </div>
    <ManualPlanningEditSort
      id="manual-planning-edit-sort"
      type="entry"
      :selectedSortConditions="selectedSortConditions"
      @after-close-set="afterCloseSetSort"
    />
    <UnassignedOrderSearch
      id="unassigned-order-search"
      type="entry"
      :periodDateFrom="searchCondition.searchBeginDate"
      :periodDateTo="searchCondition.searchEndDate"
      @after-close-set="afterCloseSetUnassignedOrderSearch"
    />
    <!-- 登録モーダル -->
    <ConfirmModal
      id="confirm-modal"
      :columnInfos="messageInfo"
      :message="confirmMessage"
      @confirm="afterConfirmMessage(obj)"
    />
  </div>
</template>
<script>
import {
  SCREEN_ID,
  BOX_ITEM_KIND,
  DECISION_FLAG,
  NODE_FIX_STATUS,
} from '@/common/const.js'
import { openSubModal } from '@/common/Common.js'
import {
  getBreadcrumbItems,
  getVisibleFromColumnInfo,
  getDisableFromColumnInfo,
} from '@/common/screen-common.js'
import {
  init,
  goCloseManualPlanningEdit,
  check,
  showMap,
  gotoGantt,
  goFix,
  goFixNode,
  goUnfix,
  goUnfixNode,
  goRegister,
  afterConfirmMessage,
  makeTripNode,
  makeDepoNode,
  makeDropNode,
  makeDepoStartNode,
  makeDepoEndNode,
  // makeLastDepoNode,
  makeOrderNode,
  changeNodeTypeToDepo,
  changeNodeTypeToLastDepo,
  getNodeFixStatus,
  searchUnassigned,
  sortVehicleNodeList,
  setUnassignedOrderVariant,
  EDIT_STATUS,
  CONFIRM_KIND,
} from './ManualPlanningEdit.js'
const screenId = SCREEN_ID.MANUAL_PLANNING_EDIT

export default {
  name: 'ManualPlanningEdit',
  components: {
    BoxChartItem: () => import('@/plan/manualPlanning/boxchart/BoxChartItem'),
    BoxChartCheckBox: () =>
      import('@/plan/manualPlanning/boxchart/BoxChartCheckBox'),
    ManualPlanningEditSort: () =>
      import('@/plan/manualPlanning/ManualPlanningEditSort'),
    UnassignedOrderSearch: () =>
      import('@/plan/manualPlanning/UnassignedOrderSearch'),
    RouteMap: () => import('@/plan/manualPlanning/RouteMap.vue'),
    GanttView: () => import('@/plan/manualPlanning/gantt/GanttView.vue'),
  },
  props: {
    beforeScreenRouteInfo: {
      type: Object,
    },
    searchCondition: {},
  },
  async mounted() {
    await init(this)
  },
  data() {
    return {
      obj: this,
      isContinuousMode: false,
      isMoveMode: false,
      isUnassignMode: false,
      isAssignMode: false,
      isFixMode: false,
      isUnfixMode: false,
      isMapMode: false,
      isGanttMode: false,
      selectedMoveBox: null,
      unassignedOrders: [],
      assignedOrders: [],
      assignedOrderSourceNodeDictionary: {},
      warnings: [],
      formData: null,
      vehicleNodeList: [],
      editable: false,
      subList: [],
      selectedUnassignedOrders: [],
      selectedAssignedOrders: [],
      sessionId: 0,
      editState: EDIT_STATUS.INITIAL,
      confirmMessage: null,
      confirmKind: CONFIRM_KIND.NONE,
      isSidebarOpened: false,
      registerFromLogic: false,
      searchUnassignedOrderIds: [],
      editedUnassignedOrderIds: [],
      editMessage1: '',
      editMessage2: '',
      editErrorMessage: '',
      checkAssignedInterval: null,
      checkAssignedCount: 0,
      searchConditionUnassign: {},
      targetMapVehicleList: [],
      targetGanttChartVehicleList: [],
      targetFixVehicleList: [],
      targetFixTripIndex: null,
      targetRegisterVehicleList: [],
      sortConditionInfo: this.$store.getters['init/getFields'](
        this.SCREEN_ID.MANUAL_PLANNING_EDIT
      )('sort_condition'),
      selectedSortConditions: [],
    }
  },
  methods: {
    openSubModal,
    goCloseManualPlanningEdit,
    check,
    showMap,
    gotoGantt,
    goFix,
    goUnfix,
    afterConfirmMessage,
    getVisibleFromColumnInfo,
    getDisableFromColumnInfo,
    sortVehicleNodeList,
    setUnassignedOrderVariant,

    editButtonClicked() {
      this.clearBoxSelectedStyle()
    },

    findVehicleNode(vehicleIndex) {
      return this.vehicleNodeList.find(
        (element) => element.vehicleIndex === vehicleIndex
      )
    },

    checkAll() {
      this.clearBoxSelectedStyle()
      this.vehicleNodeList.forEach((vehicleNode) => {
        vehicleNode.checked = true
      })
    },

    uncheckAll() {
      this.clearBoxSelectedStyle()
      this.vehicleNodeList.forEach((vehicleNode) => {
        vehicleNode.checked = false
      })
    },

    openSort() {
      this.clearBoxSelectedStyle()
      this.openSubModal(this, 'manual-planning-edit-sort')
    },

    openUnassignedOrder() {
      this.clearBoxSelectedStyle()
      this.openSubModal(this, 'unassigned-order-search')
    },

    clearEditMode() {
      this.clearBoxSelectedStyle()
      this.isMoveMode = false
      this.isUnassignMode = false
      this.isAssignMode = false
      this.isFixMode = false
      this.isUnfixMode = false
      this.selectedMoveBox = null
      this.clearEditMessage()
    },

    clearEditMessage() {
      this.editMessage1 = ''
      this.editMessage2 = ''
      this.editErrorMessage = ''
    },

    showDetailOrder() {},
    showUnassignedOrder() {},
    showStockArea() {},

    clearAssignedOrders() {
      this.assignedOrderSourceNodeDictionary = {}
      this.assignedOrders = []
    },
    addAssignedOrdersFromItemNode(item) {
      item.orderNodes.forEach((orderNode) => {
        this.assignedOrders.push(orderNode.order)
        this.assignedOrderSourceNodeDictionary[
          orderNode.order.planning_order_id
        ] = item
      })
    },
    removeAssignedOrders(orders) {
      orders.forEach((order) => {
        this.removeAssignedOrder(order)
      })
    },
    removeAssignedOrder(order) {
      let orderIndex = this.findOrderIndexInOrders(this.assignedOrders, order)
      this.assignedOrders.splice(orderIndex, 1)
      delete this.assignedOrderSourceNodeDictionary[order.planning_order_id]
    },

    displayAssignedOrders(boxInfo) {
      this.clearAssignedOrders()
      if (
        boxInfo.type === BOX_ITEM_KIND.LastDepo ||
        boxInfo.type === BOX_ITEM_KIND.Depo ||
        boxInfo.type === BOX_ITEM_KIND.Drop
      ) {
        this.addAssignedOrdersFromItemNode(boxInfo)
      } else if (boxInfo.type === BOX_ITEM_KIND.Trip) {
        let trip = boxInfo
        trip.items.forEach((item) => {
          if (item.type === BOX_ITEM_KIND.Drop) {
            this.addAssignedOrdersFromItemNode(item)
          }
        })
      } else if (boxInfo.type === BOX_ITEM_KIND.Vehicle) {
        let vehicle = boxInfo
        vehicle.trips.forEach((trip) => {
          trip.items.forEach((item) => {
            if (item.type === BOX_ITEM_KIND.Drop) {
              this.addAssignedOrdersFromItemNode(item)
            }
          })
        })
      }
    },

    clearBoxSelectedStyle() {
      this.vehicleNodeList.forEach((vehicleNode) => {
        vehicleNode.selected = false
        vehicleNode.trips.forEach((trip) => {
          trip.selected = false
          trip.items.forEach((item) => {
            item.selected = false
          })
        })
      })
    },

    onBoxSelected(boxInfo) {
      console.log('onBoxSelected', boxInfo)
      this.clearBoxSelectedStyle()
      boxInfo.selected = true

      if (!this.isMoveMode) {
        // 割付情報表示
        this.displayAssignedOrders(boxInfo)
      }

      if (this.isMoveMode) {
        // 移動モード
        this.moveBox(boxInfo)
      } else if (this.isUnassignMode) {
        // 割付解除モード
        this.unassignBox(boxInfo)
      } else if (this.isAssignMode) {
        // 割付モード
        this.assignOrders(this.selectedUnassignedOrders, boxInfo)
      } else if (this.isFixMode) {
        // 確定モード
        this.fixNode(boxInfo)
      } else if (this.isUnfixMode) {
        // 確定解除モード
        this.unfixNode(boxInfo)
      }
    },

    getSelectedBoxMessage(boxInfo) {
      console.log(boxInfo)
      let boxMessage = ''
      if (boxInfo.type === BOX_ITEM_KIND.Vehicle) {
        // (輸送手段) vehicleName
        boxMessage = this.$store.getters['init/getMessage'](
          'D006_20_SELECTED_VEHICLE',
          boxInfo.vehicle.vehicleName
        )
      } else if (boxInfo.type === BOX_ITEM_KIND.Trip) {
        // (トリップ) vehicleName トリップ+tripNo
        boxMessage = this.$store.getters['init/getMessage'](
          'D006_20_SELECTED_TRIP',
          boxInfo.vehicle.vehicleName,
          boxInfo.gantt.tripNo
        )
      } else if (boxInfo.type === BOX_ITEM_KIND.Depo) {
        // (荷積) vehicleName トリップ+tripNo placeName
        boxMessage = this.$store.getters['init/getMessage'](
          'D006_20_SELECTED_DEPO',
          boxInfo.vehicle.vehicleName,
          boxInfo.gantt.tripNo,
          boxInfo.gantt.placeName
        )
      } else if (boxInfo.type === BOX_ITEM_KIND.Drop) {
        // (荷卸) vehicleName トリップ+tripNo placeName
        boxMessage = this.$store.getters['init/getMessage'](
          'D006_20_SELECTED_DROP',
          boxInfo.vehicle.vehicleName,
          boxInfo.gantt.tripNo,
          boxInfo.gantt.placeName
        )
      } else if (boxInfo.type === BOX_ITEM_KIND.DepoStart) {
        // (出発) vehicleName
        boxMessage = this.$store.getters['init/getMessage'](
          'D006_20_SELECTED_DEPO_START',
          boxInfo.vehicle.vehicleName
        )
      } else if (boxInfo.type === BOX_ITEM_KIND.DepoEnd) {
        // (到着) vehicleName
        boxMessage = this.$store.getters['init/getMessage'](
          'D006_20_SELECTED_DEPO_END',
          boxInfo.vehicle.vehicleName
        )
      } else if (boxInfo.type === BOX_ITEM_KIND.LastDepo) {
        // (宵積) vehicleName placeName
        boxMessage = this.$store.getters['init/getMessage'](
          'D006_20_SELECTED_LAST_DEPO',
          boxInfo.vehicle.vehicleName,
          boxInfo.gantt.placeName
        )
      }
      return boxMessage
    },

    getSelectedOrdersMessage(orders) {
      let message = ''
      orders.forEach((order) => {
        if (message) {
          message += ', '
        }
        message += order.planning_order_id
      })
      // (オーダー) order_id,...
      return this.$store.getters['init/getMessage'](
        'D006_20_SELECTED_ORDER',
        message
      )
    },

    setEditMessageAssign() {
      this.clearEditMessage()
      if (this.selectedUnassignedOrders.length > 0) {
        this.setEditMessageAssignFromUnassignedOrders(
          this.selectedUnassignedOrders
        )
      } else {
        // 未割付一覧から割付するオーダーを選択してください。
        this.editMessage1 = this.$store.getters['init/getMessage'](
          'D006_20_ASSIGN_SOURCE_UNSELECTED'
        )
      }
    },
    setEditMessageAssignFromUnassignedOrders(orders) {
      // 割付：(オーダー) \n' + order_id,...
      this.editMessage1 = this.$store.getters['init/getMessage'](
        'D006_20_ASSIGN_SOURCE',
        this.getSelectedOrdersMessage(orders)
      )

      // 割付先の輸送手段、トリップ、荷積、又は荷卸を選択してください。
      this.editMessage2 = this.$store.getters['init/getMessage'](
        'D006_20_ASSIGN_TARGET_UNSELECTED'
      )
    },
    setEditMessageFromAssignTargetBox(boxInfo) {
      // 割付先：BoxMessage
      this.editMessage2 = this.$store.getters['init/getMessage'](
        'D006_20_ASSIGN_TARGET',
        this.getSelectedBoxMessage(boxInfo)
      )
    },

    setEditMessageUnassign() {
      this.clearEditMessage()
      // 割付解除する輸送手段、トリップ、荷積、荷卸、又は割付詳細のオーダーを選択してください。
      this.editMessage1 = this.$store.getters['init/getMessage'](
        'D006_20_UNASSIGN_SOURCE_UNSELECTED'
      )
    },
    setEditMessageFromAssignedOrders(orders) {
      // 割付解除：OrdersMessage
      this.editMessage1 = this.$store.getters['init/getMessage'](
        'D006_20_UNASSIGN_SOURCE',
        this.getSelectedOrdersMessage(orders)
      )
    },
    setEditMessageFromUnassignBox(boxInfo) {
      // 割付解除：BoxMessage
      this.editMessage1 = this.$store.getters['init/getMessage'](
        'D006_20_UNASSIGN_SOURCE',
        this.getSelectedBoxMessage(boxInfo)
      )
    },

    setEditMessageMove() {
      this.clearEditMessage()
      // 移動元の輸送手段、トリップ、荷積、荷卸、宵積、又は割付詳細のオーダーを選択してください。
      this.editMessage1 = this.$store.getters['init/getMessage'](
        'D006_20_MOVE_SOURCE_UNSELECTED'
      )
    },
    setEditMessageMoveFromAssignedOrders(orders) {
      // 移動元：OrdersMessage
      this.editMessage1 = this.$store.getters['init/getMessage'](
        'D006_20_MOVE_SOURCE',
        this.getSelectedOrdersMessage(orders)
      )
      // 移動先の輸送手段、トリップ、荷積、又は荷卸を選択してください。
      this.editMessage2 = this.$store.getters['init/getMessage'](
        'D006_20_MOVE_TARGET_UNSELECTED'
      )
    },
    setEditMessageFromMoveBox(boxInfo) {
      // 移動元：BoxMessage
      this.editMessage1 = this.$store.getters['init/getMessage'](
        'D006_20_MOVE_SOURCE',
        this.getSelectedBoxMessage(boxInfo)
      )
      // 移動先の輸送手段、トリップ、荷積、又は荷卸を選択してください。
      this.editMessage2 = this.$store.getters['init/getMessage'](
        'D006_20_MOVE_TARGET_UNSELECTED'
      )
    },
    setEditMessageFromMoveTargetBox(boxInfo) {
      // 移動先：BoxMessage
      this.editMessage2 = this.$store.getters['init/getMessage'](
        'D006_20_MOVE_TARGET',
        this.getSelectedBoxMessage(boxInfo)
      )
    },

    setEditMessageFix() {
      this.clearEditMessage()
      // 確定する輸送手段、トリップ、荷積、又は荷卸を選択してください。
      this.editMessage1 = this.$store.getters['init/getMessage'](
        'D006_20_FIX_SOURCE_UNSELECTED'
      )
    },
    setEditMessageFromFixBox(boxInfo) {
      // 確定：BoxMessage
      this.editMessage2 = this.$store.getters['init/getMessage'](
        'D006_20_FIX_SOURCE',
        this.getSelectedBoxMessage(boxInfo)
      )
    },

    setEditMessageUnfix() {
      this.clearEditMessage()
      // 確定解除する輸送手段、トリップ、荷積、又は荷卸を選択してください。
      this.editMessage1 = this.$store.getters['init/getMessage'](
        'D006_20_UNFIX_SOURCE_UNSELECTED'
      )
    },
    setEditMessageFromUnfixBox(boxInfo) {
      // 確定解除：BoxMessage
      this.editMessage2 = this.$store.getters['init/getMessage'](
        'D006_20_UNFIX_SOURCE',
        this.getSelectedBoxMessage(boxInfo)
      )
    },

    assignOrders(selectedOrders, boxInfo) {
      if (selectedOrders.length === 0) {
        return
      }
      this.setEditMessageFromAssignTargetBox(boxInfo)

      if (!this.checkCanAssignTargetBox(boxInfo)) {
        this.isAssignMode = false
        return
      }

      console.log('assignOrders', selectedOrders, boxInfo)
      let isEdited = false
      if (boxInfo.type === BOX_ITEM_KIND.Vehicle) {
        isEdited = this.assignOrdersToVehicle(selectedOrders, boxInfo)
      } else if (boxInfo.type === BOX_ITEM_KIND.Trip) {
        isEdited = this.assignOrdersToTrip(selectedOrders, boxInfo)
      } else if (
        boxInfo.type === BOX_ITEM_KIND.Depo ||
        boxInfo.type === BOX_ITEM_KIND.Drop
      ) {
        isEdited = this.assignOrdersToItem(selectedOrders, boxInfo)
      }
      if (isEdited) {
        selectedOrders.forEach((order) => {
          let orderIndex = this.findOrderIndexInOrders(
            this.unassignedOrders,
            order
          )
          this.unassignedOrders.splice(orderIndex, 1)
        })
      }
      if (isEdited) {
        this.clearEditMode()
        this.clearAssignedOrders()
        if (this.isContinuousMode && this.unassignedOrders.length > 0) {
          this._isAssignMode = true
        }
      } else {
        this.isAssignMode = false
      }
    },

    unassignBox(boxInfo) {
      this.setEditMessageFromUnassignBox(boxInfo)
      if (!this.checkCanUnassignNode(boxInfo)) {
        this.isUnassignMode = false
        return
      }

      if (boxInfo.type === BOX_ITEM_KIND.Vehicle) {
        this.unassignVehicle(boxInfo)
      } else if (boxInfo.type === BOX_ITEM_KIND.Trip) {
        this.unassignTrip(boxInfo)
      } else if (
        boxInfo.type === BOX_ITEM_KIND.Depo ||
        boxInfo.type === BOX_ITEM_KIND.Drop
      ) {
        this.unassignItem(boxInfo)
      }
      this.clearEditMode()
      this.clearAssignedOrders()
      if (this.isContinuousMode) {
        this._isUnassignMode = true
      }
    },

    unassignOrders(orders) {
      this.setEditMessageFromAssignedOrders(orders)
      if (!this.checkCanUnassignOrders(orders)) {
        this.isUnassignMode = false
        return
      }

      orders.forEach((order) => {
        let itemNode = this.assignedOrderSourceNodeDictionary[
          order.planning_order_id
        ]
        if (itemNode.orderNodes.length === 1) {
          this.unassignItem(itemNode)
        } else {
          this.unassignOrderInItem(itemNode, order)
        }
        this.removeAssignedOrder(order)
      })

      this.clearEditMode()
      if (this.isContinuousMode) {
        this._isUnassignMode = true
      }
    },

    moveBox(boxInfo) {
      if (
        this.selectedMoveBox === null &&
        this.selectedAssignedOrders.length === 0
      ) {
        // 移動元
        this.setEditMessageFromMoveBox(boxInfo)
        if (!this.checkCanMoveMoveBox(boxInfo)) {
          this.editMessage2 = ''
          this.isMoveMode = false
          return
        }
        this.selectedMoveBox = boxInfo
      } else {
        // 移動先
        this.setEditMessageFromMoveTargetBox(boxInfo)
        if (!this.checkCanMoveTargetBox(boxInfo)) {
          this.isMoveMode = false
          this.selectedMoveBox = null
          return
        }
        if (this.selectedAssignedOrders.length > 0) {
          if (
            !this.checkCanMoveOrdersAndTargetBox(
              this.selectedAssignedOrders,
              boxInfo
            )
          ) {
            this.isMoveMode = false
            return
          }

          // 移動
          let targetBox = boxInfo
          let orders = this.selectedAssignedOrders
          let isEdited = false

          if (targetBox.type === BOX_ITEM_KIND.Vehicle) {
            isEdited = this.moveOrdersToVehicle(orders, targetBox)
          } else if (targetBox.type === BOX_ITEM_KIND.Trip) {
            isEdited = this.moveOrdersToTrip(orders, targetBox)
          } else if (
            targetBox.type === BOX_ITEM_KIND.Depo ||
            targetBox.type === BOX_ITEM_KIND.Drop
          ) {
            isEdited = this.moveOrdersToItem(orders, targetBox)
          }
          if (isEdited) {
            this.clearEditMode()
            if (this.isContinuousMode) {
              this._isMoveMode = true
            }
          } else {
            this.isMoveMode = false
            this.selectedMoveBox = null
          }
        } else {
          if (
            !this.checkCanMoveMoveBoxAndTargetBox(this.selectedMoveBox, boxInfo)
          ) {
            this.isMoveMode = false
            this.selectedMoveBox = null
            return
          }

          // 移動
          let targetBox = boxInfo
          let moveBox = this.selectedMoveBox
          let isEdited = false

          if (targetBox.type === BOX_ITEM_KIND.Vehicle) {
            if (moveBox.type === BOX_ITEM_KIND.Vehicle) {
              isEdited = this.moveVehicleToVehicle(moveBox, targetBox)
            } else if (moveBox.type === BOX_ITEM_KIND.Trip) {
              isEdited = this.moveTripToVehicle(moveBox, targetBox)
            } else if (
              moveBox.type === BOX_ITEM_KIND.Depo ||
              moveBox.type === BOX_ITEM_KIND.Drop
            ) {
              isEdited = this.moveItemToVehicle(moveBox, targetBox)
            }
          } else if (targetBox.type === BOX_ITEM_KIND.Trip) {
            if (moveBox.type === BOX_ITEM_KIND.Vehicle) {
              isEdited = this.moveVehicleToTrip(moveBox, targetBox)
            } else if (moveBox.type === BOX_ITEM_KIND.Trip) {
              isEdited = this.moveTripToTrip(moveBox, targetBox)
            } else if (
              moveBox.type === BOX_ITEM_KIND.Depo ||
              moveBox.type === BOX_ITEM_KIND.Drop
            ) {
              isEdited = this.moveItemToTrip(moveBox, targetBox)
            } else if (moveBox.type === BOX_ITEM_KIND.LastDepo) {
              isEdited = this.changeLastDepoToDepo(moveBox)
            }
          } else if (
            targetBox.type === BOX_ITEM_KIND.Depo ||
            targetBox.type === BOX_ITEM_KIND.Drop
          ) {
            if (moveBox.type === BOX_ITEM_KIND.Vehicle) {
              isEdited = this.moveVehicleToItem(moveBox, targetBox)
            } else if (moveBox.type === BOX_ITEM_KIND.Trip) {
              isEdited = this.moveTripToItem(moveBox, targetBox)
            } else if (
              moveBox.type === BOX_ITEM_KIND.Depo ||
              moveBox.type === BOX_ITEM_KIND.Drop
            ) {
              isEdited = this.moveItemToItem(moveBox, targetBox)
            }
          } else if (targetBox.type === BOX_ITEM_KIND.DepoStart) {
            if (moveBox.type === BOX_ITEM_KIND.Depo) {
              isEdited = this.changeDepoToLastDepo(moveBox)
            }
          }
          if (isEdited) {
            this.clearEditMode()
            this.clearAssignedOrders()
            if (this.isContinuousMode) {
              this._isMoveMode = true
            }
          } else {
            this.isMoveMode = false
            this.selectedMoveBox = null
          }
        }
      }
    },

    fixNode(boxInfo) {
      this.setEditMessageFromFixBox(boxInfo)
      let selectedTripIndex = null
      let vehicleNode = this.findVehicleNode(boxInfo.vehicleIndex)

      if (boxInfo.type === BOX_ITEM_KIND.Vehicle) {
        // OK
      } else if (
        boxInfo.type === BOX_ITEM_KIND.Trip ||
        boxInfo.type === BOX_ITEM_KIND.Depo ||
        boxInfo.type === BOX_ITEM_KIND.Drop
      ) {
        selectedTripIndex = boxInfo.tripIndex
        // OK
      } else {
        // 輸送手段、トリップ、荷積、又は荷卸ボックスを選択してください。
        this.editErrorMessage = this.$store.getters['init/getMessage'](
          'D006_20_FIX_INVALID_TYPE'
        )
        this.isFixMode = false
        return
      }

      this.targetFixVehicleList = []
      this.targetFixVehicleList.push(vehicleNode)
      this.targetFixTripIndex = selectedTripIndex

      goFixNode(this)
      this.clearEditMode()
    },

    unfixNode(boxInfo) {
      this.setEditMessageFromUnfixBox(boxInfo)
      let selectedTripIndex = null
      let vehicleNode = this.findVehicleNode(boxInfo.vehicleIndex)
      if (boxInfo.type === BOX_ITEM_KIND.Vehicle) {
        // OK
      } else if (
        boxInfo.type === BOX_ITEM_KIND.Trip ||
        boxInfo.type === BOX_ITEM_KIND.Depo ||
        boxInfo.type === BOX_ITEM_KIND.Drop
      ) {
        selectedTripIndex = boxInfo.tripIndex
        // OK
      } else {
        // 輸送手段、トリップ、荷積、又は荷卸ボックスを選択してください。
        this.editErrorMessage = this.$store.getters['init/getMessage'](
          'D006_20_UNFIX_INVALID_TYPE'
        )
        this.isUnfixMode = false
        return
      }

      this.targetFixVehicleList = []
      this.targetFixVehicleList.push(vehicleNode)
      this.targetFixTripIndex = selectedTripIndex

      goUnfixNode(this)
      this.clearEditMode()
    },

    assignOrdersToVehicle(orders, targetVehicle) {
      console.log('assignOrdersToVehicle', orders, targetVehicle)
      // 最後尾に新規トリップを作成し、オーダーを格納

      // 確定輸送手段
      if (targetVehicle.gantt.decisionFlag === DECISION_FLAG.FIXED) {
        // 確定済の為編集できません。
        this.editErrorMessage = this.$store.getters['init/getMessage'](
          'D006_20_EDITING_FIXED'
        )
        return false
      }

      // 新規トリップを作成
      let newTrip = this.makeTripFromOrders(targetVehicle, orders)

      // 作成したトリップを移動先輸送手段に追加
      let newTrips = []
      newTrips.push(newTrip)
      this.addTripsToVehicle(newTrips, targetVehicle)

      // 後処理
      this.resetVehicleIndexToOwnTrips(targetVehicle)
      this.resetTripNoAndIndexToVehicle(targetVehicle)
      targetVehicle.edited = true
      this.editState = EDIT_STATUS.EDITING

      return true
    },

    assignOrdersToTrip(orders, targetTrip) {
      console.log('assignOrdersToTrip', orders, targetTrip)
      // 移動先トリップの前に新規トリップを作成し、追加

      // 対象特定
      let targetVehicle = this.findVehicleNode(targetTrip.vehicleIndex)

      // 処理可否チェック
      // 確定輸送手段
      if (targetVehicle.gantt.decisionFlag === DECISION_FLAG.FIXED) {
        // 確定済の為編集できません。
        this.editErrorMessage = this.$store.getters['init/getMessage'](
          'D006_20_EDITING_FIXED'
        )
        return false
      }
      // 確定トリップ
      if (targetTrip.gantt.decisionFlag === DECISION_FLAG.FIXED) {
        // 確定済の為編集できません。
        this.editErrorMessage = this.$store.getters['init/getMessage'](
          'D006_20_EDITING_FIXED'
        )
        return false
      }

      // 新規トリップを作成
      let newTrip = this.makeTripFromOrders(targetVehicle, orders)

      // 作成したトリップを移動先トリップの前に追加
      targetVehicle.trips.splice(targetTrip.tripIndex, 0, newTrip)

      // 後処理
      this.resetVehicleIndexToOwnTrips(targetVehicle)
      this.resetTripNoAndIndexToVehicle(targetVehicle)
      targetVehicle.edited = true
      this.editState = EDIT_STATUS.EDITING

      return true
    },

    assignOrdersToItem(orders, targetItem) {
      console.log('assignOrdersToItem', orders, targetItem)
      // 荷積へ割付
      //   - 荷積：指定位置に追加
      //   - 荷卸：移動先トリップの荷卸の末尾に追加
      // 荷卸へ割付
      //   - 荷積：移動先トリップの荷積の末尾に追加
      //   - 荷卸：指定位置に追加

      let targetVehicle = this.findVehicleNode(targetItem.vehicleIndex)
      let targetTrip = targetVehicle.trips[targetItem.tripIndex]

      // 処理可否チェック
      // 確定輸送手段
      if (targetVehicle.gantt.decisionFlag === DECISION_FLAG.FIXED) {
        // 確定済の為編集できません。
        this.editErrorMessage = this.$store.getters['init/getMessage'](
          'D006_20_EDITING_FIXED'
        )
        return false
      }
      // 確定トリップ
      if (targetTrip.gantt.decisionFlag === DECISION_FLAG.FIXED) {
        // 確定済の為編集できません。
        this.editErrorMessage = this.$store.getters['init/getMessage'](
          'D006_20_EDITING_FIXED'
        )
        return false
      }

      // 本処理
      // 積卸を生成
      let insertItems = this.makeDepoDropItemsFromOrders(
        targetVehicle,
        targetTrip,
        orders
      )
      // 移動先トリップへ挿入
      this.insertItemsToTrip(insertItems, targetItem)

      // 後処理
      this.resetVehicleIndexToOwnTrips(targetVehicle)
      this.resetTripNoAndIndexToVehicle(targetVehicle)
      targetVehicle.edited = true
      this.editState = EDIT_STATUS.EDITING

      return true
    },

    unassignVehicle(vehicle) {
      console.log('unassignVehicle', vehicle.vehicleIndex)
      vehicle.trips.forEach((trip) => {
        if (!this.isDepartureTrip(trip) && !this.isArrivalTrip(trip)) {
          trip.items.forEach((item) => {
            if (item.type === BOX_ITEM_KIND.Drop) {
              item.orderNodes.forEach((orderNode) => {
                this.unassignedOrders.push(orderNode.order)
              })
            }
          })
        }
      })
      setUnassignedOrderVariant(this)
      vehicle.trips = []
      // 後処理
      this.clearAfterEdit(vehicle)
      vehicle.edited = true
      this.editState = EDIT_STATUS.EDITING
    },

    unassignTrip(trip) {
      this.removeTrip(trip, true)
    },

    removeTrip(trip, moveToUnassignedOrders = false) {
      let vehicle = this.findVehicleNode(trip.vehicleIndex)
      let tripIndex = trip.tripIndex
      console.log('unassignTrip', tripIndex)
      let unassignedTrip = vehicle.trips.splice(tripIndex, 1)[0]
      if (moveToUnassignedOrders) {
        unassignedTrip.items.forEach((item) => {
          if (item.type === BOX_ITEM_KIND.Drop) {
            item.orderNodes.forEach((orderNode) => {
              this.unassignedOrders.push(orderNode.order)
            })
          }
        })
        setUnassignedOrderVariant(this)
      }
      // 後処理
      this.clearAfterEdit(vehicle)
      vehicle.edited = true
      this.editState = EDIT_STATUS.EDITING
    },

    unassignItem(item) {
      this.removeItem(item, true)
    },

    removeItem(item, moveToUnassignedOrders = false) {
      let vehicle = this.findVehicleNode(item.vehicleIndex)
      let tripIndex = item.tripIndex
      let trip = vehicle.trips[item.tripIndex]
      let pairItemIndexes = this.findPairItemIndexes(item)
      let orderIdList = this.getOrderIdListInItem(item)
      let hasOtherOrdersInTrip = this.hasOtherOrdersInTrip(orderIdList, trip)

      // 本処理
      if (!hasOtherOrdersInTrip) {
        // トリップ内に他の積卸が存在しない場合、トリップを割付解除
        this.removeTrip(trip, moveToUnassignedOrders)
      } else {
        pairItemIndexes.reverse().forEach((itemIndex) => {
          let currentItem = trip.items[itemIndex]
          if (this.hasOtherOrdersInItem(orderIdList, currentItem)) {
            // 積卸に他のオーダーが存在する場合、該当オーダーのみ切出す
            let moveOrderIndexes = this.findOrderIndexesInItemFromOrderIds(
              currentItem,
              orderIdList
            )
            moveOrderIndexes.reverse().forEach((orderIndex) => {
              let orderNode = currentItem.orderNodes.splice(orderIndex, 1)[0]
              if (moveToUnassignedOrders) {
                if (currentItem.type === BOX_ITEM_KIND.Drop) {
                  // オーダーは、積と卸の両方に付いているので、片方のみ処理する
                  this.unassignedOrders.push(orderNode.order)
                }
              }
            })
          } else {
            // 積卸に他のオーダーが存在しない場合、積卸を切出す
            let itemInTrip = trip.items.splice(itemIndex, 1)[0]
            if (moveToUnassignedOrders) {
              if (itemInTrip.type === BOX_ITEM_KIND.Drop) {
                // オーダーは、積と卸の両方に付いているので、片方のみ処理する
                itemInTrip.orderNodes.forEach((orderNode) => {
                  this.unassignedOrders.push(orderNode.order)
                })
              }
            }
          }
        })
        setUnassignedOrderVariant(this)
        // 後処理
        if (trip.items.length === 0) {
          vehicle.trips.splice(tripIndex, 1)
        }
        this.clearAfterEdit(vehicle)
        vehicle.edited = true
        this.editState = EDIT_STATUS.EDITING
      }
    },

    unassignOrderInItem(item, order) {
      this.removeOrderInItem(item, order, true)
    },

    removeOrderInItem(item, order, moveToUnassignedOrders = false) {
      let vehicle = this.findVehicleNode(item.vehicleIndex)
      let trip = vehicle.trips[item.tripIndex]

      // 本処理
      // 該当オーダーを切出す
      let orderIndex = this.findOrderIndexInItemFromOrder(item, order)
      item.orderNodes.splice(orderIndex, 1)
      // 荷積からオーダーを切出す
      let pairItemIndex = this.findPairItemIndexFromOrder(item, order)
      if (pairItemIndex >= 0) {
        let pairItem = trip.items[pairItemIndex]
        if (pairItem.orderNodes.length === 1) {
          trip.items.splice(pairItemIndex, 1)
        } else {
          let pairOrderIndex = this.findOrderIndexInItemFromOrder(
            pairItem,
            order
          )
          pairItem.orderNodes.splice(pairOrderIndex, 1)
        }
      }

      if (moveToUnassignedOrders) {
        // 未割付オーダーリストへ追加
        this.unassignedOrders.push(order)
      }
      setUnassignedOrderVariant(this)

      // 後処理
      this.clearAfterEdit(vehicle)
      vehicle.edited = true
      this.editState = EDIT_STATUS.EDITING
    },

    moveVehicleToVehicle(moveVehicle, targetVehicle) {
      console.log('moveVehicleToVehicle', moveVehicle, targetVehicle)
      // 最後尾に追加

      if (targetVehicle.vehicleIndex === moveVehicle.vehicleIndex) {
        // 同一輸送手段
        // いたちごっこ：処理不要
        // 移動先と移動元が同一の為、編集出来ません
        this.editErrorMessage = this.$store.getters['init/getMessage'](
          'D006_20_MOVE_INVALID_TO_ITSELF'
        )
        return false
      }
      // 処理可否チェック
      // 確定輸送手段
      if (targetVehicle.gantt.decisionFlag === DECISION_FLAG.FIXED) {
        // 確定済の為編集できません。
        this.editErrorMessage = this.$store.getters['init/getMessage'](
          'D006_20_EDITING_FIXED'
        )
        return false
      }

      // 前処理
      // 出発、帰着以外のトリップを取得
      let moveTrips = []
      moveVehicle.trips.forEach((trip) => {
        if (!this.isDepartureTrip(trip) && !this.isArrivalTrip(trip)) {
          moveTrips.push(trip)
        }
      })
      // 移動元輸送手段クリア
      moveVehicle.trips = []

      // 本処理
      // 移動元輸送手段の全トリップを移動先輸送手段に追加
      this.addTripsToVehicle(moveTrips, targetVehicle)

      // 後処理
      this.resetVehicleIndexToOwnTrips(targetVehicle)
      this.resetTripNoAndIndexToVehicle(targetVehicle)
      moveVehicle.edited = true
      targetVehicle.edited = true
      this.editState = EDIT_STATUS.EDITING

      return true
    },

    moveVehicleToTrip(moveVehicle, targetTrip) {
      console.log('moveVehicleToTrip', moveVehicle, targetTrip)
      // 移動先トリップの前に追加

      if (targetTrip.vehicleIndex === moveVehicle.vehicleIndex) {
        // 同一輸送手段
        // 同一輸送手段内のトリップの為、編集出来ません。
        this.editErrorMessage = this.$store.getters['init/getMessage'](
          'D006_20_MOVE_INVALID_VEHICLE_TO_OWN_TRIP'
        )
        return false
      }

      let targetVehicle = this.findVehicleNode(targetTrip.vehicleIndex)

      // 処理可否チェック
      // 確定輸送手段
      if (targetVehicle.gantt.decisionFlag === DECISION_FLAG.FIXED) {
        // 確定済の為編集できません。
        this.editErrorMessage = this.$store.getters['init/getMessage'](
          'D006_20_EDITING_FIXED'
        )
        return false
      }
      // 確定トリップ
      if (targetTrip.gantt.decisionFlag === DECISION_FLAG.FIXED) {
        // 確定済の為編集できません。
        this.editErrorMessage = this.$store.getters['init/getMessage'](
          'D006_20_EDITING_FIXED'
        )
        return false
      }

      // 前処理
      // 出発、帰着以外のトリップを取得
      let moveTrips = []
      moveVehicle.trips.forEach((trip) => {
        if (!this.isDepartureTrip(trip) && !this.isArrivalTrip(trip)) {
          moveTrips.push(trip)
        }
      })
      // 移動元輸送手段クリア
      moveVehicle.trips = []

      // 本処理
      // 移動元輸送手段の全トリップを移動先輸送手段の指定位置に挿入
      this.insertTripsToVehicle(moveTrips, targetVehicle, targetTrip.tripIndex)

      // 後処理
      this.resetVehicleIndexToOwnTrips(targetVehicle)
      this.resetTripNoAndIndexToVehicle(targetVehicle)
      moveVehicle.edited = true
      targetVehicle.edited = true
      this.editState = EDIT_STATUS.EDITING

      return true
    },

    moveVehicleToItem(moveVehicle, targetItem) {
      console.log('moveVehicleToItem', moveVehicle, targetItem)
      // 所属トリップの後ろに追加

      if (targetItem.vehicleIndex === moveVehicle.vehicleIndex) {
        // 同一輸送手段
        // 同一輸送手段内のトリップの為、編集出来ません。
        this.editErrorMessage = this.$store.getters['init/getMessage'](
          'D006_20_MOVE_INVALID_VEHICLE_TO_OWN_ITEM'
        )
        return false
      }

      let targetVehicle = this.findVehicleNode(targetItem.vehicleIndex)
      let nextPositionTargetTrip = null
      if (targetItem.tripIndex < targetVehicle.trips.length - 1) {
        nextPositionTargetTrip = targetVehicle.trips[targetItem.tripIndex + 1]
      }

      // 処理可否チェック
      // 確定輸送手段
      if (targetVehicle.gantt.decisionFlag === DECISION_FLAG.FIXED) {
        // 確定済の為編集できません。
        this.editErrorMessage = this.$store.getters['init/getMessage'](
          'D006_20_EDITING_FIXED'
        )
        return false
      }
      // 後ろのトリップが確定トリップ
      if (
        nextPositionTargetTrip &&
        nextPositionTargetTrip.gantt.decisionFlag === DECISION_FLAG.FIXED
      ) {
        // 確定済の為編集できません。
        this.editErrorMessage = this.$store.getters['init/getMessage'](
          'D006_20_EDITING_FIXED'
        )
        return false
      }

      if (nextPositionTargetTrip) {
        return this.moveVehicleToTrip(moveVehicle, nextPositionTargetTrip)
      } else {
        return this.moveVehicleToVehicle(moveVehicle, targetVehicle)
      }
    },

    moveTripToVehicle(moveTrip, targetVehicle) {
      console.log('moveTripToVehicle', moveTrip, targetVehicle)
      // 最後尾に追加

      // 処理可否チェック
      // 確定輸送手段
      if (targetVehicle.gantt.decisionFlag === DECISION_FLAG.FIXED) {
        // 確定済の為編集できません。
        this.editErrorMessage = this.$store.getters['init/getMessage'](
          'D006_20_EDITING_FIXED'
        )
        return false
      }

      let moveTripIndex = moveTrip.tripIndex
      let moveVehicle = this.findVehicleNode(moveTrip.vehicleIndex)
      let isSameVehicle = false

      if (targetVehicle.vehicleIndex === moveTrip.vehicleIndex) {
        // 同一輸送手段
        isSameVehicle = true
        if (moveTripIndex === this.findLastWorkTripIndex(targetVehicle)) {
          // いたちごっこ：処理不要
          // 選択した移動先への移動結果は移動前と同一です。
          this.editErrorMessage = this.$store.getters['init/getMessage'](
            'D006_20_MOVE_INVALID_RESULT_NOT_CHANGE'
          )
          return false
        }
      }

      // 本処理
      // 移動元トリップを切出す
      let movedTrip = moveVehicle.trips.splice(moveTripIndex, 1)[0]
      let moveTrips = []
      moveTrips.push(movedTrip)
      this.addTripsToVehicle(moveTrips, targetVehicle)

      // 後処理
      this.resetTripNoAndIndexToVehicle(targetVehicle)
      targetVehicle.edited = true
      if (!isSameVehicle) {
        this.resetVehicleIndexToTrip(movedTrip, targetVehicle)
        this.clearAfterEdit(moveVehicle)
        moveVehicle.edited = true
      }
      this.editState = EDIT_STATUS.EDITING

      return true
    },

    moveTripToTrip(moveTrip, targetTrip) {
      console.log('moveTripToTrip', moveTrip, targetTrip)
      // 移動先トリップの前に追加

      let targetVehicle = this.findVehicleNode(targetTrip.vehicleIndex)

      // 処理可否チェック
      // 確定輸送手段
      if (targetVehicle.gantt.decisionFlag === DECISION_FLAG.FIXED) {
        // 確定済の為編集できません。
        this.editErrorMessage = this.$store.getters['init/getMessage'](
          'D006_20_EDITING_FIXED'
        )
        return false
      }
      // 確定トリップ
      if (targetTrip.gantt.decisionFlag === DECISION_FLAG.FIXED) {
        // 確定済の為編集できません。
        this.editErrorMessage = this.$store.getters['init/getMessage'](
          'D006_20_EDITING_FIXED'
        )
        return false
      }

      let moveVehicle = this.findVehicleNode(moveTrip.vehicleIndex)
      let isSameVehicle = false
      let targetTripIndexOffset = 0

      if (targetTrip.vehicleIndex === moveTrip.vehicleIndex) {
        // 同一輸送手段
        isSameVehicle = true
        if (
          moveTrip.tripIndex === targetTrip.tripIndex ||
          moveTrip.tripIndex === targetTrip.tripIndex - 1
        ) {
          // いたちごっこ：処理不要
          // 選択した移動先への移動結果は移動前と同一です。
          this.editErrorMessage = this.$store.getters['init/getMessage'](
            'D006_20_MOVE_INVALID_RESULT_NOT_CHANGE'
          )
          return false
        }
        if (moveTrip.tripIndex < targetTrip.tripIndex) {
          targetTripIndexOffset = -1
        }
      }

      // 本処理
      // 移動元トリップを切出す
      let movedTrip = moveVehicle.trips.splice(moveTrip.tripIndex, 1)[0]
      // 移動先トリップの前に追加
      targetVehicle.trips.splice(
        targetTrip.tripIndex + targetTripIndexOffset,
        0,
        movedTrip
      )

      // 後処理
      this.resetTripNoAndIndexToVehicle(targetVehicle)
      targetVehicle.edited = true
      if (!isSameVehicle) {
        this.resetVehicleIndexToTrip(movedTrip, targetVehicle)
        this.clearAfterEdit(moveVehicle)
        moveVehicle.edited = true
      }
      this.editState = EDIT_STATUS.EDITING

      return true
    },

    moveTripToItem(moveTrip, targetItem) {
      console.log('moveTripToItem', moveTrip, targetItem)
      // 所属トリップの後ろに追加

      let targetVehicle = this.findVehicleNode(targetItem.vehicleIndex)
      let nextPositionTargetTrip = null
      if (targetItem.tripIndex < targetVehicle.trips.length - 1) {
        nextPositionTargetTrip = targetVehicle.trips[targetItem.tripIndex + 1]
      }

      // 処理可否チェック
      // 確定輸送手段
      if (targetVehicle.gantt.decisionFlag === DECISION_FLAG.FIXED) {
        // 確定済の為編集できません。
        this.editErrorMessage = this.$store.getters['init/getMessage'](
          'D006_20_EDITING_FIXED'
        )
        return false
      }
      // 後ろのトリップが確定トリップ
      if (
        nextPositionTargetTrip &&
        nextPositionTargetTrip.gantt.decisionFlag === DECISION_FLAG.FIXED
      ) {
        // 確定済の為編集できません。
        this.editErrorMessage = this.$store.getters['init/getMessage'](
          'D006_20_EDITING_FIXED'
        )
        return false
      }

      if (nextPositionTargetTrip) {
        return this.moveTripToTrip(moveTrip, nextPositionTargetTrip)
      } else {
        return this.moveTripToVehicle(moveTrip, targetVehicle)
      }
    },

    moveItemToVehicle(moveItem, targetVehicle) {
      console.log('moveItemToVehicle', moveItem, targetVehicle)
      // 最後尾に新規トリップを作成し、オーダーを格納

      // 処理可否チェック
      // 確定輸送手段
      if (targetVehicle.gantt.decisionFlag === DECISION_FLAG.FIXED) {
        // 確定済の為編集できません。
        this.editErrorMessage = this.$store.getters['init/getMessage'](
          'D006_20_EDITING_FIXED'
        )
        return false
      }

      let moveVehicle = this.findVehicleNode(moveItem.vehicleIndex)
      let moveTripIndex = moveItem.tripIndex
      let moveTrip = moveVehicle.trips[moveItem.tripIndex]
      let isSameVehicle = false
      let orderIdList = this.getOrderIdListInItem(moveItem)
      let hasOtherOrdersInTrip = this.hasOtherOrdersInTrip(
        orderIdList,
        moveTrip
      )

      if (targetVehicle.vehicleIndex === moveItem.vehicleIndex) {
        // 同一輸送手段
        isSameVehicle = true
        if (
          moveTripIndex === this.findLastWorkTripIndex(targetVehicle) &&
          !hasOtherOrdersInTrip
        ) {
          // 末尾トリップの積卸で、トリップ内に他の積卸は存在しない場合
          // いたちごっこ：処理不要
          // 選択した移動先への移動結果は移動前と同一です。
          this.editErrorMessage = this.$store.getters['init/getMessage'](
            'D006_20_MOVE_INVALID_RESULT_NOT_CHANGE'
          )
          return false
        }
      }

      // 本処理
      if (!hasOtherOrdersInTrip) {
        // トリップ内に他の積卸が存在しない場合、トリップを移動
        return this.moveTripToVehicle(moveTrip, targetVehicle)
      } else {
        // 新規トリップを作成
        let newTrip = makeTripNode(targetVehicle, 0, null)
        // 移動元積卸を切出す
        newTrip.items = this.extractItemsFromTrip(
          moveItem,
          targetVehicle,
          newTrip
        )
        // 作成したトリップを移動先輸送手段に追加
        let newTrips = []
        newTrips.push(newTrip)
        this.addTripsToVehicle(newTrips, targetVehicle)

        // 後処理
        this.resetTripNoAndIndexToVehicle(targetVehicle)
        targetVehicle.edited = true
        if (!isSameVehicle) {
          this.resetVehicleIndexToTrip(newTrip, targetVehicle)
          this.clearAfterEdit(moveVehicle)
          moveVehicle.edited = true
        }
        this.editState = EDIT_STATUS.EDITING

        return true
      }
    },

    moveItemToTrip(moveItem, targetTrip) {
      console.log('moveItemToTrip', moveItem, targetTrip)
      // 移動先トリップの前に新規トリップを作成し、追加

      let targetVehicle = this.findVehicleNode(targetTrip.vehicleIndex)

      // 処理可否チェック
      // 確定輸送手段
      if (targetVehicle.gantt.decisionFlag === DECISION_FLAG.FIXED) {
        // 確定済の為編集できません。
        this.editErrorMessage = this.$store.getters['init/getMessage'](
          'D006_20_EDITING_FIXED'
        )
        return false
      }
      // 確定トリップ
      if (targetTrip.gantt.decisionFlag === DECISION_FLAG.FIXED) {
        // 確定済の為編集できません。
        this.editErrorMessage = this.$store.getters['init/getMessage'](
          'D006_20_EDITING_FIXED'
        )
        return false
      }

      let targetTripIndex = targetTrip.tripIndex
      let moveVehicle = this.findVehicleNode(moveItem.vehicleIndex)
      let moveTripIndex = moveItem.tripIndex
      let moveTrip = moveVehicle.trips[moveItem.tripIndex]
      let isSameVehicle = false
      let orderIdList = this.getOrderIdListInItem(moveItem)
      let hasOtherOrdersInTrip = this.hasOtherOrdersInTrip(
        orderIdList,
        moveTrip
      )

      if (targetVehicle.vehicleIndex === moveItem.vehicleIndex) {
        // 同一輸送手段
        isSameVehicle = true
        if (
          (moveTripIndex === targetTripIndex ||
            moveTripIndex === targetTripIndex - 1) &&
          !hasOtherOrdersInTrip
        ) {
          // (移動先が同一トリップ又は、直後トリップ)且つ、トリップ内に他の積卸は存在しない場合
          // いたちごっこ：処理不要
          // 選択した移動先への移動結果は移動前と同一です。
          this.editErrorMessage = this.$store.getters['init/getMessage'](
            'D006_20_MOVE_INVALID_RESULT_NOT_CHANGE'
          )
          return false
        }
      }

      // 本処理
      if (!hasOtherOrdersInTrip) {
        // トリップ内に他の積卸は存在しない場合、トリップを移動
        return this.moveTripToTrip(moveTrip, targetTrip)
      } else {
        // 新規トリップを作成
        let newTrip = makeTripNode(targetVehicle, 0, null)
        // 移動元積卸を切出す
        newTrip.items = this.extractItemsFromTrip(
          moveItem,
          targetVehicle,
          newTrip
        )
        // 作成したトリップを移動先トリップの前に追加
        targetVehicle.trips.splice(targetTripIndex, 0, newTrip)

        // 後処理
        this.resetTripNoAndIndexToVehicle(targetVehicle)
        targetVehicle.edited = true
        if (!isSameVehicle) {
          this.resetVehicleIndexToTrip(newTrip, targetVehicle)
          this.clearAfterEdit(moveVehicle)
          moveVehicle.edited = true
        }
        this.editState = EDIT_STATUS.EDITING

        return true
      }
    },

    moveItemToItem(moveItem, targetItem) {
      console.log('moveItemToItem', moveItem, targetItem)
      // 荷積→荷卸、荷卸→荷積：移動不可
      // 荷積移動
      //   - 荷積：指定位置に追加
      //   - 荷卸：移動先トリップの荷卸の末尾に追加
      // 荷卸移動
      //   - 荷積：移動先トリップの荷積の末尾に追加
      //   - 荷卸：指定位置に追加

      if (moveItem.type !== targetItem.type) {
        // 荷積から荷卸、又は荷卸から荷積への移動はできません。
        this.editErrorMessage = this.$store.getters['init/getMessage'](
          'D006_20_MOVE_INVALID_ITEM_TO_DIFFERENT_TYPE_ITEM'
        )
        return false
      }

      if (
        targetItem.vehicleIndex === moveItem.vehicleIndex &&
        targetItem.tripIndex === moveItem.tripIndex
      ) {
        return this.moveItemInTrip(moveItem, targetItem)
      } else {
        let targetVehicle = this.findVehicleNode(targetItem.vehicleIndex)
        let targetTrip = targetVehicle.trips[targetItem.tripIndex]

        // 処理可否チェック
        // 確定輸送手段
        if (targetVehicle.gantt.decisionFlag === DECISION_FLAG.FIXED) {
          // 確定済の為編集できません。
          this.editErrorMessage = this.$store.getters['init/getMessage'](
            'D006_20_EDITING_FIXED'
          )
          return false
        }
        // 確定トリップ
        if (targetTrip.gantt.decisionFlag === DECISION_FLAG.FIXED) {
          // 確定済の為編集できません。
          this.editErrorMessage = this.$store.getters['init/getMessage'](
            'D006_20_EDITING_FIXED'
          )
          return false
        }

        let moveVehicle = this.findVehicleNode(moveItem.vehicleIndex)
        let moveTrip = moveVehicle.trips[moveItem.tripIndex]
        let isSameVehicle = targetVehicle.vehicleIndex === moveItem.vehicleIndex

        // 本処理
        // 移動元積卸を切出す
        let moveItems = this.extractItemsFromTrip(
          moveItem,
          targetVehicle,
          targetTrip
        )
        // 移動先トリップへ挿入
        this.insertItemsToTrip(moveItems, targetItem)

        // 後処理
        // 移動後移動元トリップが空っぽの場合、除去する
        if (moveTrip.items.length === 0) {
          moveVehicle.trips.splice(moveTrip.tripIndex, 1)
        }
        this.resetTripNoAndIndexToVehicle(targetVehicle)
        targetVehicle.edited = true
        if (!isSameVehicle) {
          this.resetVehicleIndexToTrip(targetTrip, targetVehicle)
          this.clearAfterEdit(moveVehicle)
          moveVehicle.edited = true
        }
        this.editState = EDIT_STATUS.EDITING

        return true
      }
    },

    moveItemInTrip(moveItem, targetItem) {
      console.log('moveItemInTrip', moveItem, targetItem)

      let vehicle = this.findVehicleNode(targetItem.vehicleIndex)
      let trip = vehicle.trips[targetItem.tripIndex]
      let targetItemIndex = this.findItemIndex(trip, targetItem)
      let moveItemIndex = this.findItemIndex(trip, moveItem)

      if (
        moveItemIndex === targetItemIndex + 1 &&
        moveItem.gantt.placeId !== targetItem.gantt.placeId
      ) {
        // 移動元が移動先の直後、且つ異なる場所の場合
        // いたちごっこ：処理不要
        // 選択した移動先への移動結果は移動前と同一です。
        this.editErrorMessage = this.$store.getters['init/getMessage'](
          'D006_20_MOVE_INVALID_RESULT_NOT_CHANGE'
        )
        return
      }

      console.log('placeId', moveItem.gantt.placeId, targetItem.gantt.placeId)

      if (moveItem.gantt.placeId === targetItem.gantt.placeId) {
        // 同一場所の場合、統合する
        moveItem.orderNodes.forEach((orderNode) => {
          targetItem.orderNodes.push(orderNode)
        })
        // 移動元を切出す
        trip.items.splice(moveItemIndex, 1)
      } else {
        let insertPosition =
          moveItemIndex < targetItemIndex
            ? targetItemIndex
            : targetItemIndex + 1
        if (targetItemIndex === trip.items.length - 1) {
          insertPosition = -1
        }

        // 移動元を切出す
        let item = trip.items.splice(moveItemIndex, 1)[0]
        if (insertPosition === -1) {
          trip.items.push(item)
        } else {
          trip.items.splice(insertPosition, 0, item)
        }
      }

      vehicle.edited = true
      this.editState = EDIT_STATUS.EDITING

      return true
    },

    moveOrdersToVehicle(orders, targetVehicle) {
      console.log('moveOrdersToVehicle', orders, targetVehicle)
      // 最後尾に新規トリップを作成し、オーダーを格納

      // 処理可否チェック
      // 確定輸送手段
      if (targetVehicle.gantt.decisionFlag === DECISION_FLAG.FIXED) {
        // 確定済の為編集できません。
        this.editErrorMessage = this.$store.getters['init/getMessage'](
          'D006_20_EDITING_FIXED'
        )
        return false
      }

      // 前提条件：ordersの要素は全て同一輸送手段内のオーダー
      let isSameVehicle = false
      let moveVehicle = this.findVehicleNode(
        this.assignedOrderSourceNodeDictionary[orders[0].planning_order_id]
          .vehicleIndex
      )
      if (targetVehicle.vehicleIndex === moveVehicle.vehicleIndex) {
        // 同一輸送手段
        isSameVehicle = true
      }

      // 本処理
      // 新規トリップを作成
      let newTrip = makeTripNode(targetVehicle, 0, null)
      // 積卸を生成
      newTrip.items = this.makeDepoDropItemsFromOrders(
        targetVehicle,
        newTrip,
        orders
      )
      // 作成したトリップを移動先輸送手段に追加
      let newTrips = []
      newTrips.push(newTrip)
      this.addTripsToVehicle(newTrips, targetVehicle)
      // 移動したオーダーを元の位置から削除
      orders.forEach((order) => {
        let itemNode = this.assignedOrderSourceNodeDictionary[
          order.planning_order_id
        ]
        if (itemNode.orderNodes.length === 1) {
          this.removeItem(itemNode)
        } else {
          this.removeOrderInItem(itemNode, order)
        }
        this.removeAssignedOrder(order)
      })

      // 後処理
      this.resetTripNoAndIndexToVehicle(targetVehicle)
      targetVehicle.edited = true
      if (!isSameVehicle) {
        this.resetVehicleIndexToTrip(newTrip, targetVehicle)
        this.clearAfterEdit(moveVehicle)
        moveVehicle.edited = true
      }
      this.editState = EDIT_STATUS.EDITING

      return true
    },

    moveOrdersToTrip(orders, targetTrip) {
      console.log('moveOrdersToTrip', orders, targetTrip)
      // 移動先トリップの前に新規トリップを作成し、追加

      let targetVehicle = this.findVehicleNode(targetTrip.vehicleIndex)

      // 処理可否チェック
      // 確定輸送手段
      if (targetVehicle.gantt.decisionFlag === DECISION_FLAG.FIXED) {
        // 確定済の為編集できません。
        this.editErrorMessage = this.$store.getters['init/getMessage'](
          'D006_20_EDITING_FIXED'
        )
        return false
      }
      // 確定トリップ
      if (targetTrip.gantt.decisionFlag === DECISION_FLAG.FIXED) {
        // 確定済の為編集できません。
        this.editErrorMessage = this.$store.getters['init/getMessage'](
          'D006_20_EDITING_FIXED'
        )
        return false
      }

      let targetTripIndex = targetTrip.tripIndex
      // 前提条件：ordersの要素は全て同一輸送手段内のオーダー
      let isSameVehicle = false
      let moveVehicle = this.findVehicleNode(
        this.assignedOrderSourceNodeDictionary[orders[0].planning_order_id]
          .vehicleIndex
      )
      if (targetVehicle.vehicleIndex === moveVehicle.vehicleIndex) {
        // 同一輸送手段
        isSameVehicle = true
      }

      // 本処理
      // 新規トリップを作成
      let newTrip = makeTripNode(targetVehicle, 0, null)
      // 積卸を生成
      newTrip.items = this.makeDepoDropItemsFromOrders(
        targetVehicle,
        newTrip,
        orders
      )
      // 作成したトリップを移動先トリップの前に追加
      targetVehicle.trips.splice(targetTripIndex, 0, newTrip)
      // 移動したオーダーを元の位置から削除
      orders.forEach((order) => {
        let itemNode = this.assignedOrderSourceNodeDictionary[
          order.planning_order_id
        ]
        if (itemNode.orderNodes.length === 1) {
          this.removeItem(itemNode)
        } else {
          this.removeOrderInItem(itemNode, order)
        }
        this.removeAssignedOrder(order)
      })

      // 後処理
      this.resetTripNoAndIndexToVehicle(targetVehicle)
      targetVehicle.edited = true
      if (!isSameVehicle) {
        this.resetVehicleIndexToTrip(newTrip, targetVehicle)
        this.clearAfterEdit(moveVehicle)
        moveVehicle.edited = true
      }
      this.editState = EDIT_STATUS.EDITING

      return true
    },

    moveOrdersToItem(orders, targetItem) {
      console.log('moveOrdersToItem', orders, targetItem)
      // 荷積移動
      //   - 荷積：指定位置に追加
      //   - 荷卸：移動先トリップの荷卸の末尾に追加
      // 荷卸移動
      //   - 荷積：移動先トリップの荷積の末尾に追加
      //   - 荷卸：指定位置に追加

      let targetVehicle = this.findVehicleNode(targetItem.vehicleIndex)
      let targetTrip = targetVehicle.trips[targetItem.tripIndex]

      // 処理可否チェック
      // 確定輸送手段
      if (targetVehicle.gantt.decisionFlag === DECISION_FLAG.FIXED) {
        // 確定済の為編集できません。
        this.editErrorMessage = this.$store.getters['init/getMessage'](
          'D006_20_EDITING_FIXED'
        )
        return false
      }
      // 確定トリップ
      if (targetTrip.gantt.decisionFlag === DECISION_FLAG.FIXED) {
        // 確定済の為編集できません。
        this.editErrorMessage = this.$store.getters['init/getMessage'](
          'D006_20_EDITING_FIXED'
        )
        return false
      }

      // 前提条件：ordersの要素は全て同一輸送手段内のオーダー
      let isSameVehicle = false
      let moveVehicle = this.findVehicleNode(
        this.assignedOrderSourceNodeDictionary[orders[0].planning_order_id]
          .vehicleIndex
      )
      if (targetVehicle.vehicleIndex === moveVehicle.vehicleIndex) {
        // 同一輸送手段
        isSameVehicle = true
      }

      // 本処理
      // 積卸を生成
      let moveItems = this.makeDepoDropItemsFromOrders(
        targetVehicle,
        targetTrip,
        orders
      )
      // 移動先トリップへ挿入
      this.insertItemsToTrip(moveItems, targetItem)
      // 移動したオーダーを元の位置から削除
      orders.forEach((order) => {
        let itemNode = this.assignedOrderSourceNodeDictionary[
          order.planning_order_id
        ]
        if (itemNode.orderNodes.length === 1) {
          this.removeItem(itemNode)
        } else {
          this.removeOrderInItem(itemNode, order)
        }
        this.removeAssignedOrder(order)
      })

      // 後処理
      this.resetTripNoAndIndexToVehicle(targetVehicle)
      targetVehicle.edited = true
      if (!isSameVehicle) {
        this.resetVehicleIndexToTrip(targetTrip, targetVehicle)
        this.clearAfterEdit(moveVehicle)
        moveVehicle.edited = true
      }
      this.editState = EDIT_STATUS.EDITING

      return true
    },

    changeLastDepoToDepo(lastDepoItem) {
      console.log('changeLastDepoToDepo', lastDepoItem)

      let vehicle = this.findVehicleNode(lastDepoItem.vehicleIndex)
      let firstWorkTrip = vehicle.trips[1]
      let startTrip = vehicle.trips[lastDepoItem.tripIndex]

      // 処理可否チェック
      // 確定輸送手段
      if (vehicle.gantt.decisionFlag === DECISION_FLAG.FIXED) {
        // 確定済の為編集できません。
        this.editErrorMessage = this.$store.getters['init/getMessage'](
          'D006_20_EDITING_FIXED'
        )
        return false
      }
      // 確定トリップ
      if (firstWorkTrip.gantt.decisionFlag === DECISION_FLAG.FIXED) {
        // 確定済の為編集できません。
        this.editErrorMessage = this.$store.getters['init/getMessage'](
          'D006_20_EDITING_FIXED'
        )
        return false
      }

      // 本処理
      // 宵積を切出す
      let lastDepoItemIndex = this.findItemIndex(startTrip, lastDepoItem)
      lastDepoItem = startTrip.items.splice(lastDepoItemIndex, 1)[0]
      // 荷積に変更
      changeNodeTypeToDepo(lastDepoItem)
      // 移動先トリップの先頭へ挿入
      firstWorkTrip.items.splice(0, 0, lastDepoItem)

      // 後処理
      this.resetTripNoAndIndexToVehicle(vehicle)
      vehicle.edited = true
      this.editState = EDIT_STATUS.EDITING

      return true
    },

    changeDepoToLastDepo(depoItem) {
      console.log('changeDepoToLastDepo', depoItem)

      let vehicle = this.findVehicleNode(depoItem.vehicleIndex)
      let firstWorkTrip = vehicle.trips[1]
      let startTrip = vehicle.trips[0]

      // 処理可否チェック
      // 確定輸送手段
      if (vehicle.gantt.decisionFlag === DECISION_FLAG.FIXED) {
        // 確定済の為編集できません。
        this.editErrorMessage = this.$store.getters['init/getMessage'](
          'D006_20_EDITING_FIXED'
        )
        return false
      }
      // 確定トリップ
      if (firstWorkTrip.gantt.decisionFlag === DECISION_FLAG.FIXED) {
        // 確定済の為編集できません。
        this.editErrorMessage = this.$store.getters['init/getMessage'](
          'D006_20_EDITING_FIXED'
        )
        return false
      }

      // 本処理
      // 荷積を切出す
      let depoItemIndex = this.findItemIndex(firstWorkTrip, depoItem)
      depoItem = firstWorkTrip.items.splice(depoItemIndex, 1)[0]
      // 宵積に変更
      changeNodeTypeToLastDepo(depoItem)
      // 移動先トリップの先頭へ挿入
      startTrip.items.splice(0, 0, depoItem)

      // 後処理
      this.resetTripNoAndIndexToVehicle(vehicle)
      vehicle.edited = true
      this.editState = EDIT_STATUS.EDITING

      return true
    },

    addTripsToVehicle(addTrips, targetVehicle) {
      // 前処理
      // 出発、帰着トリップ生成
      this.addDepartureArrivalTrip(targetVehicle)
      // 帰着トリップを切出す
      let arrivalTrip = this.popArrivalTrip(targetVehicle)

      // 本処理
      // 移動先輸送手段の最後尾に追加
      addTrips.forEach((trip) => {
        targetVehicle.trips.push(trip)
      })

      // 後処理
      // 帰着トリップを最後尾に追加
      if (arrivalTrip) {
        targetVehicle.trips.push(arrivalTrip)
      }
    },

    insertTripsToVehicle(insertTrips, targetVehicle, targetTripIndex) {
      // 前処理
      // 挿入位置以降のトリップを切出し
      let tailTrips = targetVehicle.trips.splice(
        targetTripIndex,
        targetVehicle.trips.length - targetTripIndex
      )

      // 本処理
      // 移動先輸送手段の最後尾に追加
      insertTrips.forEach((trip) => {
        targetVehicle.trips.push(trip)
      })

      // 後処理
      // 切出した挿入位置以降のトリップを最後尾に追加
      tailTrips.forEach((trip) => {
        targetVehicle.trips.push(trip)
      })
    },

    checkCanMoveOrders(orders) {
      // 不可：宵積オーダー
      if (
        orders.some((order) => {
          let itemNode = this.assignedOrderSourceNodeDictionary[
            order.planning_order_id
          ]
          if (
            itemNode.type === BOX_ITEM_KIND.LastDepo ||
            (itemNode.type === BOX_ITEM_KIND.Drop &&
              this.hasLastDepoOrder(itemNode))
          ) {
            return true
          }
        })
      ) {
        // 宵積オーダーは移動できません。
        this.editErrorMessage = this.$store.getters['init/getMessage'](
          'D006_20_MOVE_INVALID_LAST_DEPO'
        )
        return false
      }

      if (
        orders.some((order) => {
          let itemNode = this.assignedOrderSourceNodeDictionary[
            order.planning_order_id
          ]
          if (getNodeFixStatus(itemNode) === NODE_FIX_STATUS.OptimisticLocked) {
            return true
          }
        })
      ) {
        // ガント排他中の輸送手段のため、編集できません。
        this.editErrorMessage = this.$store.getters['init/getMessage'](
          'D006_20_MOVE_INVALID_OPTIMISTIC_LOCKED_VEHICLE'
        )
        return false
      }
      if (
        orders.some((order) => {
          let itemNode = this.assignedOrderSourceNodeDictionary[
            order.planning_order_id
          ]
          if (itemNode.gantt.decisionFlag === DECISION_FLAG.FIXED) {
            return true
          }
        })
      ) {
        // 確定済の為編集できません。
        this.editErrorMessage = this.$store.getters['init/getMessage'](
          'D006_20_EDITING_FIXED'
        )
        return false
      }

      return true
    },

    checkCanMoveMoveBox(boxInfo) {
      // 不可：未割付輸送手段
      if (
        boxInfo.type === BOX_ITEM_KIND.Vehicle &&
        boxInfo.trips.length === 0
      ) {
        // 未割付輸送手段は移動できません。
        this.editErrorMessage = this.$store.getters['init/getMessage'](
          'D006_20_MOVE_INVALID_UNASSIGNED_VEHICLE'
        )
        return false
      }
      // 不可：出発、到着、Info
      if (
        boxInfo.type === BOX_ITEM_KIND.DepoStart ||
        boxInfo.type === BOX_ITEM_KIND.DepoEnd ||
        boxInfo.type === BOX_ITEM_KIND.Info
      ) {
        // 輸送手段、トリップ、荷積、又は荷卸を選択してください。
        this.editErrorMessage = this.$store.getters['init/getMessage'](
          'D006_20_MOVE_INVALID_SOURCE_TYPE'
        )
        return false
      }
      // 不可：出発、帰着トリップ
      if (
        boxInfo.type === BOX_ITEM_KIND.Trip &&
        (this.isDepartureTrip(boxInfo) || this.isArrivalTrip(boxInfo))
      ) {
        // 輸送手段、トリップ、荷積、又は荷卸を選択してください。
        this.editErrorMessage = this.$store.getters['init/getMessage'](
          'D006_20_MOVE_INVALID_SOURCE_TYPE'
        )
        return false
      }
      // 不可：宵積がある輸送手段
      if (boxInfo.type === BOX_ITEM_KIND.Vehicle && this.hasLastDepo(boxInfo)) {
        // 宵積がある輸送手段は移動できません。
        this.editErrorMessage = this.$store.getters['init/getMessage'](
          'D006_20_MOVE_INVALID_VEHICLE_EXIST_LAST_DEPO'
        )
        return false
      }
      // 不可：宵積がある輸送手段の先頭トリップ
      if (
        boxInfo.type === BOX_ITEM_KIND.Trip &&
        boxInfo.tripIndex === 1 &&
        this.hasLastDepo(this.findVehicleNode(boxInfo.vehicleIndex))
      ) {
        // 宵積がある輸送手段の先頭トリップは移動できません。
        this.editErrorMessage = this.$store.getters['init/getMessage'](
          'D006_20_MOVE_INVALID_FIRST_TRIP_EXIST_LAST_DEPO'
        )
        return false
      }
      // 不可：宵積オーダーの荷卸
      if (
        boxInfo.type === BOX_ITEM_KIND.Drop &&
        this.hasLastDepoOrder(boxInfo)
      ) {
        // 宵積オーダーの荷卸は移動できません。
        this.editErrorMessage = this.$store.getters['init/getMessage'](
          'D006_20_MOVE_INVALID_DROP_OF_LAST_DEPO'
        )
        return false
      }

      // 状態
      if (getNodeFixStatus(boxInfo) === NODE_FIX_STATUS.OptimisticLocked) {
        // ガント排他中の輸送手段のため、編集できません。
        this.editErrorMessage = this.$store.getters['init/getMessage'](
          'D006_20_MOVE_INVALID_OPTIMISTIC_LOCKED_VEHICLE'
        )
        return false
      } else if (boxInfo.gantt.decisionFlag === DECISION_FLAG.FIXED) {
        // 確定済の為編集できません。
        this.editErrorMessage = this.$store.getters['init/getMessage'](
          'D006_20_EDITING_FIXED'
        )
        return false
      }
      if (boxInfo.type === BOX_ITEM_KIND.Vehicle) {
        if (
          boxInfo.trips.some((trip) => {
            return (
              trip.gantt.decisionFlag === DECISION_FLAG.FIXED ||
              trip.items.some((item) => {
                if (item.gantt.decisionFlag === DECISION_FLAG.FIXED) {
                  return true
                }
              })
            )
          })
        ) {
          // 確定済の為編集出来ません。
          this.editErrorMessage = this.$store.getters['init/getMessage'](
            'D006_20_EDITING_FIXED'
          )
          return false
        }
      }

      return true
    },

    checkCanMoveTargetBox(targetNode) {
      // 不可：出発、到着、Info
      if (
        targetNode.type === BOX_ITEM_KIND.DepoEnd ||
        targetNode.type === BOX_ITEM_KIND.Info
      ) {
        // 輸送手段、トリップ、荷積、又は荷卸を選択してください。
        this.editErrorMessage = this.$store.getters['init/getMessage'](
          'D006_20_MOVE_INVALID_TARGET_TYPE'
        )
        return false
      }
      // 不可：出発、帰着トリップ
      if (
        targetNode.type === BOX_ITEM_KIND.Trip &&
        (this.isDepartureTrip(targetNode) || this.isArrivalTrip(targetNode))
      ) {
        // 輸送手段、トリップ、荷積、又は荷卸を選択してください。
        this.editErrorMessage = this.$store.getters['init/getMessage'](
          'D006_20_MOVE_INVALID_TARGET_TYPE'
        )
        return false
      }

      if (getNodeFixStatus(targetNode) === NODE_FIX_STATUS.OptimisticLocked) {
        // ガント排他中の輸送手段のため、編集できません。
        this.editErrorMessage = this.$store.getters['init/getMessage'](
          'D006_20_MOVE_INVALID_OPTIMISTIC_LOCKED_VEHICLE'
        )
        return false
      }

      return true
    },

    checkCanMoveMoveBoxAndTargetBox(moveNode, targetNode) {
      if (moveNode === targetNode) {
        // 移動先と移動元が同一の為、編集出来ません
        this.editErrorMessage = this.$store.getters['init/getMessage'](
          'D006_20_MOVE_INVALID_TO_ITSELF'
        )
        return false
      }

      // 移動元が宵積の場合、先頭トリップのみ可(＝宵積解除)
      if (moveNode.type === BOX_ITEM_KIND.LastDepo) {
        if (
          targetNode.vehicleIndex !== moveNode.vehicleIndex ||
          targetNode.type !== BOX_ITEM_KIND.Trip ||
          targetNode.gantt.tripNo !== 1
        ) {
          // 宵積解除は「宵積」と同輸送手段内の「先頭のトリップ」を選択してください。
          this.editErrorMessage = this.$store.getters['init/getMessage'](
            'D006_20_MOVE_INVALID_LAST_DEPO_TO_NOT_OWN_FIRST_TRIP'
          )
          return false
        }
      }
      // 出発は、移動元が先頭荷積みの場合のみ可(＝宵積設定)
      if (targetNode.type === BOX_ITEM_KIND.DepoStart) {
        if (
          targetNode.vehicleIndex !== moveNode.vehicleIndex ||
          moveNode.type !== BOX_ITEM_KIND.Depo ||
          moveNode.gantt.tripNo !== 1 ||
          this.findItemIndex(
            this.findVehicleNode(moveNode.vehicleIndex).trips[
              moveNode.tripIndex
            ],
            moveNode
          ) !== 0
        ) {
          // 宵積設定は「先頭の荷積」と同輸送手段内の「出発」を選択してください。
          this.editErrorMessage = this.$store.getters['init/getMessage'](
            'D006_20_MOVE_INVALID_NOT_FIRST_DEPO_TO_DEPO_START'
          )
          return false
        }
      }
      // 宵積がある輸送手段の先頭トリップには移動不可
      // 但し、移動元が同輸送手段内の宵積の場合は可能
      if (
        targetNode.type === BOX_ITEM_KIND.Trip &&
        targetNode.tripIndex === 1 &&
        this.hasLastDepo(this.findVehicleNode(targetNode.vehicleIndex))
      ) {
        if (
          targetNode.vehicleIndex === moveNode.vehicleIndex &&
          moveNode.type === BOX_ITEM_KIND.LastDepo
        ) {
          // OK(＝宵積解除)
        } else {
          // 宵積がある輸送手段の先頭トリップには移動出来ません。
          this.editErrorMessage = this.$store.getters['init/getMessage'](
            'D006_20_MOVE_INVALID_NOT_LAST_DEPO_TO_FIRST_TRIP'
          )
          return false
        }
      }

      return true
    },

    checkCanMoveOrdersAndTargetBox(orders, targetNode) {
      // 宵積がある輸送手段の先頭トリップには移動不可
      if (
        targetNode.tripIndex === 1 &&
        this.hasLastDepo(this.findVehicleNode(targetNode.vehicleIndex))
      ) {
        // 宵積がある輸送手段の先頭トリップには移動出来ません。
        this.editErrorMessage = this.$store.getters['init/getMessage'](
          'D006_20_MOVE_INVALID_ORDER_TO_FIRST_TRIP_EXIST_LAST_DEPO'
        )
        return false
      }

      // 不可：同一トリップ内の移動
      if (
        (targetNode.type === BOX_ITEM_KIND.Depo ||
          targetNode.type === BOX_ITEM_KIND.Drop) &&
        orders.some((order) => {
          let itemNode = this.assignedOrderSourceNodeDictionary[
            order.planning_order_id
          ]
          if (
            targetNode.vehicleIndex === itemNode.vehicleIndex &&
            targetNode.tripIndex === itemNode.tripIndex
          ) {
            return true
          }
        })
      ) {
        // オーダーの同一トリップ内の移動は出来ません。
        this.editErrorMessage = this.$store.getters['init/getMessage'](
          'D006_20_MOVE_INVALID_ORDER_TO_SAME_TRIP_ITEM'
        )
        return false
      }

      return true
    },

    checkCanAssignTargetBox(boxInfo) {
      // 不可：出発、到着、Info
      if (
        boxInfo.type === BOX_ITEM_KIND.DepoStart ||
        boxInfo.type === BOX_ITEM_KIND.DepoEnd ||
        boxInfo.type === BOX_ITEM_KIND.Info
      ) {
        // 輸送手段、トリップ、荷積、又は荷卸を選択してください。
        this.editErrorMessage = this.$store.getters['init/getMessage'](
          'D006_20_ASSIGN_INVALID_TARGET_TYPE'
        )
        return false
      }
      // 不可：出発、帰着トリップ
      if (
        boxInfo.type === BOX_ITEM_KIND.Trip &&
        (this.isDepartureTrip(boxInfo) || this.isArrivalTrip(boxInfo))
      ) {
        // 輸送手段、トリップ、荷積、又は荷卸を選択してください。
        this.editErrorMessage = this.$store.getters['init/getMessage'](
          'D006_20_ASSIGN_INVALID_TARGET_TYPE'
        )
        return false
      }
      // 宵積がある輸送手段の先頭トリップには割付不可
      if (
        boxInfo.type === BOX_ITEM_KIND.Trip &&
        boxInfo.tripIndex === 1 &&
        this.hasLastDepo(this.findVehicleNode(boxInfo.vehicleIndex))
      ) {
        // 宵積がある輸送手段の先頭トリップには割付出来ません。
        this.editErrorMessage = this.$store.getters['init/getMessage'](
          'D006_20_ASSIGN_INVALID_TO_FIRST_TRIP_EXIST_LAST_DEPO'
        )
        return false
      }

      if (getNodeFixStatus(boxInfo) === NODE_FIX_STATUS.OptimisticLocked) {
        // ガント排他中の輸送手段のため、編集できません。
        this.editErrorMessage = this.$store.getters['init/getMessage'](
          'D006_20_MOVE_INVALID_OPTIMISTIC_LOCKED_VEHICLE'
        )
        return false
      }

      return true
    },

    checkCanUnassignNode(boxInfo) {
      // 不可：出発、到着、Info
      if (
        boxInfo.type === BOX_ITEM_KIND.DepoStart ||
        boxInfo.type === BOX_ITEM_KIND.DepoEnd ||
        boxInfo.type === BOX_ITEM_KIND.LastDepo ||
        boxInfo.type === BOX_ITEM_KIND.Info
      ) {
        // 輸送手段、トリップ、荷積、又は荷卸を選択してください。
        this.editErrorMessage = this.$store.getters['init/getMessage'](
          'D006_20_UNASSIGN_INVALID_TYPE'
        )
        return false
      }
      // 不可：出発、帰着トリップ
      if (
        boxInfo.type === BOX_ITEM_KIND.Trip &&
        (this.isDepartureTrip(boxInfo) || this.isArrivalTrip(boxInfo))
      ) {
        // 輸送手段、トリップ、荷積、又は荷卸を選択してください。
        this.editErrorMessage = this.$store.getters['init/getMessage'](
          'D006_20_UNASSIGN_INVALID_TYPE'
        )
        return false
      }
      // 不可：宵積がある輸送手段の先頭トリップ
      if (
        boxInfo.type === BOX_ITEM_KIND.Trip &&
        boxInfo.tripIndex === 1 &&
        this.hasLastDepo(this.findVehicleNode(boxInfo.vehicleIndex))
      ) {
        // 宵積がある輸送手段の先頭トリップは割付解除できません。
        this.editErrorMessage = this.$store.getters['init/getMessage'](
          'D006_20_UNASSIGN_INVALID_FIRST_TRIP_EXIST_LAST_DEPO'
        )
        return false
      }
      // 不可：宵積オーダーの荷卸
      if (
        boxInfo.type === BOX_ITEM_KIND.Drop &&
        this.hasLastDepoOrder(boxInfo)
      ) {
        // 宵積オーダーの荷卸は割付解除できません。
        this.editErrorMessage = this.$store.getters['init/getMessage'](
          'D006_20_UNASSIGN_INVALID_DROP_OF_LAST_DEPO'
        )
      }
      // 状態
      if (getNodeFixStatus(boxInfo) === NODE_FIX_STATUS.OptimisticLocked) {
        // ガント排他中の輸送手段のため、編集できません。
        this.editErrorMessage = this.$store.getters['init/getMessage'](
          'D006_20_MOVE_INVALID_OPTIMISTIC_LOCKED_VEHICLE'
        )
        return false
      }
      // 確定
      if (boxInfo.gantt.decisionFlag === DECISION_FLAG.FIXED) {
        // 確定済の為編集できません。
        this.editErrorMessage = this.$store.getters['init/getMessage'](
          'D006_20_EDITING_FIXED'
        )
        return false
      }
      if (boxInfo.type === BOX_ITEM_KIND.Vehicle) {
        if (
          boxInfo.trips.some((trip) => {
            return (
              trip.gantt.decisionFlag === DECISION_FLAG.FIXED ||
              trip.items.some((item) => {
                if (item.gantt.decisionFlag === DECISION_FLAG.FIXED) {
                  return true
                }
              })
            )
          })
        ) {
          // 確定済の為編集できません。
          this.editErrorMessage = this.$store.getters['init/getMessage'](
            'D006_20_EDITING_FIXED'
          )
          return false
        }
      }
      return true
    },

    checkCanUnassignOrders(orders) {
      // 不可：宵積オーダー
      if (
        orders.some((order) => {
          let itemNode = this.assignedOrderSourceNodeDictionary[
            order.planning_order_id
          ]
          if (
            itemNode.type === BOX_ITEM_KIND.LastDepo ||
            (itemNode.type === BOX_ITEM_KIND.Drop &&
              this.hasLastDepoOrder(itemNode))
          ) {
            return true
          }
        })
      ) {
        // 宵積オーダーは割付解除できません。
        this.editErrorMessage = this.$store.getters['init/getMessage'](
          'D006_20_UNASSIGN_INVALID_ORDER_OF_LAST_DEPO'
        )
        return false
      }

      if (
        orders.some((order) => {
          let itemNode = this.assignedOrderSourceNodeDictionary[
            order.planning_order_id
          ]
          if (getNodeFixStatus(itemNode) === NODE_FIX_STATUS.OptimisticLocked) {
            return true
          }
        })
      ) {
        // ガント排他中の輸送手段のため、編集できません。
        this.editErrorMessage = this.$store.getters['init/getMessage'](
          'D006_20_MOVE_INVALID_OPTIMISTIC_LOCKED_VEHICLE'
        )
        return false
      }
      if (
        orders.some((order) => {
          let itemNode = this.assignedOrderSourceNodeDictionary[
            order.planning_order_id
          ]
          if (itemNode.gantt.decisionFlag === DECISION_FLAG.FIXED) {
            return true
          }
        })
      ) {
        // 確定済の為編集できません。
        this.editErrorMessage = this.$store.getters['init/getMessage'](
          'D006_20_EDITING_FIXED'
        )
        return false
      }

      return true
    },

    resetVehicleIndexToOwnTrips(vehicleNode) {
      vehicleNode.trips.forEach((trip) => {
        this.resetVehicleIndexToTrip(trip, vehicleNode)
      })
    },

    resetVehicleIndexToTrip(trip, newVehicleNode) {
      trip.vehicleIndex = newVehicleNode.vehicleIndex
      trip.vehicle = newVehicleNode.vehicle
      trip.items.forEach((item) => {
        item.vehicleIndex = newVehicleNode.vehicleIndex
        item.vehicle = newVehicleNode.vehicle
      })
    },

    resetTripNoAndIndexToVehicle(vehicleNode) {
      let tripNo = 0
      let tripIndex = -1
      vehicleNode.trips.forEach((trip) => {
        ++tripIndex
        if (this.isDepartureTrip(trip) || this.isArrivalTrip(trip)) {
          this.resetTripNoAndIndexToTrip(trip, 0, tripIndex)
        } else {
          this.resetTripNoAndIndexToTrip(trip, ++tripNo, tripIndex)
        }
      })
    },

    resetTripNoAndIndexToTrip(trip, tripNo, tripIndex) {
      trip.gantt.tripNo = tripNo
      trip.tripIndex = tripIndex
      trip.items.forEach((item) => {
        item.gantt.tripNo = tripNo
        item.tripIndex = tripIndex
      })
    },

    clearAfterEdit(vehicle) {
      this.clearUnnessaryTrips(vehicle)
      this.resetTripNoAndIndexToVehicle(vehicle)
    },

    clearUnnessaryTrips(vehicle) {
      // 出発と帰着トリップのみ存在する場合、トリップを削除
      if (
        vehicle.trips.length === 2 &&
        this.hasDepartureTrip(vehicle) &&
        this.hasArrivalTrip(vehicle)
      ) {
        vehicle.trips = []
      }
    },

    findLastWorkTripIndex(vehicle) {
      if (vehicle.trips.length > 0) {
        if (this.isArrivalTrip(vehicle.trips[vehicle.trips.length - 1])) {
          if (
            vehicle.trips.length > 1 &&
            !this.isDepartureTrip(vehicle.trips[vehicle.trips.length - 2])
          ) {
            // 帰着トリップが存在する場合、帰着の直前トリップ
            return vehicle.trips.length - 2
          }
        } else {
          // 帰着トリップが存在しない場合、最後のトリップ
          return vehicle.trips.length - 1
        }
      }
      return -1
    },

    findItemIndex(trip, item) {
      for (let index = 0; index < trip.items.length; index++) {
        const itemInTrip = trip.items[index]
        if (itemInTrip === item) {
          return index
        }
      }
      return -1
    },

    findLastDepoItemIndex(trip) {
      for (let index = 0; index < trip.items.length; index++) {
        const itemInTrip = trip.items[index]
        if (itemInTrip.type === BOX_ITEM_KIND.Drop) {
          return index - 1
        }
      }
      return -1
    },

    findPairItemIndexes(findItem) {
      let pairItemIndexes = []
      let vehicle = this.findVehicleNode(findItem.vehicleIndex)
      let trip = vehicle.trips[findItem.tripIndex]
      for (let itemIndex = 0; itemIndex < trip.items.length; itemIndex++) {
        const item = trip.items[itemIndex]
        if (
          item.orderNodes.some((orderNode) => {
            for (
              let findOrderIndex = 0;
              findOrderIndex < findItem.orderNodes.length;
              findOrderIndex++
            ) {
              if (
                orderNode.order.planning_order_id ===
                findItem.orderNodes[findOrderIndex].order.planning_order_id
              ) {
                return true
              }
            }
          })
        ) {
          pairItemIndexes.push(itemIndex)
        }
      }

      return pairItemIndexes
    },

    findPairItemIndexFromOrder(findItem, findOrder) {
      let vehicle = this.findVehicleNode(findItem.vehicleIndex)
      let trip = vehicle.trips[findItem.tripIndex]
      for (let index = 0; index < trip.items.length; index++) {
        const item = trip.items[index]
        if (
          item !== findItem &&
          item.orderNodes.some((orderNode) => {
            if (
              orderNode.order.planning_order_id === findOrder.planning_order_id
            ) {
              return true
            }
          })
        ) {
          return index
        }
      }
      return -1
    },

    findOrderIndexInOrders(orders, order) {
      for (let index = 0; index < orders.length; index++) {
        const orderInOrders = orders[index]
        if (orderInOrders === order) {
          return index
        }
      }
      return -1
    },

    findOrderIndexesInItemFromOrderIds(item, findOrderIdList) {
      let foundOrderIndexes = []
      for (let index = 0; index < item.orderNodes.length; index++) {
        if (
          findOrderIdList.some((id) => {
            if (id === item.orderNodes[index].order.planning_order_id) {
              return true
            }
          })
        ) {
          foundOrderIndexes.push(index)
        }
      }
      return foundOrderIndexes
    },

    findOrderIndexInItemFromOrder(item, order) {
      for (let index = 0; index < item.orderNodes.length; index++) {
        if (
          item.orderNodes[index].order.planning_order_id ===
          order.planning_order_id
        ) {
          return index
        }
      }
      return -1
    },

    findSamePlaceItemInTrip(trip, placeId, nodeType) {
      for (let index = 0; index < trip.items.length; index++) {
        const item = trip.items[index]
        if (item.type === nodeType && item.gantt.placeId === placeId) {
          return item
        }
      }
      return null
    },

    extractItemsFromTrip(moveItem, targetVehicle, targetTrip) {
      let moveVehicle = this.findVehicleNode(moveItem.vehicleIndex)
      let moveTrip = moveVehicle.trips[moveItem.tripIndex]
      let pairItemIndexes = this.findPairItemIndexes(moveItem)
      let orderIdList = this.getOrderIdListInItem(moveItem)
      let moveItems = []
      pairItemIndexes.reverse().forEach((itemIndex) => {
        let currentItem = moveTrip.items[itemIndex]
        if (this.hasOtherOrdersInItem(orderIdList, currentItem)) {
          let newItem = null
          // 積卸に他のオーダーが存在する場合、該当オーダーのみ切出して、新しい積卸に格納する
          let moveOrderIndexes = this.findOrderIndexesInItemFromOrderIds(
            currentItem,
            orderIdList
          )
          moveOrderIndexes.reverse().forEach((orderIndex) => {
            let orderNode = currentItem.orderNodes.splice(orderIndex, 1)[0]
            if (!newItem) {
              if (currentItem.type === BOX_ITEM_KIND.Depo) {
                newItem = makeDepoNode(targetVehicle, targetTrip, null)
                newItem.gantt.placeId = orderNode.order.departure_place_id
                newItem.gantt.placeName = orderNode.order.departure_place_name
              } else {
                newItem = makeDropNode(targetVehicle, targetTrip, null)
                newItem.gantt.placeId = orderNode.order.arrival_place_id
                newItem.gantt.placeName = orderNode.order.arrival_place_name
              }
              moveItems.push(newItem)
            }
            newItem.orderNodes.push(orderNode)
          })
          newItem.orderNodes = newItem.orderNodes.reverse()
        } else {
          // 積卸に他のオーダーが存在しない場合、移動元積卸を切出す
          let item = moveTrip.items.splice(itemIndex, 1)[0]
          moveItems.push(item)
        }
      })
      moveItems = moveItems.reverse()
      return moveItems
    },

    addDepartureArrivalTrip(vehicleNode) {
      if (!this.hasDepartureTrip(vehicleNode)) {
        let trip = makeTripNode(vehicleNode, 0, null)
        vehicleNode.trips.splice(0, 0, trip)
        let item = makeDepoStartNode(vehicleNode, trip, null)
        item.gantt.placeId = vehicleNode.vehicle.vehicleBasePlaceId
        item.gantt.placeName = vehicleNode.vehicle.vehicleBasePlaceName
        trip.items.push(item)
      }
      if (!this.hasArrivalTrip(vehicleNode)) {
        let trip = makeTripNode(vehicleNode, 0, null)
        vehicleNode.trips.push(trip)
        let item = makeDepoEndNode(vehicleNode, trip, null)
        item.gantt.placeId = vehicleNode.vehicle.vehicleBasePlaceId
        item.gantt.placeName = vehicleNode.vehicle.vehicleBasePlaceName
        trip.items.push(item)
      }
    },

    insertItemsToTrip(insertItems, targetItem) {
      let targetVehicle = this.findVehicleNode(targetItem.vehicleIndex)
      let targetTrip = targetVehicle.trips[targetItem.tripIndex]

      let depoInsertPosition =
        targetItem.type === BOX_ITEM_KIND.Depo
          ? this.findItemIndex(targetTrip, targetItem)
          : this.findLastDepoItemIndex(targetTrip)
      let dropInsertPosition =
        targetItem.type === BOX_ITEM_KIND.Drop
          ? this.findItemIndex(targetTrip, targetItem)
          : -1

      // 宵積で、トリップ内に荷積が存在しない場合の補正
      if (depoInsertPosition === -1) {
        depoInsertPosition = 0
      }

      console.log(
        'insertItemsToTrip',
        insertItems,
        depoInsertPosition,
        dropInsertPosition
      )

      insertItems.reverse().forEach((item) => {
        if (item.type === BOX_ITEM_KIND.Drop) {
          let mergePlaceItem = null
          if (targetItem.type === BOX_ITEM_KIND.Drop) {
            if (
              targetTrip.items[dropInsertPosition].gantt.placeId ===
              item.gantt.placeId
            ) {
              mergePlaceItem = targetTrip.items[dropInsertPosition]
            }
            console.log(
              'Drop placeId',
              targetTrip.items[dropInsertPosition].gantt.placeId,
              item.gantt.placeId
            )
          } else {
            mergePlaceItem = this.findSamePlaceItemInTrip(
              targetTrip,
              item.gantt.placeId,
              BOX_ITEM_KIND.Drop
            )
            console.log('Drop findSamePlaceItemInTrip', mergePlaceItem)
          }

          if (mergePlaceItem) {
            console.log('Drop mergePlaceItem', mergePlaceItem)

            // 同一場所の場合、統合する
            item.orderNodes.forEach((orderNode) => {
              mergePlaceItem.orderNodes.push(orderNode)
            })
          } else {
            if (dropInsertPosition === -1) {
              targetTrip.items.push(item)
            } else {
              targetTrip.items.splice(dropInsertPosition++, 0, item)
            }
          }
        } else if (item.type === BOX_ITEM_KIND.Depo) {
          let mergePlaceItem = null
          if (targetItem.type === BOX_ITEM_KIND.Depo) {
            if (
              targetTrip.items[depoInsertPosition].gantt.placeId ===
              item.gantt.placeId
            ) {
              mergePlaceItem = targetTrip.items[depoInsertPosition]
            }
            console.log(
              'Depo placeId',
              targetTrip.items[depoInsertPosition].gantt.placeId,
              item.gantt.placeId
            )
          } else {
            mergePlaceItem = this.findSamePlaceItemInTrip(
              targetTrip,
              item.gantt.placeId,
              BOX_ITEM_KIND.Depo
            )
            console.log('Depo findSamePlaceItemInTrip', mergePlaceItem)
          }

          if (mergePlaceItem) {
            console.log('Depo mergePlaceItem', mergePlaceItem)
            // 同一場所の場合、統合する
            item.orderNodes.forEach((orderNode) => {
              mergePlaceItem.orderNodes.push(orderNode)
            })
          } else {
            targetTrip.items.splice(depoInsertPosition++, 0, item)
          }
        }
      })
    },

    /**
     * 帰着トリップの切出す
     */
    popArrivalTrip(vehicle) {
      if (vehicle.trips.length > 0) {
        let lastTrip = vehicle.trips[vehicle.trips.length - 1]
        let isArrivalTrip = lastTrip.items.some((item) => {
          return item.type === BOX_ITEM_KIND.DepoEnd
        })
        if (isArrivalTrip) {
          return vehicle.trips.pop()
        }
      }
      return null
    },

    makeTripFromOrders(vehicleNode, orders) {
      let newTrip = makeTripNode(vehicleNode, 0, null)
      let items = this.makeDepoDropItemsFromOrders(vehicleNode, newTrip, orders)
      items.forEach((item) => {
        newTrip.items.push(item)
      })

      return newTrip
    },

    makeDepoDropItemsFromOrders(vehicleNode, tripNode, orders) {
      let items = []
      // DEPO
      let depoItems = this.makeDepoItemFromOrders(vehicleNode, tripNode, orders)
      // DROP
      let dropItems = this.makeDropItemFromOrders(vehicleNode, tripNode, orders)
      // PUSH
      depoItems.forEach((item) => {
        items.push(item)
      })
      dropItems.forEach((item) => {
        items.push(item)
      })

      return items
    },

    makeDepoItemFromOrders(vehicleNode, tripNode, orders) {
      // DEPO
      let depoItems = []
      let sortedOrders = orders.sort(function (a, b) {
        return a.departure_place_id - b.departure_place_id
      })
      let prevItem = null
      sortedOrders.forEach((order) => {
        if (prevItem && prevItem.gantt.placeId === order.departure_place_id) {
          prevItem.orderNodes.push(
            makeOrderNode(vehicleNode, prevItem, order, null)
          )
        } else {
          let depoItem = makeDepoNode(vehicleNode, tripNode, null)
          depoItem.gantt.placeId = order.departure_place_id
          depoItem.gantt.placeName = order.departure_place_name
          depoItem.gantt.address = order.departure_address_full
          depoItem.orderNodes.push(
            makeOrderNode(vehicleNode, depoItem, order, null)
          )
          depoItems.push(depoItem)
          prevItem = depoItem
        }
      })
      return depoItems
    },

    makeDropItemFromOrders(vehicleNode, tripNode, orders) {
      // DROP
      let dropItems = []
      let sortedOrders = orders.sort(function (a, b) {
        return a.arrival_place_id - b.arrival_place_id
      })
      let prevItem = null
      sortedOrders.forEach((order) => {
        if (prevItem && prevItem.gantt.placeId === order.arrival_place_id) {
          prevItem.orderNodes.push(
            makeOrderNode(vehicleNode, prevItem, order, null)
          )
        } else {
          let dropItem = makeDropNode(vehicleNode, tripNode, null)
          dropItem.gantt.placeId = order.arrival_place_id
          dropItem.gantt.placeName = order.arrival_place_name
          dropItem.gantt.address = order.arrival_address_full
          dropItem.orderNodes.push(
            makeOrderNode(vehicleNode, dropItem, order, null)
          )
          dropItems.push(dropItem)
          prevItem = dropItem
        }
      })
      return dropItems
    },

    isDepartureTrip(trip) {
      return trip.items.some((item) => {
        return item.type === BOX_ITEM_KIND.DepoStart
      })
    },

    isArrivalTrip(trip) {
      return trip.items.some((item) => {
        return item.type === BOX_ITEM_KIND.DepoEnd
      })
    },

    hasLastDepoOrder(item) {
      let vehicle = this.findVehicleNode(item.vehicleIndex)
      if (this.hasLastDepo(vehicle)) {
        let lastDepoItem = vehicle.trips[0].items[0]
        let lastDepoOrderIdList = lastDepoItem.orderNodes.map(
          (orderNode) => orderNode.order.planning_order_id
        )
        return item.orderNodes.some((orderNode) => {
          return (
            lastDepoOrderIdList.indexOf(orderNode.order.planning_order_id) !==
            -1
          )
        })
      }
      return false
    },

    /**
     * 出発トリップが存在するか
     */
    hasDepartureTrip(vehicle) {
      if (vehicle.trips.length > 0) {
        let firstTrip = vehicle.trips[0]
        return this.isDepartureTrip(firstTrip)
      }
      return false
    },

    /**
     * 帰着トリップが存在するか
     */
    hasArrivalTrip(vehicle) {
      if (vehicle.trips.length > 0) {
        let lastTrip = vehicle.trips[vehicle.trips.length - 1]
        return this.isArrivalTrip(lastTrip)
      }
      return false
    },

    hasOtherOrdersInTrip(orderIdList, trip) {
      for (let index = 0; index < trip.items.length; index++) {
        if (this.hasOtherOrdersInItem(orderIdList, trip.items[index])) {
          return true
        }
      }
      return false
    },

    hasLastDepo(vehicle) {
      if (vehicle.trips.length > 0) {
        return vehicle.trips[0].items.some((item) => {
          return item.type === BOX_ITEM_KIND.LastDepo
        })
      }
      return false
    },

    hasOtherOrdersInItem(orderIdList, item) {
      let foundOtherOrders = false
      item.orderNodes.forEach((orderNode) => {
        if (
          !orderIdList.some((id) => {
            if (id === orderNode.order.planning_order_id) {
              return true
            }
          })
        ) {
          foundOtherOrders = true
        }
      })
      return foundOtherOrders
    },

    getOrderIdListInItem(item) {
      let orderIdList = []
      item.orderNodes.forEach((orderNode) => {
        orderIdList.push(orderNode.order.planning_order_id)
      })
      return orderIdList
    },

    onUnassignedOrderRowSelected(selectedItems) {
      if (selectedItems.length > 0) {
        this.$refs.assigned_order_list.clearSelected()
        this.selectedUnassignedOrders = selectedItems
        if (this.isAssignMode) {
          this.setEditMessageAssignFromUnassignedOrders(
            this.selectedUnassignedOrders
          )
        }
      } else {
        this.selectedUnassignedOrders = []
        if (this.isAssignMode) {
          this.setEditMessageAssign()
        }
      }
      console.log('onUnassignedOrderRowSelected', this.selectedUnassignedOrders)
    },

    onAssignedOrderRowSelected(selectedItems) {
      if (selectedItems.length > 0) {
        this.$refs.unassigned_order_list.clearSelected()
        this.selectedAssignedOrders = selectedItems
        if (this.isUnassignMode) {
          this.unassignOrders(this.selectedAssignedOrders)
        } else if (this.isMoveMode && this.selectedMoveBox === null) {
          this.setEditMessageMoveFromAssignedOrders(this.selectedAssignedOrders)
          if (!this.checkCanMoveOrders(this.selectedAssignedOrders)) {
            this.isMoveMode = false
            this.editMessage2 = ''
            return
          }
        }
      } else {
        this.selectedAssignedOrders = []
      }
      console.log('onAssignedOrderRowSelected', this.selectedAssignedOrders)
    },

    afterCloseSetSort(sortConditions) {
      this.selectedSortConditions = sortConditions

      if (
        sortConditions.some((element) => {
          if (element.order !== '0') {
            return true
          }
        })
      ) {
        this.vehicleNodeList = this.sortVehicleNodeList(
          this.selectedSortConditions,
          this.vehicleNodeList
        )
      } else {
        this.vehicleNodeList = this.vehicleNodeList.sort((a, b) => {
          return a.vehicleIndex - b.vehicleIndex
        })
      }
    },

    afterCloseSetUnassignedOrderSearch(searchCondition) {
      this.searchConditionUnassign = searchCondition
      if (this.searchConditionUnassign != null) {
        console.log('searchConditionUnassign', searchCondition)

        searchUnassigned(this)
      }
    },
  },

  computed: {
    messageInfo() {
      return this.$store.getters['init/getScreens'](this.SCREEN_ID.COMMON)(
        'message'
      )
    },
    isEditing() {
      return this.editState === EDIT_STATUS.EDITING
    },
    isChecked() {
      return this.editState === EDIT_STATUS.CHECKED
    },
    editStateName() {
      if (this.editState === EDIT_STATUS.EDITING) {
        return '編集中'
      } else if (this.editState === EDIT_STATUS.CHECKED) {
        return 'チェック済'
      } else {
        return ''
      }
    },
    toggleMoveVariant() {
      return this.isMoveMode ? 'danger' : 'primary'
    },
    toggleUnassignVariant() {
      return this.isUnassignMode ? 'danger' : 'primary'
    },
    toggleAssignVariant() {
      return this.isAssignMode ? 'danger' : 'primary'
    },
    toggleFixVariant() {
      return this.isFixMode ? 'danger' : 'primary'
    },
    toggleUnfixVariant() {
      return this.isUnfixMode ? 'danger' : 'primary'
    },
    _isMoveMode: {
      get() {
        return this.isMoveMode
      },
      set(v) {
        this.isMoveMode = v
        if (this.isMoveMode === true) {
          this.isUnassignMode = false
          this.isAssignMode = false
          this.isFixMode = false
          this.isUnfixMode = false
          this.setEditMessageMove()
          this.$refs.assigned_order_list.clearSelected()
        } else {
          this.clearEditMode()
        }
      },
    },
    _isUnassignMode: {
      get() {
        return this.isUnassignMode
      },
      set(v) {
        this.isUnassignMode = v
        if (this.isUnassignMode === true) {
          this.isMoveMode = false
          this.isAssignMode = false
          this.isFixMode = false
          this.isUnfixMode = false
          this.setEditMessageUnassign()
          this.$refs.assigned_order_list.clearSelected()
        } else {
          this.clearEditMode()
        }
      },
    },
    _isAssignMode: {
      get() {
        return this.isAssignMode
      },
      set(v) {
        this.isAssignMode = v
        if (this.isAssignMode === true) {
          this.isMoveMode = false
          this.isUnassignMode = false
          this.isFixMode = false
          this.isUnfixMode = false
          this.setEditMessageAssign()
        } else {
          this.clearEditMode()
        }
      },
    },
    _isFixMode: {
      get() {
        return this.isFixMode
      },
      set(v) {
        this.isFixMode = v
        if (this.isFixMode === true) {
          this.isMoveMode = false
          this.isUnassignMode = false
          this.isAssignMode = false
          this.isUnfixMode = false
          this.setEditMessageFix()
        } else {
          this.clearEditMode()
        }
      },
    },
    _isUnfixMode: {
      get() {
        return this.isUnfixMode
      },
      set(v) {
        this.isUnfixMode = v
        if (this.isUnfixMode === true) {
          this.isMoveMode = false
          this.isUnassignMode = false
          this.isAssignMode = false
          this.isFixMode = false
          this.setEditMessageUnfix()
        } else {
          this.clearEditMode()
        }
      },
    },
    detailInfo() {
      return this.$store.getters['init/getScreens'](screenId)('detail_info')
    },
    unassignedOrderFieldInfo() {
      return this.$store.getters['init/getFields'](
        this.SCREEN_ID.PLANNING_ORDER_LIST
      )(this.DOMAIN_NAME.FIELDS)
    },
    assignedOrderFieldInfo() {
      return this.$store.getters['init/getFields'](
        this.SCREEN_ID.PLANNING_ORDER_LIST
      )(this.DOMAIN_NAME.FIELDS)
    },
    warningFieldInfo() {
      return this.$store.getters['init/getFields'](
        this.SCREEN_ID.MANUAL_PLANNING_EDIT
      )('warning_fields')
    },
    isDataExist() {
      return (
        this.formData &&
        this.formData.ganttChartVehicleList &&
        this.formData.ganttChartVehicleList.length > 0
      )
    },
    isEditMode() {
      return (
        this.isMoveMode ||
        this.isUnassignMode ||
        this.isAssignMode ||
        this.isFixMode ||
        this.isUnfixMode
      )
    },
    isUnassignedOrderExist() {
      return this.unassignedOrders.length > 0
    },

    detailCtrlInfo() {
      return this.$store.getters['init/getScreens'](screenId)(
        'detail_ctrl_info'
      )
    },
    breadcrumbItems() {
      return getBreadcrumbItems(this.beforeScreenRouteInfo, screenId)
    },
    /**
     * ボタン情報リスト.
     * @return {Array}
     */
    buttonItems() {
      return [
        // ボタングループ#1
        [
          // 割付チェック
          {
            variant: 'success',
            icon: 'check-square',
            columnInfo: this.detailCtrlInfo.check,
            disabled:
              !this.isDataExist ||
              this.isEditMode ||
              this.isMapMode ||
              this.isGanttMode,
            onClickCallback: () => {
              check(this)
            },
          },
          // 地図
          {
            variant: 'success',
            icon: 'map',
            columnInfo: this.detailCtrlInfo.map,
            disabled: !this.isDataExist || this.isEditMode || this.isGanttMode,
            toggleLabel: '閉じる',
            toggleVariant: 'danger',
            isToggled: this.isMapMode,
            onClickCallback: () => {
              showMap(this)
            },
          },
          // ガントチャート
          {
            variant: 'success',
            icon: 'sliders-h',
            columnInfo: this.detailCtrlInfo.gantt,
            disabled: !this.isDataExist || this.isEditMode || this.isMapMode,
            toggleLabel: '閉じる',
            toggleVariant: 'danger',
            isToggled: this.isGanttMode,
            onClickCallback: () => {
              gotoGantt(this)
            },
          },
        ],
        // ボタングループ#2
        [
          // 確定
          {
            variant: 'success',
            icon: 'vote-yea',
            columnInfo: this.detailCtrlInfo.fix,
            disabled:
              !this.isDataExist ||
              this.isEditMode ||
              this.isMapMode ||
              this.isGanttMode,
            onClickCallback: () => {
              goFix(this)
            },
          },
          // 確定解除
          {
            variant: 'success',
            icon: 'undo',
            columnInfo: this.detailCtrlInfo.unfix,
            disabled:
              !this.isDataExist ||
              this.isEditMode ||
              this.isMapMode ||
              this.isGanttMode,
            onClickCallback: () => {
              goUnfix(this)
            },
          },
          // 登録
          {
            variant: 'success',
            icon: 'edit',
            columnInfo: this.detailCtrlInfo.register,
            disabled:
              !this.isDataExist ||
              this.isEditMode ||
              this.isMapMode ||
              this.isGanttMode,
            onClickCallback: () => {
              goRegister(this)
            },
          },
          // 戻るボタン
          {
            variant: 'success',
            icon: 'times-circle',
            columnInfo: this.detailCtrlInfo.back,
            disabled: this.isMapMode || this.isGanttMode,
            onClickCallback: () => {
              goCloseManualPlanningEdit(this)
            },
          },
        ],
      ]
    },
    infoMessage() {
      return this.$store.getters['init/getMessage'](
        'YZ00MG995I',
        this.detailCtrlInfo.register.label
      )
    },
  },
}
</script>
<style scoped lang="scss">
.chart {
  padding: 5px;
  max-width: calc(100% - 150px);
}

.chart-sidebar-on {
  max-width: calc(100% - 150px);
}

.chart-sidebar-off {
  max-width: 100%;
}

.list-group > span {
  width: 100%;
}

.list-group > span > div {
  float: left;
  position: relative;
}

.container {
  margin: 0 auto;
}

.firStyle {
  border: 1px solid black;
  margin-top: 1px;
  padding-left: 1px;
  width: 48px;
  float: left;
}

.checkSt {
  margin-top: 0px;
}
.lists {
  overflow-x: scroll;
  overflow-y: scroll;
  max-height: 400px;
}
.listSt {
  width: 100%;
}

.base-button {
  font-size: 0.8rem;
  margin-right: 4px;
  height: 32px;
}

.edit-button {
  width: 120px;
  margin-bottom: 4px;
}

.state-label {
  text-align: center;
  width: 100px;
  height: 30px;
  font-size: 16px;
  float: right;
  background-color: transparent;
  position: relative;
  margin: 1px;
  margin-right: 5px;
  padding-top: 3px;
}

.editing {
  border: 1px #000000 solid;
  background-color: orange;
}

.checked {
  border: 1px #000000 solid;
  background-color: yellowgreen;
}
</style>
