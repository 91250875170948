import {
  openMsgModal,
  //openSubModal,
  formatYYYYMMDD,
  //formatHHmmss,
  //formatYYYYYMMDDHHmm,
  displaYyyymmdd,
  displayHHmmss,
  displayYYYYYMMDDHHmm,
} from '@/common/Common.js'
import {
  SCREEN_ID,
  MSG_TYPE,
  DETAIL_SCREEN_KBN,
  //TRANSPORT_STATUS,
} from '@/common/const.js'
import router from '@/router'
import TransportRequestService from './TransportRequestService'
//import _ from 'lodash'

const screenId = SCREEN_ID.TRANSPORT_REQUEST_LIST
const detailScreenId = SCREEN_ID.TRANSPORT_REQUEST_DETAIL

export function init(obj) {
  // サーバーの処理後再検索
  if (obj.isReSearch == true) {
    reSearch(obj)
  }
}

export function reSearch(obj) {
  var searchCondition = obj.$store.getters['search/getSearchCondition'](
    screenId
  )
  if (searchCondition != null) {
    obj.searchCondition = JSON.parse(searchCondition)
    search(obj)
  }
}

/**
 * 場所を検索します.
 * @param {*} obj
 */
export function search(obj) {
  obj.searchCondition.departureBeginDate = formatYYYYMMDD(
    obj,
    obj.searchCondition.departureBeginDate
  )
  obj.searchCondition.departureEndDate = formatYYYYMMDD(
    obj,
    obj.searchCondition.departureEndDate
  )

  obj.searchCondition.arrivalBeginDate = formatYYYYMMDD(
    obj,
    obj.searchCondition.arrivalBeginDate
  )
  obj.searchCondition.arrivalEndDate = formatYYYYMMDD(
    obj,
    obj.searchCondition.arrivalEndDate
  )

  obj.$store.dispatch('init/setServiceLoading')
  TransportRequestService.search(obj.searchCondition)
    .then(
      (response) => {
        obj.tableItems = response.data.resultList

        obj.tableItems.forEach((element) => {
          element.departure_date = displaYyyymmdd(obj, element.departure_date)
          element.departure_time = displayHHmmss(element.departure_time)
          element.departure_date_time = displayYYYYYMMDDHHmm(
            obj,
            element.departure_date_time
          )
          element.arrival_date = displaYyyymmdd(obj, element.arrival_date)
          element.arrival_time = displayHHmmss(element.arrival_time)
          element.arrival_date_time = displayYYYYYMMDDHHmm(
            obj,
            element.arrival_date_time
          )
          element.real_vehicle_request_time = displayYYYYYMMDDHHmm(
            obj,
            element.real_vehicle_request_time
          )
          element.real_vehicle_entry_time = displayYYYYYMMDDHHmm(
            obj,
            element.real_vehicle_entry_time
          )
          element.planning_target_date = displaYyyymmdd(
            obj,
            element.planning_target_date
          )
          element.planning_decision_date = displaYyyymmdd(
            obj,
            element.planning_decision_date
          )
          element.planning_decision_time = displayHHmmss(
            element.planning_decision_time
          )

          //planningorder
          element.order_departure_begin_date = displaYyyymmdd(
            obj,
            element.order_departure_begin_date
          )
          element.order_departure_begin_time = displayHHmmss(
            element.order_departure_begin_time
          )
          element.order_departure_end_date = displaYyyymmdd(
            obj,
            element.order_departure_end_date
          )
          element.order_departure_end_time = displayHHmmss(
            element.order_departure_end_time
          )

          element.order_arrival_begin_date = displaYyyymmdd(
            obj,
            element.order_arrival_begin_date
          )
          element.order_arrival_begin_time = displayHHmmss(
            element.order_arrival_begin_time
          )
          element.order_arrival_end_date = displaYyyymmdd(
            obj,
            element.order_arrival_end_date
          )
          element.order_arrival_end_time = displayHHmmss(
            element.order_arrival_end_time
          )
        })

        if (obj.tableItems.length < 1) {
          // 警告モーダル表示
          openMsgModal(
            obj,
            MSG_TYPE.W,
            obj.$store.getters['init/getMessage']('YZ00MG907I')
          )
        }
      },
      (e) => {
        var msg
        if (e.response == undefined) {
          msg = obj.$store.getters['init/getMessage']('YZ00MG946E')
        } else {
          msg = e.response.data.message
        }
        // エラーモーダル表示
        openMsgModal(obj, MSG_TYPE.E, msg)
      }
    )
    .finally(() => {
      obj.$store.dispatch('init/setServiceUnLoading')
    })

  var payload = {
    viewId: screenId,
    params: JSON.stringify(obj.searchCondition),
  }
  obj.$store.dispatch('search/setSearchCondition', payload)
}

/**
 * 検索項目をクリアします.
 */
export function clear(obj) {
  obj.searchCondition = obj.defaultSearchCondition()

  var payload = {
    viewId: screenId,
    params: JSON.stringify(obj.searchCondition),
  }
  obj.$store.dispatch('search/setSearchCondition', payload)
}

// 「詳細」ボタンクリック
export function goDetail(obj) {
  if (obj.selectedItem.length != 1) {
    openMsgModal(
      obj,
      MSG_TYPE.E,
      obj.$store.getters['init/getMessage']('YZ00MG901E')
    )
  } else {
    router.push({
      name: detailScreenId,
      params: {
        planningResultId: obj.selectedItem[0].planning_result_id,
        screenKbn: DETAIL_SCREEN_KBN.DETAIL,
        beforeScreenRouteInfo: {
          routeInfo: {
            name: screenId,
            params: { isReSearch: true },
          },
        },
      },
    })
  }
}
